import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import RoleName from './RoleNameEnum'

const RoleLookUp = types.model('RoleLookUp', {
  roleName: types.enumeration<RoleName>(Object.values(RoleName)),
  usersCount: types.integer,
})

export interface IRoleLookUp extends Instance<typeof RoleLookUp> {}
export interface IRoleLookUpSnapshotIn extends SnapshotIn<typeof RoleLookUp> {}
export interface IRoleLookUpSnapshotOut
  extends SnapshotOut<typeof RoleLookUp> {}
export default RoleLookUp
