import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../../PagedListRequestModel'

const ServiceHistoryListRequestModel = PagedListRequestModel.named(
  'OrganizationsListRequestModel',
).props({
  sortBy: types.optional(types.string, ''),
  filter: types.optional(
    types.model({
      organizationId: types.union(types.number, types.undefined, types.null),
      beneficiaryIndividualFullName: types.union(
        types.string,
        types.undefined,
        types.null,
      ),
      beneficiaryIndividualCode: types.union(
        types.string,
        types.undefined,
        types.null,
      ),
      serviceTypeId: types.union(types.number, types.undefined, types.null),
      statusId: types.union(types.number, types.undefined, types.null),
      regionId: types.union(types.number, types.undefined, types.null),
      relatedToCovid19: types.union(types.boolean, types.undefined, types.null),
      serviceDeliveredStaffId: types.union(
        types.number,
        types.undefined,
        types.null,
      ),
    }),
    {},
  ),
})

export interface IServiceHistoryListRequestModel
  extends Instance<typeof ServiceHistoryListRequestModel> {}

export interface IServiceHistoryListRequestModelIn
  extends SnapshotIn<typeof ServiceHistoryListRequestModel> {}

export interface IServiceHistoryListRequestModelOut
  extends SnapshotOut<typeof ServiceHistoryListRequestModel> {}

export default ServiceHistoryListRequestModel
