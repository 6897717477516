import { forwardRef, MouseEventHandler, ReactNode } from 'react'
import './style.scss'

const CustomToggle = forwardRef<
  HTMLButtonElement,
  {
    name: ReactNode
    onClick?: MouseEventHandler
    className?: string
    withArrow?: boolean
    disabled?: boolean
  }
>(({ onClick, className, name, disabled = false, withArrow = true }, ref) => (
  <button
    className={`custom-toggle ${className}`}
    ref={ref}
    disabled={disabled}
    onClick={e => {
      onClick?.(e)
      e.stopPropagation()
    }}
  >
    <span className={withArrow ? 'pr-2' : ''}>{name}</span>
    {withArrow && <i className="ms-Icon ms-Icon--ChevronDown" />}
  </button>
))

export default CustomToggle
