import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import IndicatorReportsFilterBaseModel from '../IndicatorReportsFilterBaseModel'

const Indicator1_1_1ReportFilterModel = IndicatorReportsFilterBaseModel.named(
  'Indicator1_1_1ReportFilterModel',
).props({
  AdvocacyInfluenceLevelId: types.union(
    types.number,
    types.undefined,
    types.null
  ),
  DocumentTypeId: types.union(types.number, types.undefined, types.null),
})

export interface IIndicator1_1_1ReportFilterModel
  extends Instance<typeof Indicator1_1_1ReportFilterModel> {}

export interface IIndicator1_1_1ReportFilterSnapshotIn
  extends SnapshotIn<typeof Indicator1_1_1ReportFilterModel> {}

export interface IIndicator1_1_1ReportFilterSnapshotOut
  extends SnapshotOut<typeof Indicator1_1_1ReportFilterModel> {}

export default Indicator1_1_1ReportFilterModel
