import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const CatalogModel = types.model('CatalogModel', {
  id: types.number,
  name: types.maybeNull(types.string),
})

export interface ICatalog extends Instance<typeof CatalogModel> {}
export interface ICatalogSnapshotIn extends SnapshotIn<typeof CatalogModel> {}
export interface ICatalogSnapshotOut extends SnapshotOut<typeof CatalogModel> {}
export default CatalogModel
