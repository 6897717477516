import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const CreateServiceHistoryCommand = types.model('CreateServiceHistoryCommand', {
  organizationId: types.integer,
  beneficiaryIndividualIds: types.array(types.integer),
  serviceTypeId: types.integer,
  serviceTypeComment: types.maybeNull(types.string),
  regionId: types.integer,
  relatedToCovid19: types.boolean,
  serviceDeliveredStaffId: types.integer,
  serviceDeliveryDate: types.string,
})

export interface ICreateServiceHistoryCommand
  extends Instance<typeof CreateServiceHistoryCommand> {}
export interface ICreateServiceHistoryCommandSnapshotIn
  extends SnapshotIn<typeof CreateServiceHistoryCommand> {}
export interface ICreateServiceHistoryCommandSnapshotOut
  extends SnapshotOut<typeof CreateServiceHistoryCommand> {}
export default CreateServiceHistoryCommand
