import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const AllIndicatorsModel = types.model('AllIndicatorsModel', {
  id: types.optional(types.integer, 0),
  indicatorNumber: types.maybeNull(types.string),
  responsiblePersonFullName: types.maybeNull(types.string),
  createdDateTime: types.string,
  countryId: types.integer,
  country: types.string,
  countryParentId: types.maybeNull(types.integer),
  regionId: types.maybeNull(types.integer),
  region: types.maybeNull(types.string),
  canBePended: types.boolean,
  canBeEdited: types.boolean,
  canBeDeleted: types.boolean,
  status: types.maybeNull(types.string),
  statusId: types.integer,
})

export interface IAllIndicatorsModel
  extends Instance<typeof AllIndicatorsModel> {}
export interface IAllIndicatorsModelIn
  extends SnapshotIn<typeof AllIndicatorsModel> {}
export interface IAllIndicatorsModelOut
  extends SnapshotOut<typeof AllIndicatorsModel> {}
export default AllIndicatorsModel
