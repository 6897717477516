import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import IndicatorReportsFilterBaseModel from '../IndicatorReportsFilterBaseModel'

const Indicator3_2_2ReportFilterModel = IndicatorReportsFilterBaseModel.named(
  'Indicator3_2_2ReportFilterModel',
).props({
  GenderId: types.union(types.number, types.undefined, types.null),
  AgeCategoryEnum: types.union(types.number, types.undefined, types.null),
  EthnicityId: types.union(types.number, types.undefined, types.null),
  ServiceTypeId: types.union(types.number, types.undefined, types.null),
})

export interface IIndicator3_2_2ReportFilterModel
  extends Instance<typeof Indicator3_2_2ReportFilterModel> {}

export interface IIndicator3_2_2ReportFilterSnapshotIn
  extends SnapshotIn<typeof Indicator3_2_2ReportFilterModel> {}

export interface IIndicator3_2_2ReportFilterSnapshotOut
  extends SnapshotOut<typeof Indicator3_2_2ReportFilterModel> {}

export default Indicator3_2_2ReportFilterModel
