import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const BeneficiaryCategoryLookUpModel = LookUpModel.named(
  'BeneficiaryCategoryLookUpModel',
)

export interface IBeneficiaryCategoryLookUp
  extends Instance<typeof BeneficiaryCategoryLookUpModel> {}

export interface IBeneficiaryCategoryLookUpSnapshotIn
  extends SnapshotIn<typeof BeneficiaryCategoryLookUpModel> {}

export interface IBeneficiaryCategoryLookUpSnapshotOut
  extends SnapshotOut<typeof BeneficiaryCategoryLookUpModel> {}

export default BeneficiaryCategoryLookUpModel
