import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import IndicatorReportsFilterBaseModel from '../IndicatorReportsFilterBaseModel'

const Indicator2_2_2ReportFilterModel = IndicatorReportsFilterBaseModel.named(
  'Indicator2_2_2ReportFilterModel',
).props({
  ActivityTypeId: types.union(types.number, types.undefined, types.null),
})

export interface IIndicator2_2_2ReportFilterModel
  extends Instance<typeof Indicator2_2_2ReportFilterModel> {}

export interface IIndicator2_2_2ReportFilterSnapshotIn
  extends SnapshotIn<typeof Indicator2_2_2ReportFilterModel> {}

export interface IIndicator2_2_2ReportFilterSnapshotOut
  extends SnapshotOut<typeof Indicator2_2_2ReportFilterModel> {}

export default Indicator2_2_2ReportFilterModel
