import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../PagedListRequestModel'

const OrganizationsListRequestModel = PagedListRequestModel.named(
  'OrganizationsListRequestModel',
).props({
  sortBy: types.optional(types.string, ''),
  filter: types.optional(
    types.model({
      countryId: types.union(types.number, types.undefined, types.null),
      organizationTypeId: types.union(
        types.number,
        types.undefined,
        types.null,
      ),
    }),
    {},
  ),
})

export interface IOrganizationsListRequestModel
  extends Instance<typeof OrganizationsListRequestModel> {}

export interface IOrganizationsListRequestSnapshotIn
  extends SnapshotIn<typeof OrganizationsListRequestModel> {}

export interface IOrganizationsListRequestSnapshotOut
  extends SnapshotOut<typeof OrganizationsListRequestModel> {}

export default OrganizationsListRequestModel
