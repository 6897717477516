import { useState, useEffect, FC, MouseEventHandler, ReactNode } from 'react'
import classes from './style.module.scss'

type CheckBoxProps = {
  status: boolean
  className?: string
  isDisabled?: boolean
  round?: boolean
  checkboxCross?: boolean
  roundWithoutCheck?: boolean
  stopPropagation?: boolean
  actionClick?: (isChecked: boolean) => void
  label?: ReactNode
  wrapperClasses?: string
}

const CheckBox: FC<CheckBoxProps> = ({
  status,
  className,
  actionClick,
  round = false,
  checkboxCross = false,
  roundWithoutCheck = false,
  stopPropagation = false,
  isDisabled = false,
  label,
  wrapperClasses,
}) => {
  const [check, setCheck] = useState<boolean>(false)

  const checkHandler: MouseEventHandler = event => {
    if (isDisabled) return
    stopPropagation && event.stopPropagation()
    const isChecked = !check
    setCheck(isChecked)
    actionClick?.(isChecked)
  }

  useEffect(() => {
    setCheck(status)
  }, [status])

  const checkBoxClasses = [
    className,
    check ? classes.checked : null,
    round ? classes.round : classes.square,
    roundWithoutCheck ? classes.roundWithoutCheck : '',
    checkboxCross ? classes.cross : classes.mark,
    classes.checkbox,
    isDisabled ? (check ? classes.gray : classes.disabled) : '',
  ].join(' ')

  return !!label ? (
    <div
      onClick={e => actionClick && checkHandler(e)}
      className={[classes.wrapper, wrapperClasses].join(' ')}
    >
      <div className={checkBoxClasses} />
      <span className={classes.label}>{label}</span>
    </div>
  ) : (
    <div
      onClick={e => actionClick && checkHandler(e)}
      className={checkBoxClasses}
    />
  )
}

export default CheckBox
