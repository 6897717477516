import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const Indicator3_2_2ReportModel = types.model('Indicator3_2_2ReportModel', {
  code: types.maybeNull(types.string),
  age: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  ethnicity: types.maybeNull(types.string),
  categories: types.maybeNull(types.string),
  partner: types.maybeNull(types.string),
  serviceType: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  countryOrigin: types.maybeNull(types.string),
})

export interface IIndicator3_2_2ReportModel
  extends Instance<typeof Indicator3_2_2ReportModel> {}
export interface IIndicator3_2_2ReportSnapshotIn
  extends SnapshotIn<typeof Indicator3_2_2ReportModel> {}
export interface IIndicator3_2_2ReportSnapshotOut
  extends SnapshotOut<typeof Indicator3_2_2ReportModel> {}
export default Indicator3_2_2ReportModel
