import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { apiV1 } from 'core/requests'
import {
  Indicator1_1_1ReportPagedModel,
  IIndicator1_1_1ReportPagedSnapshotIn,
  IIndicator1_1_1ReportRequestSnapshotIn,
  Indicator1_1_1ReportRequestModel,
  IIndicator1_1_1ReportPagedSnapshotOut,
  ExportFilesType,
  Indicator1_2_1ReportPagedModel,
  IIndicator1_2_1ReportPagedSnapshotIn,
  IIndicator1_2_1ReportRequestSnapshotIn,
  Indicator1_2_1ReportRequestModel,
  IIndicator1_2_1ReportPagedSnapshotOut,
  Indicator1_2_2ReportPagedModel,
  IIndicator1_2_2ReportPagedSnapshotIn,
  Indicator1_2_2ReportRequestModel,
  IIndicator1_2_2ReportRequestSnapshotIn,
  IIndicator1_2_2ReportPagedSnapshotOut,
  IIndicator2_2_2ReportPagedSnapshotOut,
  IIndicator2_2_2ReportPagedSnapshotIn,
  Indicator2_2_2ReportPagedModel,
  IIndicator2_2_2ReportRequestSnapshotIn,
  Indicator2_2_2ReportRequestModel,
  IIndicator2_2_1ReportPagedSnapshotIn,
  Indicator2_2_1ReportPagedModel,
  Indicator3_2_1ReportPagedModel,
  IIndicator2_2_1ReportPagedSnapshotOut,
  IIndicator2_2_1ReportRequestSnapshotIn,
  IIndicator3_2_1ReportPagedSnapshotIn,
  IIndicator3_2_1ReportPagedSnapshotOut,
  IIndicator3_2_1ReportRequestSnapshotIn,
  Indicator2_2_1ReportRequestModel,
  Indicator3_2_1ReportRequestModel,
  IIndicator2_2_3ReportPagedSnapshotIn,
  IIndicator2_2_3ReportPagedSnapshotOut,
  IIndicator2_2_3ReportRequestSnapshotIn,
  Indicator2_2_3ReportPagedModel,
  Indicator2_2_3ReportRequestModel,
  Indicator4_1_1ReportPagedModel,
  IIndicator4_1_1ReportPagedSnapshotIn,
  IIndicator4_1_1ReportRequestSnapshotIn,
  Indicator4_1_1ReportRequestModel,
  IIndicator4_1_1ReportPagedSnapshotOut,
  IIndicator3_1_1ReportPagedSnapshotIn,
  IIndicator3_1_1ReportPagedSnapshotOut,
  IIndicator3_1_1ReportRequestSnapshotIn,
  Indicator3_1_1ReportPagedModel,
  Indicator3_1_1ReportRequestModel,
  IIndicator2_1_2ReportPagedSnapshotIn,
  IIndicator2_1_2ReportPagedSnapshotOut,
  IIndicator2_1_2ReportRequestSnapshotIn,
  Indicator2_1_2ReportPagedModel,
  Indicator2_1_2ReportRequestModel,
  IIndicator3_2_2ReportPagedSnapshotIn,
  IIndicator3_2_2ReportPagedSnapshotOut,
  IIndicator3_2_2ReportRequestSnapshotIn,
  Indicator3_2_2ReportPagedModel,
  Indicator3_2_2ReportRequestModel,
  IIndicatorExportReportsRequest,
  IIndicator2_1_1ReportPagedSnapshotIn,
  IIndicator2_1_1ReportPagedSnapshotOut,
  IIndicator2_1_1ReportRequestSnapshotIn,
  Indicator2_1_1ReportPagedModel,
  Indicator2_1_1ReportRequestModel,
  IIndicatorVulnerableMigrantReportPagedSnapshotIn,
  IIndicatorVulnerableMigrantReportPagedSnapshotOut,
  IIndicatorVulnerableMigrantReportRequestSnapshotIn,
  IndicatorVulnerableMigrantReportPagedModel,
  IndicatorVulnerableMigrantReportRequestModel,
} from 'stores/models'
import { when } from 'mobx'
import {
  capitalizeFirstLetter,
  getFileNameFromContentDisposition,
} from 'core/utils'
import fileDownload from 'js-file-download'

const IndicatorsReportsStore = types
  .model('IndicatorsReportsStore')
  .props({
    _indicator1_1_1Report: types.optional(Indicator1_1_1ReportPagedModel, {}),
    _indicator1_2_1Report: types.optional(Indicator1_2_1ReportPagedModel, {}),
    _indicator1_2_2Report: types.optional(Indicator1_2_2ReportPagedModel, {}),
    _indicator2_1_1Report: types.optional(Indicator2_1_1ReportPagedModel, {}),
    _indicator2_1_2Report: types.optional(Indicator2_1_2ReportPagedModel, {}),
    _indicator2_2_1Report: types.optional(Indicator2_2_1ReportPagedModel, {}),
    _indicator2_2_2Report: types.optional(Indicator2_2_2ReportPagedModel, {}),
    _indicator2_2_3Report: types.optional(Indicator2_2_3ReportPagedModel, {}),
    _indicator3_1_1Report: types.optional(Indicator3_1_1ReportPagedModel, {}),
    _indicator3_2_1Report: types.optional(Indicator3_2_1ReportPagedModel, {}),
    _indicator3_2_2Report: types.optional(Indicator3_2_2ReportPagedModel, {}),
    _indicator4_1_1Report: types.optional(Indicator4_1_1ReportPagedModel, {}),
    _indicatorVulnerableMigrantReport: types.optional(IndicatorVulnerableMigrantReportPagedModel, {}),
    _isLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setIndicator1_1_1Report: (
      indicator1_1_1Report: IIndicator1_1_1ReportPagedSnapshotIn,
    ): void => {
      applySnapshot(self._indicator1_1_1Report, indicator1_1_1Report)
    },
    setIndicator1_2_1Report: (
      indicator1_2_1Report: IIndicator1_2_1ReportPagedSnapshotIn,
    ): void => {
      applySnapshot(self._indicator1_2_1Report, indicator1_2_1Report)
    },
    setIndicator1_2_2Report: (
      indicator1_2_2Report: IIndicator1_2_2ReportPagedSnapshotIn,
    ): void => {
      applySnapshot(self._indicator1_2_2Report, indicator1_2_2Report)
    },
    setIndicator2_1_1Report: (
      indicator2_1_1Report: IIndicator2_1_1ReportPagedSnapshotIn,
    ): void => {
      applySnapshot(self._indicator2_1_1Report, indicator2_1_1Report)
    },
    setIndicator2_1_2Report: (
      indicator2_1_2Report: IIndicator2_1_2ReportPagedSnapshotIn,
    ): void => {
      applySnapshot(self._indicator2_1_2Report, indicator2_1_2Report)
    },
    setIndicator2_2_1Report: (
      indicator2_2_1Report: IIndicator2_2_1ReportPagedSnapshotIn,
    ): void => {
      applySnapshot(self._indicator2_2_1Report, indicator2_2_1Report)
    },
    setIndicator2_2_2Report: (
      indicator2_2_2Report: IIndicator2_2_2ReportPagedSnapshotIn,
    ): void => {
      applySnapshot(self._indicator2_2_2Report, indicator2_2_2Report)
    },
    setIndicator2_2_3Report: (
      indicator2_2_3Report: IIndicator2_2_3ReportPagedSnapshotIn,
    ): void => {
      applySnapshot(self._indicator2_2_3Report, indicator2_2_3Report)
    },
    setIndicator3_1_1Report: (
      indicator3_1_1Report: IIndicator3_1_1ReportPagedSnapshotIn,
    ): void => {
      applySnapshot(self._indicator3_1_1Report, indicator3_1_1Report)
    },
    setIndicator3_2_1Report: (
      indicator3_2_1Report: IIndicator3_2_1ReportPagedSnapshotIn,
    ): void => {
      applySnapshot(self._indicator3_2_1Report, indicator3_2_1Report)
    },
    setIndicator3_2_2Report: (
      indicator3_2_2Report: IIndicator3_2_2ReportPagedSnapshotIn,
    ): void => {
      applySnapshot(self._indicator3_2_2Report, indicator3_2_2Report)
    },
    setIndicator4_1_1Report: (
      indicator4_1_1Report: IIndicator4_1_1ReportPagedSnapshotIn,
    ): void => {
      applySnapshot(self._indicator4_1_1Report, indicator4_1_1Report)
    },
    setIndicatorVulnerableMigrantReport: (
        indicatorVulnerableMigrantReport: IIndicatorVulnerableMigrantReportPagedSnapshotIn,
    ): void => {
      applySnapshot(self._indicatorVulnerableMigrantReport, indicatorVulnerableMigrantReport)
    },
    setIsLoading: (isLoading: boolean) => {
      self._isLoading = isLoading
    },
  }))
  .actions(self => {
    const INDICATORS_REPORTS_API = '/api/indicators/reports'

    const downloadFinalReport = async (
      onDownloadProgress: (progressEvent: ProgressEvent) => void,
    ) => {

    const finalReportExportFileRequest = fromPromise(
      apiV1.get<ArrayBuffer>(`${INDICATORS_REPORTS_API}/export/finalreport`, {
        responseType: 'arraybuffer',
        onDownloadProgress,
      }),
    )

    when(() =>
      finalReportExportFileRequest.case({
          fulfilled: response => {
            const fileName = getFileNameFromContentDisposition(
              response.headers['content-disposition'],
            )!
            fileDownload(response.data, fileName)
            return true
          },
        }),
      )
    }

    const getIndicator1_1_1Report = async (
      requestParams: IIndicator1_1_1ReportRequestSnapshotIn,
    ) => {

      // @ts-ignore
      requestParams["filter"]["ReportingYear"] = requestParams.filter.ReportingYear.join(";")

      const indicator1_1_1ReportRequest = fromPromise(
        apiV1.get<IIndicator1_1_1ReportPagedSnapshotIn>(
          `${INDICATORS_REPORTS_API}/indicator1_1_1`,
          { params: Indicator1_1_1ReportRequestModel.create(requestParams) },
        ),
      )

      when(() =>
        indicator1_1_1ReportRequest.case({
          fulfilled: response => {
            self.setIndicator1_1_1Report(response.data)
            return true
          },
        }),
      )
      return indicator1_1_1ReportRequest
    }

    const getIndicator1_2_1Report = async (
      requestParams: IIndicator1_2_1ReportRequestSnapshotIn,
    ) => {
      // @ts-ignore
      requestParams["filter"]["ReportingYear"] = requestParams.filter.ReportingYear.join(";")

      const indicator1_2_1ReportRequest = fromPromise(
        apiV1.get<IIndicator1_2_1ReportPagedSnapshotIn>(
          `${INDICATORS_REPORTS_API}/indicator1_2_1`,
          { params: Indicator1_2_1ReportRequestModel.create(requestParams) },
        ),
      )

      when(() =>
        indicator1_2_1ReportRequest.case({
          fulfilled: response => {
            self.setIndicator1_2_1Report(response.data)
            return true
          },
        }),
      )
      return indicator1_2_1ReportRequest
    }

    const getIndicator1_2_2Report = async (
      requestParams: IIndicator1_2_2ReportRequestSnapshotIn,
    ) => {
      // @ts-ignore
      requestParams["filter"]["ReportingYear"] = requestParams.filter.ReportingYear.join(";")

      const indicator1_2_2ReportRequest = fromPromise(
        apiV1.get<IIndicator1_2_2ReportPagedSnapshotIn>(
          `${INDICATORS_REPORTS_API}/indicator1_2_2`,
          { params: Indicator1_2_2ReportRequestModel.create(requestParams) },
        ),
      )

      when(() =>
        indicator1_2_2ReportRequest.case({
          fulfilled: response => {
            self.setIndicator1_2_2Report(response.data)
            return true
          },
        }),
      )
      return indicator1_2_2ReportRequest
    }

    const getIndicatorReportExportFile = async (
      requestParams: IIndicatorExportReportsRequest,
      type: ExportFilesType,
      onDownloadProgress: (progressEvent: ProgressEvent) => void,
    ) => {
      // @ts-ignore
      requestParams["filter"]["ReportingYear"] = requestParams.filter.ReportingYear.join(";")

      const indicatorReportExportFileRequest = fromPromise(
        apiV1.get<ArrayBuffer>(`${INDICATORS_REPORTS_API}/export`, {
          params: {
            ...requestParams,
            sortBy: capitalizeFirstLetter(requestParams.sortBy),
          },
          responseType: 'arraybuffer',
          onDownloadProgress,
        }),
      )

      when(() =>
        indicatorReportExportFileRequest.case({
          fulfilled: response => {
            const fileName = getFileNameFromContentDisposition(
              response.headers['content-disposition'],
            )
            fileDownload(response.data, fileName || `report.${type}`)
            return true
          },
        }),
      )

      return indicatorReportExportFileRequest
    }

    const getIndicator2_1_1Report = async (
      requestParams: IIndicator2_1_1ReportRequestSnapshotIn,
    ) => {
      // @ts-ignore
      requestParams["filter"]["ReportingYear"] = requestParams.filter.ReportingYear.join(";")

      const indicator2_1_1ReportRequest = fromPromise(
        apiV1.get<IIndicator2_1_1ReportPagedSnapshotIn>(
          `${INDICATORS_REPORTS_API}/indicator2_1_1`,
          { params: Indicator2_1_1ReportRequestModel.create(requestParams) },
        ),
      )

      when(() =>
        indicator2_1_1ReportRequest.case({
          fulfilled: response => {
            self.setIndicator2_1_1Report(response.data)
            return true
          },
        }),
      )
      return indicator2_1_1ReportRequest
    }

    const getIndicator2_1_2Report = async (
      requestParams: IIndicator2_1_2ReportRequestSnapshotIn,
    ) => {
      // @ts-ignore
      requestParams["filter"]["ReportingYear"] = requestParams.filter.ReportingYear.join(";")

      const indicator2_1_2ReportRequest = fromPromise(
        apiV1.get<IIndicator2_1_2ReportPagedSnapshotIn>(
          `${INDICATORS_REPORTS_API}/indicator2_1_2`,
          { params: Indicator2_1_2ReportRequestModel.create(requestParams) },
        ),
      )

      when(() =>
        indicator2_1_2ReportRequest.case({
          fulfilled: response => {
            self.setIndicator2_1_2Report(response.data)
            return true
          },
        }),
      )
      return indicator2_1_2ReportRequest
    }

    const getIndicator2_2_1Report = async (
      requestParams: IIndicator2_2_1ReportRequestSnapshotIn,
    ) => {
      // @ts-ignore
      requestParams["filter"]["ReportingYear"] = requestParams.filter.ReportingYear.join(";")

      const indicator2_2_1ReportRequest = fromPromise(
        apiV1.get<IIndicator2_2_1ReportPagedSnapshotIn>(
          `${INDICATORS_REPORTS_API}/indicator2_2_1`,
          { params: Indicator2_2_1ReportRequestModel.create(requestParams) },
        ),
      )

      when(() =>
        indicator2_2_1ReportRequest.case({
          fulfilled: response => {
            self.setIndicator2_2_1Report(response.data)
            return true
          },
        }),
      )
      return indicator2_2_1ReportRequest
    }

    const getIndicator2_2_2Report = async (
      requestParams: IIndicator2_2_2ReportRequestSnapshotIn,
    ) => {
      // @ts-ignore
      requestParams["filter"]["ReportingYear"] = requestParams.filter.ReportingYear.join(";")

      const indicator2_2_2ReportRequest = fromPromise(
        apiV1.get<IIndicator2_2_2ReportPagedSnapshotIn>(
          `${INDICATORS_REPORTS_API}/indicator2_2_2`,
          { params: Indicator2_2_2ReportRequestModel.create(requestParams) },
        ),
      )

      when(() =>
        indicator2_2_2ReportRequest.case({
          fulfilled: response => {
            self.setIndicator2_2_2Report(response.data)
            return true
          },
        }),
      )
      return indicator2_2_2ReportRequest
    }

    const getIndicator2_2_3Report = async (
      requestParams: IIndicator2_2_3ReportRequestSnapshotIn,
    ) => {
      // @ts-ignore
      requestParams["filter"]["ReportingYear"] = requestParams.filter.ReportingYear.join(";")

      const indicator2_2_3ReportRequest = fromPromise(
        apiV1.get<IIndicator2_2_3ReportPagedSnapshotIn>(
          `${INDICATORS_REPORTS_API}/indicator2_2_3`,
          { params: Indicator2_2_3ReportRequestModel.create(requestParams) },
        ),
      )

      when(() =>
        indicator2_2_3ReportRequest.case({
          fulfilled: response => {
            self.setIndicator2_2_3Report(response.data)
            return true
          },
        }),
      )
      return indicator2_2_3ReportRequest
    }

    const getIndicator3_1_1Report = async (
      requestParams: IIndicator3_1_1ReportRequestSnapshotIn,
    ) => {
      // @ts-ignore
      requestParams["filter"]["ReportingYear"] = requestParams.filter.ReportingYear.join(";")

      const indicator3_1_1ReportRequest = fromPromise(
        apiV1.get<IIndicator3_1_1ReportPagedSnapshotIn>(
          `${INDICATORS_REPORTS_API}/indicator3_1_1`,
          { params: Indicator3_1_1ReportRequestModel.create(requestParams) },
        ),
      )

      when(() =>
        indicator3_1_1ReportRequest.case({
          fulfilled: response => {
            self.setIndicator3_1_1Report(response.data)
            return true
          },
        }),
      )
      return indicator3_1_1ReportRequest
    }

    const getIndicator3_2_1Report = async (
      requestParams: IIndicator3_2_1ReportRequestSnapshotIn,
    ) => {
      // @ts-ignore
      requestParams["filter"]["ReportingYear"] = requestParams.filter.ReportingYear.join(";")

      const indicator3_2_1ReportRequest = fromPromise(
        apiV1.get<IIndicator3_2_1ReportPagedSnapshotIn>(
          `${INDICATORS_REPORTS_API}/indicator3_2_1`,
          { params: Indicator3_2_1ReportRequestModel.create(requestParams) },
        ),
      )

      when(() =>
        indicator3_2_1ReportRequest.case({
          fulfilled: response => {
            self.setIndicator3_2_1Report(response.data)
            return true
          },
        }),
      )
      return indicator3_2_1ReportRequest
    }

    const getIndicator3_2_2Report = async (
      requestParams: IIndicator3_2_2ReportRequestSnapshotIn,
    ) => {
      // @ts-ignore
      requestParams["filter"]["ReportingYear"] = requestParams.filter.ReportingYear.join(";")

      const indicator3_2_2ReportRequest = fromPromise(
        apiV1.get<IIndicator3_2_2ReportPagedSnapshotIn>(
          `${INDICATORS_REPORTS_API}/indicator3_2_2`,
          { params: Indicator3_2_2ReportRequestModel.create(requestParams) },
        ),
      )

      when(() =>
        indicator3_2_2ReportRequest.case({
          fulfilled: response => {
            self.setIndicator3_2_2Report(response.data)
            return true
          },
        }),
      )
      return indicator3_2_2ReportRequest
    }

    const getIndicator4_1_1Report = async (
      requestParams: IIndicator4_1_1ReportRequestSnapshotIn,
    ) => {
      // @ts-ignore
      requestParams["filter"]["ReportingYear"] = requestParams.filter.ReportingYear.join(";")

      const indicator4_1_1ReportRequest = fromPromise(
        apiV1.get<IIndicator4_1_1ReportPagedSnapshotIn>(
          `${INDICATORS_REPORTS_API}/indicator4_1_1`,
          { params: Indicator4_1_1ReportRequestModel.create(requestParams) },
        ),
      )

      when(() =>
        indicator4_1_1ReportRequest.case({
          fulfilled: response => {
            self.setIndicator4_1_1Report(response.data)
            return true
          },
        }),
      )
      return indicator4_1_1ReportRequest
    }

    const getIndicatorVulnerableMigrantReport = async (
        requestParams: IIndicatorVulnerableMigrantReportRequestSnapshotIn,
    ) => {
      // @ts-ignore
      requestParams["filter"]["ReportingYear"] = requestParams.filter.ReportingYear.join(";")

      const indicatorVulnerableMigrantReportRequest = fromPromise(
          apiV1.get<IIndicatorVulnerableMigrantReportPagedSnapshotIn>(
              `${INDICATORS_REPORTS_API}/vulmigrant`,
              { params: IndicatorVulnerableMigrantReportRequestModel.create(requestParams) },
          ),
      )

      when(() =>
          indicatorVulnerableMigrantReportRequest.case({
            fulfilled: response => {
              self.setIndicatorVulnerableMigrantReport(response.data)
              return true
            },
          }),
      )
      return indicatorVulnerableMigrantReportRequest
    }

    return {
      getIndicator1_1_1Report,
      getIndicatorReportExportFile,
      getIndicator1_2_1Report,
      getIndicator1_2_2Report,
      getIndicator2_1_1Report,
      getIndicator2_1_2Report,
      getIndicator2_2_1Report,
      getIndicator2_2_2Report,
      getIndicator2_2_3Report,
      getIndicator3_1_1Report,
      getIndicator3_2_1Report,
      getIndicator3_2_2Report,
      getIndicator4_1_1Report,
      getIndicatorVulnerableMigrantReport,
      downloadFinalReport,
    }
  })
  .views(self => ({
    get indicator1_1_1Report(): IIndicator1_1_1ReportPagedSnapshotOut {
      return getSnapshot(self._indicator1_1_1Report)
    },
    get indicator1_1_1ReportData() {
      return this.indicator1_1_1Report.data
    },
    get indicator1_1_1ReportMetadata() {
      return this.indicator1_1_1Report.metadata
    },
    get indicator1_2_1Report(): IIndicator1_2_1ReportPagedSnapshotOut {
      return getSnapshot(self._indicator1_2_1Report)
    },
    get indicator1_2_1ReportData() {
      return this.indicator1_2_1Report.data
    },
    get indicator1_2_1ReportMetadata() {
      return this.indicator1_2_1Report.metadata
    },
    get indicator1_2_2Report(): IIndicator1_2_2ReportPagedSnapshotOut {
      return getSnapshot(self._indicator1_2_2Report)
    },
    get indicator1_2_2ReportData() {
      return this.indicator1_2_2Report.data
    },
    get indicator1_2_2ReportMetadata() {
      return this.indicator1_2_2Report.metadata
    },
    get indicator2_1_1Report(): IIndicator2_1_1ReportPagedSnapshotOut {
      return getSnapshot(self._indicator2_1_1Report)
    },
    get indicator2_1_1ReportData() {
      return this.indicator2_1_1Report.data
    },
    get indicator2_1_1ReportMetadata() {
      return this.indicator2_1_1Report.metadata
    },
    get indicator2_1_2Report(): IIndicator2_1_2ReportPagedSnapshotOut {
      return getSnapshot(self._indicator2_1_2Report)
    },
    get indicator2_1_2ReportData() {
      return this.indicator2_1_2Report.data
    },
    get indicator2_1_2ReportMetadata() {
      return this.indicator2_1_2Report.metadata
    },
    get indicator2_2_1Report(): IIndicator2_2_1ReportPagedSnapshotOut {
      return getSnapshot(self._indicator2_2_1Report)
    },
    get indicator2_2_1ReportData() {
      return this.indicator2_2_1Report.data
    },
    get indicator2_2_1ReportMetadata() {
      return this.indicator2_2_1Report.metadata
    },
    get indicator2_2_2Report(): IIndicator2_2_2ReportPagedSnapshotOut {
      return getSnapshot(self._indicator2_2_2Report)
    },
    get indicator2_2_2ReportData() {
      return this.indicator2_2_2Report.data
    },
    get indicator2_2_2ReportMetadata() {
      return this.indicator2_2_2Report.metadata
    },
    get indicator2_2_3Report(): IIndicator2_2_3ReportPagedSnapshotOut {
      return getSnapshot(self._indicator2_2_3Report)
    },
    get indicator2_2_3ReportData() {
      return this.indicator2_2_3Report.data
    },
    get indicator2_2_3ReportMetadata() {
      return this.indicator2_2_3Report.metadata
    },
    get indicator3_1_1Report(): IIndicator3_1_1ReportPagedSnapshotOut {
      return getSnapshot(self._indicator3_1_1Report)
    },
    get indicator3_1_1ReportData() {
      return this.indicator3_1_1Report.data
    },
    get indicator3_1_1ReportMetadata() {
      return this.indicator3_1_1Report.metadata
    },
    get indicator3_2_1Report(): IIndicator3_2_1ReportPagedSnapshotOut {
      return getSnapshot(self._indicator3_2_1Report)
    },
    get indicator3_2_1ReportData() {
      return this.indicator3_2_1Report.data
    },
    get indicator3_2_1ReportMetadata() {
      return this.indicator3_2_1Report.metadata
    },
    get indicator3_2_2Report(): IIndicator3_2_2ReportPagedSnapshotOut {
      return getSnapshot(self._indicator3_2_2Report)
    },
    get indicator3_2_2ReportData() {
      return this.indicator3_2_2Report.data
    },
    get indicator3_2_2ReportMetadata() {
      return this.indicator3_2_2Report.metadata
    },
    get indicator4_1_1Report(): IIndicator4_1_1ReportPagedSnapshotOut {
      return getSnapshot(self._indicator4_1_1Report)
    },
    get indicator4_1_1ReportData() {
      return this.indicator4_1_1Report.data
    },
    get indicator4_1_1ReportMetadata() {
      return this.indicator4_1_1Report.metadata
    },
    get indicatorVulnerableMigrantReport(): IIndicatorVulnerableMigrantReportPagedSnapshotOut {
      return getSnapshot(self._indicatorVulnerableMigrantReport)
    },
    get indicatorVulnerableMigrantReportData() {
      return this.indicatorVulnerableMigrantReport.data
    },
    get indicatorVulnerableMigrantReportMetadata() {
      return this.indicatorVulnerableMigrantReport.metadata
    },
    get isLoading() {
      return self._isLoading
    },
  }))

export default IndicatorsReportsStore
export interface IIndicatorsReportsStore
  extends Instance<typeof IndicatorsReportsStore> {}
