import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const LoginCommand = types.model('LoginCommand', {
  userName: types.string,
  password: types.string,
})

export interface ILoginCommand extends Instance<typeof LoginCommand> {}

export interface ILoginCommandSnapshotIn
  extends SnapshotIn<typeof LoginCommand> {}

export interface ILoginCommandSnapshotOut
  extends SnapshotOut<typeof LoginCommand> {}

export default LoginCommand
