import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const ActivityStaffWithNameModel = types.model('ActivityStaffWithNameModel', {
  id: types.optional(types.number, 0),
  code: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  organization: types.maybeNull(types.string),
  organizationType: types.maybeNull(types.string),
  memberType: types.string,
})

export interface IActivityStaffWithNameModel
  extends Instance<typeof ActivityStaffWithNameModel> {}

export interface IActivityStaffWithNameSnapshotIn
  extends SnapshotIn<typeof ActivityStaffWithNameModel> {}

export interface IActivityStaffWithNameSnapshotOut
  extends SnapshotOut<typeof ActivityStaffWithNameModel> {}

export default ActivityStaffWithNameModel
