import { useEffect } from 'react'

export function errorFocus(
  isSubmitting: boolean,
  errors: { [k: string]: any },
) {
  const keys = Object.keys(errors)
  const errorElement =
    document.querySelector(`[name="${keys[0]}"]`) ||
    document.querySelector(`[for="${keys[0]}"]`)
  if (errorElement?.parentElement) {
    errorElement.parentElement.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }
}

const useFormErrorFocus = (
  isSubmitting: boolean,
  errors: { [k: string]: any },
) => {
  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length)
      errorFocus(isSubmitting, errors)
  }, [isSubmitting, errors])
}

export default useFormErrorFocus
