import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const ServiceHistoryModel = types.model('ServiceHistoryModel', {
  id: types.optional(types.integer, 0),
  relatedToCovid19: types.boolean,
  serviceDeliveryDate: types.string,
  serviceTypeId: types.integer,
  serviceTypeName: types.string,
  serviceTypeParentId: types.maybeNull(types.integer),
  serviceDeliveredStaffId: types.integer,
  serviceDeliveredStaffName: types.maybeNull(types.string),
  regionId: types.integer,
  regionName: types.maybeNull(types.string),
  beneficiaryIndividualId: types.integer,
  beneficiaryIndividualCode: types.maybeNull(types.string),
  beneficiaryIndividualFullName: types.maybeNull(types.string),
  canBeDeleted: types.boolean,
  canBePended: types.boolean,
  canBeEdited: types.boolean,
  status: types.maybeNull(types.string),
  statusId: types.number,
})

export interface IServiceHistoryModel
  extends Instance<typeof ServiceHistoryModel> {}
export interface IServiceHistoryModelSnapshotIn
  extends SnapshotIn<typeof ServiceHistoryModel> {}
export interface IServiceHistoryModelSnapshotOut
  extends SnapshotOut<typeof ServiceHistoryModel> {}
export default ServiceHistoryModel
