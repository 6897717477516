import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const IndicatorTargetCountriesModel = LookUpModel.named(
  'IndicatorTargetCountriesModel',
)

export interface IIndicatorTargetCountriesModel
  extends Instance<typeof IndicatorTargetCountriesModel> {}
export interface IIndicatorTargetCountriesSnapshotIn
  extends SnapshotIn<typeof IndicatorTargetCountriesModel> {}
export interface IIndicatorTargetCountriesSnapshotOut
  extends SnapshotOut<typeof IndicatorTargetCountriesModel> {}
export default IndicatorTargetCountriesModel
