import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const ActivityApprovalCommand = types.model('ActivityApprovalCommand', {
  ids: types.array(types.integer),
})

export interface IActivityApprovalCommand
  extends Instance<typeof ActivityApprovalCommand> {}
export interface IActivityApprovalCommandSnapshotIn
  extends SnapshotIn<typeof ActivityApprovalCommand> {}
export interface IActivityApprovalCommandSnapshotOut
  extends SnapshotOut<typeof ActivityApprovalCommand> {}
export default ActivityApprovalCommand
