import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../../PagedListMetaData'
import ServiceHistoryModel from './ServiceHistoryModel'

const ServiceHistoryPagedDto = types.model('ServiceHistoryPagedDto', {
  data: types.array(ServiceHistoryModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export interface IServiceHistoryPagedDto
  extends Instance<typeof ServiceHistoryPagedDto> {}

export interface IServiceHistoryPagedDtoSnapshotIn
  extends SnapshotIn<typeof ServiceHistoryPagedDto> {}

export interface IServiceHistoryPagedDtoSnapshotOut
  extends SnapshotOut<typeof ServiceHistoryPagedDto> {}

export default ServiceHistoryPagedDto
