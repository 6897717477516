import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../PagedListRequestModel'

const UsersListRequestModel = PagedListRequestModel.named(
  'UsersListRequestModel',
).props({
  sortBy: types.optional(types.string, ''),
  filter: types.optional(
    types.model({
      countryId: types.union(types.number, types.undefined, types.null),
      organizationId: types.union(types.number, types.undefined, types.null),
      roleName: types.union(types.string, types.undefined, types.null),
      userName: types.union(types.string, types.undefined, types.null),
    }),
    {},
  ),
})

export interface IUsersListRequestModel
  extends Instance<typeof UsersListRequestModel> {}

export interface IUsersListRequestSnapshotIn
  extends SnapshotIn<typeof UsersListRequestModel> {}

export interface IUsersListRequestSnapshotOut
  extends SnapshotOut<typeof UsersListRequestModel> {}

export default UsersListRequestModel
