import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UserAccessInfoModel = types.model('UserAccessInfoModel', {
  id: types.optional(types.string, ''),
  email: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  organizationId: types.optional(types.number, 0),
  organizationName: types.maybeNull(types.string),
  roleName: types.maybeNull(types.string),
})

export interface IUserAccessInfo extends Instance<typeof UserAccessInfoModel> {}
export interface IUserAccessInfoSnapshotIn
  extends SnapshotIn<typeof UserAccessInfoModel> {}
export interface IUserAccessInfoSnapshotOut
  extends SnapshotOut<typeof UserAccessInfoModel> {}
export default UserAccessInfoModel
