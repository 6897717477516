import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const CollaborationAreaLookUpModel = LookUpModel.named(
  'CollaborationAreaLookUpModel',
).props({
  isComment: types.boolean,
})

export interface ICollaborationAreaLookUpModel
  extends Instance<typeof CollaborationAreaLookUpModel> {}
export interface ICollaborationAreaLookUpSnapshotIn
  extends SnapshotIn<typeof CollaborationAreaLookUpModel> {}
export interface ICollaborationAreaLookUpSnapshotOut
  extends SnapshotOut<typeof CollaborationAreaLookUpModel> {}
export default CollaborationAreaLookUpModel
