import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const MigrationactivitiesLooksUpModel = LookUpModel.named(
  'MigrationactivitiesLooksUpModel',
).props({
    isComment: types.boolean,
})

export interface IMigrationactivitiesLooksUpModel
  extends Instance<typeof MigrationactivitiesLooksUpModel> {}

export interface IMigrationactivitiesLooksUpModelSnapshotIn
  extends SnapshotIn<typeof MigrationactivitiesLooksUpModel> {}

export interface IMigrationactivitiesLooksUpModelSnapshotOut
  extends SnapshotOut<typeof MigrationactivitiesLooksUpModel> {}

export default MigrationactivitiesLooksUpModel
