import { ComponentType, lazy } from 'react'
import { MessageDescriptor } from 'react-intl'
import {
  PermissionGroupName,
  PermissionMenuName,
  CatalogName,
  IndicatorsFormNumber,
  IndicatorsNumber,
} from 'stores/models'

//beneficiaries page imports

const Beneficiaries = lazy(() => import('./views/Beneficiaries'))

const BeneficiariesIndividuals = lazy(
  () => import('./views/Beneficiaries/BeneficiariesIndividuals'),
)

const BeneficiariesOrganizations = lazy(
  () => import('./views/Beneficiaries/BeneficiariesOrganizations'),
)

//users page imports
const Users = lazy(() => import('./views/Users'))

//partners page imports
const Organizations = lazy(() => import('./views/Organizations'))
const BeneficiaryOrganizations = lazy(
  () =>
    import(
      './views/Organizations/Partner/PartnerBeneficiaries/BeneficiaryOrganizations'
    ),
)
const BeneficiaryOrganizationDetails = lazy(
  () =>
    import(
      './views/Organizations/Partner/PartnerBeneficiaries/BeneficiaryOrganizations/BeneficiaryOrganizationDetails'
    ),
)
const BeneficiaryIndividual = lazy(
  () =>
    import(
      './views/Organizations/Partner/PartnerBeneficiaries/BeneficiaryIndividual'
    ),
)

const BeneficiaryIndividualDetails = lazy(
  () =>
    import(
      './views/Organizations/Partner/PartnerBeneficiaries/BeneficiaryIndividual/BeneficiaryIndividualDetails'
    ),
)

const Partner = lazy(() => import('./views/Organizations/Partner'))
const PartnerSettings = lazy(
  () => import('./views/Organizations/Partner/PartnerSettings'),
)
const PartnerStaff = lazy(
  () => import('./views/Organizations/Partner/PartnerSettings/Staff'),
)
const PartnersServices = lazy(
  () => import('./views/Organizations/Partner/PartnerSettings/Services'),
)
const PartnersServicesSettings = lazy(
  () =>
    import(
      './views/Organizations/Partner/PartnerSettings/Services/ServicesSettings'
    ),
)
const PartnerBeneficiaries = lazy(
  () => import('./views/Organizations/Partner/PartnerBeneficiaries'),
)
const PartnerServiceHistory = lazy(
  () => import('./views/Organizations/Partner/PartnerServiceHistory'),
)
const PartnerActivities = lazy(
  () => import('./views/Organizations/Partner/Activities'),
)
const PartnerActivity = lazy(
  () => import('./views/Organizations/Partner/Activities/ActivityDetails'),
)
const ActivityForm = lazy(
  () =>
    import(
      './views/Organizations/Partner/Activities/ActivityForm/ActivityForm'
    ),
)
const PartnerIndicators = lazy(
  () => import('./views/Organizations/Partner/PartnerIndicators'),
)

const Indicator111Form = lazy(
  () =>
    import(
      'views/Organizations/Partner/PartnerIndicators/IndicatorForms/Indicator111Form/Indicator111Form'
    ),
)

const Indicator121Form = lazy(
  () =>
    import(
      'views/Organizations/Partner/PartnerIndicators/IndicatorForms/Indicator121Form/Indicator121Form'
    ),
)

const Indicator122Form = lazy(
  () =>
    import(
      'views/Organizations/Partner/PartnerIndicators/IndicatorForms/Indicator122Form/Indicator122Form'
    ),
)

const Indicator221Form = lazy(
  () =>
    import(
      'views/Organizations/Partner/PartnerIndicators/IndicatorForms/Indicator221Form/Indicator221Form'
    ),
)

const Indicator223Form = lazy(
  () =>
    import(
      'views/Organizations/Partner/PartnerIndicators/IndicatorForms/Indicator223Form/Indicator223Form'
    ),
)

const Indicator311Form = lazy(
  () =>
    import(
      'views/Organizations/Partner/PartnerIndicators/IndicatorForms/Indicator311Form/Indicator311Form'
    ),
)

const IndicatorVulnerableMigrantForm = lazy(
    () =>
        import(
            'views/Organizations/Partner/PartnerIndicators/IndicatorForms/IndicatorVulnerableMigrantForm/IndicatorVulnerableMigrantForm'
            ),
)
// activity
const Activity = lazy(() => import('./views/Activity'))

// service history
const ServiceHistory = lazy(() => import('./views/ServiceHistory'))

//catalogs page imports
const Catalogs = lazy(() => import('./views/Catalogs'))
const CatalogsLayout = lazy(() => import('./views/Catalogs/CatalogLayout'))
const Beneficiary = lazy(() => import('./views/Catalogs/Beneficiary'))

//Settings page imports
const SystemSettings = lazy(() => import('./views/SystemSettings'))
const RolePermissionsSettings = lazy(
  () => import('./views/SystemSettings/RolePermissionsSettings/RoleSettings'),
)
const UserPermissionsSettings = lazy(
  () => import('./views/SystemSettings/UserPermissionsSettings/UserSettings'),
)

//dashboards imports
const Dashboards = lazy(() => import('./views/Dashboards'))
const Dashboard = lazy(() => import('./views/Dashboards/Dashboard'))

//indicators imports
const Indicators = lazy(() => import('./views/Indicators'))
const IndicatorsReports = lazy(() => import('./views/Indicators/Reports'))
const IndicatorsTargets = lazy(
  () => import('./views/Indicators/IndicatorTargets'),
)
const IndicatorsTargetsForm = lazy(
  () => import('./views/Indicators/IndicatorTargets/IndicatorTargetsForm'),
)
const Indicator111Report = lazy(
  () => import('./views/Indicators/Reports/Indicator111Report'),
)
const Indicator121Report = lazy(
  () => import('./views/Indicators/Reports/Indicator121Report'),
)

const Indicator122Report = lazy(
  () => import('./views/Indicators/Reports/Indicator122Report'),
)

const Indicator211Report = lazy(
  () => import('./views/Indicators/Reports/Indicator211Report'),
)

const Indicator212Report = lazy(
  () => import('./views/Indicators/Reports/Indicator212Report'),
)

const Indicator221Report = lazy(
  () => import('./views/Indicators/Reports/Indicator221Report'),
)

const Indicator222Report = lazy(
  () => import('./views/Indicators/Reports/Indicator222Report'),
)

const Indicator223Report = lazy(
  () => import('./views/Indicators/Reports/Indicator223Report'),
)

const Indicator311Report = lazy(
  () => import('./views/Indicators/Reports/Indicator311Report'),
)

const Indicator321Report = lazy(
  () => import('./views/Indicators/Reports/Indicator321Report'),
)

const Indicator322Report = lazy(
  () => import('./views/Indicators/Reports/Indicator322Report'),
)

const Indicator411Report = lazy(
  () => import('./views/Indicators/Reports/Indicator411Report'),
)

const IndicatorVulnerableMigrantReport = lazy(
    () => import ('./views/Indicators/Reports/IndicatorVulnerableMigrantReport')
)

const IndicatorForms = lazy(() => import('./views/Indicators/IndicatorForms'))

//beneficiaries page routes
export const BENEFICIARIES_ROUTE = '/beneficiaries'
export const BENEFICIARIES_INDIVIDUAL_ROUTE = `${BENEFICIARIES_ROUTE}/individual`
export const BENEFICIARIES_INDIVIDUAL_DETAILS_ROUTE = `${BENEFICIARIES_INDIVIDUAL_ROUTE}/:individualId` as const

export const BENEFICIARIES_ORGANIZATIONS_ROUTE = `${BENEFICIARIES_ROUTE}/organizations`
export const BENEFICIARIES_ORGANIZATION_DETAILS_ROUTE = `${BENEFICIARIES_ORGANIZATIONS_ROUTE}/:organizationId` as const

//users page routes
export const USERS_ROUTE = '/users' as const

//partners page routes
export const PARTNERS_ROUTE = '/partners' as const
export const PARTNER_ROUTE = `${PARTNERS_ROUTE}/:partnerId` as const
export const PARTNER_SETTINGS_ROUTE = `${PARTNER_ROUTE}/settings` as const
export const PARTNER_SETTINGS_STAFF_ROUTE = `${PARTNER_SETTINGS_ROUTE}/staff` as const
export const PARTNER_SETTINGS_SERVICES_ROUTE = `${PARTNER_SETTINGS_ROUTE}/services` as const
export const PARTNER_SETTINGS_SERVICES_EDIT_ROUTE = `${PARTNER_SETTINGS_SERVICES_ROUTE}/edit` as const
export const PARTNER_BENEFICIARIES_ROUTE = `${PARTNER_ROUTE}/beneficiaries` as const
export const PARTNER_SERVICE_HISTORY_ROUTE = `${PARTNER_ROUTE}/servicehistory` as const

export const PARTNER_INDICATORS_ROUTE = `${PARTNER_ROUTE}/indicators` as const
export const PARTNER_INDICATORS_FORM_ROUTE = `${PARTNER_INDICATORS_ROUTE}/:indicatorNumber/:indicatorRecordId?` as const
export const PARTNER_INDICATORS_VIEW_ROUTE = `${PARTNER_INDICATORS_FORM_ROUTE}/view` as const

export const PARTNER_INDICATORS_1_1_1_ROUTE = `${PARTNER_INDICATORS_ROUTE}/${IndicatorsFormNumber.Indicator1}/:indicatorRecordId?` as const
export const PARTNER_INDICATORS_1_1_1_VIEW_ROUTE = `${PARTNER_INDICATORS_1_1_1_ROUTE}/view` as const
export const PARTNER_INDICATORS_1_2_1_ROUTE = `${PARTNER_INDICATORS_ROUTE}/${IndicatorsFormNumber.Indicator2}/:indicatorRecordId?` as const
export const PARTNER_INDICATORS_1_2_1_VIEW_ROUTE = `${PARTNER_INDICATORS_1_2_1_ROUTE}/view` as const
export const PARTNER_INDICATORS_1_2_2_ROUTE = `${PARTNER_INDICATORS_ROUTE}/${IndicatorsFormNumber.Indicator3}/:indicatorRecordId?` as const
export const PARTNER_INDICATORS_1_2_2_VIEW_ROUTE = `${PARTNER_INDICATORS_1_2_2_ROUTE}/view` as const
export const PARTNER_INDICATORS_2_2_1_ROUTE = `${PARTNER_INDICATORS_ROUTE}/${IndicatorsFormNumber.Indicator4}/:indicatorRecordId?` as const
export const PARTNER_INDICATORS_2_2_1_VIEW_ROUTE = `${PARTNER_INDICATORS_2_2_1_ROUTE}/view` as const
export const PARTNER_INDICATORS_2_2_3_ROUTE = `${PARTNER_INDICATORS_ROUTE}/${IndicatorsFormNumber.Indicator5}/:indicatorRecordId?` as const
export const PARTNER_INDICATORS_2_2_3_VIEW_ROUTE = `${PARTNER_INDICATORS_2_2_3_ROUTE}/view` as const
export const PARTNER_INDICATORS_3_1_1_ROUTE = `${PARTNER_INDICATORS_ROUTE}/${IndicatorsFormNumber.Indicator6}/:indicatorRecordId?` as const
export const PARTNER_INDICATORS_3_1_1_VIEW_ROUTE = `${PARTNER_INDICATORS_3_1_1_ROUTE}/view` as const
export const PARTNER_INDICATORS_VULNERABLE_MIGRANT_ROUTE = `${PARTNER_INDICATORS_ROUTE}/${IndicatorsFormNumber.Indicator7}/:indicatorRecordId?` as const
export const PARTNER_INDICATORS_VULNERABLE_MIGRANT_VIEW_ROUTE = `${PARTNER_INDICATORS_VULNERABLE_MIGRANT_ROUTE}/view` as const

export const PARTNER_BENEFICIARIES_INDIVIDUALS_ROUTE = `${PARTNER_BENEFICIARIES_ROUTE}/individuals` as const
export const PARTNER_BENEFICIARIES_INDIVIDUAL_ROUTE = `${PARTNER_BENEFICIARIES_INDIVIDUALS_ROUTE}/:individualId` as const
export const PARTNER_BENEFICIARIES_ORGANIZATIONS_ROUTE = `${PARTNER_BENEFICIARIES_ROUTE}/organizations` as const
export const PARTNER_BENEFICIARIES_ORGANIZATION_DETAILS_ROUTE = `${PARTNER_BENEFICIARIES_ORGANIZATIONS_ROUTE}/:organizationId` as const
export const PARTNER_ACTIVITIES_ROUTE = `${PARTNER_ROUTE}/activities` as const
export const PARTNER_ACTIVITIES_CREATE_ROUTE = `${PARTNER_ACTIVITIES_ROUTE}/create` as const
export const PARTNER_ACTIVITY_DETAILS_ROUTE = `${PARTNER_ACTIVITIES_ROUTE}/:activityId` as const
export const PARTNER_ACTIVITY_DETAILS_EDIT_ROUTE = `${PARTNER_ACTIVITY_DETAILS_ROUTE}/edit` as const

// activity
export const ACTIVITY_ROUTE = '/activities' as const
export const ACTIVITY_DETAILS_ROUTE = `${ACTIVITY_ROUTE}/:activityId` as const

// service history
export const SERVICE_HISTORY_ROUTE = '/servicehistory' as const

//catalogs page routes
export const CATALOGS_ROUTE = '/catalogs' as const
export const CATALOGS_GENDER_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.Gender}` as const
export const CATALOGS_ETHNICITY_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.Ethnicity}` as const
export const CATALOGS_BENEFICIARY_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.Beneficiary}` as const
export const CATALOGS_TYPES_OF_SERVICE_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.TypesOfService}` as const
export const CATALOGS_TYPES_OF_ORGANIZATION_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.OrganizationTypes}` as const
export const CATALOGS_TYPES_OF_ACTIVITIES_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.ActivityTypes}` as const
export const CATALOGS_TRAFFICKING_FORMS_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.TraffickingForms}` as const
export const CATALOGS_IDENTIFYING_AGENCIES_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.IdentifyingAgencies}` as const
export const CATALOGS_TIP_CASE_STATUTES_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.TipCaseStatuses}` as const
export const CATALOGS_ADVOCACY_ISSUES_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.AdvocacyIssues}` as const
export const CATALOGS_ADVOCACY_TYPES_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.AdvocacyTypes}` as const
export const CATALOGS_SUPPORT_TYPES_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.SupportTypes}` as const
export const CATALOGS_ADVOCACY_INFLUENCE_LEVEL_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.AdvocacyInfluenceLevel}` as const
export const CATALOGS_DEVELOPMENT_TYPES_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.DevelopmentStages}` as const
export const CATALOGS_INFLUENCE_TYPES_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.InfluenceTypes}` as const
export const CATALOGS_DOCUMENT_TYPE_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.DocumentType}` as const
export const CATALOGS_IMPROVEMENT_AREA_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.ImprovementArea}` as const
export const CATALOGS_CSO_TYPES_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.CSOTypes}` as const
export const CATALOGS_DATA_TYPES_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.DataTypes}` as const
export const CATALOGS_DATA_COLLECTION_FREQUENCY_TYPES_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.DataCollectionFrequency}` as const
export const CATALOGS_BUSINESS_SCALE_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.BusinessScale}` as const
export const CATALOGS_INDUSTRY_TYPES_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.IndustryTypes}` as const
export const CATALOGS_SUPPORTING_DOCUMENTS_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.SupportingDocuments}` as const
export const CATALOGS_LEGAL_ENTITY_TYPES_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.LegalEntityTypes}` as const
export const CATALOGS_COLLABORATION_AREAS_ROUTE = `${CATALOGS_ROUTE}/${CatalogName.CollaborationAreas}` as const

//settings page routes
export const SETTINGS_ROUTE = '/settings' as const
export const SETTINGS_ROLES_PERMISSIONS_ROUTE = `${SETTINGS_ROUTE}/roles/:roleName/permissions` as const
export const SETTINGS_USERS_PERMISSIONS_ROUTE = `${SETTINGS_ROUTE}/users/:userId/permissions` as const

//dashboards imports
export const DASHBOARDS_ROUTE = `/dashboards` as const
export const DASHBOARD_REPORT_ROUTE = `${DASHBOARDS_ROUTE}/:reportId/db` as const
export const DASHBOARD_ROUTE = `${DASHBOARDS_ROUTE}/:reportId` as const

//indicators routes
export const INDICATORS_ROUTE = `/indicators` as const
export const INDICATORS_TARGETS_ROUTE = `${INDICATORS_ROUTE}/targets` as const
export const INDICATOR_TARGETS_ROUTE = `${INDICATORS_TARGETS_ROUTE}/:indicatorNumber` as const
export const INDICATORS_REPORTS_ROUTE = `${INDICATORS_ROUTE}/reports` as const
export const INDICATORS_REPORT_ROUTE = `${INDICATORS_REPORTS_ROUTE}/:indicatorNumber` as const
export const INDICATORS_REPORT_1_1_1_ROUTE = `${INDICATORS_REPORTS_ROUTE}/${IndicatorsNumber.Indicator1}` as const
export const INDICATORS_REPORT_1_2_1_ROUTE = `${INDICATORS_REPORTS_ROUTE}/${IndicatorsNumber.Indicator2}` as const
export const INDICATORS_REPORT_1_2_2_ROUTE = `${INDICATORS_REPORTS_ROUTE}/${IndicatorsNumber.Indicator3}` as const
export const INDICATORS_REPORT_2_1_1_ROUTE = `${INDICATORS_REPORTS_ROUTE}/${IndicatorsNumber.Indicator4}` as const
export const INDICATORS_REPORT_2_1_2_ROUTE = `${INDICATORS_REPORTS_ROUTE}/${IndicatorsNumber.Indicator5}` as const
export const INDICATORS_REPORT_2_2_1_ROUTE = `${INDICATORS_REPORTS_ROUTE}/${IndicatorsNumber.Indicator6}` as const
export const INDICATORS_REPORT_2_2_2_ROUTE = `${INDICATORS_REPORTS_ROUTE}/${IndicatorsNumber.Indicator7}` as const
export const INDICATORS_REPORT_2_2_3_ROUTE = `${INDICATORS_REPORTS_ROUTE}/${IndicatorsNumber.Indicator8}` as const
export const INDICATORS_REPORT_3_1_1_ROUTE = `${INDICATORS_REPORTS_ROUTE}/${IndicatorsNumber.Indicator9}` as const
export const INDICATORS_REPORT_3_2_1_ROUTE = `${INDICATORS_REPORTS_ROUTE}/${IndicatorsNumber.Indicator10}` as const
export const INDICATORS_REPORT_3_2_2_ROUTE = `${INDICATORS_REPORTS_ROUTE}/${IndicatorsNumber.Indicator11}` as const
export const INDICATORS_REPORT_4_1_1_ROUTE = `${INDICATORS_REPORTS_ROUTE}/${IndicatorsNumber.Indicator12}` as const
export const INDICATORS_REPORT_VULNERABLE_MIGRANT_ROUTE = `${INDICATORS_REPORTS_ROUTE}/${IndicatorsNumber.Indicator13}` as const

export const INDICATORS_FORMS_ROUTE = `${INDICATORS_ROUTE}/indicatorforms` as const
export const INDICATORS_FORM_VIEW_ROUTE = `${INDICATORS_FORMS_ROUTE}/:indicatorNumber/:indicatorRecordId` as const
export const INDICATORS_FORM_1_1_1_VIEW_ROUTE = `${INDICATORS_FORMS_ROUTE}/${IndicatorsFormNumber.Indicator1}/:indicatorRecordId?` as const
export const INDICATORS_FORM_1_2_1_VIEW_ROUTE = `${INDICATORS_FORMS_ROUTE}/${IndicatorsFormNumber.Indicator2}/:indicatorRecordId?` as const
export const INDICATORS_FORM_1_2_2_VIEW_ROUTE = `${INDICATORS_FORMS_ROUTE}/${IndicatorsFormNumber.Indicator3}/:indicatorRecordId?` as const
export const INDICATORS_FORM_2_2_1_VIEW_ROUTE = `${INDICATORS_FORMS_ROUTE}/${IndicatorsFormNumber.Indicator4}/:indicatorRecordId?` as const
export const INDICATORS_FORM_2_2_3_VIEW_ROUTE = `${INDICATORS_FORMS_ROUTE}/${IndicatorsFormNumber.Indicator5}/:indicatorRecordId?` as const
export const INDICATORS_FORM_3_1_1_VIEW_ROUTE = `${INDICATORS_FORMS_ROUTE}/${IndicatorsFormNumber.Indicator6}/:indicatorRecordId?` as const
export const INDICATORS_FORM_VULNERABLE_MIGRANT_VIEW_ROUTE = `${INDICATORS_FORMS_ROUTE}/${IndicatorsFormNumber.Indicator7}/:indicatorRecordId?` as const

export type RouteConfigObject = {
  navigated: boolean
  path: string
  icon?: string
  component: ComponentType<any>
  name: MessageDescriptor
  permissionName?: PermissionMenuName | PermissionGroupName | string
  permissionId?: number | null
  isAuthRequired?: boolean
  children?: Array<RouteConfigObject>
}

const routes: Array<RouteConfigObject> = [
  {
    navigated: true,
    path: DASHBOARDS_ROUTE,
    icon: 'ms-Icon ms-Icon--BIDashboard',
    component: Dashboards,
    name: {
      id: 'navigation.dashboards',
      defaultMessage: 'Дэшборды',
    },
    permissionName: PermissionMenuName.Dashboards,
    permissionId: null,
    isAuthRequired: true,
    children: [
      {
        navigated: true,
        path: DASHBOARD_ROUTE,
        component: Dashboard,
        name: {
          id: 'navigation.dashboards.dashboard',
          defaultMessage: 'Dashboard',
        },
        permissionName: PermissionGroupName.Dashboards,
        permissionId: null,
        isAuthRequired: true,
        children: [],
      },
      {
        navigated: true,
        path: DASHBOARD_REPORT_ROUTE,
        component: Dashboard,
        name: {
          id: 'navigation.dashboards.dashboard',
          defaultMessage: 'Dashboard',
        },
        permissionName: PermissionGroupName.Dashboards,
        permissionId: null,
        isAuthRequired: true,
        children: [],
      },
    ],
  },
  {
    navigated: true,
    path: PARTNERS_ROUTE,
    icon: 'ms-Icon ms-Icon--People',
    component: Organizations,
    name: {
      id: 'navigation.partners',
      defaultMessage: 'Партнеры',
    },
    permissionName: PermissionMenuName.Partners,
    permissionId: null,
    isAuthRequired: true,
    children: [
      {
        navigated: false,
        path: PARTNER_ROUTE,
        component: Partner,
        name: {
          id: 'navigation.partners.partner',
          defaultMessage: 'Партнер',
        },
        isAuthRequired: true,
        children: [
          {
            navigated: true,
            path: PARTNER_BENEFICIARIES_ROUTE,
            component: PartnerBeneficiaries,
            name: {
              id: 'navigation.partners.beneficiaries',
              defaultMessage: 'Бенефициары',
            },
            isAuthRequired: true,
            children: [
              {
                navigated: true,
                path: PARTNER_BENEFICIARIES_INDIVIDUALS_ROUTE,
                component: BeneficiaryIndividual,
                name: {
                  id: 'navigation.partners.beneficiaries.individuals',
                  defaultMessage: 'Физические лица',
                },
                permissionName:
                  PermissionGroupName.PartnersBeneficiaryIndividuals,
                permissionId: null,
                isAuthRequired: true,
                children: [
                  {
                    navigated: true,
                    path: PARTNER_BENEFICIARIES_INDIVIDUAL_ROUTE,
                    component: BeneficiaryIndividualDetails,
                    name: {
                      id: 'navigation.partners.beneficiaries.individuals',
                      defaultMessage: 'Физические лица',
                    },
                    permissionName:
                      PermissionGroupName.PartnersBeneficiaryIndividuals,
                    permissionId: null,
                    isAuthRequired: true,
                    children: [],
                  },
                ],
              },
              {
                navigated: true,
                path: PARTNER_BENEFICIARIES_ORGANIZATIONS_ROUTE,
                component: BeneficiaryOrganizations,
                name: {
                  id: 'navigation.partners.beneficiaries.organizations',
                  defaultMessage: 'Организации',
                },
                permissionName:
                  PermissionGroupName.PartnersBeneficiaryOrganization,
                permissionId: null,
                isAuthRequired: true,
                children: [
                  {
                    navigated: true,
                    path: PARTNER_BENEFICIARIES_ORGANIZATION_DETAILS_ROUTE,
                    component: BeneficiaryOrganizationDetails,
                    name: {
                      id: 'navigation.partners.beneficiaries.organizations',
                      defaultMessage: 'Организации',
                    },
                    permissionName:
                      PermissionGroupName.PartnersBeneficiaryOrganization,
                    permissionId: null,
                    isAuthRequired: true,
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            navigated: true,
            path: PARTNER_SERVICE_HISTORY_ROUTE,
            component: PartnerServiceHistory,
            name: {
              id: 'navigation.partners.service_history',
              defaultMessage: 'История оказания услуг',
            },
            permissionName: PermissionGroupName.PartnersServiceHistory,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: PARTNER_ACTIVITIES_ROUTE,
            component: PartnerActivities,
            name: {
              id: 'partners.beneficiaries.activities',
              defaultMessage: 'Мероприятия',
            },
            isAuthRequired: true,
            permissionName: PermissionGroupName.PartnersActivities,
            permissionId: null,
            children: [
              {
                navigated: true,
                path: PARTNER_ACTIVITIES_CREATE_ROUTE,
                component: ActivityForm,
                name: {
                  id: 'activities.create_activity',
                  defaultMessage: 'Создать мероприятие',
                },
                isAuthRequired: true,
                permissionName: PermissionGroupName.PartnersActivities,
                permissionId: null,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_ACTIVITY_DETAILS_ROUTE,
                component: PartnerActivity,
                name: {
                  id: 'partners.beneficiaries.activity',
                  defaultMessage: 'Мероприятие',
                },
                permissionName: PermissionGroupName.PartnersActivities,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_ACTIVITY_DETAILS_EDIT_ROUTE,
                component: ActivityForm,
                name: {
                  id: 'activities.edit_activity',
                  defaultMessage: 'Редактировать мероприятие',
                },
                isAuthRequired: true,
                permissionName: PermissionGroupName.PartnersActivities,
                permissionId: null,
                children: [],
              },
            ],
          },
          {
            navigated: true,
            path: PARTNER_INDICATORS_ROUTE,
            component: PartnerIndicators,
            name: {
              id: 'navigation.partners.indicators',
              defaultMessage: 'Индикаторы',
            },
            permissionName: PermissionGroupName.PartnersIndicators,
            permissionId: null,
            isAuthRequired: true,
            children: [
              {
                navigated: true,
                path: PARTNER_INDICATORS_1_1_1_ROUTE,
                component: Indicator111Form,
                name: {
                  id: 'navigation.partners.indicators',
                  defaultMessage: 'Индикаторы',
                },
                permissionName: PermissionGroupName.PartnersIndicators,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_INDICATORS_1_1_1_VIEW_ROUTE,
                component: Indicator111Form,
                name: {
                  id: 'navigation.partners.indicators',
                  defaultMessage: 'Индикаторы',
                },
                permissionName: PermissionGroupName.PartnersIndicators,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_INDICATORS_1_2_1_ROUTE,
                component: Indicator121Form,
                name: {
                  id: 'navigation.partners.indicators',
                  defaultMessage: 'Индикаторы',
                },
                permissionName: PermissionGroupName.PartnersIndicators,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_INDICATORS_1_2_1_VIEW_ROUTE,
                component: Indicator121Form,
                name: {
                  id: 'navigation.partners.indicators',
                  defaultMessage: 'Индикаторы',
                },
                permissionName: PermissionGroupName.PartnersIndicators,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_INDICATORS_1_2_2_ROUTE,
                component: Indicator122Form,
                name: {
                  id: 'navigation.partners.indicators',
                  defaultMessage: 'Индикаторы',
                },
                permissionName: PermissionGroupName.PartnersIndicators,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_INDICATORS_1_2_2_VIEW_ROUTE,
                component: Indicator122Form,
                name: {
                  id: 'navigation.partners.indicators',
                  defaultMessage: 'Индикаторы',
                },
                permissionName: PermissionGroupName.PartnersIndicators,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_INDICATORS_2_2_1_ROUTE,
                component: Indicator221Form,
                name: {
                  id: 'navigation.partners.indicators',
                  defaultMessage: 'Индикаторы',
                },
                permissionName: PermissionGroupName.PartnersIndicators,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_INDICATORS_2_2_1_VIEW_ROUTE,
                component: Indicator221Form,
                name: {
                  id: 'navigation.partners.indicators',
                  defaultMessage: 'Индикаторы',
                },
                permissionName: PermissionGroupName.PartnersIndicators,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_INDICATORS_2_2_3_ROUTE,
                component: Indicator223Form,
                name: {
                  id: 'navigation.partners.indicators',
                  defaultMessage: 'Индикаторы',
                },
                permissionName: PermissionGroupName.PartnersIndicators,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_INDICATORS_2_2_3_VIEW_ROUTE,
                component: Indicator223Form,
                name: {
                  id: 'navigation.partners.indicators',
                  defaultMessage: 'Индикаторы',
                },
                permissionName: PermissionGroupName.PartnersIndicators,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_INDICATORS_3_1_1_ROUTE,
                component: Indicator311Form,
                name: {
                  id: 'navigation.partners.indicators',
                  defaultMessage: 'Индикаторы',
                },
                permissionName: PermissionGroupName.PartnersIndicators,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_INDICATORS_3_1_1_VIEW_ROUTE,
                component: Indicator311Form,
                name: {
                  id: 'navigation.partners.indicators',
                  defaultMessage: 'Индикаторы',
                },
                permissionName: PermissionGroupName.PartnersIndicators,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_INDICATORS_VULNERABLE_MIGRANT_ROUTE,
                component: IndicatorVulnerableMigrantForm,
                name: {
                  id: 'navigation.partners.indicators',
                  defaultMessage: 'Индикаторы',
                },
                permissionName: PermissionGroupName.PartnersIndicators,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_INDICATORS_VULNERABLE_MIGRANT_VIEW_ROUTE,
                component: IndicatorVulnerableMigrantForm,
                name: {
                  id: 'navigation.partners.indicators',
                  defaultMessage: 'Индикаторы',
                },
                permissionName: PermissionGroupName.PartnersIndicators,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
            ],
          },
          {
            navigated: false,
            path: PARTNER_SETTINGS_ROUTE,
            icon: 'ms-Icon ms-Icon--Settings',
            component: PartnerSettings,
            name: {
              id: 'navigation.partners.partner.settings',
              defaultMessage: 'Настройки',
            },
            isAuthRequired: true,
            children: [
              {
                navigated: true,
                path: PARTNER_SETTINGS_STAFF_ROUTE,
                component: PartnerStaff,
                name: {
                  id: 'navigation.partners.partner.settings.staff',
                  defaultMessage: 'Сотрудники',
                },
                permissionName: PermissionGroupName.PartnersStaff,
                permissionId: null,
                isAuthRequired: true,
                children: [],
              },
              {
                navigated: true,
                path: PARTNER_SETTINGS_SERVICES_ROUTE,
                component: PartnersServices,
                name: {
                  id: 'navigation.partners.partner.settings.services',
                  defaultMessage: 'Услуги',
                },
                permissionName: PermissionGroupName.PartnersServiceTypes,
                permissionId: null,
                isAuthRequired: true,
                children: [
                  {
                    navigated: true,
                    path: PARTNER_SETTINGS_SERVICES_EDIT_ROUTE,
                    component: PartnersServicesSettings,
                    name: {
                      id: 'navigation.partners.partner.settings.services',
                      defaultMessage: 'Услуги',
                    },
                    permissionName: PermissionGroupName.PartnersServiceTypes,
                    permissionId: null,
                    isAuthRequired: true,
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    navigated: true,
    path: BENEFICIARIES_ROUTE,
    icon: 'ms-Icon ms-Icon--Family',
    component: Beneficiaries,
    name: {
      id: 'navigation.beneficiaries',
      defaultMessage: 'Бенефициары',
    },
    permissionName: PermissionMenuName.Beneficiaries,
    permissionId: null,
    isAuthRequired: true,
    children: [
      {
        navigated: true,
        path: BENEFICIARIES_INDIVIDUAL_ROUTE,
        component: BeneficiariesIndividuals,
        name: {
          id: 'navigation.beneficiaries.individuals',
          defaultMessage: 'Физические лица',
        },
        permissionName: PermissionGroupName.Beneficiaries,
        permissionId: null,
        isAuthRequired: true,
        children: [
          {
            navigated: true,
            path: BENEFICIARIES_INDIVIDUAL_DETAILS_ROUTE,
            component: BeneficiaryIndividualDetails,
            name: {
              id: 'navigation.partners.beneficiaries.individuals',
              defaultMessage: 'Физические лица',
            },
            permissionName: PermissionGroupName.Beneficiaries,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
        ],
      },
      {
        navigated: true,
        path: BENEFICIARIES_ORGANIZATIONS_ROUTE,
        component: BeneficiariesOrganizations,
        name: {
          id: 'navigation.beneficiaries.organizations',
          defaultMessage: 'Организации',
        },
        permissionName: PermissionGroupName.Beneficiaries,
        permissionId: null,
        isAuthRequired: true,
        children: [
          {
            navigated: true,
            path: BENEFICIARIES_ORGANIZATION_DETAILS_ROUTE,
            component: BeneficiaryOrganizationDetails,
            name: {
              id: 'navigation.beneficiaries.organizations',
              defaultMessage: 'Организации',
            },
            permissionName: PermissionGroupName.Beneficiaries,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
        ],
      },
    ],
  },
  {
    navigated: true,
    path: ACTIVITY_ROUTE,
    icon: 'ms-Icon ms-Icon--Event',
    component: Activity,
    name: {
      id: 'navigation.activities',
      defaultMessage: 'Мероприятия',
    },
    permissionName: PermissionMenuName.Activities,
    permissionId: null,
    isAuthRequired: true,
    children: [
      {
        navigated: true,
        path: ACTIVITY_DETAILS_ROUTE,
        component: PartnerActivity,
        name: {
          id: 'partners.beneficiaries.activity',
          defaultMessage: 'Мероприятие',
        },
        permissionName: PermissionGroupName.Activities,
        permissionId: null,
        isAuthRequired: true,
        children: [],
      },
    ],
  },
  {
    navigated: true,
    path: SERVICE_HISTORY_ROUTE,
    icon: 'ms-Icon ms-Icon--DocLibrary',
    component: ServiceHistory,
    name: {
      id: 'navigation.service_history',
      defaultMessage: 'История оказния услуг',
    },
    permissionName: PermissionMenuName.ServiceHistory,
    permissionId: null,
    isAuthRequired: true,
    children: [],
  },
  {
    navigated: true,
    path: USERS_ROUTE,
    icon: 'ms-Icon ms-Icon--UserFollowed',
    component: Users,
    name: {
      id: 'navigation.users',
      defaultMessage: 'Пользователи',
    },
    permissionName: PermissionMenuName.Users,
    permissionId: null,
    isAuthRequired: true,
    children: [],
  },
  {
    navigated: true,
    path: CATALOGS_ROUTE,
    icon: 'ms-Icon ms-Icon--DietPlanNotebook',
    component: Catalogs,
    name: {
      id: 'navigation.catalogs',
      defaultMessage: 'Справочники',
    },
    permissionName: PermissionMenuName.Catalogs,
    permissionId: null,
    isAuthRequired: true,
    children: [
      {
        navigated: true,
        path: CATALOGS_GENDER_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.gender',
          defaultMessage: 'Пол',
        },
        permissionName: PermissionGroupName.Genders,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_ETHNICITY_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.ethnicity',
          defaultMessage: 'Этническая принадлежность',
        },
        permissionName: PermissionGroupName.Ethnicities,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_BENEFICIARY_ROUTE,
        component: Beneficiary,
        name: {
          id: 'catalogs.beneficiary',
          defaultMessage: 'Бенефициары',
        },
        permissionName: PermissionGroupName.BeneficiaryCategories,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_TYPES_OF_ACTIVITIES_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.activityTypes',
          defaultMessage: 'Виды мероприятий',
        },
        permissionName: PermissionGroupName.ActivityTypes,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_ADVOCACY_ISSUES_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.advocacyIssue',
          defaultMessage: 'Вопрос адвокации',
        },
        permissionName: PermissionGroupName.AdvocacyIssue,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_ADVOCACY_TYPES_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.advocacyType',
          defaultMessage: 'Виды адвокации',
        },
        permissionName: PermissionGroupName.AdvocacyTypes,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_SUPPORT_TYPES_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.supportType',
          defaultMessage: 'Виды поддержки/мероприятия',
        },
        permissionName: PermissionGroupName.SupportTypes,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_INFLUENCE_TYPES_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.influence_types',
          defaultMessage: 'Виды произведенного воздействия',
        },
        permissionName: PermissionGroupName.InfluenceTypes,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_ADVOCACY_INFLUENCE_LEVEL_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.AdvocacyInfluenceLevel',
          defaultMessage: 'Уровни влияния адвокации',
        },
        permissionName: PermissionGroupName.AdvocacyInfluenceLevel,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_DEVELOPMENT_TYPES_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.development_stages',
          defaultMessage: 'Этапы разработки',
        },
        permissionName: PermissionGroupName.DevelopmentStages,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_DOCUMENT_TYPE_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.documentType',
          defaultMessage: 'Тип документа',
        },
        permissionName: PermissionGroupName.DocumentType,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_IMPROVEMENT_AREA_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.improvement_areas',
          defaultMessage: 'Области улучшения',
        },
        permissionName: PermissionGroupName.ImprovementArea,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_DATA_TYPES_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.dataTypes',
          defaultMessage: 'Тип данных',
        },
        permissionName: PermissionGroupName.DataTypes,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_DATA_COLLECTION_FREQUENCY_TYPES_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.dataCollectionFrequency',
          defaultMessage: 'Частота сбора данных и обмена',
        },
        permissionName: PermissionGroupName.DataCollectionFrequency,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_CSO_TYPES_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.CSOTypes',
          defaultMessage: 'Тип организации гражданского общества',
        },
        permissionName: PermissionGroupName.CSOTypes,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_BUSINESS_SCALE_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.business_scale',
          defaultMessage: 'Размер бизнеса',
        },
        permissionName: PermissionGroupName.BusinessScale,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_LEGAL_ENTITY_TYPES_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.entity_type',
          defaultMessage: 'Типы организации/юридического лица',
        },
        permissionName: PermissionGroupName.LegalEntityTypes,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_SUPPORTING_DOCUMENTS_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.supporting_documents',
          defaultMessage: 'Сопроводительные документы',
        },
        permissionName: PermissionGroupName.SupportingDocuments,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_INDUSTRY_TYPES_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.industry_type',
          defaultMessage: 'Вид отрасли',
        },
        permissionName: PermissionGroupName.IndustryTypes,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_COLLABORATION_AREAS_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.collaboration_areas',
          defaultMessage: 'Области сотрудничества',
        },
        permissionName: PermissionGroupName.CollaborationAreas,
        permissionId: null,
        isAuthRequired: true,
      },

      {
        navigated: true,
        path: CATALOGS_TRAFFICKING_FORMS_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.traffickingForms',
          defaultMessage: 'Формы торговли людьми',
        },
        permissionName: PermissionGroupName.TraffickingForms,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_IDENTIFYING_AGENCIES_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.identifyingAgencies',
          defaultMessage: 'Идентифицирующее учреждение',
        },
        permissionName: PermissionGroupName.IdentifyingAgencies,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_TIP_CASE_STATUTES_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.tipCaseStatuses',
          defaultMessage: 'Статус дела о ТЛ',
        },
        permissionName: PermissionGroupName.TipCaseStatuses,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_TYPES_OF_ORGANIZATION_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.organization_types',
          defaultMessage: 'Типы организации',
        },
        permissionName: PermissionGroupName.OrganizationTypes,
        permissionId: null,
        isAuthRequired: true,
      },
      {
        navigated: true,
        path: CATALOGS_TYPES_OF_SERVICE_ROUTE,
        component: CatalogsLayout,
        name: {
          id: 'catalogs.typesOfServices',
          defaultMessage: 'Типы услуг',
        },
        permissionName: PermissionGroupName.ServiceTypes,
        permissionId: null,
        isAuthRequired: true,
      },
    ],
  },
  {
    navigated: true,
    path: INDICATORS_ROUTE,
    icon: 'ms-Icon ms-Icon--TextDocument',
    component: Indicators,
    name: {
      id: 'global.indicators',
      defaultMessage: 'Indicators',
    },
    permissionName: PermissionMenuName.Indicators,
    permissionId: null,
    isAuthRequired: true,
    children: [
      {
        navigated: true,
        path: INDICATORS_FORMS_ROUTE,
        component: IndicatorForms,
        name: {
          id: 'indicators.indicator_records',
          defaultMessage: 'Записи по Индикаторам',
        },
        permissionName: PermissionGroupName.Indicators,
        permissionId: null,
        isAuthRequired: true,
        children: [
          {
            navigated: true,
            path: INDICATORS_FORM_1_1_1_VIEW_ROUTE,
            component: Indicator111Form,
            name: {
              id: 'navigation.partners.indicators',
              defaultMessage: 'Индикаторы',
            },
            permissionName: PermissionGroupName.PartnersIndicators,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_FORM_1_2_1_VIEW_ROUTE,
            component: Indicator121Form,
            name: {
              id: 'navigation.partners.indicators',
              defaultMessage: 'Индикаторы',
            },
            permissionName: PermissionGroupName.PartnersIndicators,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_FORM_1_2_2_VIEW_ROUTE,
            component: Indicator122Form,
            name: {
              id: 'navigation.partners.indicators',
              defaultMessage: 'Индикаторы',
            },
            permissionName: PermissionGroupName.PartnersIndicators,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_FORM_2_2_1_VIEW_ROUTE,
            component: Indicator221Form,
            name: {
              id: 'navigation.partners.indicators',
              defaultMessage: 'Индикаторы',
            },
            permissionName: PermissionGroupName.PartnersIndicators,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_FORM_2_2_3_VIEW_ROUTE,
            component: Indicator223Form,
            name: {
              id: 'navigation.partners.indicators',
              defaultMessage: 'Индикаторы',
            },
            permissionName: PermissionGroupName.PartnersIndicators,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_FORM_3_1_1_VIEW_ROUTE,
            component: Indicator311Form,
            name: {
              id: 'navigation.partners.indicators',
              defaultMessage: 'Индикаторы',
            },
            permissionName: PermissionGroupName.PartnersIndicators,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_FORM_VULNERABLE_MIGRANT_VIEW_ROUTE,
            component: IndicatorVulnerableMigrantForm,
            name: {
              id: 'navigation.partners.indicators',
              defaultMessage: 'Индикаторы',
            },
            permissionName: PermissionGroupName.PartnersIndicators,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
        ],
      },
      {
        navigated: true,
        path: INDICATORS_TARGETS_ROUTE,
        component: IndicatorsTargets,
        name: {
          id: 'indicators.targets',
          defaultMessage: 'Indicator targets',
        },
        permissionId: null,
        isAuthRequired: true,
        children: [
          {
            navigated: false,
            path: INDICATOR_TARGETS_ROUTE,
            component: IndicatorsTargetsForm,
            name: {
              id: 'indicators.targets',
              defaultMessage: 'indicator targets',
            },
            isAuthRequired: true,
            permissionId: null,
          },
        ],
      },
      {
        navigated: true,
        path: INDICATORS_REPORTS_ROUTE,
        component: IndicatorsReports,
        name: {
          id: 'indicators.reports',
          defaultMessage: 'Reports',
        },
        permissionName: PermissionGroupName.IndicatorsReports,
        permissionId: null,
        isAuthRequired: true,
        children: [
          {
            navigated: true,
            path: INDICATORS_REPORT_1_1_1_ROUTE,
            component: Indicator111Report,
            name: {
              id: 'indicators.report111',
              defaultMessage: 'Indicator 111',
            },
            permissionName: PermissionGroupName.IndicatorsReports,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_REPORT_2_1_1_ROUTE,
            component: Indicator211Report,
            name: {
              id: 'indicators.report211',
              defaultMessage: 'Indicator 211',
            },
            permissionName: PermissionGroupName.IndicatorsReports,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_REPORT_2_1_2_ROUTE,
            component: Indicator212Report,
            name: {
              id: 'indicators.report212',
              defaultMessage: 'Indicator 212',
            },
            permissionName: PermissionGroupName.IndicatorsReports,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_REPORT_2_2_1_ROUTE,
            component: Indicator221Report,
            name: {
              id: 'indicators.report221',
              defaultMessage: 'Indicator 221',
            },
            permissionName: PermissionGroupName.IndicatorsReports,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_REPORT_2_2_2_ROUTE,
            component: Indicator222Report,
            name: {
              id: 'indicators.report222',
              defaultMessage: 'Indicator 222',
            },
            permissionName: PermissionGroupName.IndicatorsReports,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_REPORT_2_2_3_ROUTE,
            component: Indicator223Report,
            name: {
              id: 'indicators.report223',
              defaultMessage: 'Indicator 223',
            },
            permissionName: PermissionGroupName.IndicatorsReports,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_REPORT_3_1_1_ROUTE,
            component: Indicator311Report,
            name: {
              id: 'indicators.report311',
              defaultMessage: 'Indicator 311',
            },
            permissionName: PermissionGroupName.IndicatorsReports,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_REPORT_3_2_1_ROUTE,
            component: Indicator321Report,
            name: {
              id: 'indicators.report321',
              defaultMessage: 'Indicator 321',
            },
            permissionName: PermissionGroupName.IndicatorsReports,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_REPORT_3_2_2_ROUTE,
            component: Indicator322Report,
            name: {
              id: 'indicators.report322',
              defaultMessage: 'Indicator 322',
            },
            permissionName: PermissionGroupName.IndicatorsReports,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_REPORT_1_2_1_ROUTE,
            component: Indicator121Report,
            name: {
              id: 'indicators.report121',
              defaultMessage: 'Indicator 121',
            },
            permissionName: PermissionGroupName.IndicatorsReports,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_REPORT_1_2_2_ROUTE,
            component: Indicator122Report,
            name: {
              id: 'indicators.report122',
              defaultMessage: 'Indicator 122',
            },
            permissionName: PermissionGroupName.IndicatorsReports,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_REPORT_4_1_1_ROUTE,
            component: Indicator411Report,
            name: {
              id: 'indicators.report411',
              defaultMessage: 'Indicator 411',
            },
            permissionName: PermissionGroupName.IndicatorsReports,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
          {
            navigated: true,
            path: INDICATORS_REPORT_VULNERABLE_MIGRANT_ROUTE,
            component: IndicatorVulnerableMigrantReport,
            name: {
              id: 'indicators.reportVulnerableMigrant',
              defaultMessage: 'Indicator VulnerableMigrant',
            },
            permissionName: PermissionGroupName.IndicatorsReports,
            permissionId: null,
            isAuthRequired: true,
            children: [],
          },
        ],
      },
    ],
  },
  {
    navigated: true,
    path: SETTINGS_ROUTE,
    icon: 'ms-Icon ms-Icon--Settings',
    component: SystemSettings,
    name: {
      id: 'navigation.system_settings',
      defaultMessage: 'System settings',
    },
    permissionName: PermissionMenuName.SystemSettings,
    permissionId: null,
    isAuthRequired: true,
    children: [
      {
        navigated: false,
        path: SETTINGS_ROLES_PERMISSIONS_ROUTE,
        icon: 'ms-Icon ms-Icon--Settings',
        component: RolePermissionsSettings,
        name: {
          id: 'global.roles',
          defaultMessage: 'Roles',
        },
        permissionName: PermissionGroupName.RolesPermissions,
        permissionId: null,
        isAuthRequired: true,
        children: [],
      },
      {
        navigated: false,
        path: SETTINGS_USERS_PERMISSIONS_ROUTE,
        icon: 'ms-Icon ms-Icon--Settings',
        component: UserPermissionsSettings,
        name: {
          id: 'navigation.users',
          defaultMessage: 'Пользователи',
        },
        permissionName: PermissionGroupName.UsersPermissions,
        permissionId: null,
        isAuthRequired: true,
        children: [],
      },
    ],
  },
]

export default routes
