import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const IndicatorTargetYearsDto = types.model('IndicatorTargetYearsDto', {
  countryId: types.number,
  target: types.union(types.number, types.string),
  year: types.number,
  countryCode: types.maybeNull(types.string),
})

export interface IIndicatorTargetYearsDto
  extends Instance<typeof IndicatorTargetYearsDto> {}
export interface IIndicatorTargetYearsDtoSnapshotIn
  extends SnapshotIn<typeof IndicatorTargetYearsDto> {}
export interface IIndicatorTargetYearsDtoSnapshotOut
  extends SnapshotOut<typeof IndicatorTargetYearsDto> {}
export default IndicatorTargetYearsDto
