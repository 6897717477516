import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UserModel = types.model('UserModel', {
  id: types.identifier,
  email: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  countryId: types.integer,
  country: types.maybeNull(types.string),
  organizationId: types.integer,
  organization: types.maybeNull(types.string),
  roleName: types.maybeNull(types.string),
  blockUser: types.optional(types.boolean, true),
  isConfirmed: types.optional(types.boolean, true),
  canChangePassword: types.optional(types.boolean, true),
})

export interface IUserModel extends Instance<typeof UserModel> {}
export interface IUserSnapshotIn extends SnapshotIn<typeof UserModel> {}
export interface IUserSnapshotOut extends SnapshotOut<typeof UserModel> {}
export default UserModel
