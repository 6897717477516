import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const Indicator1_2_2ReportModel = types.model('Indicator1_2_2ReportModel', {
  partnerName: types.maybeNull(types.string),
  organizationName: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  organizationType: types.maybeNull(types.string),
  supportTypes: types.array(types.string),
  dataTypes: types.array(types.string),
  dataCollectionFrequency: types.maybeNull(types.string),
})

export interface IIndicator1_2_2ReportModel
  extends Instance<typeof Indicator1_2_2ReportModel> {}
export interface IIndicator1_2_2ReportSnapshotIn
  extends SnapshotIn<typeof Indicator1_2_2ReportModel> {}
export interface IIndicator1_2_2ReportSnapshotOut
  extends SnapshotOut<typeof Indicator1_2_2ReportModel> {}
export default Indicator1_2_2ReportModel
