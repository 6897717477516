import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const RecruitmentToolLookUpModel = LookUpModel.named('RecruitmentToolLookUpModel').props({
    isComment: types.boolean
})

export interface IRecruitmentToolLookUp
    extends Instance<typeof RecruitmentToolLookUpModel> {}

export interface IRecruitmentToolLookUpSnapshotIn
    extends SnapshotIn<typeof RecruitmentToolLookUpModel> {}

export interface IRecruitmentToolLookUpSnapshotOut
    extends SnapshotOut<typeof RecruitmentToolLookUpModel> {}

export default RecruitmentToolLookUpModel