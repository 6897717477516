import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import IndicatorLookUpModel from '../lookUps/IndicatorLookUpModel'
import LookUpModel from '../lookUps/LookUpModel'

const ActivityApprovalModel = types.model('ActivityApprovalModel', {
  id: types.optional(types.integer, 0),
  name: types.maybeNull(types.string),
  indicators: types.maybeNull(types.array(IndicatorLookUpModel)),
  countryId: types.integer,
  country: types.maybeNull(types.string),
  typeId: types.integer,
  type: types.maybeNull(types.string),
  activityType: types.maybeNull(types.string),
  activityTypeId: types.optional(types.number, 0),
  organizationId: types.integer,
  organization: types.maybeNull(types.string),
  statusId: types.integer,
  status: types.maybeNull(types.string),
  canBeReviewed: types.boolean,
  startDate: types.string,
  endDate: types.string,
  relatedToCovid19: types.maybeNull(types.boolean),
  attendeesCount: types.optional(types.number, 0),
  memberTypes: types.array(LookUpModel),
  organizationTypes: types.array(LookUpModel),
})

export interface IActivityApprovalModel
  extends Instance<typeof ActivityApprovalModel> {}
export interface IActivityApprovalModelSnapshotIn
  extends SnapshotIn<typeof ActivityApprovalModel> {}
export interface IActivityApprovalModelSnapshotOut
  extends SnapshotOut<typeof ActivityApprovalModel> {}
export default ActivityApprovalModel
