import styles from './Toast.module.scss'
import { toast } from 'react-toastify'
import { SuccessIcon, WarnIcon, ErrorIcon, InfoIcon } from '../../icons'
import { FC, ReactNode } from 'react'

export declare type ToastType = 'info' | 'success' | 'error' | 'warn'

interface Props {
  message: ReactNode
  type: ToastType
}

const Toast: FC<Props> = props => {
  return (
    <>
      <div className={[styles.stick, styles[props.type]].join(' ')} />
      <div className={styles.iconWrapper}>
        {props.type === 'success' ? (
          <SuccessIcon />
        ) : props.type === 'error' ? (
          <ErrorIcon />
        ) : props.type === 'warn' ? (
          <WarnIcon />
        ) : (
          <InfoIcon />
        )}
      </div>
      <p className={styles.message}>{props.message}</p>
      <div className={styles.closeButton}>
        <i className="ms-Icon ms-Icon--Cancel" />
      </div>
    </>
  )
}

export const sendNotification = (message: ReactNode, type: ToastType) => {
  toast(<Toast message={message} type={type} />, {
    hideProgressBar: true,
    draggable: true,
    progress: undefined,
    closeButton: false,
    bodyClassName: styles.body,
  })
}

export default Toast
