import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import { apiV1 } from 'core/requests'
import {
  CreateStaffCommand,
  ICreateStaffCommandSnapshotIn,
  IStaffListRequestSnapshotIn,
  IStaffPagedDtoSnapshotIn,
  IStaffPagedDtoSnapshotOut,
  IStaffSnapshotOut,
  IUpdateStaffCommandSnapshotIn,
  StaffListRequestModel,
  StaffPagedDto,
  UpdateStaffCommand,
} from 'stores/models'

const PartnersStaffStore = types
  .model('PartnersStaffStore')
  .props({
    _staff: types.optional(StaffPagedDto, {}),
    _isLoading: types.optional(types.boolean, false),
  })
  .actions(self => {
    const STAFF_API = '/api/staff'

    const setStaff = (staff: IStaffPagedDtoSnapshotIn): void => {
      applySnapshot(self._staff, staff)
    }

    const setIsLoading = (isLoading: boolean) => {
      self._isLoading = isLoading
    }

    const getStaffListByOrganizationId = async (
      params: IStaffListRequestSnapshotIn,
    ) => {
      const staffListRequest = fromPromise(
        apiV1.get<IStaffPagedDtoSnapshotIn>(STAFF_API, {
          params: StaffListRequestModel.create(params),
        }),
      )

      when(() =>
        staffListRequest.case({
          fulfilled: response => {
            setStaff(response.data)
            return true
          },
        }),
      )
      return staffListRequest
    }

    const createStaff = async (
      staff: ICreateStaffCommandSnapshotIn,
    ): Promise<void> => apiV1.post(STAFF_API, CreateStaffCommand.create(staff))

    const editStaff = async (
      staff: IUpdateStaffCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.put(STAFF_API, UpdateStaffCommand.create(staff), {
        params: { id: staff.id },
      })

    const deleteEmployee = async (
      employeeId: IStaffSnapshotOut['id'],
    ): Promise<void> =>
      apiV1.delete(STAFF_API, {
        params: { id: employeeId },
      })

    const deactivateEmployee = async (
      employeeId: IStaffSnapshotOut['id'],
    ): Promise<void> =>
      apiV1.put(`${STAFF_API}/deactivate`, null, {
        params: { id: employeeId },
      })

    const activateEmployee = async (
      employeeId: IStaffSnapshotOut['id'],
    ): Promise<void> =>
      apiV1.put(`${STAFF_API}/activate`, null, {
        params: { id: employeeId },
      })

    return {
      getStaffListByOrganizationId,
      createStaff,
      editStaff,
      activateEmployee,
      deactivateEmployee,
      deleteEmployee,
      setIsLoading,
    }
  })
  .views(self => ({
    get staff(): IStaffPagedDtoSnapshotOut {
      return getSnapshot(self._staff)
    },
    get staffList() {
      return this.staff.data
    },
    get staffMetadata() {
      return this.staff.metadata
    },
    get isLoading() {
      return self._isLoading
    },
  }))

export default PartnersStaffStore
export interface IPartnersStaffStore
  extends Instance<typeof PartnersStaffStore> {}
