enum IndicatorsFormNumber {
  Indicator1 = '1.1.1',
  Indicator2 = '1.2.1',
  Indicator3 = '1.2.2',
  Indicator4 = '2.2.1',
  Indicator5 = '2.2.3',
  Indicator6 = '3.1.1',
  Indicator7 = 'VulMigrant'
}

export default IndicatorsFormNumber
