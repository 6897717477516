import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const IndicatorTargetYearsModel = LookUpModel.named('IndicatorTargetYearsModel')

export interface IIndicatorTargetYearsModel
  extends Instance<typeof IndicatorTargetYearsModel> {}
export interface IIndicatorTargetYearsSnapshotIn
  extends SnapshotIn<typeof IndicatorTargetYearsModel> {}
export interface IIndicatorTargetYearsSnapshotOut
  extends SnapshotOut<typeof IndicatorTargetYearsModel> {}
export default IndicatorTargetYearsModel
