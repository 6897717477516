import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import IndicatorReportsFilterBaseModel from '../IndicatorReportsFilterBaseModel'

const Indicator3_1_1ReportFilterModel = IndicatorReportsFilterBaseModel.named(
  'Indicator3_1_1ReportFilterModel',
).props({
  IsFormal: types.union(types.boolean, types.undefined, types.null),
  IdentifyingAgencyId: types.union(types.number, types.undefined, types.null),
  GenderId: types.union(types.number, types.undefined, types.null),
  AgeCategoryEnum: types.union(types.number, types.undefined, types.null),
  EthnicityId: types.union(types.number, types.undefined, types.null),
  CountryOfOriginId: types.union(types.number, types.undefined, types.null),
  TraffickingFormId: types.union(types.number, types.undefined, types.null),
})

export interface IIndicator3_1_1ReportFilterModel
  extends Instance<typeof Indicator3_1_1ReportFilterModel> {}

export interface IIndicator3_1_1ReportFilterSnapshotIn
  extends SnapshotIn<typeof Indicator3_1_1ReportFilterModel> {}

export interface IIndicator3_1_1ReportFilterSnapshotOut
  extends SnapshotOut<typeof Indicator3_1_1ReportFilterModel> {}

export default Indicator3_1_1ReportFilterModel
