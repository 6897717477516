import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from 'stores/models/PagedListMetaData'
import ActivityTestResultModel from './ActivityTestResultModel'

const ActivityTestResultPagedDto = types.model('ActivityTestResultPagedDto', {
  data: types.array(ActivityTestResultModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export interface IActivityTestResultPagedDto
  extends Instance<typeof ActivityTestResultPagedDto> {}

export interface IActivityTestResultPagedDtoSnapshotIn
  extends SnapshotIn<typeof ActivityTestResultPagedDto> {}

export interface IActivityTestResultPagedDtoSnapshotOut
  extends SnapshotOut<typeof ActivityTestResultPagedDto> {}

export default ActivityTestResultPagedDto
