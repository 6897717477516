import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import CatalogModel from './CatalogModel'
import IndicatorModel from './IndicatorModel'

const BeneficiaryModel = CatalogModel.named('BeneficiaryModel').props({
  isActive: types.optional(types.boolean, false),
  indicators: types.array(IndicatorModel),
})

export interface IBeneficiaryModel extends Instance<typeof BeneficiaryModel> {}

export interface IBeneficiarySnapshotIn
  extends SnapshotIn<typeof BeneficiaryModel> {}

export interface IBeneficiarySnapshotOut
  extends SnapshotOut<typeof BeneficiaryModel> {}

export default BeneficiaryModel
