import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const ActivityParticipantTypeLookUpModel = LookUpModel.named(
  'ActivityParticipantTypeLookUpModel',
)

export interface IActivityParticipantTypeLookUp
  extends Instance<typeof ActivityParticipantTypeLookUpModel> {}

export interface IActivityParticipantTypeLookUpSnapshotIn
  extends SnapshotIn<typeof ActivityParticipantTypeLookUpModel> {}

export interface IActivityParticipantTypeLookUpSnapshotOut
  extends SnapshotOut<typeof ActivityParticipantTypeLookUpModel> {}

export default ActivityParticipantTypeLookUpModel
