import { MessageDescriptor } from '@formatjs/intl'

export const pageSizes = [10, 25, 50, 100] as const

export const PARTNERS_LIST_SORTING_KEY = 'PARTNERS_LIST_SORTING_KEY'
export const PARTNERS_LIST_PAGINATION_KEY = 'PARTNERS_LIST_PAGINATION_KEY'
export const PARTNERS_LIST_FILTERS_KEY = 'PARTNERS_LIST_FILTERS_KEY'
export const PARTNERS_LIST_TABLE_COLUMNS_SIZES_KEY =
  'PARTNERS_LIST_TABLE_COLUMNS_SIZES_KEY'

export const PARTNERS_STAFF_SORTING_KEY = 'PARTNERS_STAFF_SORTING_KEY'
export const PARTNERS_STAFF_PAGINATION_KEY = 'PARTNERS_STAFF_PAGINATION_KEY'
export const PARTNERS_STAFF_TABLE_COLUMNS_SIZES_KEY =
  'PARTNERS_STAFF_TABLE_COLUMNS_SIZES_KEY'

export const PARTNERS_SERVICE_HISTORY_SORTING_KEY =
  'PARTNERS_SERVICE_HISTORY_SORTING_KEY'
export const PARTNERS_SERVICE_HISTORY_PAGINATION_KEY =
  'PARTNERS_SERVICE_HISTORY_PAGINATION_KEY'
export const PARTNERS_SERVICE_HISTORY_FILTERS_KEY =
  'PARTNERS_SERVICE_HISTORY_FILTERS_KEY'
export const PARTNERS_SERVICE_HISTORY_TABLE_COLUMNS_SIZES_KEY =
  'PARTNERS_SERVICE_HISTORY_TABLE_COLUMNS_SIZES_KEY'

export const PARTNERS_INDICATORS_SORTING_KEY = 'PARTNERS_INDICATORS_SORTING_KEY'
export const PARTNERS_INDICATORS_PAGINATION_KEY =
  'PARTNERS_INDICATORS_PAGINATION_KEY'
export const PARTNERS_INDICATORS_FILTERS_KEY = 'PARTNERS_INDICATORS_FILTERS_KEY'
export const PARTNERS_INDICATORS_TABLE_COLUMNS_SIZES_KEY =
  'PARTNERS_INDICATORS_TABLE_COLUMNS_SIZES_KEY'

export const ACTIVITY_SORTING_KEY = 'ACTIVITY_SORTING_KEY'
export const ACTIVITY_PAGINATION_KEY = 'ACTIVITY_PAGINATION_KEY'
export const ACTIVITY_FILTERS_KEY = 'ACTIVITY_FILTERS_KEY'
export const ACTIVITY_TABLE_COLUMNS_SIZES_KEY =
  'ACTIVITY_TABLE_COLUMNS_SIZES_KEY'

export const SERVICE_HISTORY_SORTING_KEY = 'SERVICE_HISTORY_SORTING_KEY'
export const SERVICE_HISTORY_PAGINATION_KEY = 'SERVICE_HISTORY_PAGINATION_KEY'
export const SERVICE_HISTORY_FILTERS_KEY = 'SERVICE_HISTORY_FILTERS_KEY'
export const SERVICE_HISTORY_TABLE_COLUMNS_SIZES_KEY =
  'SERVICE_HISTORY_TABLE_COLUMNS_SIZES_KEY'

export const USERS_LIST_SORTING_KEY = 'USERS_LIST_SORTING_KEY'
export const USERS_LIST_PAGINATION_KEY = 'USERS_LIST_PAGINATION_KEY'
export const USERS_LIST_FILTERS_KEY = 'USERS_LIST_FILTERS_KEY'
export const USERS_LIST_TABLE_COLUMNS_SIZES_KEY =
  'USERS_LIST_TABLE_COLUMNS_SIZES_KEY'

export const BENEFICIARIES_INDIVIDUALS_SORTING_KEY =
  'BENEFICIARIES_INDIVIDUALS_SORTING_KEY'
export const BENEFICIARIES_INDIVIDUALS_PAGINATION_KEY =
  'BENEFICIARIES_INDIVIDUALS_PAGINATION_KEY'
export const BENEFICIARIES_INDIVIDUALS_FILTERS_KEY =
  'BENEFICIARIES_INDIVIDUALS_FILTERS_KEY'
export const BENEFICIARIES_INDIVIDUALS_TABLE_COLUMNS_SIZES_KEY =
  'BENEFICIARIES_INDIVIDUALS_TABLE_COLUMNS_SIZES_KEY'

export const BENEFICIARIES_ORGANIZATIONS_SORTING_KEY =
  'BENEFICIARIES_ORGANIZATIONS_SORTING_KEY'
export const BENEFICIARIES_ORGANIZATIONS_PAGINATION_KEY =
  'BENEFICIARIES_ORGANIZATIONS_PAGINATION_KEY'
export const BENEFICIARIES_ORGANIZATIONS_FILTERS_KEY =
  'BENEFICIARIES_ORGANIZATIONS_FILTERS_KEY'
export const BENEFICIARIES_ORGANIZATIONS_TABLE_COLUMNS_SIZES_KEY =
  'BENEFICIARIES_ORGANIZATIONS_TABLE_COLUMNS_SIZES_KEY'

export const PARTNERS_BENEFICIARY_INDIVIDUALS_SORTING_KEY =
  'PARTNERS_BENEFICIARY_INDIVIDUALS_SORTING_KEY'
export const PARTNERS_BENEFICIARY_INDIVIDUALS_PAGINATION_KEY =
  'PARTNERS_BENEFICIARY_INDIVIDUALS_PAGINATION_KEY'
export const PARTNERS_BENEFICIARY_INDIVIDUALS_FILTERS_KEY =
  'PARTNERS_BENEFICIARY_INDIVIDUALS_FILTERS_KEY'
export const PARTNERS_BENEFICIARY_INDIVIDUALS_TABLE_COLUMNS_SIZES_KEY =
  'PARTNERS_BENEFICIARY_INDIVIDUALS_TABLE_COLUMNS_SIZES_KEY'

export const PARTNERS_BENEFICIARY_INDIVIDUAL_CATEGORIES_SORTING_KEY =
  'PARTNERS_BENEFICIARY_INDIVIDUAL_CATEGORIES_SORTING_KEY'
export const PARTNERS_BENEFICIARY_INDIVIDUAL_CATEGORIES_TABLE_COLUMNS_SIZES_KEY =
  'PARTNERS_BENEFICIARY_INDIVIDUAL_CATEGORIES_TABLE_COLUMNS_SIZES_KEY'

export const PARTNERS_BENEFICIARY_INDIVIDUAL_ACTIVITIES_SORTING_KEY =
  'PARTNERS_BENEFICIARY_INDIVIDUAL_ACTIVITIES_SORTING_KEY'
export const PARTNERS_BENEFICIARY_INDIVIDUAL_ACTIVITIES_TABLE_COLUMNS_SIZES_KEY =
  'PARTNERS_BENEFICIARY_INDIVIDUAL_ACTIVITIES_TABLE_COLUMNS_SIZES_KEY'

export const PARTNERS_BENEFICIARY_INDIVIDUAL_SERVICE_HISTORY_SORTING_KEY =
  'PARTNERS_BENEFICIARY_INDIVIDUAL_SERVICE_HISTORY_SORTING_KEY'
export const PARTNERS_BENEFICIARY_INDIVIDUAL_SERVICE_HISTORY_TABLE_COLUMNS_SIZES_KEY =
  'PARTNERS_BENEFICIARY_INDIVIDUAL_SERVICE_HISTORY_TABLE_COLUMNS_SIZES_KEY'

export const PARTNERS_BENEFICIARY_ORGANIZATION_ACTIVITIES_SORTING_KEY =
  'PARTNERS_BENEFICIARY_ORGANIZATION_ACTIVITIES_SORTING_KEY'
export const PARTNERS_BENEFICIARY_ORGANIZATION_ACTIVITIES_TABLE_COLUMNS_SIZES_KEY =
  'PARTNERS_BENEFICIARY_ORGANIZATION_ACTIVITIES_TABLE_COLUMNS_SIZES_KEY'

export const SETTINGS_USERS_LIST_SORTING_KEY = 'SETTINGS_USERS_LIST_SORTING_KEY'
export const SETTINGS_USERS_LIST_PAGINATION_KEY =
  'SETTINGS_USERS_LIST_PAGINATION_KEY'
export const SETTINGS_USERS_LIST_FILTERS_KEY = 'SETTINGS_USERS_LIST_FILTERS_KEY'
export const SETTINGS_USERS_TABLE_COLUMNS_SIZES_KEY =
  'SETTINGS_USERS_TABLE_COLUMNS_SIZES_KEY'

export const PARTNERS_BENEFICIARY_ORGANIZATIONS_SORTING_KEY =
  'PARTNERS_BENEFICIARY_ORGANIZATIONS_SORTING_KEY'
export const PARTNERS_BENEFICIARY_ORGANIZATIONS_PAGINATION_KEY =
  'PARTNERS_BENEFICIARY_ORGANIZATIONS_PAGINATION_KEY'
export const PARTNERS_BENEFICIARY_ORGANIZATIONS_FILTERS_KEY =
  'PARTNERS_BENEFICIARY_ORGANIZATIONS_FILTERS_KEY'
export const PARTNERS_BENEFICIARY_ORGANIZATIONS_TABLE_COLUMNS_SIZES_KEY =
  'PARTNERS_BENEFICIARY_ORGANIZATIONS_TABLE_COLUMNS_SIZES_KEY'

export const PARTNERS_ACTIVITIES_LIST_SORTING_KEY =
  'PARTNERS_ACTIVITIES_LIST_SORTING_KEY'
export const PARTNERS_ACTIVITIES_LIST_PAGINATION_KEY =
  'PARTNERS_ACTIVITIES_LIST_PAGINATION_KEY'
export const PARTNERS_ACTIVITIES_LIST_FILTERS_KEY =
  'PARTNERS_ACTIVITIES_LIST_FILTERS_KEY'
export const PARTNERS_ACTIVITIES_LIST_TABLE_COLUMNS_SIZES_KEY =
  'PARTNERS_ACTIVITIES_LIST_TABLE_COLUMNS_SIZES_KEY'

export const PARTNERS_ACTIVITIES_INDIVIDUALS_SORTING_KEY =
  'PARTNERS_ACTIVITIES_INDIVIDUALS_SORTING_KEY'
export const PARTNERS_ACTIVITIES_INDIVIDUALS_PAGINATION_KEY =
  'PARTNERS_ACTIVITIES_INDIVIDUALS_PAGINATION_KEY'
export const PARTNERS_ACTIVITIES_INDIVIDUALS_TABLE_COLUMNS_SIZES_KEY =
  'PARTNERS_ACTIVITIES_INDIVIDUALS_TABLE_COLUMNS_SIZES_KEY'

export const PARTNERS_ACTIVITIES_TESTS_INDIVIDUAL_SORTING_KEY =
  'PARTNERS_ACTIVITIES_TESTS_INDIVIDUAL_SORTING_KEY'
export const PARTNERS_ACTIVITIES_TESTS_ORGANIZATIONS_SORTING_KEY =
  'PARTNERS_ACTIVITIES_TESTS_ORGANIZATIONS_SORTING_KEY'
export const PARTNERS_ACTIVITIES_TESTS_PAGINATION_KEY =
  'PARTNERS_ACTIVITIES_TESTS_PAGINATION_KEY'
export const PARTNERS_ACTIVITIES_TESTS_TABLE_COLUMNS_SIZES_KEY =
  'PARTNERS_ACTIVITIES_TESTS_TABLE_COLUMNS_SIZES_KEY'

export const PARTNERS_ACTIVITIES_ORGANIZATIONS_STAFF_SORTING_KEY =
  'PARTNERS_ACTIVITIES_ORGANIZATIONS_STAFF_SORTING_KEY'
export const PARTNERS_ACTIVITIES_ORGANIZATIONS_STAFF_PAGINATION_KEY =
  'PARTNERS_ACTIVITIES_ORGANIZATIONS_STAFF_PAGINATION_KEY'
export const PARTNERS_ACTIVITIES_ORGANIZATIONS_STAFF_TABLE_COLUMNS_SIZES_KEY =
  'PARTNERS_ACTIVITIES_ORGANIZATIONS_STAFF_TABLE_COLUMNS_SIZES_KEY'

export const INDICATOR_1_1_1_SORTING_KEY = 'INDICATOR_1_1_1_SORTING_KEY'
export const INDICATOR_1_1_1_PAGINATION_KEY = 'INDICATOR_1_1_1_PAGINATION_KEY'
export const INDICATOR_1_1_1_TABLE_COLUMNS_SIZES_KEY =
  'INDICATOR_1_1_1_TABLE_COLUMNS_SIZES_KEY'
export const INDICATOR_1_1_1_FILTERS_KEY = 'INDICATOR_1_1_1_FILTERS_KEY'

export const INDICATOR_1_2_1_SORTING_KEY = 'INDICATOR_1_2_1_SORTING_KEY'
export const INDICATOR_1_2_1_PAGINATION_KEY = 'INDICATOR_1_2_1_PAGINATION_KEY'
export const INDICATOR_1_2_1_TABLE_COLUMNS_SIZES_KEY =
  'INDICATOR_1_2_1_TABLE_COLUMNS_SIZES_KEY'
export const INDICATOR_1_2_1_FILTERS_KEY = 'INDICATOR_1_2_1_FILTERS_KEY'

export const INDICATOR_1_2_2_SORTING_KEY = 'INDICATOR_1_2_2_SORTING_KEY'
export const INDICATOR_1_2_2_PAGINATION_KEY = 'INDICATOR_1_2_2_PAGINATION_KEY'
export const INDICATOR_1_2_2_TABLE_COLUMNS_SIZES_KEY =
  'INDICATOR_1_2_2_TABLE_COLUMNS_SIZES_KEY'
export const INDICATOR_1_2_2_FILTERS_KEY = 'INDICATOR_1_2_2_FILTERS_KEY'

export const INDICATOR_2_1_1_SORTING_KEY = 'INDICATOR_2_1_1_SORTING_KEY'
export const INDICATOR_2_1_1_PAGINATION_KEY = 'INDICATOR_2_1_1_PAGINATION_KEY'
export const INDICATOR_2_1_1_TABLE_COLUMNS_SIZES_KEY =
  'INDICATOR_2_1_1_TABLE_COLUMNS_SIZES_KEY'
export const INDICATOR_2_1_1_FILTERS_KEY = 'INDICATOR_2_1_1_FILTERS_KEY'

export const INDICATOR_2_1_2_SORTING_KEY = 'INDICATOR_2_1_2_SORTING_KEY'
export const INDICATOR_2_1_2_PAGINATION_KEY = 'INDICATOR_2_1_2_PAGINATION_KEY'
export const INDICATOR_2_1_2_TABLE_COLUMNS_SIZES_KEY =
  'INDICATOR_2_1_2_TABLE_COLUMNS_SIZES_KEY'
export const INDICATOR_2_1_2_FILTERS_KEY = 'INDICATOR_2_1_2_FILTERS_KEY'

export const INDICATOR_2_2_1_SORTING_KEY = 'INDICATOR_2_2_1_SORTING_KEY'
export const INDICATOR_2_2_1_PAGINATION_KEY = 'INDICATOR_2_2_1_PAGINATION_KEY'
export const INDICATOR_2_2_1_TABLE_COLUMNS_SIZES_KEY =
  'INDICATOR_2_2_1_TABLE_COLUMNS_SIZES_KEY'
export const INDICATOR_2_2_1_FILTERS_KEY = 'INDICATOR_2_2_1_FILTERS_KEY'

export const INDICATOR_2_2_2_SORTING_KEY = 'INDICATOR_2_2_2_SORTING_KEY'
export const INDICATOR_2_2_2_PAGINATION_KEY = 'INDICATOR_2_2_2_PAGINATION_KEY'
export const INDICATOR_2_2_2_TABLE_COLUMNS_SIZES_KEY =
  'INDICATOR_2_2_2_TABLE_COLUMNS_SIZES_KEY'
export const INDICATOR_2_2_2_FILTERS_KEY = 'INDICATOR_2_2_2_FILTERS_KEY'

export const INDICATOR_2_2_3_SORTING_KEY = 'INDICATOR_2_2_3_SORTING_KEY'
export const INDICATOR_2_2_3_PAGINATION_KEY = 'INDICATOR_2_2_3_PAGINATION_KEY'
export const INDICATOR_2_2_3_TABLE_COLUMNS_SIZES_KEY =
  'INDICATOR_2_2_3_TABLE_COLUMNS_SIZES_KEY'
export const INDICATOR_2_2_3_FILTERS_KEY = 'INDICATOR_2_2_3_FILTERS_KEY'

export const INDICATOR_3_1_1_SORTING_KEY = 'INDICATOR_3_1_1_SORTING_KEY'
export const INDICATOR_3_1_1_PAGINATION_KEY = 'INDICATOR_3_1_1_PAGINATION_KEY'
export const INDICATOR_3_1_1_TABLE_COLUMNS_SIZES_KEY =
  'INDICATOR_3_1_1_TABLE_COLUMNS_SIZES_KEY'
export const INDICATOR_3_1_1_FILTERS_KEY = 'INDICATOR_3_1_1_FILTERS_KEY'

export const INDICATOR_3_2_1_SORTING_KEY = 'INDICATOR_3_2_1_SORTING_KEY'
export const INDICATOR_3_2_1_PAGINATION_KEY = 'INDICATOR_3_2_1_PAGINATION_KEY'
export const INDICATOR_3_2_1_TABLE_COLUMNS_SIZES_KEY =
  'INDICATOR_3_2_1_TABLE_COLUMNS_SIZES_KEY'
export const INDICATOR_3_2_1_FILTERS_KEY = 'INDICATOR_3_2_1_FILTERS_KEY'

export const INDICATOR_3_2_2_SORTING_KEY = 'INDICATOR_3_2_2_SORTING_KEY'
export const INDICATOR_3_2_2_PAGINATION_KEY = 'INDICATOR_3_2_2_PAGINATION_KEY'
export const INDICATOR_3_2_2_TABLE_COLUMNS_SIZES_KEY =
  'INDICATOR_3_2_2_TABLE_COLUMNS_SIZES_KEY'
export const INDICATOR_3_2_2_FILTERS_KEY = 'INDICATOR_3_2_2_FILTERS_KEY'

export const INDICATOR_4_1_1_SORTING_KEY = 'INDICATOR_4_1_1_SORTING_KEY'
export const INDICATOR_4_1_1_PAGINATION_KEY = 'INDICATOR_4_1_1_PAGINATION_KEY'
export const INDICATOR_4_1_1_TABLE_COLUMNS_SIZES_KEY =
  'INDICATOR_4_1_1_TABLE_COLUMNS_SIZES_KEY'
export const INDICATOR_4_1_1_FILTERS_KEY = 'INDICATOR_4_1_1_FILTERS_KEY'

export const INDICATOR_VULNERABLE_MIGRANT_SORTING_KEY = 'INDICATOR_VULNERABLE_MIGRANT_SORTING_KEY'
export const INDICATOR_VULNERABLE_MIGRANT_PAGINATION_KEY = 'INDICATOR_VULNERABLE_MIGRANT_PAGINATION_KEY'
export const INDICATOR_VULNERABLE_MIGRANT_TABLE_COLUMNS_SIZES_KEY =
    'INDICATOR_VULNERABLE_MIGRANT_TABLE_COLUMNS_SIZES_KEY'
export const INDICATOR_VULNERABLE_MIGRANT_FILTERS_KEY = 'INDICATOR_VULNERABLE_MIGRANT_FILTERS_KEY'

export const INDICATOR_FORMS_SORTING_KEY = 'INDICATOR_FORMS_SORTING_KEY'
export const INDICATOR_FORMS_PAGINATION_KEY = 'INDICATOR_FORMS_PAGINATION_KEY'
export const INDICATOR_FORMS_TABLE_COLUMNS_SIZES_KEY =
  'INDICATOR_FORMS_TABLE_COLUMNS_SIZES_KEY'
export const INDICATOR_FORMS_FILTERS_KEY = 'INDICATOR_FORMS_FILTERS_KEY'

export const EXPORT_DROPDOWN_SELECTED_TYPE_KEY =
  'EXPORT_DROPDOWN_SELECTED_TYPE_KEY'

export const PASSWORD_REGEXP = /^(?=.*[!@#$%^&*])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,32}$/g
export const PHONE_REGEXP = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{2,3}[ -]?[0-9]{2,3}$/
export const WINROCK_EMAIL_NEGATIVE_REGEXP = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?!(winrock.org))/

interface IRelatedToCovid19Type {
  value: boolean | null
  label: MessageDescriptor
}

export const relatedToCovid19Types: Array<IRelatedToCovid19Type> = [
  {
    value: null,
    label: {
      id: 'global.all',
      defaultMessage: 'Все',
    },
  },
  {
    value: true,
    label: {
      id: 'global.covid_associated_yes',
      defaultMessage: 'Да',
    },
  },
  {
    value: false,
    label: {
      id: 'global.covid_associated_no',
      defaultMessage: 'Нет',
    },
  },
]

export const acceptedUploadedFilesTypes: Array<string> = [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.txt',
  '.jpg',
  '.png',
  '.gif',
  '.zip',
  '.rar',
  '.ppt',
  '.pptx',
]

type AnswerOption = {
  value: boolean | null
  label: MessageDescriptor
  isComment: boolean
}

export const answerOptions: Array<AnswerOption> = [
  {
    value: true,
    label: { id: 'global.yes', defaultMessage: 'Да' },
    isComment: false,
  },
  {
    value: false,
    label: { id: 'global.no', defaultMessage: 'Нет' },
    isComment: false,
  },
  {
    value: null,
    label: { id: 'global.other', defaultMessage: 'Другое' },
    isComment: true,
  },
]

type IdentificationType = {
  value: boolean | null
  label: MessageDescriptor
}

export const identificationTypes: readonly IdentificationType[] = [
  {
    value: true,
    label: {
      id: 'partners.indicators.3_1_1.formal',
      defaultMessage: 'Формальный',
    },
  },
  {
    value: false,
    label: {
      id: 'partners.indicators.3_1_1.informal',
      defaultMessage: 'Неформальный',
    },
  },
  {
    value: null,
    label: {
      id: 'global.all',
      defaultMessage: 'Все',
    },
  },
]
