import { ReactElement } from 'react'
import { Form, ListGroup } from 'react-bootstrap'
import CheckBox from '../CheckBox'
import './CheckList.scss'
import Input, { InputProps } from '../Input'
import { FormattedMessage, MessageDescriptor } from 'react-intl'

interface CheckListProps<
  Item extends { [K: string]: any },
  EventKey extends keyof Item
> {
  data: Array<Item>
  itemEventKey: EventKey
  itemLabelKey: keyof Item
  itemCommentKey?: keyof Item
  checkedKeys: Array<Item[EventKey]>
  onCheck?: (
    checkedKeys: Array<Item[EventKey]>,
    item: Item,
    isChecked: boolean,
  ) => void
  roundedCheckBox?: boolean
  errorMessage?: string | Array<string>
  commentInputProps?: InputProps
  isDisabled?: boolean
  label?: MessageDescriptor
  name?: string
}

const CheckList = <Item extends {}, EventKey extends keyof Item>({
  data,
  onCheck,
  checkedKeys,
  itemEventKey,
  itemLabelKey,
  roundedCheckBox = false,
  errorMessage,
  itemCommentKey,
  commentInputProps,
  isDisabled = false,
  label,
  name = '',
}: CheckListProps<Item, EventKey>): ReactElement => {
  return (
    <>
      {label && (
        <Form.Label htmlFor={name}>
          <FormattedMessage {...label} />
        </Form.Label>
      )}
      <ListGroup className="check-list" variant="flush">
        {data.map((item, index) => (
          <ListGroup.Item
            disabled={isDisabled}
            className={itemCommentKey && item[itemCommentKey] ? 'py-1' : ''}
            action
            as="li"
            key={index}
            onClick={() => {
              const isChecked = !checkedKeys.includes(item[itemEventKey])
              onCheck?.(
                isChecked
                  ? [...checkedKeys, item[itemEventKey]]
                  : checkedKeys.filter(
                      checkedKey => checkedKey !== item[itemEventKey],
                    ),
                item,
                isChecked,
              )
            }}
          >
            <CheckBox
              round={roundedCheckBox}
              status={checkedKeys.includes(item[itemEventKey])}
            />
            <span className="mx-3">{item[itemLabelKey]}</span>
            {itemCommentKey && commentInputProps && item[itemCommentKey] && (
              <Input {...commentInputProps} />
            )}
          </ListGroup.Item>
        ))}
        {!!errorMessage && <span className="error">{errorMessage}</span>}
      </ListGroup>
    </>
  )
}

export default CheckList
