import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../../PagedListRequestModel'

const IndicatorFormRequestListModel = PagedListRequestModel.named(
  'IndicatorFormRequestListModel',
).props({
  sortBy: types.optional(types.string, ''),
  filter: types.optional(
    types.model({
      organizationId: types.union(types.number, types.undefined, types.null),
      indicatorNumber: types.union(types.string, types.undefined, types.null),
      responsiblePersonFullName: types.union(
        types.string,
        types.undefined,
        types.null,
      ),
      createdDateTime: types.union(types.string, types.undefined, types.null),
      approvalStatus: types.union(types.number, types.undefined, types.null),
      countryId: types.union(types.number, types.undefined, types.null),
    }),
    {},
  ),
})

export interface IIndicatorFormRequestListModel
  extends Instance<typeof IndicatorFormRequestListModel> {}

export interface IIndicatorFormRequestListModelIn
  extends SnapshotIn<typeof IndicatorFormRequestListModel> {}

export interface IIndicatorFormRequestListModelOut
  extends SnapshotOut<typeof IndicatorFormRequestListModel> {}

export default IndicatorFormRequestListModel
