enum IndicatorsNumber {
  Indicator1 = '1.1.1',
  Indicator2 = '1.2.1',
  Indicator3 = '1.2.2',
  Indicator4 = '2.1.1',
  Indicator5 = '2.1.2',
  Indicator6 = '2.2.1',
  Indicator7 = '2.2.2',
  Indicator8 = '2.2.3',
  Indicator9 = '3.1.1',
  Indicator10 = '3.2.1',
  Indicator11 = '3.2.2',
  Indicator12 = '4.1.1',
  Indicator13 = 'VulMigrant'
}

export default IndicatorsNumber
