import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import IndicatorReportsFilterBaseModel from '../IndicatorReportsFilterBaseModel'

const Indicator3_2_1ReportFilterModel = IndicatorReportsFilterBaseModel.named(
  'Indicator3_2_1ReportFilterModel',
).props({
  TypeOfOrganizationId: types.union(types.number, types.undefined, types.null),
  TypeOfActivityId: types.union(types.number, types.undefined, types.null),
})

export interface IIndicator3_2_1ReportFilterModel
  extends Instance<typeof Indicator3_2_1ReportFilterModel> {}

export interface IIndicator3_2_1ReportFilterSnapshotIn
  extends SnapshotIn<typeof Indicator3_2_1ReportFilterModel> {}

export interface IIndicator3_2_1ReportFilterSnapshotOut
  extends SnapshotOut<typeof Indicator3_2_1ReportFilterModel> {}

export default Indicator3_2_1ReportFilterModel
