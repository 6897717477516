import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const RelationshipEstablishmentsLookUpModel = LookUpModel
    .named('RelationshipEstablishmentsLookUpModel')
    .props({
        isComment: types.boolean,
    })

export interface IRelationshipEstablishmentsLookUp extends Instance<typeof RelationshipEstablishmentsLookUpModel> {}
export interface IRelationshipEstablishmentlookUpSnapshotIn
    extends SnapshotIn<typeof RelationshipEstablishmentsLookUpModel> {}
export interface IRelationshipEstablishmentlookUpSnapshotOut
    extends SnapshotOut<typeof RelationshipEstablishmentsLookUpModel> {}
export default RelationshipEstablishmentsLookUpModel