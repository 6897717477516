import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const PartnerLookUpModel = LookUpModel.named('PartnerLookUpModel')

export interface IPartnerLookUpModel
  extends Instance<typeof PartnerLookUpModel> {}
export interface IPartnerLookUpSnapshotIn
  extends SnapshotIn<typeof PartnerLookUpModel> {}
export interface IPartnerLookUpSnapshotOut
  extends SnapshotOut<typeof PartnerLookUpModel> {}
export default PartnerLookUpModel
