import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import IndicatorTargetsDto from './IndicatorTargetsDto'

const CreateIndicatorTargetCommand = types.model(
  'CreateIndicatorTargetCommand',
  {
    indicatorTargetsDto: types.optional(IndicatorTargetsDto, {}),
  },
)

export interface ICreateIndicatorTargetCommand
  extends Instance<typeof CreateIndicatorTargetCommand> {}
export interface ICreateIndicatorTargetCommandSnapshotIn
  extends SnapshotIn<typeof CreateIndicatorTargetCommand> {}
export interface ICreateIndicatorTargetCommandSnapshotOut
  extends SnapshotOut<typeof CreateIndicatorTargetCommand> {}
export default CreateIndicatorTargetCommand
