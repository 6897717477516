import axios, { AxiosError } from 'axios'
import qs from 'qs'
import { IAppStore, IAuthStore } from 'stores/domain'

const apiV1 = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const setupInterceptors = (appStore: IAppStore, authStore: IAuthStore) => {
  apiV1.interceptors.request.use(
    config => {
      const accessToken = JSON.parse(localStorage.getItem('authStore') || '{}')
        ._user.accessToken
      return {
        ...config,
        headers: {
          ...config.headers,
          'Accept-Language':
            appStore.currentLanguage === 'ru' ? 'ru-RU' : 'en-US',
          ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
        },
        paramsSerializer: params =>
          qs.stringify(params, { allowDots: true, skipNulls: true }),
      }
    },
    error => Promise.reject(error),
  )
  apiV1.interceptors.response.use(
    response => response,
    async error => {
      const storedUserTokenInfo = JSON.parse(
        localStorage.getItem('authStore') || '{}',
      )._user
      const { refreshToken } = storedUserTokenInfo
      const originalRequestConfig: AxiosError['config'] & { _retry: boolean } =
        error.config

      if (refreshToken) {
        switch (error.response?.status) {
          case 401: {
            if (!originalRequestConfig._retry && !authStore.isTokenRefreshing) {
              authStore.setUser({ ...authStore.user, accessToken: null })
              originalRequestConfig._retry = true
              await authStore.getUserTokenInfoByRefresh(refreshToken)
              return apiV1(originalRequestConfig)
            } else if (!originalRequestConfig._retry) {
              try {
                await new Promise<void>((resolve, reject) => {
                  const interval = setInterval(() => {
                    const accessToken = JSON.parse(
                      localStorage.getItem('authStore') || '{}',
                    )._user.accessToken
                    if (!authStore.isTokenRefreshing && accessToken) {
                      resolve()
                      clearInterval(interval)
                    } else if (!authStore.isTokenRefreshing && !accessToken) {
                      reject()
                      clearInterval(interval)
                    }
                  }, 1000)
                })
                return apiV1(originalRequestConfig)
              } catch {
                return Promise.reject(error)
              }
            }
            authStore.resetUserInfo()
            break
          }
          case 400: {
            if (originalRequestConfig.url?.includes('refreshaccesstoken')) {
              authStore.resetUserInfo()
            }
            break
          }
        }
      } else authStore.resetUserInfo()

      return Promise.reject(error)
    },
  )
}
export { setupInterceptors, apiV1 }
