import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateServiceHistoryCommand = types.model('UpdateServiceHistoryCommand', {
  id: types.integer,
  beneficiaryIndividualId: types.integer,
  serviceTypeId: types.integer,
  serviceTypeComment: types.maybeNull(types.string),
  regionId: types.integer,
  relatedToCovid19: types.boolean,
  serviceDeliveredStaffId: types.integer,
  serviceDeliveryDate: types.string,
})

export interface IUpdateServiceHistoryCommand
  extends Instance<typeof UpdateServiceHistoryCommand> {}
export interface IUpdateServiceHistoryCommandSnapshotIn
  extends SnapshotIn<typeof UpdateServiceHistoryCommand> {}
export interface IUpdateServiceHistoryCommandSnapshotOut
  extends SnapshotOut<typeof UpdateServiceHistoryCommand> {}
export default UpdateServiceHistoryCommand
