import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const BeneficiaryIndividualsLookUpModel = LookUpModel.named(
  'BeneficiaryIndividualsLookUpModel',
)

export interface IBeneficiaryIndividualsLookUpModel
  extends Instance<typeof BeneficiaryIndividualsLookUpModel> {}
export interface IBeneficiaryIndividualsLookUpModelIn
  extends SnapshotIn<typeof BeneficiaryIndividualsLookUpModel> {}
export interface IBeneficiaryIndividualsLookUpModelOut
  extends SnapshotOut<typeof BeneficiaryIndividualsLookUpModel> {}
export default BeneficiaryIndividualsLookUpModel
