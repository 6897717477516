import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../PagedListMetaData'
import ServiceHistoryModel from './ServiceHistoryWithStatusModel'

const ServiceHistoryWithStatusPagedDto = types.model(
  'ServiceHistoryWithStatusPagedDto',
  {
    data: types.array(ServiceHistoryModel),
    metadata: types.optional(PagedListMetaData, {}),
  },
)

export interface IServiceHistoryWithStatusPagedDto
  extends Instance<typeof ServiceHistoryWithStatusPagedDto> {}

export interface IServiceHistoryWithStatusPagedDtoSnapshotIn
  extends SnapshotIn<typeof ServiceHistoryWithStatusPagedDto> {}

export interface IServiceHistoryWithStatusPagedDtoSnapshotOut
  extends SnapshotOut<typeof ServiceHistoryWithStatusPagedDto> {}

export default ServiceHistoryWithStatusPagedDto
