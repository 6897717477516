import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import BeneficiaryIndividualWithOrganizationsModel from './BeneficiaryIndividualWithOrganizationsModel'
import PagedListMetaData from '../../PagedListMetaData'

const BeneficiaryIndividualWithOrganizationsPagedDto = types.model(
  'BeneficiaryIndividualWithOrganizationsPagedDto',
  {
    data: types.array(BeneficiaryIndividualWithOrganizationsModel),

    metadata: types.optional(PagedListMetaData, {}),
  },
)

export interface IBeneficiaryIndividualWithOrganizationsPagedDtoModel
  extends Instance<typeof BeneficiaryIndividualWithOrganizationsPagedDto> {}

export interface IBeneficiaryIndividualWithOrganizationsPagedDtoSnapshotIn
  extends SnapshotIn<typeof BeneficiaryIndividualWithOrganizationsPagedDto> {}

export interface IBeneficiaryIndividualWithOrganizationsPagedDtoSnapshotOut
  extends SnapshotOut<typeof BeneficiaryIndividualWithOrganizationsPagedDto> {}

export default BeneficiaryIndividualWithOrganizationsPagedDto
