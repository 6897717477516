import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const IndicatorFormModel = types.model('IndicatorFormModel', {
  id: types.optional(types.integer, 0),
  indicatorNumber: types.string,
  responsiblePersonFullName: types.maybeNull(types.string),
  createdDateTime: types.string,
  countryId: types.integer,
  countryParentId: types.maybeNull(types.integer),
  country: types.maybeNull(types.string),
  regionId: types.maybeNull(types.integer),
  region: types.maybeNull(types.string),
  partner: types.maybeNull(types.string),
  partnerId: types.integer,
  statusId: types.integer,
  status: types.maybeNull(types.string),
  canBeReviewed: types.boolean,
})

export interface IIndicatorFormModel
  extends Instance<typeof IndicatorFormModel> {}
export interface IIndicatorFormModelSnapshotIn
  extends SnapshotIn<typeof IndicatorFormModel> {}
export interface IIndicatorFormModelSnapshotOut
  extends SnapshotOut<typeof IndicatorFormModel> {}
export default IndicatorFormModel
