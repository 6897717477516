import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const IdentifyingAgencyLookUpModel = LookUpModel.named(
  'IdentifyingAgencyLookUpModel',
).props({
  isComment: types.boolean,
})

export interface IIdentifyingAgencyLookUp
  extends Instance<typeof IdentifyingAgencyLookUpModel> {}
export interface IIdentifyingAgencyLookUpIn
  extends SnapshotIn<typeof IdentifyingAgencyLookUpModel> {}
export interface IIdentifyingAgencyLookUpOut
  extends SnapshotOut<typeof IdentifyingAgencyLookUpModel> {}
export default IdentifyingAgencyLookUpModel
