import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../../PagedListMetaData'
import IndicatorFormModel from './IndicatorFormModel'

const IndicatorFormPagedDto = types.model('IndicatorFormPagedDto', {
  data: types.array(IndicatorFormModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export interface IIndicatorFormPagedDto
  extends Instance<typeof IndicatorFormPagedDto> {}

export interface IIndicatorFormPagedDtoSnapshotIn
  extends SnapshotIn<typeof IndicatorFormPagedDto> {}

export interface IIndicatorFormPagedDtoSnapshotOut
  extends SnapshotOut<typeof IndicatorFormPagedDto> {}

export default IndicatorFormPagedDto
