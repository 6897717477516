import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PermissionActionName from './PermissionActionNameEnum'

const PermissionActionModel = types.model('PermissionActionModel', {
  id: types.identifierNumber,
  name: types.union(
    types.enumeration<PermissionActionName>(
      Object.values(PermissionActionName),
    ),
    types.string,
  ),
})

export interface IPermissionActionModel
  extends Instance<typeof PermissionActionModel> {}

export interface IPermissionActionSnapshotIn
  extends SnapshotIn<typeof PermissionActionModel> {}

export interface IPermissionActionSnapshotOut
  extends SnapshotOut<typeof PermissionActionModel> {}

export default PermissionActionModel
