import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import { apiV1 } from 'core/requests'
import {
  ExportFilesType,
  IServiceHistoryApprovalCommandSnapshotIn,
  IServiceHistoryWithStatusListRequestModelIn,
  IServiceHistoryWithStatusPagedDtoSnapshotIn,
  ServiceHistoryApprovalCommand,
  IServiceHistoryWithStatusExportRequest,
  ServiceHistoryWithStatusListRequestModel,
  ServiceHistoryWithStatusPagedDto,
} from 'stores/models'
import {
  capitalizeFirstLetter,
  capitalizeObjectKeys,
  getFileNameFromContentDisposition,
} from 'core/utils'
import fileDownload from 'js-file-download'

const ServiceHistoryStore = types
  .model('ServiceHistoryStore')
  .props({
    _isLoading: types.optional(types.boolean, false),
    _serviceHistoryList: types.optional(ServiceHistoryWithStatusPagedDto, {}),
    _serviceHistoryCounter: types.optional(types.number, 0),
  })
  .actions(self => ({
    setServiceHistoryCounter(serviceHistoryCounter: number): void {
      self._serviceHistoryCounter = serviceHistoryCounter
    },
    setServiceHistoryList(
      serviceHistoryList: IServiceHistoryWithStatusPagedDtoSnapshotIn,
    ): void {
      applySnapshot(self._serviceHistoryList, serviceHistoryList)
    },
  }))
  .actions(self => {
    const SERVICE_HISTORY_API = '/api/servicehistories'

    const setIsLoading = (isLoading: boolean) => {
      self._isLoading = isLoading
    }

    const getServiceHistoryList = async (
      params: IServiceHistoryWithStatusListRequestModelIn,
    ) => {
      const serviceHistoryListRequest = fromPromise(
        apiV1.get<IServiceHistoryWithStatusListRequestModelIn>(
          SERVICE_HISTORY_API,
          {
            params: ServiceHistoryWithStatusListRequestModel.create(params),
          },
        ),
      )

      when(() =>
        serviceHistoryListRequest.case({
          fulfilled: response => {
            self.setServiceHistoryList(response.data)
            return true
          },
        }),
      )
      return serviceHistoryListRequest
    }

    const getServiceHistoryWithFullNameList = async (
      params: IServiceHistoryWithStatusListRequestModelIn,
    ) => {
      const serviceHistoryWithFullNameListRequest = fromPromise(
        apiV1.get<IServiceHistoryWithStatusListRequestModelIn>(
          `${SERVICE_HISTORY_API}/withfullname`,
          {
            params: ServiceHistoryWithStatusListRequestModel.create(params),
          },
        ),
      )

      when(() =>
        serviceHistoryWithFullNameListRequest.case({
          fulfilled: response => {
            self.setServiceHistoryList(response.data)
            return true
          },
        }),
      )
      return serviceHistoryWithFullNameListRequest
    }

    const getServiceHistoryExportFile = async (
      params: IServiceHistoryWithStatusExportRequest,
      type: ExportFilesType,
      onDownloadProgress: (progressEvent: ProgressEvent) => void,
    ) => {
      const serviceHistoryExportRequest = fromPromise(
        apiV1.get<ArrayBuffer>(`${SERVICE_HISTORY_API}/export`, {
          params: {
            ...params,
            sortBy: capitalizeFirstLetter(params.sortBy),
            filter: capitalizeObjectKeys(params.filter),
          },
          responseType: 'arraybuffer',
          onDownloadProgress,
        }),
      )

      when(() =>
        serviceHistoryExportRequest.case({
          fulfilled: response => {
            const fileName = getFileNameFromContentDisposition(
              response.headers['content-disposition'],
            )
            fileDownload(response.data, fileName ? fileName : `report.${type}`)
            return true
          },
        }),
      )

      return serviceHistoryExportRequest
    }

    const getServiceHistoryWithNameExportFile = async (
      params: IServiceHistoryWithStatusExportRequest,
      type: ExportFilesType,
      onDownloadProgress: (progressEvent: ProgressEvent) => void,
    ) => {
      const serviceHistoryExportRequest = fromPromise(
        apiV1.get<ArrayBuffer>(`${SERVICE_HISTORY_API}/withfullname/export`, {
          params: {
            ...params,
            sortBy: capitalizeFirstLetter(params.sortBy),
            filter: capitalizeObjectKeys(params.filter),
          },
          responseType: 'arraybuffer',
          onDownloadProgress,
        }),
      )

      when(() =>
        serviceHistoryExportRequest.case({
          fulfilled: response => {
            const fileName = getFileNameFromContentDisposition(
              response.headers['content-disposition'],
            )
            fileDownload(response.data, fileName ? fileName : `report.${type}`)
            return true
          },
        }),
      )

      return serviceHistoryExportRequest
    }

    const rejectServiceHistory = async (
      ids: IServiceHistoryApprovalCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.put(
        `${SERVICE_HISTORY_API}/reject`,
        ServiceHistoryApprovalCommand.create(ids),
      )

    const approveServiceHistory = async (
      ids: IServiceHistoryApprovalCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.put(
        `${SERVICE_HISTORY_API}/approve`,
        ServiceHistoryApprovalCommand.create(ids),
      )

    const getServiceHistoryCounter = async () => {
      const serviceHistoryCounterRequest = fromPromise(
        apiV1.get<number>(`${SERVICE_HISTORY_API}/count`),
      )

      when(() =>
        serviceHistoryCounterRequest.case({
          fulfilled: response => {
            self.setServiceHistoryCounter(response.data)
            return true
          },
        }),
      )
      return serviceHistoryCounterRequest
    }

    return {
      getServiceHistoryExportFile,
      getServiceHistoryWithNameExportFile,
      setIsLoading,
      getServiceHistoryList,
      rejectServiceHistory,
      approveServiceHistory,
      getServiceHistoryWithFullNameList,
      getServiceHistoryCounter,
    }
  })
  .views(self => ({
    get isLoading() {
      return self._isLoading
    },
    get serviceHistory() {
      return getSnapshot(self._serviceHistoryList)
    },
    get serviceHistoryList() {
      return this.serviceHistory.data
    },
    get serviceHistoryMetadata() {
      return this.serviceHistory.metadata
    },
    get serviceHistoryCounter() {
      return self._serviceHistoryCounter
    },
  }))

export default ServiceHistoryStore
export interface IServiceHistoryStore
  extends Instance<typeof ServiceHistoryStore> {}
