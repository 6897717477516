import { AxiosError } from 'axios'
import { sendNotification } from 'components/Toast'
import { IntlShape } from 'react-intl'
import { RouteConfigObject } from 'routes'
import { PermissionGroupName, PermissionMenuName } from 'stores/models'
import objectHash from 'object-hash'

export type Errors = {
  [K: string]: string | Array<string>
}

export const sendErrorNotifications = (
  defaultErrorMessage: string,
  errors?: Errors,
) => {
  if (!errors) return sendNotification(defaultErrorMessage, 'error')
  Object.entries(errors).forEach(([errorKey, errorMessage]) => {
    sendNotification(errorKey ? errorMessage : defaultErrorMessage, 'error')
  })
}

export const handleError = (
  error: AxiosError<{
    errors: Errors
  }>,
  defaultErrorMessage: string,
) => {
  const errors = error.response?.data.errors
  sendErrorNotifications(defaultErrorMessage, errors)
}

export const checkIsRouteAllowed = <
  T extends { name: PermissionGroupName | PermissionMenuName | string }
>(
  route: RouteConfigObject,
  permissions: Array<T>,
): boolean =>
  permissions.some(permission => permission.name === route.permissionName) ||
  !!route.children?.some(route => checkIsRouteAllowed(route, permissions))

export const getWordDeclensionByNumber = (
  number: number,
  words: Array<string>,
): string =>
  words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
  ]
export const getFileNameFromContentDisposition = (
  contentDisposition: string,
) => {
  const regexp = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matchedText = contentDisposition.match(regexp)?.[1]
  return matchedText
}

export const getFileTypeFromFileName = (fileName: string) => {
  const fileNameParts = fileName.split('.')
  return '.' + fileNameParts[fileNameParts.length - 1]
}

type FileValidationOptions = {
  accept?: Array<string>
  fileMaxSize?: number
  fileNames?: Array<string>
}

export const validateFiles = (
  files: FileList,
  { accept, fileMaxSize, fileNames }: FileValidationOptions,
  intl: IntlShape,
) =>
  Array.from(files).reduce<{
    validFiles: Array<File>
    errors: Errors
  }>(
    ({ validFiles, errors }, file) => {
      switch (true) {
        case !file.size:
          return {
            validFiles,
            errors: {
              ...errors,
              [file.name]: `${file.name}: ${intl.formatMessage({
                id: 'file_input.error.empty_file',
                defaultMessage: 'Пустой файл не может быть загружен',
              })}`,
            },
          }
        case fileNames && fileNames.some(fileName => fileName === file.name):
          return {
            validFiles,
            errors: {
              ...errors,
              [file.name]: `${file.name}: ${intl.formatMessage({
                id: 'file_input.error.unique_name',
                defaultMessage: 'Имя файла должно быть уникальным',
              })}`,
            },
          }
        case accept && !accept.includes(getFileTypeFromFileName(file.name)):
          return {
            validFiles,
            errors: {
              ...errors,
              [file.name]: `${file.name}: ${intl.formatMessage({
                id: 'file_input.error.file_type',
                defaultMessage: 'Недопустимый тип файла',
              })}`,
            },
          }
        case fileMaxSize && file.size / (1024 * 1024) > fileMaxSize:
          return {
            validFiles,
            errors: {
              ...errors,
              [file.name]: `${file.name}: ${intl.formatMessage(
                {
                  id: 'file_input.error.max_file_size',
                  defaultMessage:
                    'Размер файла должен быть не больше {fileMaxSize} МБ.',
                },
                { fileMaxSize },
              )}`,
            },
          }
        default:
          return { validFiles: [...validFiles, file], errors }
      }
    },
    { validFiles: [], errors: {} },
  )

export const isObjectsEqual = (
  a: { [K: string]: any },
  b: { [K: string]: any },
): boolean =>
  objectHash(a, { unorderedArrays: true }) ===
  objectHash(b, { unorderedArrays: true })

export const capitalizeFirstLetter = <T extends string>(string: T) =>
  (string ? `${string[0].toUpperCase()}${string.slice(1)}` : '') as T extends ''
    ? ''
    : Capitalize<T>

export type CapitalizeObjectKeys<T extends object> = {
  [key in Capitalize<keyof T & string>]: Uncapitalize<key> extends keyof T
    ? T[Uncapitalize<key>]
    : never
}

export const capitalizeObjectKeys = <T extends Record<string, any>>(
  object: T,
): CapitalizeObjectKeys<T> =>
  Object.fromEntries(
    Object.entries(object).map(([key, value]) => [
      capitalizeFirstLetter(key),
      value,
    ]),
  ) as CapitalizeObjectKeys<T>

export const isValidDate = (date: Date): boolean =>
  date instanceof Date && !isNaN(date.getDate())
