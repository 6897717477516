import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../../../PagedListMetaData'
import Indicator2_1_2ReportModel from './Indicator2_1_2ReportModel'

const Indicator2_1_2ReportPagedModel = types.model(
  'Indicator2_1_2ReportPagedModel',
  {
    data: types.array(Indicator2_1_2ReportModel),
    metadata: types.optional(PagedListMetaData, {}),
  },
)

export interface IIndicator2_1_2ReportPagedModel
  extends Instance<typeof Indicator2_1_2ReportPagedModel> {}

export interface IIndicator2_1_2ReportPagedSnapshotIn
  extends SnapshotIn<typeof Indicator2_1_2ReportPagedModel> {}

export interface IIndicator2_1_2ReportPagedSnapshotOut
  extends SnapshotOut<typeof Indicator2_1_2ReportPagedModel> {}

export default Indicator2_1_2ReportPagedModel
