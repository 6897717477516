import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const CreateActivityCommand = types.model('CreateActivityCommand', {
  name: types.string,
  relatedToCovid19: types.boolean,
  indicatorIds: types.array(types.number),
  countryId: types.integer,
  countryComment: types.optional(types.string, ''),
  regionId: types.maybeNull(types.integer),
  activityTypeId: types.integer,
  activityTypeComment: types.optional(types.string, ''),
  type: types.integer,
  memberTypeIds: types.array(types.number),
  organizationTypeIds: types.array(types.number),
  startDate: types.string,
  endDate: types.maybeNull(types.string),
  hasSupportedDocuments: types.boolean,
  testQuestionCount: types.maybeNull(types.number),
  organizationId: types.integer,
})

export interface ICreateActivityCommand
  extends Instance<typeof CreateActivityCommand> {}

export interface ICreateActivityCommandSnapshotIn
  extends SnapshotIn<typeof CreateActivityCommand> {}

export interface ICreateActivityCommandSnapshotOut
  extends SnapshotOut<typeof CreateActivityCommand> {}

export default CreateActivityCommand
