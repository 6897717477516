import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const BeneficiaryIndividualActivitiesParticipateRequestModel = types.model(
  'BeneficiaryIndividualActivitiesParticipateRequestModel',
  {
    id: types.number,
    organizerId: types.number,
    sortBy: types.optional(types.string, ''),
  },
)

export interface IBeneficiaryIndividualActivitiesParticipateRequestModel
  extends Instance<
    typeof BeneficiaryIndividualActivitiesParticipateRequestModel
  > {}

export interface IBeneficiaryIndividualActivitiesParticipateRequestModelIn
  extends SnapshotIn<
    typeof BeneficiaryIndividualActivitiesParticipateRequestModel
  > {}

export interface IBeneficiaryIndividualActivitiesParticipateRequestModelOut
  extends SnapshotOut<
    typeof BeneficiaryIndividualActivitiesParticipateRequestModel
  > {}

export default BeneficiaryIndividualActivitiesParticipateRequestModel
