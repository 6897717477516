import { PermissionActionName } from 'stores/models'
import { MessageDescriptor } from 'react-intl'

export const PermissionActionTranslations = new Map<
  PermissionActionName | string,
  MessageDescriptor
>([
  [
    PermissionActionName.Create,
    {
      id: 'global.create',
      defaultMessage: 'Создать',
    },
  ],
  [
    PermissionActionName.View,
    {
      id: 'global.view',
      defaultMessage: 'Просмотр',
    },
  ],
  [
    PermissionActionName.Edit,
    {
      id: 'global.edit',
      defaultMessage: 'Редактировать',
    },
  ],
  [
    PermissionActionName.Delete,
    {
      id: 'global.delete',
      defaultMessage: 'Удалить',
    },
  ],
  [
    PermissionActionName.Activate,
    {
      id: 'global.activate',
      defaultMessage: 'Активировать',
    },
  ],
  [
    PermissionActionName.Deactivate,
    {
      id: 'global.deactivate',
      defaultMessage: 'Деактивировать',
    },
  ],
  [
    PermissionActionName.ExcludeCategories,
    {
      id: 'catalogs.beneficiary.exclude.exclusion',
      defaultMessage: 'Исключение категорий',
    },
  ],
  [
    PermissionActionName.EditOnRoleLevel,
    {
      id: 'permissions.edit_role_permissions',
      defaultMessage: 'Редактирование настроек доступа роли',
    },
  ],
  [
    PermissionActionName.EditOnUserLevel,
    {
      id: 'permissions.edit_user_permissions',
      defaultMessage: 'Редактирование настроек доступа пользователя',
    },
  ],
  [
    PermissionActionName.ConfirmUserViaAzureAd,
    {
      id: 'permissions.microsoft_signup_confirmation',
      defaultMessage: 'Подтверждение регистрации через Azure AD',
    },
  ],
  [
    PermissionActionName.ChangePassword,
    {
      id: 'permissions.change_password',
      defaultMessage: 'Сменить пароль',
    },
  ],
  [
    PermissionActionName.BeneficiaryIndividualFullName,
    {
      id: 'permissions.users.get_beneficiary_full_name',
      defaultMessage: 'Отображение ФИО физических лиц',
    },
  ],
  [
    PermissionActionName.StaffFullName,
    {
      id: 'permissions.users.get_beneficiary_staff_full_name',
      defaultMessage: 'Отображение ФИО сотрудников бенефициаров-организаций',
    },
  ],
  [
    PermissionActionName.DownloadFile,
    {
      id: 'permissions.download_file',
      defaultMessage: 'Скачать файл',
    },
  ],
  [
    PermissionActionName.ExportReport,
    {
      id: 'permissions.export_report',
      defaultMessage: 'Экспортировать отчет',
    },
  ],
  [
    PermissionActionName.ImportIndicator,
    {
      id: 'partners.indicators.import',
      defaultMessage: 'Импортировать',
    },
  ],
  [
    PermissionActionName.EditCategory,
    {
      id: 'permissions.users.edit_category',
      defaultMessage: 'Редактировать категорию',
    },
  ],
])
