import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from 'stores/models/PagedListMetaData'
import ActivityStaffWithNameModel from './ActivityStaffWithNameModel'

const ActivityStaffPagedDto = types.model('ActivityStaffPagedDto', {
  data: types.array(ActivityStaffWithNameModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export interface IActivityStaffPagedDto
  extends Instance<typeof ActivityStaffPagedDto> {}

export interface IActivityStaffPagedDtoSnapshotIn
  extends SnapshotIn<typeof ActivityStaffPagedDto> {}

export interface IActivityStaffPagedDtoSnapshotOut
  extends SnapshotOut<typeof ActivityStaffPagedDto> {}

export default ActivityStaffPagedDto
