import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const ApprovalStatusLookUpModel = LookUpModel.named('ApprovalStatusLookUpModel')

export interface IApprovalStatusLookUp
  extends Instance<typeof ApprovalStatusLookUpModel> {}

export interface IApprovalStatusLookUpSnapshotIn
  extends SnapshotIn<typeof ApprovalStatusLookUpModel> {}

export interface IApprovalStatusLookUpSnapshotOut
  extends SnapshotOut<typeof ApprovalStatusLookUpModel> {}

export default ApprovalStatusLookUpModel
