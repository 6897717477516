import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../../../PagedListRequestModel'
import Indicator3_1_1ReportFilterModel from './Indicator3_1_1ReportFilterModel'

const Indicator3_1_1ReportRequestModel = PagedListRequestModel.named(
  'Indicator3_1_1ReportRequestModel',
).props({
  sortBy: types.optional(types.string, ''),
  filter: types.optional(Indicator3_1_1ReportFilterModel, {}),
})

export interface IIndicator3_1_1ReportRequestModel
  extends Instance<typeof Indicator3_1_1ReportRequestModel> {}

export interface IIndicator3_1_1ReportRequestSnapshotIn
  extends SnapshotIn<typeof Indicator3_1_1ReportRequestModel> {}

export interface IIndicator3_1_1ReportRequestSnapshotOut
  extends SnapshotOut<typeof Indicator3_1_1ReportRequestModel> {}

export default Indicator3_1_1ReportRequestModel
