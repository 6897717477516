import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const EthnicityLookUpModel = LookUpModel.named('EthnicityLookUpModel').props({
  isComment: types.boolean,
})

export interface IEthnicityLookUp
  extends Instance<typeof EthnicityLookUpModel> {}

export interface IEthnicityLookUpSnapshotIn
  extends SnapshotIn<typeof EthnicityLookUpModel> {}

export interface IEthnicityLookUpSnapshotOut
  extends SnapshotOut<typeof EthnicityLookUpModel> {}

export default EthnicityLookUpModel
