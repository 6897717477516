import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import BeneficiariesIndividualPartnersModel from './BeneficiariesIndividualPartners'

const BeneficiaryIndividualWithOrganizationsModel = types.model(
  'BeneficiaryIndividualWithOrganizationsModel',
  {
    id: types.optional(types.integer, 0),
    fullName: types.maybeNull(types.string),
    code: types.maybeNull(types.string),
    serviceCountry: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    ethnicity: types.maybeNull(types.string),
    age: types.maybeNull(types.string),
    categories: types.maybeNull(types.string),
    organizations: types.array(BeneficiariesIndividualPartnersModel),
  },
)

export interface IBeneficiaryIndividualWithOrganizationsModel
  extends Instance<typeof BeneficiaryIndividualWithOrganizationsModel> {}
export interface IBeneficiaryIndividualWithOrganizationsSnapshotIn
  extends SnapshotIn<typeof BeneficiaryIndividualWithOrganizationsModel> {}
export interface IBeneficiaryIndividualWithOrganizationsSnapshotOut
  extends SnapshotOut<typeof BeneficiaryIndividualWithOrganizationsModel> {}
export default BeneficiaryIndividualWithOrganizationsModel
