import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateBeneficiaryIndividualCategoriesCommand = types.model(
  'UpdateBeneficiaryIndividualCategoriesCommand',
  {
    id: types.integer,
    categoryIds: types.array(types.number),
  },
)

export interface IUpdateBeneficiaryIndividualCategoriesCommand
  extends Instance<typeof UpdateBeneficiaryIndividualCategoriesCommand> {}

export interface IUpdateBeneficiaryIndividualCategoriesCommandIn
  extends SnapshotIn<typeof UpdateBeneficiaryIndividualCategoriesCommand> {}

export interface IUpdateBeneficiaryIndividualCategoriesCommandOut
  extends SnapshotOut<typeof UpdateBeneficiaryIndividualCategoriesCommand> {}

export default UpdateBeneficiaryIndividualCategoriesCommand
