import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { BeneficiaryCategoryLookUpModel } from '../../lookUps'

const BeneficiaryIndividualDetailedNameLookUpDto = types.model(
  'BeneficiaryIndividualDetailedNameLookUpDto',
  {
    fullName: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    middleName: types.maybeNull(types.string),
    code: types.maybeNull(types.string),
    age: types.maybeNull(types.number),
    ageCategory: types.maybeNull(types.string),
    ageCategoryId: types.maybeNull(types.number),
    birthDate: types.maybeNull(types.string),
    serviceCountry: types.maybeNull(types.string),
    serviceCountryId: types.maybeNull(types.number),
    serviceCountryParentId: types.maybeNull(types.integer),
    birthCountryId: types.maybeNull(types.number),
    birthCountry: types.maybeNull(types.string),
    birthCountryParentId: types.maybeNull(types.integer),
    ethnicity: types.maybeNull(types.string),
    ethnicityId: types.maybeNull(types.number),
    ethnicityParentId: types.maybeNull(types.integer),
    gender: types.maybeNull(types.string),
    genderId: types.maybeNull(types.number),
    genderParentId: types.maybeNull(types.integer),
    phoneNumber: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    categories: types.array(BeneficiaryCategoryLookUpModel),
    controlQuestion: types.maybeNull(types.string),
    controlQuestionAnswer: types.maybeNull(types.string),
    controlQuestionId: types.maybeNull(types.number),
    partners: types.array(types.string),
    isEdit: types.optional(types.boolean, false),
  },
)

export interface IBeneficiaryIndividualDetailedNameLookUpDto
  extends Instance<typeof BeneficiaryIndividualDetailedNameLookUpDto> {}
export interface IBeneficiaryIndividualDetailedNameLookUpDtoIn
  extends SnapshotIn<typeof BeneficiaryIndividualDetailedNameLookUpDto> {}
export interface IBeneficiaryIndividualDetailedNameLookUpDtoOut
  extends SnapshotOut<typeof BeneficiaryIndividualDetailedNameLookUpDto> {}
export default BeneficiaryIndividualDetailedNameLookUpDto
