import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../PagedListMetaData'
import OrganizationModel from './OrganizationModel'

const OrganizationPagedDto = types.model('OrganizationPagedDto', {
  data: types.array(OrganizationModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export interface IOrganizationPagedDto
  extends Instance<typeof OrganizationPagedDto> {}

export interface IOrganizationPagedDtoSnapshotIn
  extends SnapshotIn<typeof OrganizationPagedDto> {}

export interface IOrganizationPagedDtoSnapshotOut
  extends SnapshotOut<typeof OrganizationPagedDto> {}

export default OrganizationPagedDto
