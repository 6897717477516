import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../../../PagedListMetaData'
import IndicatorVulnerableMigrantReportModel from "./IndicatorVulnerableMigrantReportModel";

const IndicatorVulnerableMigrantReportPagedModel = types.model(
    'IndicatorVulnerableMigrantReportPagedModel',
    {
        data: types.array(IndicatorVulnerableMigrantReportModel),
        metadata: types.optional(PagedListMetaData, {}),
    },
)

export interface IIndicatorVulnerableMigrantReportPagedModel
    extends Instance<typeof IndicatorVulnerableMigrantReportPagedModel> {}

export interface IIndicatorVulnerableMigrantReportPagedSnapshotIn
    extends SnapshotIn<typeof IndicatorVulnerableMigrantReportPagedModel> {}

export interface IIndicatorVulnerableMigrantReportPagedSnapshotOut
    extends SnapshotOut<typeof IndicatorVulnerableMigrantReportPagedModel> {}

export default IndicatorVulnerableMigrantReportPagedModel