import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { GenericWithParentIdLookUpDto } from '../../../lookUps'
import IndicatorBaseDtoModel from '../IndicatorBaseDtoModel'

const Indicator1_2_2DtoModel = IndicatorBaseDtoModel.named(
  'Indicator1_2_2DtoModel',
).props({
  organizationId: types.optional(types.integer, 0),
  organization: types.maybeNull(types.string),
  recordDate: types.maybeNull(types.string),
  organizationTypeId: types.optional(types.integer, 0),
  organizationType: types.maybeNull(types.string),
  organizationTypeParentId: types.maybeNull(types.number),
  dataCollectionFrequencyId: types.optional(types.integer, 0),
  dataCollectionFrequency: types.maybeNull(types.string),
  dataCollectionFrequencyParentId: types.maybeNull(types.number),
  dataTypes: types.optional(types.array(GenericWithParentIdLookUpDto), []),
  supportTypes: types.optional(types.array(GenericWithParentIdLookUpDto), []),
  hasSupportedDocuments: types.optional(types.boolean, false),
})

export interface IIndicator1_2_2DtoModel
  extends Instance<typeof Indicator1_2_2DtoModel> {}
export interface IIndicator1_2_2DtoModelSnapshotIn
  extends SnapshotIn<typeof Indicator1_2_2DtoModel> {}
export interface IIndicator1_2_2DtoModelSnapshotOut
  extends SnapshotOut<typeof Indicator1_2_2DtoModel> {}
export default Indicator1_2_2DtoModel
