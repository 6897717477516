import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import { apiV1 } from 'core/requests'
import {
  IOrganizationSnapshotOut,
  PartnersServiceTypeModel,
  IPartnersServiceTypeSnapshotOut,
  IPartnersServiceTypeSnapshotIn,
} from 'stores/models'
import { AxiosResponse } from 'axios'

const PartnersServiceTypesStore = types
  .model('PartnersServiceTypesStore')
  .props({
    _isLoading: types.optional(types.boolean, false),
    _serviceTypes: types.array(PartnersServiceTypeModel),
  })
  .actions(self => {
    const PARTNERS_SERVICE_TYPES_API = '/api/partners/organization/servicetypes'

    const setIsLoading = (isLoading: boolean) => {
      self._isLoading = isLoading
    }

    const setPartnersServiceTypes = (
      serviceTypes: Array<IPartnersServiceTypeSnapshotIn>,
    ): void => {
      applySnapshot(self._serviceTypes, serviceTypes)
    }

    const getPartnersServiceTypes = async (
      organizationId: IOrganizationSnapshotOut['id'],
    ) => {
      const partnersServiceTypesRequest = fromPromise(
        apiV1.get<Array<IPartnersServiceTypeSnapshotOut>>(
          PARTNERS_SERVICE_TYPES_API,
          {
            params: { id: organizationId },
          },
        ),
      )

      when(() =>
        partnersServiceTypesRequest.case({
          fulfilled: response => {
            setPartnersServiceTypes(response.data)
            return true
          },
        }),
      )

      return partnersServiceTypesRequest
    }

    const editPartnersServiceTypes = async (
      organizationId: IOrganizationSnapshotOut['id'],
      serviceTypeIds: Array<IPartnersServiceTypeSnapshotOut['id']>,
    ): Promise<AxiosResponse> =>
      apiV1.put(
        PARTNERS_SERVICE_TYPES_API,
        {
          serviceTypeIds,
        },
        {
          params: { id: organizationId },
        },
      )

    return {
      setIsLoading,
      getPartnersServiceTypes,
      editPartnersServiceTypes,
    }
  })
  .views(self => ({
    get isLoading() {
      return self._isLoading
    },
    get serviceTypes() {
      return getSnapshot(self._serviceTypes)
    },
  }))

export default PartnersServiceTypesStore
export interface IPartnersServiceTypesStore
  extends Instance<typeof PartnersServiceTypesStore> {}
