import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const BeneficiaryActivitiesHistoryRequestModel = types.model(
  'BeneficiaryActivitiesHistoryRequestModel',
  {
    id: types.number,
    organizationId: types.maybeNull(types.number),
    sortBy: types.optional(types.string, ''),
  },
)

export interface IBeneficiaryActivitiesHistoryRequestModel
  extends Instance<typeof BeneficiaryActivitiesHistoryRequestModel> {}

export interface IBeneficiaryActivitiesHistoryRequestModelIn
  extends SnapshotIn<typeof BeneficiaryActivitiesHistoryRequestModel> {}

export interface IBeneficiaryActivitiesHistoryRequestModelOut
  extends SnapshotOut<typeof BeneficiaryActivitiesHistoryRequestModel> {}

export default BeneficiaryActivitiesHistoryRequestModel
