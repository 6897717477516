import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const SupportTypeLookUpModel = LookUpModel.named(
  'SupportTypeLookUpModel',
).props({
  isComment: types.boolean,
})

export interface ISupportTypeLookUp
  extends Instance<typeof SupportTypeLookUpModel> {}

export interface ISupportTypeLookUpSnapshotIn
  extends SnapshotIn<typeof SupportTypeLookUpModel> {}

export interface ISupportTypeLookUpSnapshotOut
  extends SnapshotOut<typeof SupportTypeLookUpModel> {}

export default SupportTypeLookUpModel
