import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const CreateUserCommand = types.model('CreateUserCommand', {
  email: types.string,
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  password: types.string,
  roleName: types.maybeNull(types.string),
  countryId: types.integer,
  organizationId: types.integer,
})

export interface ICreateUserCommand
  extends Instance<typeof CreateUserCommand> {}

export interface ICreateUserCommandSnapshotIn
  extends SnapshotIn<typeof CreateUserCommand> {}

export interface ICreateUserCommandSnapshotOut
  extends SnapshotOut<typeof CreateUserCommand> {}

export default CreateUserCommand
