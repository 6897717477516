import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const ActivityBeneficiaryIndividualWithFullNameModel = types.model(
  'ActivityBeneficiaryIndividualWithFullNameModel',
  {
    id: types.optional(types.number, 0),
    code: types.maybeNull(types.string),
    serviceCountry: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    ethnicity: types.maybeNull(types.string),
    fullName: types.maybeNull(types.string),
    memberTypes: types.string,
  },
)

export interface IActivityBeneficiaryIndividualWithFullNameModel
  extends Instance<typeof ActivityBeneficiaryIndividualWithFullNameModel> {}

export interface IActivityBeneficiaryIndividualWithFullNameSnapshotIn
  extends SnapshotIn<typeof ActivityBeneficiaryIndividualWithFullNameModel> {}

export interface IActivityBeneficiaryIndividualWithFullNameSnapshotOut
  extends SnapshotOut<typeof ActivityBeneficiaryIndividualWithFullNameModel> {}

export default ActivityBeneficiaryIndividualWithFullNameModel
