import { StatusName, StatusTypes } from 'stores/models'

export interface IStatusData {
  overlayText: string
  statusType: StatusTypes
}

export const StatusesData = new Map<StatusName, IStatusData>([
  [
    StatusName.New,
    {
      overlayText: '',
      statusType: StatusTypes.New,
    },
  ],
  [
    StatusName.PendingForReview,
    {
      overlayText: 'complete_review',
      statusType: StatusTypes.Pending,
    },
  ],
  [
    StatusName.RejectedByReviewer,
    {
      overlayText: '',
      statusType: StatusTypes.Rejected,
    },
  ],
  [
    StatusName.PendingForApproval,
    {
      overlayText: 'Approve',
      statusType: StatusTypes.Pending,
    },
  ],
  [
    StatusName.RejectedByApprover,
    {
      overlayText: '',
      statusType: StatusTypes.Rejected,
    },
  ],
  [
    StatusName.PendingForVerification,
    {
      overlayText: 'Verify',
      statusType: StatusTypes.Pending,
    },
  ],
  [
    StatusName.RejectedByApprover,
    {
      overlayText: '',
      statusType: StatusTypes.Rejected,
    },
  ],
  [
    StatusName.Verified,
    {
      overlayText: '',
      statusType: StatusTypes.Verified,
    },
  ],
  [
    StatusName.RejectedByVerifier,
    {
      overlayText: '',
      statusType: StatusTypes.Rejected,
    },
  ],
  [
    StatusName.RejectedByVerifier,
    {
      overlayText: '',
      statusType: StatusTypes.Rejected,
    },
  ],
])
