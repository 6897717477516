import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const MonthLookUpModel = LookUpModel.named('MonthLookUpModel')

export interface IMonthLookUpModel extends Instance<typeof MonthLookUpModel> {}
export interface IMonthLookUpSnapshotIn
  extends SnapshotIn<typeof MonthLookUpModel> {}
export interface IMonthLookUpSnapshotOut
  extends SnapshotOut<typeof MonthLookUpModel> {}
export default MonthLookUpModel
