import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const CreateBeneficiaryOrganizationCommand = types.model(
  'CreateBeneficiaryOrganizationCommand',
  {
    name: types.maybeNull(types.string),
    countryId: types.integer,
    countryComment: types.optional(types.string, ''),
    organizationTypeId: types.number,
    organizationId: types.number,
    organizationTypeComment: types.maybeNull(types.string),
  },
)

export interface ICreateBeneficiaryOrganizationCommand
  extends Instance<typeof CreateBeneficiaryOrganizationCommand> {}

export interface ICreateBeneficiaryOrganizationCommandIn
  extends SnapshotIn<typeof CreateBeneficiaryOrganizationCommand> {}

export interface ICreateBeneficiaryOrganizationCommandOut
  extends SnapshotOut<typeof CreateBeneficiaryOrganizationCommand> {}

export default CreateBeneficiaryOrganizationCommand
