import { FC } from 'react'
import { pageSizes } from 'core/constants'
import Select from 'react-select'
import styles from './PageSizeSelectStyles'
import './style.scss'
import { FormattedMessage } from 'react-intl'

type PageSizeSelectProps = {
  pageSize: number
  onPageSizeChange: (pageSize: number) => void
  className?: string
}

const PageSizeSelect: FC<PageSizeSelectProps> = ({
  pageSize,
  onPageSizeChange,
  className = '',
}) => {
  return (
    <div className={`pagination-sort ${className}`}>
      <p>
        <FormattedMessage
          id="page_size_select.items_per_page"
          defaultMessage="Элементов на странице:"
        />
      </p>
      <Select
        className="pageSize-select"
        value={{
          label: pageSize,
          value: pageSize,
        }}
        options={pageSizes.map(pageSize => ({
          label: pageSize,
          value: pageSize,
        }))}
        onChange={pageSize => {
          onPageSizeChange(pageSize.value)
        }}
        styles={styles}
        isClearable={false}
      />
    </div>
  )
}

export default PageSizeSelect
