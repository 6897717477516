enum PermissionActionName {
  Create = 'Create',
  View = 'View',
  Edit = 'Edit',
  Delete = 'Delete',
  Deactivate = 'Deactivate',
  Activate = 'Activate',
  ExcludeCategories = 'ExcludeCategories',
  EditOnRoleLevel = 'EditOnRoleLevel',
  EditOnUserLevel = 'EditOnUserLevel',
  ChangePassword = 'ChangePassword',
  ConfirmUserViaAzureAd = 'ConfirmUserViaAzureAd',
  BeneficiaryIndividualFullName = 'BeneficiaryIndividualFullName',
  StaffFullName = 'StaffFullName',
  DownloadFile = 'DownloadFile',
  ExportReport = 'ExportReport',
  ImportIndicator = 'ImportIndicator',
  EditCategory = 'EditCategory'
}

export default PermissionActionName
