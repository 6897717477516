import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../../../PagedListRequestModel'
import Indicator2_2_1ReportFilterModel from './Indicator2_2_1ReportFilterModel'

const Indicator2_2_1ReportRequestModel = PagedListRequestModel.named(
  'Indicator2_2_1ReportRequestModel',
).props({
  sortBy: types.optional(types.string, ''),
  filter: types.optional(Indicator2_2_1ReportFilterModel, {}),
})

export interface IIndicator2_2_1ReportRequestModel
  extends Instance<typeof Indicator2_2_1ReportRequestModel> {}

export interface IIndicator2_2_1ReportRequestSnapshotIn
  extends SnapshotIn<typeof Indicator2_2_1ReportRequestModel> {}

export interface IIndicator2_2_1ReportRequestSnapshotOut
  extends SnapshotOut<typeof Indicator2_2_1ReportRequestModel> {}

export default Indicator2_2_1ReportRequestModel
