import { StylesConfig } from 'react-select'

const white = '#ffffff'
const bsBorderRed = '#e74c3c'
const bsBackgroundOption = '#f8f9fa'
const bsBackgroundOptionSelected = '#eeeeee'
const bsControlTextColor = '#201F1E'
const bsPlaceHolderColor = 'rgba(32, 31, 30, 0.3)'
const DisabledContentColor = '#9F9E99'

export const styles: StylesConfig<any, any, any> = {
  container: base => ({
    ...base,
    width: '100%',
    fontWeight: 'normal',
    fontSize: '14px',
  }),
  control: base => ({
    ...base,
    minHeight: '2.1333rem',
    backgroundColor: white,
    boxShadow: 'none',
    color: bsControlTextColor,
    alignItems: 'center',
    borderRadius: '0',
  }),
  valueContainer: (base, state) => ({
    ...base,
    height: '100%',
    fontSize: '15px',
    padding: '0 18px 0 10px',
    flexWrap: state.isMulti ? 'wrap' : 'nowrap',
  }),
  input: base => ({
    ...base,
    color: bsControlTextColor,
  }),
  singleValue: (base, state) => ({
    ...base,
    color: state.isDisabled ? DisabledContentColor : bsControlTextColor,
  }),
  placeholder: (base, state) => ({
    display: state.selectProps.menuIsOpen ? 'none' : 'inline',
    color: bsPlaceHolderColor,
    paddingLeft: 3,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (base, state) => {
    const provided = {
      ...base,
      height: '100%',
    }

    if (state.isDisabled) {
      provided.display = 'none'
    }

    return provided
  },
  clearIndicator: base => ({
    ...base,
    padding: 0,
    border: 'none',
    background: 'transparent',
    width: 20,
    height: '100%',
    color: bsControlTextColor,
    alignItems: 'center',
    ':hover': {
      color: bsControlTextColor,
    },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    border: 'none',
    backgroundColor: 'transparent',
    height: '2.1333rem',
    width: 23,
    color: state.selectProps.invalid ? bsBorderRed : bsControlTextColor,
    padding: '5px 2px',
  }),
  option: (base, state) => ({
    ...base,
    color: bsControlTextColor,
    backgroundColor: state.isSelected
      ? bsBackgroundOptionSelected
      : state.isFocused
      ? bsBackgroundOption
      : white,
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  menu: base => ({
    ...base,
    padding: '0',
    margin: '0',
    borderRadius: '0',
  }),
}
