import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import IndicatorReportsFilterBaseModel from '../IndicatorReportsFilterBaseModel'

const IndicatorVulnerableMigrantReportFilterModel = IndicatorReportsFilterBaseModel.named(
    'IndicatorVulnerableMigrantReportFilterModel'
).props({
    GenderId: types.union(types.number, types.undefined, types.null),
    AgeCategoryEnum: types.union(types.number, types.undefined, types.null),
    EthnicityId: types.union(types.number, types.undefined, types.null),
    CountryOfOriginId: types.union(types.number, types.undefined, types.null),
    DestinationCountryId: types.union(types.number, types.undefined, types.null),
    RefferalOrgTypeId: types.union(types.number, types.undefined, types.null),
    CountryId: types.union(types.number, types.undefined, types.null)
})

export interface IIndicatorVulnerableMigrantReportFilterModel
    extends Instance<typeof IndicatorVulnerableMigrantReportFilterModel> {}

export interface IIndicatorVulnerableMigrantReportFilterSnapshotIn
    extends SnapshotIn<typeof IndicatorVulnerableMigrantReportFilterModel>{}

export interface IIndicatorVulnerableMigrantReportFilterSnapshotOut
    extends SnapshotOut<typeof IndicatorVulnerableMigrantReportFilterModel>{}

export  default IndicatorVulnerableMigrantReportFilterModel