import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../../PagedListRequestModel'

const StaffListRequestModel = PagedListRequestModel.named(
  'StaffListRequestModel',
).props({
  organizationId: types.number,
  sortBy: types.optional(types.string, ''),
  filter: types.optional(
    types.model({
      fullName: types.union(types.string, types.undefined, types.null),
      position: types.union(types.string, types.undefined, types.null),
      email: types.union(types.string, types.undefined, types.null),
    }),
    {},
  ),
})

export interface IStaffListRequestModel
  extends Instance<typeof StaffListRequestModel> {}

export interface IStaffListRequestSnapshotIn
  extends SnapshotIn<typeof StaffListRequestModel> {}

export interface IStaffListRequestSnapshotOut
  extends SnapshotOut<typeof StaffListRequestModel> {}

export default StaffListRequestModel
