import { FC, PropsWithChildren, ReactNode, useEffect } from 'react'
import SlidingPanel from 'react-sliding-side-panel'
import './Style.scss'
import 'react-sliding-side-panel/lib/index.css'

type RightSideBarProps = PropsWithChildren<{
  isOpen: boolean
  panelSize?: number
  close?: () => void
  headerText: string
  headerActionButton?: ReactNode
  subtitleText?: string
}>

const RightSideBar: FC<RightSideBarProps> = ({
  isOpen,
  panelSize = 24,
  close,
  headerText,
  subtitleText,
  children,
  headerActionButton,
}) => {
  useEffect(() => {
    const escKeyPress = ({ key }: KeyboardEvent): void =>
      void (key === 'Escape' && close?.())
    document.addEventListener('keydown', escKeyPress, false)

    return () => {
      document.removeEventListener('keydown', escKeyPress, false)
    }
  }, [close])

  return (
    <SlidingPanel
      type={'right'}
      isOpen={isOpen}
      size={panelSize}
      panelContainerClassName="rightSideBar"
    >
      <div className="rightSideBar__barContent position-relative">
        <div className="rightSideBar__header">
          <h4 className="rightSideBar__title">{headerText}</h4>
          {headerActionButton || (
            <i
              onClick={() => close?.()}
              className="rightSideBar__icon ms-Icon ms-Icon--Cancel"
            />
          )}
        </div>
        {!!subtitleText && (
          <div className="rightSideBar__subtitle">{subtitleText}</div>
        )}
        <div className="rightSideBar__body">{children}</div>
      </div>
    </SlidingPanel>
  )
}

// RightSideBar.propTypes = {
//   children: PropTypes.element.isRequired,
//   isOpen: PropTypes.bool.isRequired,
//   headerText: PropTypes.string.isRequired,
//   close: PropTypes.func.isRequired
// }

export default RightSideBar
