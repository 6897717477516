import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const BeneficiaryServiceHistoryLookUpDto = types.model(
  'BeneficiaryServiceHistoryLookUpDto',
  {
    id: types.number,
    serviceTypeId: types.integer,
    serviceTypeName: types.maybeNull(types.string),
    serviceDeliveredStaffId: types.integer,
    serviceDeliveredStaffName: types.maybeNull(types.string),
    serviceDeliveryDate: types.string,
    partnerName: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    countryId: types.number,
    region: types.maybeNull(types.string),
    regionId: types.number,
  },
)

export interface IBeneficiaryServiceHistoryLookUpDto
  extends Instance<typeof BeneficiaryServiceHistoryLookUpDto> {}
export interface IBeneficiaryServiceHistoryLookUpDtoIn
  extends SnapshotIn<typeof BeneficiaryServiceHistoryLookUpDto> {}
export interface IBeneficiaryServiceHistoryLookUpDtoOut
  extends SnapshotOut<typeof BeneficiaryServiceHistoryLookUpDto> {}
export default BeneficiaryServiceHistoryLookUpDto
