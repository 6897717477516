import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const ActivityTypeLookUpModel = LookUpModel.named(
  'ActivityTypeLookUpModel',
).props({
  isComment: types.boolean,
})

export interface IActivityTypeLookUp
  extends Instance<typeof ActivityTypeLookUpModel> {}

export interface IActivityTypeLookUpSnapshotIn
  extends SnapshotIn<typeof ActivityTypeLookUpModel> {}

export interface IActivityTypeLookUpSnapshotOut
  extends SnapshotOut<typeof ActivityTypeLookUpModel> {}

export default ActivityTypeLookUpModel
