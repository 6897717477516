import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import IndicatorReportsFilterBaseModel from '../IndicatorReportsFilterBaseModel'

const Indicator2_2_3ReportFilterModel = IndicatorReportsFilterBaseModel.named(
  'Indicator2_2_3ReportFilterModel',
).props({
  LegalEntityTypeId: types.union(types.number, types.undefined, types.null),
  BusinessScaleId: types.union(types.number, types.undefined, types.null),
  IndustryTypeId: types.union(types.number, types.undefined, types.null),
  SupportTypeId: types.union(types.number, types.undefined, types.null),
})

export interface IIndicator2_2_3ReportFilterModel
  extends Instance<typeof Indicator2_2_3ReportFilterModel> {}

export interface IIndicator2_2_3ReportFilterSnapshotIn
  extends SnapshotIn<typeof Indicator2_2_3ReportFilterModel> {}

export interface IIndicator2_2_3ReportFilterSnapshotOut
  extends SnapshotOut<typeof Indicator2_2_3ReportFilterModel> {}

export default Indicator2_2_3ReportFilterModel
