import { DashboardName, IndicatorsNumber } from 'stores/models'
import { MessageDescriptor } from 'react-intl'

type DashboardTitleTranslationMessage = MessageDescriptor & {
  values?: {
    indicatorNumber: string
  }
}

export const DashboardsTitlesTranslations = new Map<
  DashboardName,
  DashboardTitleTranslationMessage
>([
  [
    DashboardName.Services,
    {
      id: 'dashboards.services',
      defaultMessage: 'История оказания услуг SMICA',
    },
  ],
  [
    DashboardName.Activities,
    {
      id: 'dashboards.activities',
      defaultMessage: 'Мероприятия SMICA',
    },
  ],
  [
    DashboardName.Indicator1,
    {
      id: 'global.indicator.name',
      defaultMessage: 'Индикатор {indicatorNumber}',
      values: {
        indicatorNumber: IndicatorsNumber.Indicator1,
      },
    },
  ],
  [
    DashboardName.Indicator2,
    {
      id: 'global.indicator.name',
      defaultMessage: 'Индикатор {indicatorNumber}',
      values: {
        indicatorNumber: IndicatorsNumber.Indicator2,
      },
    },
  ],
  [
    DashboardName.Indicator3,
    {
      id: 'global.indicator.name',
      defaultMessage: 'Индикатор {indicatorNumber}',
      values: {
        indicatorNumber: IndicatorsNumber.Indicator3,
      },
    },
  ],
  [
    DashboardName.Indicator4,
    {
      id: 'global.indicator.name',
      defaultMessage: 'Индикатор {indicatorNumber}',
      values: {
        indicatorNumber: IndicatorsNumber.Indicator4,
      },
    },
  ],
  [
    DashboardName.Indicator5,
    {
      id: 'global.indicator.name',
      defaultMessage: 'Индикатор {indicatorNumber}',
      values: {
        indicatorNumber: IndicatorsNumber.Indicator5,
      },
    },
  ],
  [
    DashboardName.Indicator6,
    {
      id: 'global.indicator.name',
      defaultMessage: 'Индикатор {indicatorNumber}',
      values: {
        indicatorNumber: IndicatorsNumber.Indicator6,
      },
    },
  ],
  [
    DashboardName.Indicator7,
    {
      id: 'global.indicator.name',
      defaultMessage: 'Индикатор {indicatorNumber}',
      values: {
        indicatorNumber: IndicatorsNumber.Indicator7,
      },
    },
  ],
  [
    DashboardName.Indicator8,
    {
      id: 'global.indicator.name',
      defaultMessage: 'Индикатор {indicatorNumber}',
      values: {
        indicatorNumber: IndicatorsNumber.Indicator8,
      },
    },
  ],
  [
    DashboardName.Indicator9,
    {
      id: 'global.indicator.name',
      defaultMessage: 'Индикатор {indicatorNumber}',
      values: {
        indicatorNumber: IndicatorsNumber.Indicator9,
      },
    },
  ],
  [
    DashboardName.Indicator10,
    {
      id: 'global.indicator.name',
      defaultMessage: 'Индикатор {indicatorNumber}',
      values: {
        indicatorNumber: IndicatorsNumber.Indicator10,
      },
    },
  ],
  [
    DashboardName.Indicator11,
    {
      id: 'global.indicator.name',
      defaultMessage: 'Индикатор {indicatorNumber}',
      values: {
        indicatorNumber: IndicatorsNumber.Indicator11,
      },
    },
  ],
  [
    DashboardName.Indicator12,
    {
      id: 'global.indicator.name',
      defaultMessage: 'Индикатор {indicatorNumber}',
      values: {
        indicatorNumber: IndicatorsNumber.Indicator12,
      },
    },
  ],
  [
    DashboardName.Indicator13,
    {
      id: 'dashboards.vulnerable_migrant',
      defaultMessage: 'Уязвимый мигрант',
      values: {
        indicatorNumber: IndicatorsNumber.Indicator13,
      },
    },
  ],
])
