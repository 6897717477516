import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const BeneficiaryIndividualModel = types.model('BeneficiaryIndividualModel', {
  id: types.optional(types.integer, 0),
  fullName: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  serviceCountry: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  ethnicity: types.maybeNull(types.string),
  age: types.maybeNull(types.string),
  categories: types.maybeNull(types.string),
})

export interface IBeneficiaryIndividual
  extends Instance<typeof BeneficiaryIndividualModel> {}
export interface IBeneficiaryIndividualSnapshotIn
  extends SnapshotIn<typeof BeneficiaryIndividualModel> {}
export interface IBeneficiaryIndividualSnapshotOut
  extends SnapshotOut<typeof BeneficiaryIndividualModel> {}
export default BeneficiaryIndividualModel
