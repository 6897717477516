import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const PagedListRequestModel = types.model('PagedListRequestModel', {
  pageNumber: types.optional(types.number, 1),
  pageSize: types.optional(types.number, 10),
})

export interface IPagedListRequestModel
  extends Instance<typeof PagedListRequestModel> {}

export interface IPagedListRequestSnapshotIn
  extends SnapshotIn<typeof PagedListRequestModel> {}

export interface IPagedListRequestSnapshotOut
  extends SnapshotOut<typeof PagedListRequestModel> {}

export default PagedListRequestModel
