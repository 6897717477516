import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import { apiV1 } from 'core/requests'
import {
  ActivityPagedDto,
  IActivityPagedDtoSnapshotIn,
  IActivityListRequestSnapshotIn,
  ActivityListRequestModel,
  ICreateActivityCommandSnapshotIn,
  CreateActivityCommand,
  IActivityPagedDtoSnapshotOut,
  ActivityDetailsModel,
  IActivityDetailsSnapshotIn,
  IActivitySnapshotOut,
  IActivityDetailsSnapshotOut,
  UpdateActivityCommand,
  IUpdateActivityCommandSnapshotIn,
  ActivityBeneficiaryIndividualsPagedDto,
  ActivityStaffPagedDto,
  IActivityStaffPagedDtoSnapshotIn,
  ActivityBeneficiaryIndividualsRequestModel,
  IActivityBeneficiaryIndividualsPagedDtoSnapshotIn,
  IActivityBeneficiaryIndividualsRequestSnapshotIn,
  IActivityStaffRequestSnapshotIn,
  ActivityStaffRequestModel,
  UpdateActivityBeneficiaryIndividualsCommand,
  IUpdateActivityBeneficiaryIndividualsCommandSnapshotIn,
  UpdateActivityStaffCommand,
  IUpdateActivityStaffCommandSnapshotIn,
  IActivityBeneficiaryIndividualsPagedDtoSnapshotOut,
  IActivityStaffPagedDtoSnapshotOut,
  BeneficiaryIndividualsLookUpModel,
  IBeneficiaryIndividualsLookUpModelIn,
  IActivityStaffWithNameSnapshotOut,
  IActivityBeneficiaryIndividualWithFullNameSnapshotOut,
  FileLookUpModel,
  IFileLookUpSnapshotIn,
  IFileLookUpSnapshotOut,
  IFileDataSnapshotOut,
  IActivityTestResultRequestIn,
  ActivityTestResultRequestModel,
  ActivityTestResultPagedDto,
  IActivityTestResultPagedDtoSnapshotIn,
  IUpdateActivityTestResultCommandSnapshotIn,
  UpdateActivityTestResultCommand,
  ActivityTestType,
  ActivityTestsApiData,
  IUpdateSingleActivityStaffTestResultCommandSnapshotIn,
  UpdateSingleActivityStaffTestResultCommand,
  IOrganizationStaffLookUpModelSnapshotIn,
  OrganizationStaffLookUpModel,
} from 'stores/models'
import fileDownload from 'js-file-download'

const PartnersActivitiesStore = types
  .model('PartnersActivitiesStore')
  .props({
    _activities: types.optional(ActivityPagedDto, {}),
    _activityDetails: types.optional(ActivityDetailsModel, {}),
    _activityTests: types.optional(ActivityTestResultPagedDto, {}),
    _activityBeneficiaryIndividuals: types.optional(
      ActivityBeneficiaryIndividualsPagedDto,
      {},
    ),
    _activityCurrentBeneficiaryIndividualsIds: types.array(types.number),
    _activityBeneficiaryIndividualsLookUps: types.array(
      BeneficiaryIndividualsLookUpModel,
    ),
    _partnersBeneficiaryIndividuals: types.array(
      BeneficiaryIndividualsLookUpModel,
    ),
    _activityStaffLookUps: types.array(OrganizationStaffLookUpModel),
    _activityStaff: types.optional(ActivityStaffPagedDto, {}),
    _activityCurrentStaffIds: types.array(types.number),
    _activityAttachments: types.array(FileLookUpModel),
    _isLoading: types.optional(types.boolean, false),
    _isDetailsLoading: types.optional(types.boolean, false),
    _isAttachmentsLoading: types.optional(types.boolean, false),
    _isAttachmentsUploading: types.optional(types.boolean, false),
    _activityCounter: types.optional(types.number, 0),
  })
  .actions(self => ({
    setActivityCounter(activityCounter: number): void {
      self._activityCounter = activityCounter
    },
    setActivityDetails: (activityDetails: IActivityDetailsSnapshotIn): void => {
      applySnapshot(self._activityDetails, activityDetails)
    },
    setActivityBeneficiaryIndividuals: (
      activityBeneficiaryIndividuals: IActivityBeneficiaryIndividualsPagedDtoSnapshotIn,
    ): void => {
      applySnapshot(
        self._activityBeneficiaryIndividuals,
        activityBeneficiaryIndividuals,
      )
    },
    setActivityCurrentBeneficiaryIndividualsIds: (
      activityCurrentBeneficiaryIndividualsIds: Array<number>,
    ): void => {
      applySnapshot(
        self._activityCurrentBeneficiaryIndividualsIds,
        activityCurrentBeneficiaryIndividualsIds,
      )
    },
    setPartnersBeneficiaryIndividuals: (
      partnersBeneficiaryIndividuals: Array<IBeneficiaryIndividualsLookUpModelIn>,
    ): void => {
      applySnapshot(
        self._partnersBeneficiaryIndividuals,
        partnersBeneficiaryIndividuals,
      )
    },
    setActivityStaffLookUps: (
      activityStaffLookUps: Array<IOrganizationStaffLookUpModelSnapshotIn>,
    ): void => {
      applySnapshot(self._activityStaffLookUps, activityStaffLookUps)
    },
    setActivityBeneficiaryIndividualsLookUps: (
      activityBeneficiaryIndividualsLookUps: Array<IBeneficiaryIndividualsLookUpModelIn>,
    ): void => {
      applySnapshot(
        self._activityBeneficiaryIndividualsLookUps,
        activityBeneficiaryIndividualsLookUps,
      )
    },
    setActivityStaff: (
      activityStaff: IActivityStaffPagedDtoSnapshotIn,
    ): void => {
      applySnapshot(self._activityStaff, activityStaff)
    },
    setActivityCurrentStaffIds: (
      activityCurrentStaffIds: Array<number>,
    ): void => {
      applySnapshot(self._activityCurrentStaffIds, activityCurrentStaffIds)
    },
    setActivityAttachments: (
      activityAttachments: Array<IFileLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._activityAttachments, activityAttachments)
    },
    setActivityTests: (
      activityTests: IActivityTestResultPagedDtoSnapshotIn,
    ): void => {
      applySnapshot(self._activityTests, activityTests)
    },
  }))
  .actions(self => {
    const PARTNER_ACTIVITIES_API = '/api/partners/activities'
    const PARTNER_ACTIVITIES_INDIVIDUALS_API = `${PARTNER_ACTIVITIES_API}/beneficiaryindividuals`
    const PARTNER_ACTIVITIES_STAFF_API = `${PARTNER_ACTIVITIES_API}/staff`
    const PARTNER_ACTIVITIES_ATTACHMENTS_API = `${PARTNER_ACTIVITIES_API}/attachments`

    const PARTNER_ACTIVITIES_INDIVIDUAL_TESTS_API = `${PARTNER_ACTIVITIES_API}/tests/beneficiaryindividuals`
    const PARTNER_ACTIVITIES_STAFF_TESTS_API = `${PARTNER_ACTIVITIES_API}/tests/staff`

    const setActivities = (activities: IActivityPagedDtoSnapshotIn): void => {
      applySnapshot(self._activities, activities)
    }

    const setIsLoading = (isLoading: boolean) => {
      self._isLoading = isLoading
    }

    const setIsDetailsLoading = (isLoading: boolean) => {
      self._isDetailsLoading = isLoading
    }

    const setIsAttachmentsLoading = (isLoading: boolean) => {
      self._isAttachmentsLoading = isLoading
    }

    const setIsAttachmentsUploading = (isLoading: boolean) => {
      self._isAttachmentsUploading = isLoading
    }

    const getActivitiesList = async (
      params: IActivityListRequestSnapshotIn,
    ) => {
      const activitiesListRequest = fromPromise(
        apiV1.get<IActivityPagedDtoSnapshotIn>(PARTNER_ACTIVITIES_API, {
          params: ActivityListRequestModel.create(params),
        }),
      )

      when(() =>
        activitiesListRequest.case({
          fulfilled: response => {
            setActivities(response.data)
            return true
          },
        }),
      )

      return activitiesListRequest
    }

    const getActivityDetailsById = async (
      activityId: IActivitySnapshotOut['id'],
    ) => {
      const activitiesDetailsRequest = fromPromise(
        apiV1.get<IActivityDetailsSnapshotIn>(
          `${PARTNER_ACTIVITIES_API}/details`,
          {
            params: { id: activityId },
          },
        ),
      )

      when(() =>
        activitiesDetailsRequest.case({
          fulfilled: response => {
            self.setActivityDetails(response.data)
            return true
          },
        }),
      )
      return activitiesDetailsRequest
    }

    const createActivity = async (
      activity: ICreateActivityCommandSnapshotIn,
    ): Promise<IActivitySnapshotOut['id']> => {
      const { data: createdActivityId } = await apiV1.post<
        IActivitySnapshotOut['id']
      >(PARTNER_ACTIVITIES_API, CreateActivityCommand.create(activity))

      return createdActivityId
    }

    const editActivity = async (
      activity: IUpdateActivityCommandSnapshotIn,
      activityId: IActivitySnapshotOut['id'],
    ): Promise<void> =>
      apiV1.put(
        PARTNER_ACTIVITIES_API,
        UpdateActivityCommand.create(activity),
        {
          params: { id: activityId },
        },
      )

    const getActivityBeneficiaryIndividuals = async (
      params: IActivityBeneficiaryIndividualsRequestSnapshotIn,
    ) => {
      const activityBeneficiaryIndividualsRequest = fromPromise(
        apiV1.get<IActivityBeneficiaryIndividualsPagedDtoSnapshotIn>(
          PARTNER_ACTIVITIES_INDIVIDUALS_API,
          {
            params: ActivityBeneficiaryIndividualsRequestModel.create(params),
          },
        ),
      )

      when(() =>
        activityBeneficiaryIndividualsRequest.case({
          fulfilled: response => {
            self.setActivityBeneficiaryIndividuals(response.data)
            return true
          },
        }),
      )

      return activityBeneficiaryIndividualsRequest
    }

    const getActivityBeneficiaryIndividualsWithFullName = async (
      params: IActivityBeneficiaryIndividualsRequestSnapshotIn,
    ) => {
      const activityBeneficiaryIndividualsWithFullNameRequest = fromPromise(
        apiV1.get<IActivityBeneficiaryIndividualsPagedDtoSnapshotIn>(
          `${PARTNER_ACTIVITIES_INDIVIDUALS_API}/withfullname`,
          {
            params: ActivityBeneficiaryIndividualsRequestModel.create(params),
          },
        ),
      )

      when(() =>
        activityBeneficiaryIndividualsWithFullNameRequest.case({
          fulfilled: response => {
            self.setActivityBeneficiaryIndividuals(response.data)
            return true
          },
        }),
      )

      return activityBeneficiaryIndividualsWithFullNameRequest
    }

    const getActivityCurrentBeneficiaryIndividualsIds = async (
      activityId: IActivitySnapshotOut['id'],
    ) => {
      const activityCurrentBeneficiaryIndividualsIdsRequest = fromPromise(
        apiV1.get<Array<number>>(
          `${PARTNER_ACTIVITIES_INDIVIDUALS_API}/current`,
          {
            params: { id: activityId },
          },
        ),
      )

      when(() =>
        activityCurrentBeneficiaryIndividualsIdsRequest.case({
          fulfilled: response => {
            self.setActivityCurrentBeneficiaryIndividualsIds(response.data)
            return true
          },
        }),
      )

      return activityCurrentBeneficiaryIndividualsIdsRequest
    }

    const getPartnersBeneficiaryIndividuals = async (
      organizationId: number,
    ) => {
      const partnersBeneficiaryIndividualsRequest = fromPromise(
        apiV1.get<Array<IBeneficiaryIndividualsLookUpModelIn>>(
          `${PARTNER_ACTIVITIES_INDIVIDUALS_API}/list`,
          {
            params: { id: organizationId },
          },
        ),
      )

      when(() =>
        partnersBeneficiaryIndividualsRequest.case({
          fulfilled: response => {
            self.setPartnersBeneficiaryIndividuals(response.data)
            return true
          },
        }),
      )

      return partnersBeneficiaryIndividualsRequest
    }

    const getActivityStaffByActivityId = async (activityId: number) => {
      const activityStaffRequest = fromPromise(
        apiV1.get<Array<IOrganizationStaffLookUpModelSnapshotIn>>(
          `${PARTNER_ACTIVITIES_API}/staffbyactivityid/withfullname`,
          {
            params: { id: activityId },
          },
        ),
      )

      when(() =>
        activityStaffRequest.case({
          fulfilled: response => {
            self.setActivityStaffLookUps(response.data)
            return true
          },
        }),
      )

      return activityStaffRequest
    }

    const getActivityBeneficiaryIndividualsLookUps = async (
      activityId: IActivitySnapshotOut['id'],
    ) => {
      const activityBeneficiaryIndividualsRequest = fromPromise(
        apiV1.get<Array<IBeneficiaryIndividualsLookUpModelIn>>(
          `${PARTNER_ACTIVITIES_INDIVIDUALS_API}/lookup`,
          {
            params: { id: activityId },
          },
        ),
      )

      when(() =>
        activityBeneficiaryIndividualsRequest.case({
          fulfilled: response => {
            self.setActivityBeneficiaryIndividualsLookUps(response.data)
            return true
          },
        }),
      )

      return activityBeneficiaryIndividualsRequest
    }

    const editActivityBeneficiaryIndividuals = async (
      updateRequestData: IUpdateActivityBeneficiaryIndividualsCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.put(
        PARTNER_ACTIVITIES_INDIVIDUALS_API,
        UpdateActivityBeneficiaryIndividualsCommand.create(updateRequestData),
      )

    const deleteActivityBeneficiaryIndividual = async (
      beneficiaryIndividualId: IActivityBeneficiaryIndividualWithFullNameSnapshotOut['id'],
      activityId: IActivitySnapshotOut['id'],
    ): Promise<void> =>
      apiV1.delete(PARTNER_ACTIVITIES_INDIVIDUALS_API, {
        params: { beneficiaryIndividualId, id: activityId },
      })

    const getActivityStaff = async (
      params: IActivityStaffRequestSnapshotIn,
    ) => {
      const activityStaffRequest = fromPromise(
        apiV1.get<IActivityStaffPagedDtoSnapshotIn>(
          PARTNER_ACTIVITIES_STAFF_API,
          {
            params: ActivityStaffRequestModel.create(params),
          },
        ),
      )
      when(() =>
        activityStaffRequest.case({
          fulfilled: response => {
            self.setActivityStaff(response.data)
            return true
          },
        }),
      )

      return activityStaffRequest
    }

    const getActivityStaffWithName = async (
      params: IActivityStaffRequestSnapshotIn,
    ) => {
      const activityStaffRequest = fromPromise(
        apiV1.get<IActivityStaffPagedDtoSnapshotIn>(
          `${PARTNER_ACTIVITIES_STAFF_API}/withfullname`,
          {
            params: ActivityStaffRequestModel.create(params),
          },
        ),
      )
      when(() =>
        activityStaffRequest.case({
          fulfilled: response => {
            self.setActivityStaff(response.data)
            return true
          },
        }),
      )

      return activityStaffRequest
    }

    const getActivityCurrentStaffIds = async (
      activityId: IActivitySnapshotOut['id'],
    ) => {
      const activityCurrentStaffIdsRequest = fromPromise(
        apiV1.get<Array<number>>(`${PARTNER_ACTIVITIES_STAFF_API}/current`, {
          params: { id: activityId },
        }),
      )

      when(() =>
        activityCurrentStaffIdsRequest.case({
          fulfilled: response => {
            self.setActivityCurrentStaffIds(response.data)
            return true
          },
        }),
      )

      return activityCurrentStaffIdsRequest
    }

    const editActivityStaff = async (
      updateRequestData: IUpdateActivityStaffCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.put(
        PARTNER_ACTIVITIES_STAFF_API,
        UpdateActivityStaffCommand.create(updateRequestData),
      )

    const deleteActivityStaff = async (
      staffId: IActivityStaffWithNameSnapshotOut['id'],
      activityId: IActivitySnapshotOut['id'],
    ): Promise<void> =>
      apiV1.delete(PARTNER_ACTIVITIES_STAFF_API, {
        params: { staffId, id: activityId },
      })

    const pendActivity = async (
      id: IActivitySnapshotOut['id'],
    ): Promise<void> => apiV1.put(`${PARTNER_ACTIVITIES_API}/pend`, { id })

    const deleteActivity = async (
      id: IActivitySnapshotOut['id'],
    ): Promise<void> =>
      apiV1.delete(`${PARTNER_ACTIVITIES_API}`, {
        params: { id },
      })

    const getActivityAttachments = async (
      activityId: IActivitySnapshotOut['id'],
    ) => {
      const activityAttchmentsRequest = fromPromise(
        apiV1.get<Array<IFileLookUpSnapshotIn>>(
          PARTNER_ACTIVITIES_ATTACHMENTS_API,
          {
            params: { activityId },
          },
        ),
      )

      when(() =>
        activityAttchmentsRequest.case({
          fulfilled: response => {
            self.setActivityAttachments(response.data)
            return true
          },
        }),
      )

      return activityAttchmentsRequest
    }

    const getActivityAttachmentDataById = async (
      fileId: IFileLookUpSnapshotOut['id'],
      onDownloadProgress: (progressEvent: ProgressEvent) => void,
    ) =>
      apiV1.get<IFileDataSnapshotOut>(
        `${PARTNER_ACTIVITIES_ATTACHMENTS_API}/download`,
        {
          params: { fileId },
          onDownloadProgress,
        },
      )

    const downloadAttachment = async (
      fileId: IFileLookUpSnapshotOut['id'],
      onDownloadProgress: (progressEvent: ProgressEvent) => void,
    ) => {
      const {
        data: { file, originalFileName, contentType },
      } = await getActivityAttachmentDataById(fileId, onDownloadProgress)
      if (!file || !originalFileName || !contentType) throw new Error()
      const fileDataUrl = `data:${contentType};base64,${file}`
      const fetchedFile = new File(
        [await (await fetch(fileDataUrl)).arrayBuffer()],
        originalFileName,
        { type: contentType },
      )
      fileDownload(fetchedFile, originalFileName)
    }

    const addActivityAttachments = async (
      activityId: IActivitySnapshotOut['id'],
      attachments: Array<File>,
    ): Promise<void> => {
      const formData = new FormData()
      formData.append('activityId', `${activityId}`)
      for (const attachment of attachments) {
        formData.append('attachments', attachment)
      }

      return apiV1.post(PARTNER_ACTIVITIES_ATTACHMENTS_API, formData)
    }

    const deleteActivityAttachments = async (
      id: IFileLookUpSnapshotOut['id'],
    ): Promise<void> =>
      apiV1.delete(PARTNER_ACTIVITIES_ATTACHMENTS_API, {
        params: { id },
      })

    const getActivityBeneficiaryIndividualTestResult = async (
      params: IActivityTestResultRequestIn,
    ) => {
      const activityBeneficiaryIndividualTestResultRequest = fromPromise(
        apiV1.get<IActivityTestResultPagedDtoSnapshotIn>(
          PARTNER_ACTIVITIES_INDIVIDUAL_TESTS_API,
          {
            params: ActivityTestResultRequestModel.create(params),
          },
        ),
      )

      when(() =>
        activityBeneficiaryIndividualTestResultRequest.case({
          fulfilled: response => {
            self.setActivityTests(response.data)
            return true
          },
        }),
      )

      return activityBeneficiaryIndividualTestResultRequest
    }

    const getActivityBeneficiaryIndividualTestResultWithFullName = async (
      params: IActivityTestResultRequestIn,
    ) => {
      const activityBeneficiaryIndividualTestResultRequest = fromPromise(
        apiV1.get<IActivityTestResultPagedDtoSnapshotIn>(
          `${PARTNER_ACTIVITIES_INDIVIDUAL_TESTS_API}/withfullname`,
          {
            params: ActivityTestResultRequestModel.create(params),
          },
        ),
      )

      when(() =>
        activityBeneficiaryIndividualTestResultRequest.case({
          fulfilled: response => {
            self.setActivityTests(response.data)
            return true
          },
        }),
      )

      return activityBeneficiaryIndividualTestResultRequest
    }

    const getActivityStaffTestResult = async (
      params: IActivityTestResultRequestIn,
    ) => {
      const activityStaffTestResultRequest = fromPromise(
        apiV1.get<IActivityTestResultPagedDtoSnapshotIn>(
          PARTNER_ACTIVITIES_STAFF_TESTS_API,
          {
            params: ActivityTestResultRequestModel.create(params),
          },
        ),
      )

      when(() =>
        activityStaffTestResultRequest.case({
          fulfilled: response => {
            self.setActivityTests(response.data)
            return true
          },
        }),
      )

      return activityStaffTestResultRequest
    }

    const getActivityStaffTestResultWithName = async (
      params: IActivityTestResultRequestIn,
    ) => {
      const activityStaffTestResultRequest = fromPromise(
        apiV1.get<IActivityTestResultPagedDtoSnapshotIn>(
          `${PARTNER_ACTIVITIES_STAFF_TESTS_API}/withfullname`,
          {
            params: ActivityTestResultRequestModel.create(params),
          },
        ),
      )

      when(() =>
        activityStaffTestResultRequest.case({
          fulfilled: response => {
            self.setActivityTests(response.data)
            return true
          },
        }),
      )

      return activityStaffTestResultRequest
    }

    const addActivityTestResult = async (
      testResult: IUpdateActivityTestResultCommandSnapshotIn,
      activityTestType: ActivityTestType,
    ): Promise<void> => {
      const TEST_RESULT_API = ActivityTestsApiData.get(activityTestType)
      if (!TEST_RESULT_API) throw Error('TEST_RESULT_API is empty')
      await apiV1.put(
        TEST_RESULT_API,
        UpdateActivityTestResultCommand.create(testResult),
      )
    }

    const updateSingleActivityTestResult = async (
      testResult: IUpdateSingleActivityStaffTestResultCommandSnapshotIn,
      activityTestType: ActivityTestType,
    ): Promise<void> => {
      const TEST_RESULT_API = ActivityTestsApiData.get(activityTestType)
      if (!TEST_RESULT_API) throw Error('TEST_RESULT_API is empty')
      await apiV1.put(
        TEST_RESULT_API,
        UpdateSingleActivityStaffTestResultCommand.create(testResult),
      )
    }

    const deleteSingleActivityTestResult = async (
      activityId: number,
      participantId: number,
      activityTestType: ActivityTestType,
    ): Promise<void> => {
      const TEST_RESULT_API = ActivityTestsApiData.get(activityTestType)
      if (!TEST_RESULT_API) throw Error('TEST_RESULT_API is empty')
      await apiV1.delete(TEST_RESULT_API, {
        params: { activityId, participantId },
      })
    }

    const getActivityCounter = async (organizationId: number) => {
      const activityCounterRequest = fromPromise(
        apiV1.get<number>(`${PARTNER_ACTIVITIES_API}/count`, {
          params: { organizationId },
        }),
      )

      when(() =>
        activityCounterRequest.case({
          fulfilled: response => {
            self.setActivityCounter(response.data)
            return true
          },
        }),
      )
      return activityCounterRequest
    }

    return {
      getActivitiesList,
      getActivityDetailsById,
      getActivityBeneficiaryIndividuals,
      getActivityBeneficiaryIndividualsWithFullName,
      getActivityCurrentBeneficiaryIndividualsIds,
      getPartnersBeneficiaryIndividuals,
      getActivityStaffByActivityId,
      getActivityBeneficiaryIndividualsLookUps,
      getActivityStaff,
      getActivityStaffWithName,
      getActivityCurrentStaffIds,
      createActivity,
      editActivity,
      editActivityBeneficiaryIndividuals,
      editActivityStaff,
      deleteActivityBeneficiaryIndividual,
      deleteActivityStaff,
      setIsLoading,
      setIsDetailsLoading,
      pendActivity,
      deleteActivity,
      getActivityAttachments,
      getActivityAttachmentDataById,
      downloadAttachment,
      addActivityAttachments,
      deleteActivityAttachments,
      setIsAttachmentsLoading,
      setIsAttachmentsUploading,
      getActivityBeneficiaryIndividualTestResultWithFullName,
      getActivityBeneficiaryIndividualTestResult,
      getActivityStaffTestResult,
      getActivityStaffTestResultWithName,
      addActivityTestResult,
      updateSingleActivityTestResult,
      deleteSingleActivityTestResult,
      getActivityCounter,
    }
  })
  .views(self => ({
    get activities(): IActivityPagedDtoSnapshotOut {
      return getSnapshot(self._activities)
    },
    get activitiesList() {
      return this.activities.data
    },
    get activitiesMetadata() {
      return this.activities.metadata
    },
    get activityDetails(): IActivityDetailsSnapshotOut {
      return getSnapshot(self._activityDetails)
    },
    get activityBeneficiaryIndividuals(): IActivityBeneficiaryIndividualsPagedDtoSnapshotOut {
      return getSnapshot(self._activityBeneficiaryIndividuals)
    },
    get activityBeneficiaryIndividualsList() {
      return this.activityBeneficiaryIndividuals.data
    },
    get activityBeneficiaryIndividualsMetadata() {
      return this.activityBeneficiaryIndividuals.metadata
    },
    get activityCurrentBeneficiaryIndividualsIds() {
      return getSnapshot(self._activityCurrentBeneficiaryIndividualsIds)
    },
    get partnersBeneficiaryIndividuals() {
      return getSnapshot(self._partnersBeneficiaryIndividuals)
    },
    get activityStaff(): IActivityStaffPagedDtoSnapshotOut {
      return getSnapshot(self._activityStaff)
    },
    get activityStaffList() {
      return this.activityStaff.data
    },
    get activityStaffMetadata() {
      return this.activityStaff.metadata
    },
    get activityCurrentStaffIds() {
      return getSnapshot(self._activityCurrentStaffIds)
    },
    get activityAttachments() {
      return getSnapshot(self._activityAttachments)
    },
    get activityTests() {
      return getSnapshot(self._activityTests)
    },
    get activityTestsList() {
      return this.activityTests.data
    },
    get activityTestsMetadata() {
      return this.activityTests.metadata
    },
    get activityBeneficiaryIndividualsLookUps() {
      return getSnapshot(self._activityBeneficiaryIndividualsLookUps)
    },
    get activityStaffLookUps() {
      return getSnapshot(self._activityStaffLookUps)
    },
    get isLoading() {
      return self._isLoading
    },
    get isDetailsLoading() {
      return self._isDetailsLoading
    },
    get isAttachmentsLoading() {
      return self._isAttachmentsLoading
    },
    get isAttachmentsUploading() {
      return self._isAttachmentsUploading
    },
    get activityCounter() {
      return self._activityCounter
    },
  }))

export default PartnersActivitiesStore
export interface IPartnersActivitiesStore
  extends Instance<typeof PartnersActivitiesStore> {}
