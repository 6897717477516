import AppStore from './AppStore'
import AuthStore from './AuthStore'
import PartnersStore from './partners/PartnersStore'
import PartnersStaffStore from './partners/PartnersStaffStore'
import PartnersServiceTypesStore from './partners/PartnersServiceTypesStore'
import UsersStore from './UsersStore'
import RolePermissionsStore from './RolePermissionsStore'
import UserPermissionsStore from './UserPermissionsStore'

import BeneficiariesIndividualStore from './BeneficiariesIndividualStore'
import BeneficiariesOrganizationsStore from './BeneficiariesOrganizationsStore'

import LookUpStore from './LookUpStore'

import BeneficiaryStore from './catalogs/BeneficiaryStore'
import CatalogLayoutStore from './catalogs/CatalogLayoutStore'

import PartnersBeneficiaryIndividualStore from './partners/PartnersBeneficiaryIndividualStore'
import PartnersBeneficiaryOrganizationStore from './partners/PartnersBeneficiaryOrganizationStore'
import PartnersServiceHistoryStore from './partners/PartnersServiceHistoryStore'
import PartnersActivitiesStore from './partners/PartnersActivitiesStore'
import PartnersIndicatorsStore from './partners/PartnersIndicatorsStore'

import DictionaryByIndicatorStore from './DictionaryByIndicatorStore'

import ActivityStore from './ActivityStore'
import ServiceHistoryStore from './ServiceHistoryStore'
import PowerBIReportsStore from './PowerBIReportsStore'

import IndicatorsReportsStore from './IndicatorsReportsStore'
import IndicatorsStore from './IndicatorsStore'
import IndicatorTargetsStore from './IndicatorTargetsStore'

const storesRoot = {
  appStore: AppStore.create(),
  authStore: AuthStore.create(),
  rolePermissionsStore: RolePermissionsStore.create(),
  userPermissionsStore: UserPermissionsStore.create(),
  beneficiariesIndividualStore: BeneficiariesIndividualStore.create(),
  beneficiariesOrganizationsStore: BeneficiariesOrganizationsStore.create(),
  beneficiaryStore: BeneficiaryStore.create(),
  catalogLayoutStore: CatalogLayoutStore.create(),
  indicatorsStore: IndicatorsStore.create(),
  partnersStore: PartnersStore.create(),
  partnersStaffStore: PartnersStaffStore.create(),
  partnersServiceTypesStore: PartnersServiceTypesStore.create(),
  partnersBeneficiaryIndividualStore: PartnersBeneficiaryIndividualStore.create(),
  partnersBeneficiaryOrganizationStore: PartnersBeneficiaryOrganizationStore.create(),
  partnersServiceHistoryStore: PartnersServiceHistoryStore.create(),
  partnersActivitiesStore: PartnersActivitiesStore.create(),
  partnersIndicatorsStore: PartnersIndicatorsStore.create(),
  dictionaryByIndicatorStore: DictionaryByIndicatorStore.create(),
  activityStore: ActivityStore.create(),
  serviceHistoryStore: ServiceHistoryStore.create(),
  usersStore: UsersStore.create(),
  powerBIReportsStore: PowerBIReportsStore.create(),
  lookUpStore: LookUpStore.create(),
  indicatorsReportsStore: IndicatorsReportsStore.create(),
  indicatorTargetsStore: IndicatorTargetsStore.create(),
}
type StoresRootType = typeof storesRoot

export * from './BeneficiariesIndividualStore'
export * from './BeneficiariesOrganizationsStore'
export * from './AppStore'
export * from './AuthStore'
export * from './partners/PartnersStore'
export * from './partners/PartnersStaffStore'
export * from './partners/PartnersServiceTypesStore'
export * from './partners/PartnersBeneficiaryIndividualStore'
export * from './partners/PartnersBeneficiaryOrganizationStore'
export * from './partners/PartnersServiceHistoryStore'
export * from './partners/PartnersActivitiesStore'
export * from './partners/PartnersIndicatorsStore'
export * from './DictionaryByIndicatorStore'
export * from './ActivityStore'
export * from './ServiceHistoryStore'
export * from './UsersStore'
export * from './RolePermissionsStore'
export * from './UserPermissionsStore'
export * from './LookUpStore'
export * from './PowerBIReportsStore'
export * from './IndicatorsReportsStore'
export * from './IndicatorTargetsStore'

export * from './catalogs/BeneficiaryStore'
export * from './IndicatorsStore'
export * from './catalogs/CatalogLayoutStore'

export type { StoresRootType }
export {
  storesRoot as stores,
  AppStore,
  PartnersStore,
  PartnersStaffStore,
  PartnersServiceTypesStore,
  PartnersBeneficiaryIndividualStore,
  PartnersBeneficiaryOrganizationStore,
  PartnersServiceHistoryStore,
  ActivityStore,
  ServiceHistoryStore,
  PartnersActivitiesStore,
  PartnersIndicatorsStore,
  DictionaryByIndicatorStore,
  UsersStore,
  BeneficiaryStore,
  IndicatorsStore,
  AuthStore,
  RolePermissionsStore,
  UserPermissionsStore,
  LookUpStore,
  CatalogLayoutStore,
  BeneficiariesIndividualStore,
  BeneficiariesOrganizationsStore,
  PowerBIReportsStore,
  IndicatorsReportsStore,
  IndicatorTargetsStore,
}
