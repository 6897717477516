import { PermissionGroupName } from 'stores/models'
import { MessageDescriptor } from 'react-intl'

export const PermissionGroupTranslations = new Map<
  PermissionGroupName | string,
  MessageDescriptor
>([
  [
    PermissionGroupName.Users,
    {
      id: 'navigation.users',
      defaultMessage: 'Пользователи',
    },
  ],
  [
    PermissionGroupName.Partners,
    {
      id: 'navigation.partners',
      defaultMessage: 'Партнеры',
    },
  ],
  [
    PermissionGroupName.Beneficiaries,
    {
      id: 'navigation.beneficiaries',
      defaultMessage: 'Бенефициары',
    },
  ],

  [
    PermissionGroupName.PartnersStaff,
    {
      id: 'navigation.partners.partner.settings.staff',
      defaultMessage: 'Сотрудники',
    },
  ],
  [
    PermissionGroupName.PartnersServiceTypes,
    {
      id: 'navigation.partners.partner.settings.services',
      defaultMessage: 'Сервисы',
    },
  ],
  [
    PermissionGroupName.PartnersBeneficiaryIndividuals,
    {
      id: 'navigation.partners.beneficiaries.individuals',
      defaultMessage: 'Физические лица',
    },
  ],
  [
    PermissionGroupName.PartnersServiceHistory,
    {
      id: 'navigation.partners.service_history',
      defaultMessage: 'История оказания услуг',
    },
  ],
  [
    PermissionGroupName.PartnersIndicators,
    {
      id: 'navigation.partners.indicators',
      defaultMessage: 'Индикаторы',
    },
  ],
  [
    PermissionGroupName.IndicatorsTargets,
    {
      id: 'indicators.targets',
      defaultMessage: 'Цели по индикаторам',
    },
  ],
  [
    PermissionGroupName.PartnersActivities,
    {
      id: 'navigation.activities',
      defaultMessage: 'Мероприятия',
    },
  ],
  [
    PermissionGroupName.PartnersBeneficiaryOrganization,
    {
      id: 'navigation.partners.beneficiaries.organizations',
      defaultMessage: 'Организации',
    },
  ],
  [
    PermissionGroupName.Activities,
    {
      id: 'navigation.activities',
      defaultMessage: 'Мероприятия',
    },
  ],
  [
    PermissionGroupName.ServiceHistories,
    {
      id: 'navigation.service_history',
      defaultMessage: 'История оказания услуг',
    },
  ],
  [
    PermissionGroupName.Indicators,
    {
      id: 'indicators.title',
      defaultMessage: 'Индикаторы',
    },
  ],
  [
    PermissionGroupName.IndicatorsReports,
    {
      id: 'indicators.reports',
      defaultMessage: 'Reports',
    },
  ],
  [
    PermissionGroupName.Genders,
    {
      id: 'catalogs.gender',
      defaultMessage: 'Пол',
    },
  ],
  [
    PermissionGroupName.Ethnicities,
    {
      id: 'catalogs.ethnicity',
      defaultMessage: 'Этническая принадлежность',
    },
  ],
  [
    PermissionGroupName.ServiceTypes,
    {
      id: 'catalogs.typesOfServices',
      defaultMessage: 'Типы услуг',
    },
  ],
  [
    PermissionGroupName.BeneficiaryCategories,
    {
      id: 'catalogs.beneficiary',
      defaultMessage: 'Бенефициары',
    },
  ],
  [
    PermissionGroupName.OrganizationTypes,
    {
      id: 'catalogs.organizationTypes',
      defaultMessage: 'Типы организаций',
    },
  ],
  [
    PermissionGroupName.ActivityTypes,
    {
      id: 'catalogs.activityTypes',
      defaultMessage: 'Виды мероприятий',
    },
  ],
  [
    PermissionGroupName.TraffickingForms,
    {
      id: 'catalogs.traffickingForms',
      defaultMessage: 'Формы торговли людьми',
    },
  ],
  [
    PermissionGroupName.IdentifyingAgencies,
    {
      id: 'catalogs.identifyingAgencies',
      defaultMessage: 'Идентифицирующее учреждение',
    },
  ],
  [
    PermissionGroupName.TipCaseStatuses,
    {
      id: 'catalogs.tipCaseStatuses',
      defaultMessage: 'Статус дела о ТЛ',
    },
  ],
  [
    PermissionGroupName.AdvocacyIssue,
    {
      id: 'catalogs.advocacyIssue',
      defaultMessage: 'Вопрос адвокации',
    },
  ],
  [
    PermissionGroupName.AdvocacyTypes,
    {
      id: 'catalogs.advocacyType',
      defaultMessage: 'Виды адвокации',
    },
  ],
  [
    PermissionGroupName.SupportTypes,
    {
      id: 'catalogs.supportType',
      defaultMessage: 'Виды поддержки/мероприятия',
    },
  ],
  [
    PermissionGroupName.AdvocacyInfluenceLevel,
    {
      id: 'catalogs.AdvocacyInfluenceLevel',
      defaultMessage: 'Уровни влияния адвокации',
    },
  ],
  [
    PermissionGroupName.DevelopmentStages,
    {
      id: 'catalogs.development_stages',
      defaultMessage: 'Этапы разработки',
    },
  ],
  [
    PermissionGroupName.InfluenceTypes,
    {
      id: 'catalogs.influence_types',
      defaultMessage: 'Виды произведенного воздействия',
    },
  ],
  [
    PermissionGroupName.DocumentType,
    {
      id: 'catalogs.documentType',
      defaultMessage: 'Тип документа',
    },
  ],
  [
    PermissionGroupName.CSOTypes,
    {
      id: 'catalogs.CSOTypes',
      defaultMessage: 'Тип организации гражданского общества',
    },
  ],
  [
    PermissionGroupName.DataTypes,
    {
      id: 'catalogs.dataTypes',
      defaultMessage: 'Тип данных',
    },
  ],
  [
    PermissionGroupName.DataCollectionFrequency,
    {
      id: 'catalogs.dataCollectionFrequency',
      defaultMessage: 'Частота сбора данных и обмена',
    },
  ],
  [
    PermissionGroupName.BusinessScale,
    {
      id: 'catalogs.business_scale',
      defaultMessage: 'Размер бизнеса',
    },
  ],
  [
    PermissionGroupName.SupportingDocuments,
    {
      id: 'catalogs.supporting_documents',
      defaultMessage: 'Сопроводительные документы',
    },
  ],
  [
    PermissionGroupName.ImprovementArea,
    {
      id: 'catalogs.improvement_areas',
      defaultMessage: 'Области улучшения',
    },
  ],
  [
    PermissionGroupName.IndustryTypes,
    {
      id: 'catalogs.industry_type',
      defaultMessage: 'Вид отрасли',
    },
  ],
  [
    PermissionGroupName.LegalEntityTypes,
    {
      id: 'catalogs.entity_type',
      defaultMessage: 'Типы организации/юридического лица',
    },
  ],
  [
    PermissionGroupName.CollaborationAreas,
    {
      id: 'catalogs.collaboration_areas',
      defaultMessage: 'Области сотрудничества',
    },
  ],
  [
    PermissionGroupName.BeneficiaryIndividual,
    {
      id: 'navigation.partners.beneficiaries.individuals',
      defaultMessage: 'Физические лица',
    },
  ],
  [
    PermissionGroupName.RolesPermissions,
    {
      id: 'global.roles',
      defaultMessage: 'Roles',
    },
  ],
  [
    PermissionGroupName.UsersPermissions,
    {
      id: 'navigation.users',
      defaultMessage: 'Пользователи',
    },
  ],
  [
    PermissionGroupName.Dashboards,
    {
      id: 'navigation.dashboards',
      defaultMessage: 'Dashboards',
    },
  ],
])
