import { ReactElement, ReactNode } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect from 'react-select/async-creatable'
import CreatableSelect from 'react-select/creatable'
import { Props as SelectProps } from 'react-select/src/Select'
import { styles } from './CustomSelectStyles'

export type CustomSelectProps = {
  label?: string
  errorMessage?: ReactNode
  wrapperClasses?: string
}

const CustomSelect = <OptionType, isMulti extends boolean = false>({
  type,
  label,
  getSelectRef,
  disabled,
  errorMessage,
  wrapperClasses = 'd-flex flex-column',
  ...attributes
}: SelectProps<OptionType, isMulti> & CustomSelectProps): ReactElement => (
  <div className={'customSelect ' + wrapperClasses}>
    {label && <label className="font-weight-bold">{label}</label>}
    {type === 'async-select' ? (
      <AsyncSelect ref={getSelectRef} styles={styles} {...attributes} />
    ) : type === 'async-creatable-select' ? (
      <AsyncCreatableSelect
        ref={getSelectRef}
        styles={styles}
        {...attributes}
      />
    ) : type === 'creatable-select' ? (
      <CreatableSelect ref={getSelectRef} styles={styles} {...attributes} />
    ) : (
      <Select
        type="select"
        isDisabled={disabled}
        ref={getSelectRef}
        styles={styles}
        invalid={errorMessage}
        {...attributes}
      />
    )}
    {errorMessage ? (
      <span className="invalid-feedback invalid-feedback--customInput">
        {errorMessage}
      </span>
    ) : null}
  </div>
)

export default CustomSelect
