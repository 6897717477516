import ToolbarButton from '../ToolbarButton'
import {
  useEffect,
  useState,
  forwardRef,
  ChangeEventHandler,
  MouseEventHandler,
  FC,
} from 'react'
import { ButtonGroup, Dropdown } from 'react-bootstrap'
import FileInputButton from './FileInputButton'
import CrudButton from '../CrudButton'
import './styles.scss'
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl'
import { fileTypeIcons } from './fileTypeIcons'
import Overlay from 'components/Overlay'
import { getFileTypeFromFileName, validateFiles } from 'core/utils'

type Errors = { [filename: string]: string | Array<string> }

export type FileInputDropdownButtonProps = {
  onFilesListChange: (files: Array<File>) => void
  onError: (errors: Errors) => void
  title: MessageDescriptor
  fileListTitle: MessageDescriptor
  fileListButtonTitle: MessageDescriptor
  accept?: Array<string>
  fileMaxSize?: number
}

const FilesDropdownToggle = forwardRef<
  HTMLButtonElement,
  {
    onClick?: MouseEventHandler
    className?: string
  }
>(({ onClick = () => {}, className }, ref) => (
  <span className={className} ref={ref}>
    <ToolbarButton
      buttonClassName="mr-0"
      iconClassName="ms-Icon ms-Icon--FabricOpenFolderHorizontal mr-0"
      action={onClick}
    />
  </span>
))

const FileInputDropdownButton: FC<FileInputDropdownButtonProps> = ({
  onFilesListChange,
  onError,
  title,
  fileListTitle,
  fileListButtonTitle,
  accept,
  fileMaxSize,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [filesList, setFilesList] = useState<Array<File>>([])
  const intl = useIntl()

  const handleFilesChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { files },
  }) => {
    if (!files) return console.error('No files uploaded')
    const { validFiles, errors } = validateFiles(
      files,
      {
        accept,
        fileMaxSize,
        fileNames: filesList.map(file => file.name),
      },
      intl,
    )
    if (Object.values(errors).length) onError(errors)
    if (validFiles.length)
      setFilesList(previousFilesList => [...previousFilesList, ...validFiles])
    if (!isDropdownOpen && (validFiles.length || filesList.length))
      setIsDropdownOpen(true)
  }

  useEffect(() => {
    onFilesListChange(filesList)
  }, [filesList, onFilesListChange])

  return (
    <Dropdown
      show={isDropdownOpen}
      onToggle={setIsDropdownOpen}
      as={ButtonGroup}
      className="file-input-dropdown-button"
    >
      <FileInputButton
        isMulti
        buttonClassName="mr-0"
        iconClassName="ms-Icon ms-Icon--Attach"
        title={title}
        onFileChange={handleFilesChange}
        accept={accept}
      />
      <Dropdown.Toggle as={FilesDropdownToggle} />

      <Dropdown.Menu>
        <Dropdown.ItemText className="files-dropdown-title">
          <FormattedMessage {...fileListTitle} />
        </Dropdown.ItemText>
        <Dropdown.Divider />
        {!filesList.length ? (
          <Dropdown.ItemText>
            <FormattedMessage id="global.no_records" />
          </Dropdown.ItemText>
        ) : (
          filesList.map(file => (
            <Dropdown.ItemText as="div" key={file.name}>
              <div className="files-dropdown-item-content">
                {fileTypeIcons.get(getFileTypeFromFileName(file.name)) || (
                  <i className="ms-Icon ms-Icon--Page" />
                )}
                <Overlay text={file.name}>
                  <span className="pl-2">{file.name}</span>
                </Overlay>
              </div>
              <CrudButton
                iconClassName="ms-Icon ms-Icon--Delete"
                tooltip="Delete"
                onClick={() =>
                  setFilesList(previousFilesList =>
                    previousFilesList.filter(
                      previousFileListItem =>
                        previousFileListItem.name !== file.name,
                    ),
                  )
                }
              />
            </Dropdown.ItemText>
          ))
        )}
        <Dropdown.Divider />
        <Dropdown.ItemText className="files-dropdown-footer">
          <FileInputButton
            isMulti
            buttonClassName="m-0"
            title={fileListButtonTitle}
            onFileChange={handleFilesChange}
            accept={accept}
          />
        </Dropdown.ItemText>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default FileInputDropdownButton
