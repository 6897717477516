import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { apiV1 } from 'core/requests'
import {
  CatalogLayoutModel,
  ICatalogLayoutSnapshotIn,
  ICatalogLayoutSnapshotOut,
  CatalogsApiData,
  CatalogName,
} from 'stores/models'
import { when } from 'mobx'

const CatalogLayoutStore = types
  .model('CatalogLayoutStore')
  .props({
    _catalogList: types.array(CatalogLayoutModel),
    _isLoading: types.optional(types.boolean, false),
  })
  .actions(self => {
    const setCatalogList = (
      catalogList: Array<ICatalogLayoutSnapshotIn>,
    ): void => {
      applySnapshot(self._catalogList, catalogList)
    }
    const setIsLoading = (isLoading: boolean) => {
      self._isLoading = isLoading
    }

    const getCatalogList = async (catalogName: CatalogName) => {
      const CATALOG_API = CatalogsApiData.get(catalogName)
      if (!CATALOG_API) throw Error('CATALOG_API is empty')
      const catalogListRequest = fromPromise(
        apiV1.get<Array<ICatalogLayoutSnapshotIn>>(CATALOG_API),
      )

      when(() =>
        catalogListRequest.case({
          fulfilled: response => {
            setCatalogList(response.data)
            return true
          },
        }),
      )
      return catalogListRequest
    }

    const createCatalogItem = async (
      catalogItem: Omit<ICatalogLayoutSnapshotIn, 'id'>,
      catalogName: CatalogName,
    ): Promise<void> => {
      const CATALOG_API = CatalogsApiData.get(catalogName)
      if (!CATALOG_API) throw Error('CATALOG_API is empty')
      await apiV1.post(CATALOG_API, catalogItem)
    }

    const editCatalogItem = async (
      catalogItem: ICatalogLayoutSnapshotIn,
      catalogName: CatalogName,
    ): Promise<void> => {
      const CATALOG_API = CatalogsApiData.get(catalogName)
      if (!CATALOG_API) throw Error('CATALOG_API is empty')
      await apiV1.put(CATALOG_API, catalogItem)
    }

    const deleteCatalogItem = async (
      catalogItemId: ICatalogLayoutSnapshotIn['id'],
      catalogName: CatalogName,
    ): Promise<void> => {
      const CATALOG_API = CatalogsApiData.get(catalogName)
      if (!CATALOG_API) throw Error('CATALOG_API is empty')
      await apiV1.delete(CATALOG_API, { params: { id: catalogItemId } })
    }

    const deactivateCatalogItem = async (
      catalogItemId: ICatalogLayoutSnapshotIn['id'],
      catalogName: CatalogName,
    ): Promise<void> => {
      const CATALOG_API = CatalogsApiData.get(catalogName)
      if (!CATALOG_API) throw Error('CATALOG_API is empty')
      await apiV1.put(`${CATALOG_API}/deactivate`, null, {
        params: { id: catalogItemId },
      })
    }

    const activateCatalogItem = async (
      catalogItemId: ICatalogLayoutSnapshotIn['id'],
      catalogName: CatalogName,
    ): Promise<void> => {
      const CATALOG_API = CatalogsApiData.get(catalogName)
      if (!CATALOG_API) throw Error('CATALOG_API is empty')
      await apiV1.put(`${CATALOG_API}/activate`, null, {
        params: { id: catalogItemId },
      })
    }

    return {
      setCatalogList,
      getCatalogList,
      createCatalogItem,
      editCatalogItem,
      deleteCatalogItem,
      deactivateCatalogItem,
      activateCatalogItem,
      setIsLoading,
    }
  })
  .views(self => ({
    get catalogList(): Array<ICatalogLayoutSnapshotOut> {
      return getSnapshot(self._catalogList)
    },
    get isLoading() {
      return self._isLoading
    },
  }))

export default CatalogLayoutStore
export interface ICatalogLayoutStore
  extends Instance<typeof CatalogLayoutStore> {}
