import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const Indicator2_1_2ReportModel = types.model('Indicator2_1_2ReportModel', {
  code: types.maybeNull(types.string),
  age: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  ethnicity: types.maybeNull(types.string),
  categories: types.maybeNull(types.string),
  activityName: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  region: types.maybeNull(types.string),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  activityType: types.maybeNull(types.string),
  organizerName: types.maybeNull(types.string),
})

export interface IIndicator2_1_2ReportModel
  extends Instance<typeof Indicator2_1_2ReportModel> {}
export interface IIndicator2_1_2ReportSnapshotIn
  extends SnapshotIn<typeof Indicator2_1_2ReportModel> {}
export interface IIndicator2_1_2ReportSnapshotOut
  extends SnapshotOut<typeof Indicator2_1_2ReportModel> {}
export default Indicator2_1_2ReportModel
