import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const ServiceHistoryWithStatusModel = types.model(
  'ServiceHistoryWithStatusModel',
  {
    id: types.optional(types.integer, 0),
    relatedToCovid19: types.boolean,
    serviceTypeName: types.maybeNull(types.string),
    serviceTypeId: types.integer,
    serviceDeliveryDate: types.string,
    serviceDeliveredStaffId: types.integer,
    serviceDeliveredStaffName: types.maybeNull(types.string),
    regionId: types.integer,
    regionName: types.maybeNull(types.string),
    beneficiaryIndividualId: types.integer,
    beneficiaryIndividualCode: types.maybeNull(types.string),
    beneficiaryIndividualFullName: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    statusId: types.integer,
    beneficiaryOrganizationId: types.integer,
    beneficiaryOrganizationName: types.maybeNull(types.string),
    canBeReviewed: types.boolean,
    memberTypes: types.string,
  },
)

export interface IServiceHistoryWithStatusModel
  extends Instance<typeof ServiceHistoryWithStatusModel> {}
export interface IServiceHistoryWithStatusModelSnapshotIn
  extends SnapshotIn<typeof ServiceHistoryWithStatusModel> {}
export interface IServiceHistoryWithStatusModelSnapshotOut
  extends SnapshotOut<typeof ServiceHistoryWithStatusModel> {}
export default ServiceHistoryWithStatusModel
