import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { GenericWithParentIdLookUpDto } from '../../../lookUps'
import IndicatorBaseDtoModel from '../IndicatorBaseDtoModel'

const Indicator2_2_1DtoModel = IndicatorBaseDtoModel.named(
  'Indicator2_2_1DtoModel',
).props({
  organizationId: types.optional(types.integer, 0),
  organization: types.maybeNull(types.string),
  recordDate: types.maybeNull(types.string),
  csoTypeId: types.optional(types.integer, 0),
  csoType: types.maybeNull(types.string),
  csoTypeParentId: types.maybeNull(types.number),
  supportTypeId: types.optional(types.integer, 0),
  supportType: types.maybeNull(types.string),
  supportTypeParentId: types.maybeNull(types.number),
  advocacyIssueId: types.optional(types.integer, 0),
  advocacyIssue: types.maybeNull(types.string),
  advocacyIssueParentId: types.maybeNull(types.number),
  advocacyInfluenceLevelId: types.optional(types.integer, 0),
  advocacyInfluenceLevel: types.maybeNull(types.string),
  advocacyTypes: types.optional(types.array(GenericWithParentIdLookUpDto), []),
  plansWereDeveloped: types.maybeNull(types.boolean),
  plansWereDevelopedComment: types.maybeNull(types.string),
})

export interface IIndicator2_2_1DtoModel
  extends Instance<typeof Indicator2_2_1DtoModel> {}
export interface IIndicator2_2_1DtoModelSnapshotIn
  extends SnapshotIn<typeof Indicator2_2_1DtoModel> {}
export interface IIndicator2_2_1DtoModelSnapshotOut
  extends SnapshotOut<typeof Indicator2_2_1DtoModel> {}
export default Indicator2_2_1DtoModel
