import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UserInformationModel = types.model('UserInformationModel', {
  fullName: types.optional(types.string, '', [null, undefined]),
  userRole: types.optional(types.string, '', [null, undefined]),
  countryId: types.optional(types.number, 0, [null, undefined]),
  partnerId: types.optional(types.number, 0, [null, undefined]),
})

export interface IUserInformationModel
  extends Instance<typeof UserInformationModel> {}

export interface IUserInformationSnapshotIn
  extends SnapshotIn<typeof UserInformationModel> {}

export interface IUserInformationSnapshotOut
  extends SnapshotOut<typeof UserInformationModel> {}

export default UserInformationModel
