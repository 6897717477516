import { FC } from 'react'
import { Container, Spinner } from 'react-bootstrap'
import './Loader.scss'

const Loader: FC<{ isOverlay?: boolean }> = ({ isOverlay = false }) => (
  <Container fluid className={`loader-container ${isOverlay ? 'overlay' : ''}`}>
    <Spinner
      className="loader-spinner"
      animation="border"
      variant="secondary"
    />
  </Container>
)

export default Loader
