import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const RelationshipTypeLookUpModel = LookUpModel.named('RelationshipTypeLookUpModel').props({
    isComment: types.boolean
})

export interface IRelationshipTypeLookUp
    extends Instance<typeof RelationshipTypeLookUpModel> {}

export interface IRelationshipTypeLookUpSnapshotIn
    extends SnapshotIn<typeof RelationshipTypeLookUpModel> {}

export interface IRelationshipTypeLookUpSnapshotOut
    extends SnapshotOut<typeof RelationshipTypeLookUpModel> {}

export default RelationshipTypeLookUpModel