enum PermissionMenuName {
  Users = 'Users',
  Partners = 'Partners',
  Indicators = 'Indicators',
  Catalogs = 'Catalogs',
  Beneficiaries = 'Beneficiaries',
  SystemSettings = 'SystemSettings',
  Activities = 'Activities',
  ServiceHistory = 'ServiceHistory',
  Dashboards = 'Dashboards',
}

export default PermissionMenuName
