import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const TransportationControlToolLookUpModel = LookUpModel.named('TransportationControlToolLookUpModel').props({
    isComment: types.boolean
})

export interface ITransportationControlToolLookUp
    extends Instance<typeof TransportationControlToolLookUpModel> {}

export interface ITransportationControlToolLookUpSnapshotIn
    extends SnapshotIn<typeof TransportationControlToolLookUpModel> {}

export interface ITransportationControlToolLookUpSnapshotOut
    extends SnapshotOut<typeof TransportationControlToolLookUpModel> {}

export default TransportationControlToolLookUpModel