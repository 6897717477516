import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const AddBeneficiaryOrganizationStaffCommand = types.model(
  'AddBeneficiaryOrganizationStaffCommand',
  {
    firstName: types.string,
    lastName: types.string,
    position: types.string,
    memberTypeId: types.integer,
    organizationId: types.integer,
    genderId: types.number,
    genderComment: types.maybeNull(types.string),
  },
)

export interface IAddBeneficiaryOrganizationStaffCommand
  extends Instance<typeof AddBeneficiaryOrganizationStaffCommand> {}

export interface IAddBeneficiaryOrganizationStaffCommandIn
  extends SnapshotIn<typeof AddBeneficiaryOrganizationStaffCommand> {}

export interface IAddBeneficiaryOrganizationStaffCommandOut
  extends SnapshotOut<typeof AddBeneficiaryOrganizationStaffCommand> {}

export default AddBeneficiaryOrganizationStaffCommand
