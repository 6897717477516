import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const BeneficiaryActivityLogLookUpDto = types.model(
  'BeneficiaryActivityLogLookUpDto',
  {
    id: types.number,
    name: types.maybeNull(types.string),
    activityDate: types.string,
    partnerName: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    countryId: types.number,
    region: types.maybeNull(types.string),
    regionId: types.number,
  },
)

export interface IBeneficiaryActivityLogLookUpDto
  extends Instance<typeof BeneficiaryActivityLogLookUpDto> {}
export interface IBeneficiaryActivityLogLookUpDtoIn
  extends SnapshotIn<typeof BeneficiaryActivityLogLookUpDto> {}
export interface IBeneficiaryActivityLogLookUpDtoOut
  extends SnapshotOut<typeof BeneficiaryActivityLogLookUpDto> {}
export default BeneficiaryActivityLogLookUpDto
