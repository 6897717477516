import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const ActivitiesParticipateLookUpModel = LookUpModel.named(
  'ActivitiesParticipateLookUpModel',
)

export interface IActivitiesParticipateLookUpModel
  extends Instance<typeof ActivitiesParticipateLookUpModel> {}

export interface IActivitiesParticipateLookUpModelIn
  extends SnapshotIn<typeof ActivitiesParticipateLookUpModel> {}

export interface IActivitiesParticipateLookUpModelOut
  extends SnapshotOut<typeof ActivitiesParticipateLookUpModel> {}

export default ActivitiesParticipateLookUpModel
