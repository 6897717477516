import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../PagedListRequestModel'
import ActivityApprovalListFilterModel from './ActivityApprovalListFilterModel'

const ActivityApprovalListRequestModel = PagedListRequestModel.named(
  'ActivityApprovalListRequestModel',
).props({
  sortBy: types.optional(types.string, ''),
  filter: types.optional(ActivityApprovalListFilterModel, {}),
})

export interface IActivityApprovalListRequestModel
  extends Instance<typeof ActivityApprovalListRequestModel> {}

export interface IActivityApprovalListRequestModelIn
  extends SnapshotIn<typeof ActivityApprovalListRequestModel> {}

export interface IActivityApprovalListRequestModelOut
  extends SnapshotOut<typeof ActivityApprovalListRequestModel> {}

export default ActivityApprovalListRequestModel
