import { FC, useState, useCallback } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Prompt, useHistory } from 'react-router'
import { FormattedMessage, useIntl } from 'react-intl'
import Loader from '../Loader'
import './FormLeavingPrompt.scss'
import { useBeforeUnload } from 'hooks'

const FormLeavingPrompt: FC<{
  onSaveAndExit: () => void | Promise<void>
  onDiscard: () => void | Promise<void>
  when: boolean
  isFormValid?: boolean
}> = ({ onSaveAndExit, onDiscard, when, isFormValid = true }) => {
  const history = useHistory()
  const [isModal, setIsModal] = useState(false)
  const [isPendingRequest, setIsPendingRequest] = useState(false)
  const [nextLocation, setNextLocation] = useState('')
  const intl = useIntl()

  const handleSaveAndExit = async () => {
    setIsPendingRequest(true)
    await onSaveAndExit()
    setIsPendingRequest(false)
    if (isFormValid) history.push(nextLocation)
    else setTimeout(() => setIsModal(false), 500)
  }

  const handleDiscard = async () => {
    await onDiscard()
    history.push(nextLocation)
  }

  const handleWindowUnload: OnBeforeUnloadEventHandlerNonNull = useCallback(
    event => {
      event.preventDefault()
      event.returnValue = true
      return intl.formatMessage({
        id: 'form_leaving_prompt.description',
        defaultMessage: `У вас есть несохраненные изменения. 
              Вы можете сохранить изменения, сбросить изменения, 
              или отменить, чтобы продолжить редактирование`,
      })
    },
    [intl],
  )

  useBeforeUnload(when ? handleWindowUnload : null)

  return (
    <>
      <Prompt
        message={location => {
          setNextLocation(location.pathname)
          setIsModal(true)
          return false
        }}
        when={when}
      />
      <Modal
        dialogClassName="form-leaving-prompt__dialog"
        centered
        onHide={() => setIsModal(false)}
        show={isModal}
      >
        {isPendingRequest && <Loader isOverlay />}
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id="form_leaving_prompt.title"
              defaultMessage="Вы действительно хотите покинуть страницу?"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <FormattedMessage
              id="form_leaving_prompt.description"
              defaultMessage={`У вас есть несохраненные изменения. 
              Вы можете сохранить изменения, сбросить изменения, 
              или отменить, чтобы продолжить редактирование`}
            />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSaveAndExit}>
            <FormattedMessage
              id="form_leaving_prompt.save_changes"
              defaultMessage="Сохранить"
            />
          </Button>
          <Button variant="secondary" onClick={handleDiscard}>
            <FormattedMessage
              id="form_leaving_prompt.discard_changes"
              defaultMessage="Сбросить"
            />
          </Button>
          <Button onClick={() => setIsModal(false)} variant="secondary">
            <FormattedMessage id="global.cancel" defaultMessage="Отмена" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FormLeavingPrompt
