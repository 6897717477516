import { useEffect } from 'react'

const useBeforeUnload = (listener: OnBeforeUnloadEventHandler) => {
  useEffect(() => {
    if (listener) window.addEventListener('beforeunload', listener)
    return () => {
      if (listener) window.removeEventListener('beforeunload', listener)
    }
  }, [listener])
}

export default useBeforeUnload
