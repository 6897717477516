import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const GenericWithCommentLookUpDto = types.model('GenericWithCommentLookUpDto', {
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
  isComment: types.boolean,
})

export interface IGenericWithCommentLookUpDto
  extends Instance<typeof GenericWithCommentLookUpDto> {}
export interface IGenericWithCommentSnapshotIn
  extends SnapshotIn<typeof GenericWithCommentLookUpDto> {}
export interface IGenericWithCommentSnapshotOut
  extends SnapshotOut<typeof GenericWithCommentLookUpDto> {}
export default GenericWithCommentLookUpDto
