import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const GenericWithParentIdLookUpDto = types.model(
  'GenericWithParentIdLookUpDto',
  {
    id: types.identifierNumber,
    name: types.maybeNull(types.string),
    parentId: types.maybeNull(types.number),
  },
)

export interface IGenericWithParentIdLookUpDto
  extends Instance<typeof GenericWithParentIdLookUpDto> {}
export interface IGenericWithParentIdSnapshotIn
  extends SnapshotIn<typeof GenericWithParentIdLookUpDto> {}
export interface IGenericWithParentIdSnapshotOut
  extends SnapshotOut<typeof GenericWithParentIdLookUpDto> {}
export default GenericWithParentIdLookUpDto
