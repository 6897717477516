import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const AdvocacyTypeLookUpModel = LookUpModel.named(
  'AdvocacyTypeLookUpModel',
).props({
  isComment: types.boolean,
})

export interface IAdvocacyTypeLookUpModel
  extends Instance<typeof AdvocacyTypeLookUpModel> {}
export interface IAdvocacyTypeLookUpSnapshotIn
  extends SnapshotIn<typeof AdvocacyTypeLookUpModel> {}
export interface IAdvocacyTypeLookUpSnapshotOut
  extends SnapshotOut<typeof AdvocacyTypeLookUpModel> {}
export default AdvocacyTypeLookUpModel
