import { FC } from 'react'
import { StatusName } from 'stores/models'
import { FormattedMessage, useIntl } from 'react-intl'
import classes from './style.module.scss'
import { Overlay } from 'components'

type StatusCellProps = {
  statusName: StatusName
  className: string
  isAvailable?: boolean
}

const StatusCell: FC<StatusCellProps> = ({
  statusName,
  className,
  isAvailable = true,
}) => {
  const intl = useIntl()

  return (
    <div className={classes.truncate}>
      <Overlay text={intl.formatMessage({ id: statusName })}>
        <span
          className={[
            classes.status,
            classes[className],
            isAvailable ? '' : classes.notAvailable,
          ].join(' ')}
        >
          <FormattedMessage id={statusName} />
        </span>
      </Overlay>
    </div>
  )
}

export default StatusCell
