import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const BeneficiaryOrganizationModel = types.model(
  'BeneficiaryOrganizationModel',
  {
    id: types.optional(types.integer, 0),
    name: types.maybeNull(types.string),
    countryId: types.optional(types.integer, 0),
    country: types.optional(types.string, ''),
    countryParentId: types.maybeNull(types.integer),
    organizationTypeId: types.optional(types.integer, 0),
    organizationType: types.maybeNull(types.string),
    organizationTypeParentId: types.maybeNull(types.integer),
    partnerName: types.optional(types.string, ''),
    partnerId: types.optional(types.integer, 0),
  },
)

export interface IBeneficiaryOrganizationModel
  extends Instance<typeof BeneficiaryOrganizationModel> {}
export interface IBeneficiaryOrganizationModelIn
  extends SnapshotIn<typeof BeneficiaryOrganizationModel> {}
export interface IBeneficiaryOrganizationModelOut
  extends SnapshotOut<typeof BeneficiaryOrganizationModel> {}
export default BeneficiaryOrganizationModel
