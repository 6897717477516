import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const OrganizationServiceTypesLookUpModel = LookUpModel.named(
  'OrganizationServiceTypesLookUpModel',
).props({
  isComment: types.boolean,
})

export interface IOrganizationServiceTypesLookUp
  extends Instance<typeof OrganizationServiceTypesLookUpModel> {}

export interface IOrganizationServiceTypesLookUpSnapshotIn
  extends SnapshotIn<typeof OrganizationServiceTypesLookUpModel> {}

export interface IOrganizationServiceTypesLookUpSnapshotOut
  extends SnapshotOut<typeof OrganizationServiceTypesLookUpModel> {}

export default OrganizationServiceTypesLookUpModel
