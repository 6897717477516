import React, { FC, MouseEventHandler, PropsWithChildren } from 'react'
import { Button } from 'react-bootstrap'
import { ButtonType } from 'react-bootstrap/esm/Button'
import styles from './ToolbarButton.module.scss'

type ToolbarButtonProps = PropsWithChildren<{
  iconClassName?: string
  action: MouseEventHandler
  disabled?: boolean
  type?: ButtonType
  buttonClassName?: string
}>

const ToolbarButton: FC<ToolbarButtonProps> = ({
  children,
  iconClassName,
  action,
  disabled = false,
  type = 'button',
  buttonClassName,
}) => {
  return (
    <>
      <Button
        variant="light"
        className={`${styles.button} ${buttonClassName ? buttonClassName : ''}`}
        onClick={action}
        disabled={disabled}
        type={type}
      >
        {iconClassName && (
          <i className={[styles.icon, iconClassName].join(' ')} />
        )}
        {children}
      </Button>
    </>
  )
}

export default ToolbarButton
