import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const Indicator2_2_2ReportModel = types.model('Indicator2_2_2ReportModel', {
  partnerName: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  activityType: types.maybeNull(types.string),
})

export interface IIndicator2_2_2ReportModel
  extends Instance<typeof Indicator2_2_2ReportModel> {}
export interface IIndicator2_2_2ReportSnapshotIn
  extends SnapshotIn<typeof Indicator2_2_2ReportModel> {}
export interface IIndicator2_2_2ReportSnapshotOut
  extends SnapshotOut<typeof Indicator2_2_2ReportModel> {}
export default Indicator2_2_2ReportModel
