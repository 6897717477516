import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const AddBeneficiaryIndividualToOrganizationCommand = types.model(
  'AddBeneficiaryIndividualToOrganizationCommand',
  {
    beneficiaryId: types.integer,
    organizationId: types.integer,
  },
)

export interface IAddBeneficiaryIndividualToOrganizationCommand
  extends Instance<typeof AddBeneficiaryIndividualToOrganizationCommand> {}

export interface IAddBeneficiaryIndividualToOrganizationCommandIn
  extends SnapshotIn<typeof AddBeneficiaryIndividualToOrganizationCommand> {}

export interface IAddBeneficiaryIndividualToOrganizationCommandOut
  extends SnapshotOut<typeof AddBeneficiaryIndividualToOrganizationCommand> {}

export default AddBeneficiaryIndividualToOrganizationCommand
