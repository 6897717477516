import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const DataFrequencyLookUpModel = LookUpModel.named(
  'DataFrequencyLookUpModel',
).props({
  isComment: types.boolean,
})

export interface IDataFrequencyLookUp
  extends Instance<typeof DataFrequencyLookUpModel> {}

export interface IDataFrequencyLookUpSnapshotIn
  extends SnapshotIn<typeof DataFrequencyLookUpModel> {}

export interface IDataFrequencyLookUpSnapshotOut
  extends SnapshotOut<typeof DataFrequencyLookUpModel> {}

export default DataFrequencyLookUpModel
