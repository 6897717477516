import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../../../PagedListRequestModel'
import Indicator4_1_1ReportFilterModel from './Indicator4_1_1ReportFilterModel'

const Indicator4_1_1ReportRequestModel = PagedListRequestModel.named(
  'Indicator4_1_1ReportRequestModel',
).props({
  sortBy: types.optional(types.string, ''),
  filter: types.optional(Indicator4_1_1ReportFilterModel, {}),
})

export interface IIndicator4_1_1ReportRequestModel
  extends Instance<typeof Indicator4_1_1ReportRequestModel> {}

export interface IIndicator4_1_1ReportRequestSnapshotIn
  extends SnapshotIn<typeof Indicator4_1_1ReportRequestModel> {}

export interface IIndicator4_1_1ReportRequestSnapshotOut
  extends SnapshotOut<typeof Indicator4_1_1ReportRequestModel> {}

export default Indicator4_1_1ReportRequestModel
