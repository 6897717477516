import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const CreateIndicator1_1_1Command = types.model('CreateIndicator1_1_1Command', {
  organizationId: types.integer,
  documentName: types.string,
  recordDate: types.maybeNull(types.string),
  countryId: types.integer,
  countryComment: types.optional(types.string, ''),
  regionId: types.maybeNull(types.integer),
  documentTypeId: types.integer,
  documentTypeComment: types.maybeNull(types.string),
  advocacyIssueId: types.integer,
  advocacyIssueComment: types.maybeNull(types.string),
  advocacyInfluenceLevelId: types.integer,
  reportingYear: types.integer,
  reportingQuarterId: types.integer,
  actualEngagementDate: types.string,
  engagementDevelopmentStageId: types.integer,
  recordDevelopmentStageId: types.integer,
  influenceTypeId: types.integer,
  influenceTypeComment: types.maybeNull(types.string),
  advocacyTypeIds: types.array(types.integer),
  advocacyTypeComment: types.maybeNull(types.string),
  notes: types.maybeNull(types.string),
  hasSupportedDocuments: types.boolean,
})

export interface ICreateIndicator1_1_1Command
  extends Instance<typeof CreateIndicator1_1_1Command> {}
export interface ICreateIndicator1_1_1CommandSnapshotIn
  extends SnapshotIn<typeof CreateIndicator1_1_1Command> {}
export interface ICreateIndicator1_1_1CommandSnapshotOut
  extends SnapshotOut<typeof CreateIndicator1_1_1Command> {}
export default CreateIndicator1_1_1Command
