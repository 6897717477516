import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import BeneficiaryCategoryLookUpModel from './BeneficiaryCategoryLookUpModel'
import ExcludedCategoriesLookUpDto from './ExcludedCategoriesLookUpDto'

const ExcludedCategoryModel = types.model('ExcludedCategoryModel', {
  categories: types.array(BeneficiaryCategoryLookUpModel),
  excludedCategories: types.array(ExcludedCategoriesLookUpDto),
})

export interface IExcludedCategoryModel
  extends Instance<typeof ExcludedCategoryModel> {}
export interface IExcludedSnapshotIn
  extends SnapshotIn<typeof ExcludedCategoryModel> {}
export interface IExcludedSnapshotOut
  extends SnapshotOut<typeof ExcludedCategoryModel> {}
export default ExcludedCategoryModel
