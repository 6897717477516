import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const WorkTypesLookUpModel = LookUpModel.named('WorkTypesLookUpModel').props({
    isComment: types.boolean,
})

export interface IWorkTypesLookUpModelLookUp extends Instance<typeof WorkTypesLookUpModel> {}
export interface IWorkTypesLookUpModelLookUpSnapshotIn
    extends SnapshotIn<typeof WorkTypesLookUpModel> {}
export interface IWorkTypesLookUpModellookUpSnapshotOut
    extends SnapshotOut<typeof WorkTypesLookUpModel> {}
export default WorkTypesLookUpModel