import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const CreateOrganizationCommand = types.model('CreateOrganizationCommand', {
  name: types.maybeNull(types.string),
  countryId: types.integer,
  countryComment: types.optional(types.string, ''),
  regionIds: types.maybeNull(types.array(types.integer)),
  organizationTypeId: types.integer,
  organizationTypeComment: types.maybeNull(types.string),
})

export interface ICreateOrganizationCommand
  extends Instance<typeof CreateOrganizationCommand> {}
export interface ICreateOrganizationCommandSnapshotIn
  extends SnapshotIn<typeof CreateOrganizationCommand> {}
export interface ICreateOrganizationCommandSnapshotOut
  extends SnapshotOut<typeof CreateOrganizationCommand> {}
export default CreateOrganizationCommand
