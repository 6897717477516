import CustomSelect, { CustomSelectProps } from '../CustomSelect'
import { Props as SelectProps } from 'react-select/src/Select'
import Input, { InputProps } from '../Input'
import { ReactElement } from 'react'

type CommentableSelectProps<
  SelectOptionType extends { isComment: boolean },
  isMulti extends boolean = false
> = {
  selectProps: { value: SelectOptionType | undefined | null } & SelectProps<
    SelectOptionType,
    isMulti
  > &
    CustomSelectProps
  commentInputProps: InputProps
}

const CommentableSelect = <
  OptionType extends { isComment: boolean },
  isMulti extends boolean = false
>({
  selectProps,
  commentInputProps,
}: CommentableSelectProps<OptionType, isMulti>): ReactElement => (
  <>
    <CustomSelect {...selectProps} />
    {selectProps.value?.isComment && <Input {...commentInputProps} />}
  </>
)

export default CommentableSelect
