import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const Indicator2_2_1ReportModel = types.model('Indicator2_2_1ReportModel', {
  csoName: types.maybeNull(types.string),
  csoType: types.maybeNull(types.string),
  supportType: types.maybeNull(types.string),
  advocacyIssue: types.maybeNull(types.string),
  advocacyTypes: types.array(types.string),
  advocacyInfluenceLevel: types.maybeNull(types.string),
  plansWereDevelopedComment: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
})

export interface IIndicator2_2_1ReportModel
  extends Instance<typeof Indicator2_2_1ReportModel> {}
export interface IIndicator2_2_1ReportSnapshotIn
  extends SnapshotIn<typeof Indicator2_2_1ReportModel> {}
export interface IIndicator2_2_1ReportSnapshotOut
  extends SnapshotOut<typeof Indicator2_2_1ReportModel> {}
export default Indicator2_2_1ReportModel
