import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const Indicator3_2_1ReportModel = types.model('Indicator3_2_1ReportModel', {
  code: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  organization: types.maybeNull(types.string),
  organizationType: types.maybeNull(types.string),
  activityName: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  region: types.maybeNull(types.string),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  activityType: types.maybeNull(types.string),
  organizerName: types.maybeNull(types.string),
})

export interface IIndicator3_2_1ReportModel
  extends Instance<typeof Indicator3_2_1ReportModel> {}
export interface IIndicator3_2_1ReportSnapshotIn
  extends SnapshotIn<typeof Indicator3_2_1ReportModel> {}
export interface IIndicator3_2_1ReportSnapshotOut
  extends SnapshotOut<typeof Indicator3_2_1ReportModel> {}
export default Indicator3_2_1ReportModel
