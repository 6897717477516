import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const PartnersServiceTypeModel = types.model('PartnersServiceTypeModel', {
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
})

export interface IPartnersServiceType
  extends Instance<typeof PartnersServiceTypeModel> {}
export interface IPartnersServiceTypeSnapshotIn
  extends SnapshotIn<typeof PartnersServiceTypeModel> {}
export interface IPartnersServiceTypeSnapshotOut
  extends SnapshotOut<typeof PartnersServiceTypeModel> {}
export default PartnersServiceTypeModel
