import { CSSProperties, ReactElement, ReactNode } from 'react'
import { ListGroupItemProps } from 'react-bootstrap'
import { ListGroup } from 'react-bootstrap'
import { Link, LinkProps } from 'react-router-dom'
import './FlatList.scss'

type ListItem = {
  name: ReactNode
  id: any
}

interface FlatListProps<Item extends ListItem, IsItemAsLink extends boolean> {
  data: Array<Item>
  title: string
  activeItemId?: Item['id']
  emptyListMessage: ReactNode
  maxHeight?: CSSProperties['maxHeight']
  onItemClick?: (item: Item) => void
  isItemAsLink?: IsItemAsLink
  onItem?: (
    item: Item,
  ) => IsItemAsLink extends true
    ? LinkProps & ListGroupItemProps
    : ListGroupItemProps
  renderActionButtons?: (item: Item) => ReactElement
  renderLastItem?: () => ReactElement
}

const FlatList = <T extends ListItem, IsItemAsLink extends boolean>({
  data,
  title,
  activeItemId,
  emptyListMessage,
  maxHeight,
  onItemClick,
  renderActionButtons,
  isItemAsLink,
  onItem,
  renderLastItem,
}: FlatListProps<T, IsItemAsLink>): ReactElement => {
  return (
    <div className="flat-list">
      <ListGroup variant="flush">
        <ListGroup.Item className="font-weight-bold title">
          <i className="ms-Icon ms-Icon--List mr-2" />
          <span>{title}</span>
        </ListGroup.Item>
        <div
          className="scroll-container"
          style={{ maxHeight, overflow: 'auto' }}
        >
          {data.length ? (
            data
              .map(item => (
                <ListGroup.Item
                  as={!isItemAsLink ? 'div' : Link}
                  key={item.id}
                  action
                  onClick={() => {
                    onItemClick?.(item)
                  }}
                  active={activeItemId === item.id}
                  {...(onItem ? onItem(item) : {})}
                >
                  {item.name}
                  {renderActionButtons && (
                    <div className="action-buttons">
                      {renderActionButtons(item)}
                    </div>
                  )}
                </ListGroup.Item>
              ))
              .concat(renderLastItem?.() ?? <></>)
          ) : (
            <ListGroup.Item as="div" className="justify-content-center">
              {emptyListMessage}
            </ListGroup.Item>
          )}
        </div>
      </ListGroup>
    </div>
  )
}

export default FlatList
