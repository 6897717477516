import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const BeneficiariesIndividualPartnersModel = types
  .model('BeneficiariesIndividualPartners')
  .props({
    id: types.identifierNumber,
    name: types.maybeNull(types.string),
  })

export interface IBeneficiariesIndividualPartnersModel
  extends Instance<typeof BeneficiariesIndividualPartnersModel> {}

export interface IBeneficiariesIndividualPartnersSnapshotIn
  extends SnapshotIn<typeof BeneficiariesIndividualPartnersModel> {}

export interface IBeneficiariesIndividualPartnersSnapshotOut
  extends SnapshotOut<typeof BeneficiariesIndividualPartnersModel> {}

export default BeneficiariesIndividualPartnersModel
