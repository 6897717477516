import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const BeneficiaryIndividualCategoryChangeLogDto = types.model(
  'BeneficiaryIndividualCategoryChangeLogDto',
  {
    id: types.number,
    name: types.maybeNull(types.string),
    startDate: types.string,
    endDate: types.maybeNull(types.string),
  },
)

export interface IBeneficiaryIndividualCategoryChangeLogDto
  extends Instance<typeof BeneficiaryIndividualCategoryChangeLogDto> {}
export interface IBeneficiaryIndividualCategoryChangeLogDtoIn
  extends SnapshotIn<typeof BeneficiaryIndividualCategoryChangeLogDto> {}
export interface IBeneficiaryIndividualCategoryChangeLogDtoOut
  extends SnapshotOut<typeof BeneficiaryIndividualCategoryChangeLogDto> {}
export default BeneficiaryIndividualCategoryChangeLogDto
