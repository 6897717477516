import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../../PagedListMetaData'
import BeneficiaryOrganizationModel from './BeneficiaryOrganizationModel'

const BeneficiaryOrganizationPagedDto = types.model(
  'BeneficiaryOrganizationPagedDto',
  {
    data: types.array(BeneficiaryOrganizationModel),
    metadata: types.optional(PagedListMetaData, {}),
  },
)

export interface IBeneficiaryOrganizationPagedDto
  extends Instance<typeof BeneficiaryOrganizationPagedDto> {}

export interface IBeneficiaryOrganizationPagedDtoSnapshotIn
  extends SnapshotIn<typeof BeneficiaryOrganizationPagedDto> {}

export interface IBeneficiaryOrganizationPagedDtoSnapshotOut
  extends SnapshotOut<typeof BeneficiaryOrganizationPagedDto> {}

export default BeneficiaryOrganizationPagedDto
