import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { GenericWithParentIdLookUpDto } from '../../../lookUps'
import IndicatorBaseDtoModel from '../IndicatorBaseDtoModel'

const IndicatorVulnerableMigrantDtoModel = IndicatorBaseDtoModel.named(
  'IndicatorVulnerableMigrantDtoModel',
).props({
  beneficiaryIndividualId: types.optional(types.integer, 0),
  beneficiaryIndividualName: types.maybeNull(types.string),
  interviewDate: types.maybeNull(types.string),
  interviewerStaffId: types.optional(types.integer, 0),
  interviewerStaffName: types.maybeNull(types.string),
  refferalOrgTypes:  types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
  isVulnerableMigrant: types.optional(types.boolean, false),
  howBecameVulnerableMigrant: types.maybeNull(types.string),
  hasRelativesInCountryWhereIsVulnerable: types.maybeNull(types.boolean),
  relativesFinancialSituationType: types.maybeNull(types.integer),
  // indicatorVulnerableMigrantDestinationMigrationActivityIds: types.optional(types.array(GenericWithParentIdLookUpDto), []),
  destinationMigrationActivities: types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
  // indicatorVulnerableMigrantDestinationMigrationActivityComment: types.maybeNull(types.string),
  migrationProcessEnterDate: types.maybeNull(types.string),
  migrationProcessEnterCountryId: types.maybeNull(types.integer),
  migrationProcessEnterCountryComment: types.optional(types.string, ''),
  migrationProcessEndCountryId: types.maybeNull(types.integer),
  migrationProcessEndCountryComment: types.optional(types.string, ''),
  spentTimeInTransitCountries: types.maybeNull(types.boolean),
  chronologyInTransitCountry: types.maybeNull(types.string),
  engagedInActivityWhenTransit: types.optional(types.boolean, false),
  // indicatorVulnerableMigrantTransitMigrationActivityIds: types.optional(types.array(GenericWithParentIdLookUpDto), []),
  // indicatorVulnerableMigrantTransitMigrationActivityComment: types.maybeNull(types.string),
  transitMigrationActivities: types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
  vulnerableStartDate: types.maybeNull(types.string),
  returnDate: types.maybeNull(types.string),
  becameVulnerableLocationType: types.optional(types.integer, 0),
  meetWinrockAssistanceRequirement: types.optional(types.boolean, false),
  winrockAssistanceRejectionReasonId: types.maybeNull(types.integer),
  winrockAssistanceRejectionReasonComment: types.maybeNull(types.string),
  agreeToGetWinrockAssistance: types.optional(types.boolean, false),
  // indicatorVulnerableMigrantWinrockAssistanceRefuseReasonIds: types.optional(types.array(GenericWithParentIdLookUpDto), []),
  // indicatorVulnerableMigrantWinrockAssistanceRefuseReasonComment: types.maybeNull(types.string),
  winrockAssistanceRefuseReasons: types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
  winrockAssistanceRejectionReasonParentId: types.maybeNull(types.integer),
  winrockAssistanceRejectionReason: types.maybeNull(types.string),
  doesPersonNeedHelp: types.optional(types.boolean, false),
  // indicatorVulnerableMigrantPersonConditionIds: types.optional(types.array(GenericWithParentIdLookUpDto), []),
  // indicatorVulnerableMigrantPersonConditionComment: types.optional(types.string, ''),
  personConditions: types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
})

export interface IIndicatorVulnerableMigrantDtoModel
  extends Instance<typeof IndicatorVulnerableMigrantDtoModel> {}
export interface IIndicatorVulnerableMigrantDtoModelSnapshotIn
  extends SnapshotIn<typeof IndicatorVulnerableMigrantDtoModel> {}
export interface IIndicatorVulnerableMigrantDtoModelSnapshotOut
  extends SnapshotOut<typeof IndicatorVulnerableMigrantDtoModel> {}
export default IndicatorVulnerableMigrantDtoModel
