import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../../PagedListRequestModel'

const BeneficiaryIndividualCategoryCLRequestModel = PagedListRequestModel.named(
  'BeneficiaryIndividualCategoryCLRequestModel',
).props({
  id: types.number,
  sortBy: types.optional(types.string, ''),
})

export interface IBeneficiaryIndividualCategoryCLRequestModel
  extends Instance<typeof BeneficiaryIndividualCategoryCLRequestModel> {}

export interface IBeneficiaryIndividualCategoryCLRequestModelIn
  extends SnapshotIn<typeof BeneficiaryIndividualCategoryCLRequestModel> {}

export interface IBeneficiaryIndividualCategoryCLRequestModelOut
  extends SnapshotOut<typeof BeneficiaryIndividualCategoryCLRequestModel> {}

export default BeneficiaryIndividualCategoryCLRequestModel
