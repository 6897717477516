import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../PagedListMetaData'
import ActivityApprovalModel from './ActivityApprovalModel'

const ActivityApprovalPagedDto = types.model('ActivityApprovalPagedDto', {
  data: types.array(ActivityApprovalModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export interface IActivityApprovalPagedDto
  extends Instance<typeof ActivityApprovalPagedDto> {}

export interface IActivityApprovalPagedDtoSnapshotIn
  extends SnapshotIn<typeof ActivityApprovalPagedDto> {}

export interface IActivityApprovalPagedDtoSnapshotOut
  extends SnapshotOut<typeof ActivityApprovalPagedDto> {}

export default ActivityApprovalPagedDto
