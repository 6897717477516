import { FC } from 'react'
import { FormattedMessage, MessageDescriptor } from 'react-intl'

const TableTitle: FC<{
  title: MessageDescriptor
  sortingKey?: string
  currentSorting?: `${string}.${'asc' | 'desc'}`
  onClick?: () => void
}> = ({ title, onClick, sortingKey, currentSorting }) => {
  const [sortBy, orderBy] = currentSorting?.split('.') || []
  return (
    <div
      onClick={() => onClick?.()}
      style={{
        cursor: !!sortingKey ? 'pointer' : 'initial',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <FormattedMessage {...title} />
      </span>
      {!!sortingKey &&
        (sortBy === sortingKey ? (
          <i
            className={`mx-3 ms-Icon  ms-Icon--${
              orderBy === 'asc' ? 'SortUp' : 'SortDown'
            }`}
            style={{
              color: '#0078d4',
            }}
          />
        ) : (
          <i className="mx-3 ms-Icon  ms-Icon--Sort" />
        ))}
    </div>
  )
}

export default TableTitle
