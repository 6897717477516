import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { RegionLookUpModel } from '../lookUps'

const OrganizationModel = types.model('OrganizationModel', {
  id: types.optional(types.integer, 0),
  name: types.maybeNull(types.string),
  countryId: types.optional(types.integer, 0),
  countryParentId: types.maybeNull(types.number),
  country: types.optional(types.string, ''),
  isActive: types.optional(types.boolean, false),
  regions: types.optional(
    types.array(RegionLookUpModel),
    [],
    [null, undefined],
  ),
  organizationTypeId: types.optional(types.integer, 0),
  organizationType: types.maybeNull(types.string),
  organizationTypeParentId: types.maybeNull(types.integer),
})

export interface IOrganization extends Instance<typeof OrganizationModel> {}
export interface IOrganizationSnapshotIn
  extends SnapshotIn<typeof OrganizationModel> {}
export interface IOrganizationSnapshotOut
  extends SnapshotOut<typeof OrganizationModel> {}
export default OrganizationModel
