import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../../PagedListMetaData'
import StaffModel from './StaffModel'

const StaffPagedDto = types.model('StaffPagedDto', {
  data: types.array(StaffModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export interface IStaffPagedDto extends Instance<typeof StaffPagedDto> {}

export interface IStaffPagedDtoSnapshotIn
  extends SnapshotIn<typeof StaffPagedDto> {}

export interface IStaffPagedDtoSnapshotOut
  extends SnapshotOut<typeof StaffPagedDto> {}

export default StaffPagedDto
