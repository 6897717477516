import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import CatalogModel from './CatalogModel'
import CountryModel from './CountryModel'
import IndicatorModel from './IndicatorModel'

const CatalogLayoutModel = CatalogModel.named('CatalogLayoutModel').props({
  countries: types.maybeNull(types.array(CountryModel)),
  indicators: types.maybeNull(types.array(IndicatorModel)),
  isActive: types.optional(types.boolean, false),
})

export interface ICatalogLayoutModel
  extends Instance<typeof CatalogLayoutModel> {}

export interface ICatalogLayoutSnapshotIn
  extends SnapshotIn<typeof CatalogLayoutModel> {}

export interface ICatalogLayoutSnapshotOut
  extends SnapshotOut<typeof CatalogLayoutModel> {}

export default CatalogLayoutModel
