import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateIndicator1_2_1Command = types.model('UpdateIndicator1_2_1Command', {
  organizationId: types.integer,
  recordDate: types.maybeNull(types.string),
  countryId: types.integer,
  countryComment: types.optional(types.string, ''),
  regionId: types.maybeNull(types.integer),
  organizationTypeId: types.integer,
  organizationTypeComment: types.maybeNull(types.string),
  improvementAreaIds: types.array(types.integer),
  supportTypeIds: types.array(types.integer),
  supportTypeComment: types.maybeNull(types.string),
  notes: types.maybeNull(types.string),
  hasSupportedDocuments: types.boolean,
})

export interface IUpdateIndicator1_2_1Command
  extends Instance<typeof UpdateIndicator1_2_1Command> {}
export interface IUpdateIndicator1_2_1CommandSnapshotIn
  extends SnapshotIn<typeof UpdateIndicator1_2_1Command> {}
export interface IUpdateIndicator1_2_1CommandSnapshotOut
  extends SnapshotOut<typeof UpdateIndicator1_2_1Command> {}
export default UpdateIndicator1_2_1Command
