import { FC } from 'react'
import { Form } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import format from 'date-fns/format'
import localeRu from 'date-fns/locale/ru'
import localeEn from 'date-fns/locale/en-US'
import 'react-datepicker/dist/react-datepicker.css'
import './CustomDatePicker.scss'
import YearSelect from './YearSelect'
import { isValidDate } from 'core/utils'
import MaskedTextInput from 'react-text-mask'

export type CustomDatePickerProps = {
  startDate: Date | null
  endDate: Date | null
  label?: string
  errorMessage?: string | Array<string>
} & ReactDatePickerProps

const CustomDatePicker: FC<CustomDatePickerProps> = ({
  errorMessage,
  label,
  name = '',
  showYearDropdown = true,
  ...props
}) => {
  const {
    startDate,
    endDate,
    onChange,
    selectsRange,
    placeholderText,
    minDate,
    maxDate,
  } = props
  const intl = useIntl()

  const renderCustomHeader: ReactDatePickerProps['renderCustomHeader'] = params => {
    const selectedDate: Date = isValidDate(params.date)
      ? params.date
      : new Date()
    return (
      <div className="custom-datepicker__header">
        <div
          className="custom-datepicker__month-year"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {format(selectedDate, `LLLL${!showYearDropdown ? ' Y' : ''}`, {
            locale: intl.locale === 'ru' ? localeRu : localeEn,
          })}
          {showYearDropdown && (
            <YearSelect
              minYear={minDate?.getFullYear() || 1900}
              maxYear={maxDate?.getFullYear() || new Date().getFullYear()}
              currentYear={selectedDate.getFullYear()}
              onChange={selectedYear =>
                selectedYear && params.changeYear(selectedYear.value)
              }
            />
          )}
        </div>
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          className="custom-datepicker__navigation"
        >
          <button
            className="custom-datepicker__navigation__button custom-datepicker__navigation__button--next"
            onClick={e => {
              e.preventDefault()
              params.decreaseMonth()
            }}
          >
            <i className="ms-Icon ms-Icon--SortUp" />
          </button>
          <button
            className="custom-datepicker__navigation__button custom-datepicker__navigation__button--previous"
            onClick={e => {
              e.preventDefault()
              params.increaseMonth()
            }}
          >
            <i className="ms-Icon ms-Icon--SortDown" />
          </button>
        </div>
      </div>
    )
  }

  const inputMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]

  return (
    <Form.Group className="mb-0">
      {!!label && <Form.Label htmlFor={label}>{label}</Form.Label>}
      {selectsRange ? (
        <DatePicker
          autoComplete="off"
          renderCustomHeader={renderCustomHeader}
          placeholderText={placeholderText}
          selectsRange
          focusSelectedMonth
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          shouldCloseOnSelect={false}
          name={name}
          dateFormat="dd.MM.yyyy"
          customInput={
            <MaskedTextInput
              type="text"
              mask={inputMask.concat([' ', '-', ' ']).concat(inputMask)}
              onInput={event => {
                const eventTarget = event.target as HTMLInputElement
                const [start, end] = eventTarget.value.split(' - ')

                const [startDay, startMonth, startYear] = (start || '')
                  .substring(0, 10)
                  .split('.')
                const [endDay, endMonth, endYear] = (end || '')
                  .substring(0, 10)
                  .split('.')

                const startDate = start
                  ? new Date(+startYear, +startMonth - 1, +startDay)
                  : null
                const endDate = end
                  ? new Date(+endYear, +endMonth - 1, +endDay)
                  : null

                const isStartDateValid = !!startDate && isValidDate(startDate)
                const isEndDateValid = !!endDate && isValidDate(endDate)

                onChange(
                  [
                    isStartDateValid ? startDate : null,
                    isEndDateValid ? endDate : null,
                  ],
                  event,
                )
              }}
              showMask={false}
            />
          }
        />
      ) : (
        <DatePicker
          autoComplete="off"
          renderCustomHeader={renderCustomHeader}
          name={name}
          dateFormat="dd.MM.yyyy"
          customInput={
            <MaskedTextInput
              type="text"
              mask={inputMask}
              onInput={event => {
                const eventTarget = event.target as HTMLInputElement

                const [day, month, year] = (eventTarget.value || '')
                  .substring(0, 10)
                  .split('.')

                const date = eventTarget.value
                  ? new Date(+year, +month - 1, +day)
                  : null

                if (date && isValidDate(date)) onChange(date, event)
                if (!date) onChange(null, event)
              }}
              showMask={false}
            />
          }
          {...props}
        />
      )}

      {!!errorMessage && (
        <Form.Control.Feedback type="invalid" style={{ display: 'initial' }}>
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

export default CustomDatePicker
