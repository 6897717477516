import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import IndicatorReportsFilterBaseModel from '../IndicatorReportsFilterBaseModel'

const Indicator1_2_2ReportFilterModel = IndicatorReportsFilterBaseModel.named(
  'Indicator1_2_2ReportFilterModel',
).props({
  OrganizationTypeId: types.union(types.number, types.undefined, types.null),
  DataTypeId: types.union(types.number, types.undefined, types.null),
})

export interface IIndicator1_2_2ReportFilterModel
  extends Instance<typeof Indicator1_2_2ReportFilterModel> {}

export interface IIndicator1_2_2ReportFilterSnapshotIn
  extends SnapshotIn<typeof Indicator1_2_2ReportFilterModel> {}

export interface IIndicator1_2_2ReportFilterSnapshotOut
  extends SnapshotOut<typeof Indicator1_2_2ReportFilterModel> {}

export default Indicator1_2_2ReportFilterModel
