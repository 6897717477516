import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateSingleActivityStaffTestResultCommand = types.model(
  'UpdateSingleActivityStaffTestResultCommand',
  {
    activityId: types.integer,
    participantId: types.number,
    preTestCorrectAnswerCount: types.maybeNull(types.number),
    postTestCorrectAnswerCount: types.maybeNull(types.number),
  },
)

export interface IUpdateSingleActivityStaffTestResultCommand
  extends Instance<typeof UpdateSingleActivityStaffTestResultCommand> {}

export interface IUpdateSingleActivityStaffTestResultCommandSnapshotIn
  extends SnapshotIn<typeof UpdateSingleActivityStaffTestResultCommand> {}

export interface IUpdateSingleActivityStaffTestResultCommandSnapshotOut
  extends SnapshotOut<typeof UpdateSingleActivityStaffTestResultCommand> {}

export default UpdateSingleActivityStaffTestResultCommand
