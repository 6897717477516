import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const StaffModel = types.model('StaffModel', {
  id: types.identifierNumber,
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  isActive: types.optional(types.boolean, false),
})

export interface IStaff extends Instance<typeof StaffModel> {}
export interface IStaffSnapshotIn extends SnapshotIn<typeof StaffModel> {}
export interface IStaffSnapshotOut extends SnapshotOut<typeof StaffModel> {}
export default StaffModel
