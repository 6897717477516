import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const InfluenceTypeLookUpModel = LookUpModel.named(
  'InfluenceTypeLookUpModel',
).props({
  isComment: types.boolean,
})

export interface IInfluenceTypeLookUpModel
  extends Instance<typeof InfluenceTypeLookUpModel> {}
export interface IInfluenceTypeLookUpSnapshotIn
  extends SnapshotIn<typeof InfluenceTypeLookUpModel> {}
export interface IInfluenceTypeLookUpSnapshotOut
  extends SnapshotOut<typeof InfluenceTypeLookUpModel> {}
export default InfluenceTypeLookUpModel
