import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const ImprovementAreaLookUpModel = LookUpModel.named(
  'ImprovementAreaLookUpModel',
).props({
  isComment: types.boolean,
})

export interface IImprovementAreaLookUp
  extends Instance<typeof ImprovementAreaLookUpModel> {}

export interface IImprovementAreaLookUpSnapshotIn
  extends SnapshotIn<typeof ImprovementAreaLookUpModel> {}

export interface IImprovementAreaLookUpSnapshotOut
  extends SnapshotOut<typeof ImprovementAreaLookUpModel> {}

export default ImprovementAreaLookUpModel
