import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../../PagedListMetaData'
import AllIndicatorsModel from './AllIndicatorsModel'

const AllIndicatorsPagedDto = types.model('AllIndicatorsPagedDto', {
  data: types.array(AllIndicatorsModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export interface IAllIndicatorsPagedDto
  extends Instance<typeof AllIndicatorsPagedDto> {}

export interface IAllIndicatorsPagedDtoSnapshotIn
  extends SnapshotIn<typeof AllIndicatorsPagedDto> {}

export interface IAllIndicatorsPagedDtoSnapshotOut
  extends SnapshotOut<typeof AllIndicatorsPagedDto> {}

export default AllIndicatorsPagedDto
