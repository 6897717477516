import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateRolePermissionsCommand = types.model(
  'UpdateRolePermissionsCommand',
  {
    roleName: types.identifier,
    permissionIds: types.array(types.number),
  },
)

export interface IUpdateRolePermissionsCommand
  extends Instance<typeof UpdateRolePermissionsCommand> {}

export interface IUpdateRolePermissionsCommandSnapshotIn
  extends SnapshotIn<typeof UpdateRolePermissionsCommand> {}

export interface IUpdateRolePermissionsCommandSnapshotOut
  extends SnapshotOut<typeof UpdateRolePermissionsCommand> {}

export default UpdateRolePermissionsCommand
