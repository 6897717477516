import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../PagedListMetaData'
import UserAccessInfoModel from './UserAccessInfoModel'

const UserAccessInfoPagedDto = types.model('UserAccessInfoPagedDto', {
  data: types.array(UserAccessInfoModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export interface IUserAccessInfoPagedDto
  extends Instance<typeof UserAccessInfoPagedDto> {}

export interface IUserAccessInfoPagedDtoSnapshotIn
  extends SnapshotIn<typeof UserAccessInfoPagedDto> {}

export interface IUserAccessInfoPagedDtoSnapshotOut
  extends SnapshotOut<typeof UserAccessInfoPagedDto> {}

export default UserAccessInfoPagedDto
