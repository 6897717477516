import { Resizable, ResizeCallbackData, Axis } from 'react-resizable'
import { CellType } from 'rc-table/lib/interface'

interface ResizableOptions {
  minConstraints?: [number, number]
  maxConstraints?: [number, number]
  height?: number
  width?: number | string
  axis?: Axis
}

interface ResizableHeaderCellProps<RecordType>
  extends CellType<RecordType>,
    ResizableOptions {
  onResize?: (event: React.SyntheticEvent, data: ResizeCallbackData) => any
}

const ResizableHeaderCell = <RecordType extends {}>({
  width,
  height = 10,
  onResize,
  minConstraints = [70, 10],
  maxConstraints = [400, 10],
  axis = 'x',
  ...props
}: ResizableHeaderCellProps<RecordType>) => {
  if (!width) return <th {...props} />
  if (typeof width === 'string')
    return (
      <Resizable
        className="resizable-none-handles"
        width={parseInt(width)}
        height={height}
        axis="none"
      >
        <th {...props} />
      </Resizable>
    )

  return (
    <Resizable
      className="resizable-hover-handles"
      width={width}
      height={height}
      onResize={onResize}
      axis={axis}
      maxConstraints={maxConstraints}
      minConstraints={minConstraints}
    >
      <th {...props} />
    </Resizable>
  )
}

export type { ResizableOptions, ResizableHeaderCellProps }
export default ResizableHeaderCell
