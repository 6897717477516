import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const LookUpModelWithComment = LookUpModel.named(
  'LookUpModelWithComment',
).props({
    isComment: types.boolean,
})

export interface ILookUpModelWithComment
  extends Instance<typeof LookUpModelWithComment> {}

export interface ILookUpModelWithCommentSnapshotIn
  extends SnapshotIn<typeof LookUpModelWithComment> {}

export interface ILookUpModelWithCommentSnapshotOut
  extends SnapshotOut<typeof LookUpModelWithComment> {}

export default LookUpModelWithComment
