import { FC } from 'react'
import ReactPaginate from 'react-paginate'

type PaginationProps = {
  pageCount: number
  currentPage: number
  onPageChange: (selected: number) => void
}

const Pagination: FC<PaginationProps> = ({
  currentPage,
  pageCount,
  onPageChange,
}) => {
  return pageCount ? (
    <ReactPaginate
      containerClassName="pagination"
      activeClassName="active"
      breakLabel="..."
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      forcePage={currentPage - 1}
      onPageChange={({ selected }) => {
        onPageChange(selected + 1)
      }}
    />
  ) : null
}

export default Pagination
