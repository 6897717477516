enum DashboardName {
  Services = 'Services',
  Activities = 'Activities',
  Indicator1 = 'Indicator1_1_1',
  Indicator2 = 'Indicator1_2_1',
  Indicator3 = 'Indicator1_2_2',
  Indicator4 = 'Indicator2_1_1',
  Indicator5 = 'Indicator2_1_2',
  Indicator6 = 'Indicator2_2_1',
  Indicator7 = 'Indicator2_2_2',
  Indicator8 = 'Indicator2_2_3',
  Indicator9 = 'Indicator3_1_1',
  Indicator10 = 'Indicator3_2_1',
  Indicator11 = 'Indicator3_2_2',
  Indicator12 = 'Indicator4_1_1',
  Indicator13 = 'VulnerableMigrant'
}

export default DashboardName
