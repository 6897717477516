import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const CreateBeneficiaryIndividualCommand = types.model(
  'CreateBeneficiaryIndividualCommand',
  {
    firstName: types.string,
    lastName: types.string,
    middleName: types.maybeNull(types.string),
    birthDate: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    serviceCountryId: types.number,
    serviceCountryComment: types.maybeNull(types.string),
    birthCountryId: types.maybeNull(types.number),
    birthCountryComment: types.maybeNull(types.string),
    genderId: types.number,
    genderComment: types.maybeNull(types.string),
    ethnicityId: types.maybeNull(types.number),
    ethnicityComment: types.maybeNull(types.string),
    age: types.maybeNull(types.number),
    ageCategory: types.maybeNull(types.number),
    categoryIds: types.array(types.number),
    organizationId: types.integer,
    controlQuestionId: types.maybeNull(types.number),
    controlQuestionAnswer: types.maybeNull(types.string),
  },
)

export interface ICreateBeneficiaryIndividualCommand
  extends Instance<typeof CreateBeneficiaryIndividualCommand> {}

export interface ICreateBeneficiaryIndividualCommandIn
  extends SnapshotIn<typeof CreateBeneficiaryIndividualCommand> {}

export interface ICreateBeneficiaryIndividualCommandOut
  extends SnapshotOut<typeof CreateBeneficiaryIndividualCommand> {}

export default CreateBeneficiaryIndividualCommand
