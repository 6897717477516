import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const IndicatorLookUpModel = LookUpModel.named('IndicatorLookUpModel').props({
  number: types.maybeNull(types.string),
})

export interface IIndicatorLookUp
  extends Instance<typeof IndicatorLookUpModel> {}

export interface IIndicatorLookUpSnapshotIn
  extends SnapshotIn<typeof IndicatorLookUpModel> {}

export interface IIndicatorLookUpSnapshotOut
  extends SnapshotOut<typeof IndicatorLookUpModel> {}

export default IndicatorLookUpModel
