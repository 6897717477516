import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UserAccessTokenModel = types.model('UserAccessTokenModel', {
  userName: types.optional(types.string, ''),
  userId: types.maybeNull(types.string),
  isFirstLogin: types.optional(types.boolean, false),
  firstName: types.optional(types.string, '', [null, undefined]),
  accessToken: types.maybeNull(types.string),
  refreshToken: types.maybeNull(types.string),
})

export interface IUserAccessTokenModel
  extends Instance<typeof UserAccessTokenModel> {}

export interface IUserAccessTokenSnapshotIn
  extends SnapshotIn<typeof UserAccessTokenModel> {}

export interface IUserAccessTokenSnapshotOut
  extends SnapshotOut<typeof UserAccessTokenModel> {}

export default UserAccessTokenModel
