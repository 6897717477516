import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const Indicator4_1_1ReportModel = types.model('Indicator4_1_1ReportModel', {
  code: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  memberType: types.maybeNull(types.string),
  activityName: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  region: types.maybeNull(types.string),
  dates: types.maybeNull(types.string),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  activityType: types.maybeNull(types.string),
  organizerName: types.maybeNull(types.string),
})

export interface IIndicator4_1_1ReportModel
  extends Instance<typeof Indicator4_1_1ReportModel> {}
export interface IIndicator4_1_1ReportSnapshotIn
  extends SnapshotIn<typeof Indicator4_1_1ReportModel> {}
export interface IIndicator4_1_1ReportSnapshotOut
  extends SnapshotOut<typeof Indicator4_1_1ReportModel> {}
export default Indicator4_1_1ReportModel
