import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const TIPCaseStatusLookUpModel = LookUpModel.named(
  'TIPCaseStatusLookUpModel',
).props({
  isComment: types.boolean,
})

export interface ITIPCaseStatusLookUp
  extends Instance<typeof TIPCaseStatusLookUpModel> {}
export interface ITIPCaseStatusLookUpIn
  extends SnapshotIn<typeof TIPCaseStatusLookUpModel> {}
export interface ITIPCaseStatusLookUpOut
  extends SnapshotOut<typeof TIPCaseStatusLookUpModel> {}
export default TIPCaseStatusLookUpModel
