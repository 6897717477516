import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const BusinessScaleLookUpModel = LookUpModel.named(
  'BusinessScaleLookUpModel',
).props({
  isComment: types.boolean,
})

export interface IBusinessScaleLookUpModel
  extends Instance<typeof BusinessScaleLookUpModel> {}
export interface IBusinessScaleLookUpSnapshotIn
  extends SnapshotIn<typeof BusinessScaleLookUpModel> {}
export interface IBusinessScaleLookUpSnapshotOut
  extends SnapshotOut<typeof BusinessScaleLookUpModel> {}
export default BusinessScaleLookUpModel
