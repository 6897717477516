import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const OrganizationTypesLookUpModel = LookUpModel.named(
  'OrganizationTypesLookUpModel',
).props({
  isComment: types.boolean,
})

export interface IOrganizationTypesLookUp
  extends Instance<typeof OrganizationTypesLookUpModel> {}
export interface IOrganizationTypesLookUpIn
  extends SnapshotIn<typeof OrganizationTypesLookUpModel> {}
export interface IOrganizationTypesLookUpOut
  extends SnapshotOut<typeof OrganizationTypesLookUpModel> {}
export default OrganizationTypesLookUpModel
