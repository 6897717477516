import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import EmbedReportModel from './EmbedReportModel'

const ReportEmbedParams = types.model('ReportEmbedParams', {
  embedReport: types.optional(EmbedReportModel, {}),
  embedReportPage: types.optional(EmbedReportModel, {}),
  type: types.optional(types.string, ''),
  embedToken: types.optional(types.string, ''),
})

export interface IReportEmbedParams
  extends Instance<typeof ReportEmbedParams> {}

export interface IReportEmbedParamsSnapshotIn
  extends SnapshotIn<typeof ReportEmbedParams> {}

export interface IReportEmbedParamsSnapshotOut
  extends SnapshotOut<typeof ReportEmbedParams> {}

export default ReportEmbedParams
