import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateIndicator3_1_1Command = types.model('UpdateIndicator3_1_1Command', {
  beneficiaryIndividualId: types.integer,
  identificationDate: types.maybeNull(types.string),
  countryId: types.integer,
  countryComment: types.optional(types.string, ''),
  regionId: types.maybeNull(types.integer),
  mainTraffickingFormIds: types.array(types.integer),
  mainTraffickingFormComment: types.maybeNull(types.string),
  identifyingAgencyIds: types.array(types.integer),
  identifyingAgencyComment: types.maybeNull(types.string),
  isReffered: types.maybeNull(types.boolean),
  refferalOrgTypeIds: types.array(types.integer),
  refferalOrgTypeComment: types.maybeNull(types.string),
  tipCaseStatusIds: types.array(types.integer),
  tipCaseStatusComment: types.maybeNull(types.string),
  isFormal: types.boolean,
  dateOfRecruitment: types.maybeNull(types.string),
  countryOfRecruitmentId: types.maybeNull(types.integer),
  countryOfRecruitmentComment: types.optional(types.string, ''),
  relationshipEstablishmentIds: types.array(types.integer),
  relationshipEstablishmentComment: types.maybeNull(types.string),
  workTypeIds: types.array(types.integer),
  workTypeComment: types.maybeNull(types.string),
  workLocationId: types.maybeNull(types.integer),
  workLocationComment: types.optional(types.string, ''),
  // workLocation: types.maybeNull(types.string),
  destinationSalary: types.maybeNull(types.string),
  isRecruiterWasPaid: types.maybeNull(types.boolean),
  recruiterPaymentAmount: types.maybeNull(types.string),
  recruiterType: types.maybeNull(types.integer),
  recruiterLegalEntityTypeId: types.maybeNull(types.integer),
  recruiterLegalEntityTypeComment: types.maybeNull(types.string),
  // legalEntityTypeIds: types.array(types.integer),
  // legalEntityTypeComment: types.maybeNull(types.string),
  individualEmployer: types.maybeNull(types.string),
  numberOfRecruiters: types.maybeNull(types.integer),
  firstRecruiterGender: types.maybeNull(types.integer),
  firstRecruiterCitizenshipId: types.maybeNull(types.integer),
  firstRecruiterCitizenshipComment: types.maybeNull(types.string),
  firstRecruiterEthnicityId: types.maybeNull(types.integer),
  firstRecruiterEthnicityComment: types.maybeNull(types.string),
  firstRecruiterRelationshipTypeIds: types.array(types.integer),
  firstRecruiterRelationshipTypeComment: types.maybeNull(types.string),
  //second recruiter
  secondRecruiterGender: types.maybeNull(types.integer),
  secondRecruiterCitizenshipId: types.maybeNull(types.integer),
  secondRecruiterCitizenshipComment: types.maybeNull(types.string),
  secondRecruiterEthnicityId: types.maybeNull(types.integer),
  secondRecruiterEthnicityComment: types.maybeNull(types.string),
  secondRecruiterRelationshipTypeIds: types.array(types.integer),
  secondRecruiterRelationshipTypeComment: types.maybeNull(types.string),
  recruitmentToolIds: types.array(types.integer),
  recruitmentToolComment: types.maybeNull(types.string),
  hasDebtToRecruiter: types.maybeNull(types.integer),
  recruiterDebtAmount: types.maybeNull(types.string),
  recruiterDebtPaymentStartDate: types.maybeNull(types.string),
  recruiterDebtPaymentEndDate: types.maybeNull(types.string),
  recruiterDebtReason: types.maybeNull(types.string),
  personCostAmount: types.maybeNull(types.string),
  hasDisplacedFromCountry: types.maybeNull(types.boolean),
  wasRemovedFromCountry: types.maybeNull(types.boolean),
  travelCostPayer: types.maybeNull(types.integer),
  travelMethodId: types.maybeNull(types.integer),
  travelMethodComment: types.maybeNull(types.string),
  hasCrossedBorder: types.integer,
  borderCrossType: types.maybeNull(types.integer),
  borderDocumentType: types.maybeNull(types.integer),
  visaRequired: types.maybeNull(types.integer),
  visaType: types.maybeNull(types.integer),
  identityDocumentLocation: types.maybeNull(types.integer),
  hasTransitTravel: types.maybeNull(types.integer),
  engagedInTransitWork: types.maybeNull(types.integer),
  transitWorkTypeIds: types.array(types.integer),
  transitWorkTypeComment: types.maybeNull(types.string),
  transportationControlToolIds: types.array(types.integer),
  transportationControlToolComment: types.maybeNull(types.string),
  hasDebtToCarrier: types.maybeNull(types.integer),
  carrierDebtAmount: types.maybeNull(types.string),
  carrierDebtPaymentStartDate: types.maybeNull(types.string),
  carrierDebtPaymentEndDate: types.maybeNull(types.string),
  carrierDebtReason: types.maybeNull(types.string),
  wasExploited: types.maybeNull(types.integer),
  exploitationStartDate: types.maybeNull(types.string),
  exploitationCountryId: types.maybeNull(types.integer),
  exploitationCountryComment: types.optional(types.string, ''),
  numberOfTraffickers: types.maybeNull(types.integer),
  firstTraffickerRole: types.maybeNull(types.string),
  firstTraffickerGender: types.maybeNull(types.integer),
  firstTraffickerEthnicityId: types.maybeNull(types.integer),
  firstTraffickerEthnicityComment: types.maybeNull(types.string),
  secondTraffickerRole: types.maybeNull(types.string),
  secondTraffickerGender: types.maybeNull(types.integer),
  secondTraffickerEthnicityId: types.maybeNull(types.integer),
  secondTraffickerEthnicityComment: types.maybeNull(types.string),
  traffickingFormIds: types.array(types.integer),
  traffickingFormListComment: types.maybeNull(types.string),
  exploitationControlToolIds: types.array(types.integer),
  exploitationControlToolComment: types.maybeNull(types.string),
  hasDebtToExploiter: types.maybeNull(types.integer),
  exploiterDebtAmount: types.maybeNull(types.string),
  exploiterDebtPaymentStartDate: types.maybeNull(types.string),
  exploiterDebtPaymentEndDate: types.maybeNull(types.string),
  exploiterDebtReason: types.maybeNull(types.string),
  exploitationConditionIds: types.array(types.integer),
  exploitationConditionComment: types.maybeNull(types.string),
  hasReceivedMoney: types.maybeNull(types.integer),
  receivedMoneyAmount: types.maybeNull(types.string),
  allowedToCollectMoney: types.maybeNull(types.integer),
  hasSimilarCases: types.maybeNull(types.integer),
  similarCaseCount: types.maybeNull(types.integer),
  similarCaseGender: types.maybeNull(types.integer),
  similarCaseAgeGroup: types.maybeNull(types.integer),
  exploitationEndDate: types.maybeNull(types.string),
  victimReleaseTypeIds: types.array(types.integer),
  victimReleaseTypeComment: types.maybeNull(types.string),
  hasExperiencedTrafficking: types.maybeNull(types.integer),
  previousCaseDate: types.maybeNull(types.string),
  previousCaseCountryId: types.maybeNull(types.integer),
  previousCaseCountryComment: types.optional(types.string, ''),
  previousCaseTotalYears: types.maybeNull(types.string),
  previousTraffickingFormIds: types.array(types.integer),
  previousTraffickingFormComment: types.maybeNull(types.string),
  identifiedAsVictimPreviously: types.maybeNull(types.integer),
  identificationCountryId: types.maybeNull(types.integer),
  identificationCountryComment: types.optional(types.string, ''),
  identifiedOrganization: types.maybeNull(types.string),
  assistanceProvidedInPreviousCase: types.maybeNull(types.integer),
  situationDetails: types.maybeNull(types.string),
  isIdentifiedAsVictimOfTrafficking: types.maybeNull(types.boolean)
})

export interface IUpdateIndicator3_1_1Command
  extends Instance<typeof UpdateIndicator3_1_1Command> {}
export interface IUpdateIndicator3_1_1CommandSnapshotIn
  extends SnapshotIn<typeof UpdateIndicator3_1_1Command> {}
export interface IUpdateIndicator3_1_1CommandSnapshotOut
  extends SnapshotOut<typeof UpdateIndicator3_1_1Command> {}
export default UpdateIndicator3_1_1Command
