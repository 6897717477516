import CatalogName from './CatalogName'

export const CatalogsApiData = new Map<CatalogName, string>([
  [CatalogName.Gender, '/api/genders'],
  [CatalogName.Ethnicity, '/api/ethnicities'],
  [CatalogName.TypesOfService, '/api/servicetypes'],
  [CatalogName.OrganizationTypes, '/api/organizationtypes'],
  [CatalogName.ActivityTypes, '/api/activitytypes'],
  [CatalogName.TraffickingForms, '/api/traffickingforms'],
  [CatalogName.IdentifyingAgencies, '/api/identifyingagencies'],
  [CatalogName.TipCaseStatuses, '/api/tipcasestatuses'],
  [CatalogName.AdvocacyIssues, '/api/advocacyissues'],
  [CatalogName.AdvocacyTypes, '/api/advocacytypes'],
  [CatalogName.SupportTypes, '/api/supporttypes'],
  [CatalogName.AdvocacyInfluenceLevel, '/api/advocacyinfluencelevels'],
  [CatalogName.DevelopmentStages, '/api/developmentstages'],
  [CatalogName.InfluenceTypes, '/api/influencetypes'],
  [CatalogName.DocumentType, '/api/documenttypes'],
  [CatalogName.ImprovementArea, '/api/improvementareas'],
  [CatalogName.CSOTypes, '/api/csotypes'],
  [CatalogName.DataTypes, '/api/datatypes'],
  [CatalogName.DataCollectionFrequency, '/api/datacollectionfrequencies'],
  [CatalogName.BusinessScale, '/api/businessscales'],
  [CatalogName.SupportingDocuments, '/api/supportingdocuments'],
  [CatalogName.IndustryTypes, '/api/industrytypes'],
  [CatalogName.LegalEntityTypes, '/api/legalentitytypes'],
  [CatalogName.CollaborationAreas, '/api/collaborationareas'],
])
