import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateBeneficiaryOrganizationStaffCommand = types.model(
  'UpdateBeneficiaryOrganizationStaffCommand',
  {
    firstName: types.string,
    lastName: types.string,
    position: types.string,
    memberTypeId: types.integer,
    id: types.integer,
    genderId: types.number,
    genderComment: types.maybeNull(types.string),
  },
)

export interface IUpdateBeneficiaryOrganizationStaffCommand
  extends Instance<typeof UpdateBeneficiaryOrganizationStaffCommand> {}

export interface IUpdateBeneficiaryOrganizationStaffCommandIn
  extends SnapshotIn<typeof UpdateBeneficiaryOrganizationStaffCommand> {}

export interface IUpdateBeneficiaryOrganizationStaffCommandOut
  extends SnapshotOut<typeof UpdateBeneficiaryOrganizationStaffCommand> {}

export default UpdateBeneficiaryOrganizationStaffCommand
