import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const Indicator1_1_1ReportModel = types.model('Indicator1_1_1ReportModel', {
  documentName: types.maybeNull(types.string),
  partnerName: types.maybeNull(types.string),
  documentTypeId: types.optional(types.integer, 0),
  documentType: types.maybeNull(types.string),
  advocacyIssueId: types.optional(types.integer, 0),
  advocacyIssue: types.maybeNull(types.string),
  advocacyIssueParentId: types.maybeNull(types.number),
  advocacyInfluenceLevelId: types.optional(types.integer, 0),
  advocacyInfluenceLevel: types.maybeNull(types.string),
  reportingYear: types.optional(types.integer, -1),
  reportingQuarterId: types.optional(types.integer, 0),
  reportingQuarter: types.maybeNull(types.string),
  engagementDevelopmentStageId: types.optional(types.integer, 0),
  engagementDevelopmentStage: types.maybeNull(types.string),
  recordDevelopmentStageId: types.optional(types.integer, 0),
  recordDevelopmentStage: types.maybeNull(types.string),
  influenceTypeId: types.optional(types.integer, 0),
  influenceType: types.optional(types.string, ''),
  advocacyTypes: types.optional(types.array(types.string), []),
  countryId: types.optional(types.integer, 0),
  country: types.maybeNull(types.string),
  hasSupportedDocuments: types.optional(types.boolean, false),
})

export interface IIndicator1_1_1ReportModel
  extends Instance<typeof Indicator1_1_1ReportModel> {}
export interface IIndicator1_1_1ReportSnapshotIn
  extends SnapshotIn<typeof Indicator1_1_1ReportModel> {}
export interface IIndicator1_1_1ReportSnapshotOut
  extends SnapshotOut<typeof Indicator1_1_1ReportModel> {}
export default Indicator1_1_1ReportModel
