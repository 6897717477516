import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const WinrockassistancerefusereasonsLooksUpModel = LookUpModel.named(
  'WinrockassistancerefusereasonsLooksUpModel',
).props({
    isComment: types.boolean,
})

export interface IWinrockassistancerefusereasonsLooksUpModel
  extends Instance<typeof WinrockassistancerefusereasonsLooksUpModel> {}

export interface IWinrockassistancerefusereasonsLooksUpModelSnapshotIn
  extends SnapshotIn<typeof WinrockassistancerefusereasonsLooksUpModel> {}

export interface IWinrockassistancerefusereasonsLooksUpModelSnapshotOut
  extends SnapshotOut<typeof WinrockassistancerefusereasonsLooksUpModel> {}

export default WinrockassistancerefusereasonsLooksUpModel
