enum PermissionGroupName {
  Dashboards = 'api/dashboards',
  Beneficiaries = 'api/beneficiaries',
  Users = 'api/users',
  Partners = 'api/partners',
  PartnersStaff = 'api/staff',
  PartnersServiceTypes = 'api/partners/organization/serviceTypes',
  PartnersBeneficiaryOrganization = 'api/partners/beneficiaryOrganization',
  PartnersBeneficiaryIndividuals = 'api/partners/beneficiaryIndividuals',
  PartnersServiceHistory = 'api/partners/serviceHistories',
  PartnersActivities = 'api/partners/activities',
  PartnersIndicators = 'api/partners/indicators',
  Activities = 'api/activities',
  ServiceHistories = 'api/serviceHistories',
  Indicators = 'api/indicators',
  IndicatorsReports = 'api/indicators/reports',
  IndicatorsTargets = 'api/indicators/targets',
  Genders = 'api/genders',
  Ethnicities = 'api/ethnicities',
  ServiceTypes = 'api/serviceTypes',
  BeneficiaryCategories = 'api/beneficiaryCategories',
  BeneficiaryIndividual = 'api/beneficiaryIndividuals',
  RolesPermissions = 'api/roles/permissions',
  UsersPermissions = 'api/users/permissions',
  OrganizationTypes = 'api/organizationTypes',
  ActivityTypes = 'api/activityTypes',
  TraffickingForms = 'api/traffickingForms',
  IdentifyingAgencies = 'api/identifyingAgencies',
  TipCaseStatuses = 'api/tipCaseStatuses',
  AdvocacyIssue = 'api/advocacyIssues',
  AdvocacyTypes = 'api/advocacyTypes',
  SupportTypes = 'api/supportTypes',
  AdvocacyInfluenceLevel = 'api/advocacyInfluenceLevels',
  DevelopmentStages = 'api/developmentStages',
  InfluenceTypes = 'api/influenceTypes',
  DocumentType = 'api/documentTypes',
  ImprovementArea = 'api/improvementAreas',
  CSOTypes = 'api/csoTypes',
  DataTypes = 'api/dataTypes',
  DataCollectionFrequency = 'api/dataCollectionFrequencies',
  BusinessScale = 'api/businessScales',
  SupportingDocuments = 'api/supportingDocuments',
  IndustryTypes = 'api/industryTypes',
  LegalEntityTypes = 'api/legalEntityTypes',
  CollaborationAreas = 'api/collaborationAreas',
}

export default PermissionGroupName
