import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const FileLookUpModel = types.model('FileLookUpModel', {
  id: types.optional(types.number, 0),
  originalFileName: types.maybeNull(types.string),
})

export interface IFileLookUpModel extends Instance<typeof FileLookUpModel> {}

export interface IFileLookUpSnapshotIn
  extends SnapshotIn<typeof FileLookUpModel> {}

export interface IFileLookUpSnapshotOut
  extends SnapshotOut<typeof FileLookUpModel> {}

export default FileLookUpModel
