import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const CreateStaffCommand = types.model('CreateStaffCommand', {
  firstName: types.string,
  lastName: types.string,
  position: types.string,
  email: types.string,
  organizationId: types.integer,
})

export interface ICreateStaffCommand
  extends Instance<typeof CreateStaffCommand> {}
export interface ICreateStaffCommandSnapshotIn
  extends SnapshotIn<typeof CreateStaffCommand> {}
export interface ICreateStaffCommandSnapshotOut
  extends SnapshotOut<typeof CreateStaffCommand> {}
export default CreateStaffCommand
