import ActivityTestType from './ActivityTestType'

const PARTNER_ACTIVITIES_TESTS_API = '/api/partners/activities/tests'

export const ActivityTestsApiData = new Map<ActivityTestType, string>([
  [
    ActivityTestType.BeneficiaryIndividualPreTest,
    `${PARTNER_ACTIVITIES_TESTS_API}/beneficiaryindividuals/pre`,
  ],
  [
    ActivityTestType.BeneficiaryIndividualPostTest,
    `${PARTNER_ACTIVITIES_TESTS_API}/beneficiaryindividuals/post`,
  ],
  [ActivityTestType.StaffPreTest, `${PARTNER_ACTIVITIES_TESTS_API}/staff/pre`],
  [
    ActivityTestType.StaffPostTest,
    `${PARTNER_ACTIVITIES_TESTS_API}/staff/post`,
  ],
  [
    ActivityTestType.SingleBeneficiaryIndividualTest,
    `${PARTNER_ACTIVITIES_TESTS_API}/beneficiaryindividuals/single`,
  ],
  [
    ActivityTestType.SingleStaffTest,
    `${PARTNER_ACTIVITIES_TESTS_API}/staff/single`,
  ],
])
