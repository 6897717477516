import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateUserPermissionsCommand = types.model(
  'UpdateUserPermissionsCommand',
  {
    userId: types.identifier,
    permissionIds: types.array(types.number),
  },
)

export interface IUpdateUserPermissionsCommand
  extends Instance<typeof UpdateUserPermissionsCommand> {}

export interface IUpdateUserPermissionsCommandSnapshotIn
  extends SnapshotIn<typeof UpdateUserPermissionsCommand> {}

export interface IUpdateUserPermissionsCommandSnapshotOut
  extends SnapshotOut<typeof UpdateUserPermissionsCommand> {}

export default UpdateUserPermissionsCommand
