import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { GenericWithParentIdLookUpDto } from '../../../lookUps'
import IndicatorBaseDtoModel from '../IndicatorBaseDtoModel'

const Indicator3_1_1DtoModel = IndicatorBaseDtoModel.named(
  'Indicator3_1_1DtoModel',
).props({
  beneficiaryIndividualName: types.maybeNull(types.string),
  beneficiaryIndividualId: types.optional(types.integer, 0),
  mainTraffickingForms: types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
  identificationDate: types.maybeNull(types.string),
  identifyingAgencies: types.optional(
    types.array(GenericWithParentIdLookUpDto),
    [],
  ),
  isReffered: types.maybeNull(types.boolean),
  refferalOrgTypes: types.optional(
    types.array(GenericWithParentIdLookUpDto),
    [],
  ),
  tipCaseStatuses: types.optional(
    types.array(GenericWithParentIdLookUpDto),
    [],
  ),
  isFormal: types.optional(types.boolean, false),
  dateOfRecruitment: types.maybeNull(types.string),
  countryOfRecruitmentId: types.maybeNull(types.integer),
  countryOfRecruitment: types.maybeNull(types.string),
  countryOfRecruitmentParentId: types.maybeNull(types.integer),
  relationshipEstablishments: types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
  workTypes: types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
  workLocationId: types.maybeNull(types.integer),
  workLocationParentId: types.maybeNull(types.integer),
  workLocation: types.maybeNull(types.string),
  destinationSalary: types.maybeNull(types.string),
  isRecruiterWasPaid: types.maybeNull(types.boolean),
  recruiterPaymentAmount: types.maybeNull(types.string),
  recruiterType: types.maybeNull(types.integer),
  recruiterLegalEntityTypeId: types.maybeNull(types.integer),
  recruiterLegalEntityTypeParentId: types.maybeNull(types.integer),
  recruiterLegalEntityType: types.maybeNull(types.string),
  individualEmployer: types.maybeNull(types.optional(types.string, '')),
  numberOfRecruiters: types.maybeNull(types.integer),
  firstRecruiterRelationshipTypes: types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
  firstRecruiterGender: types.maybeNull(types.integer),
  firstRecruiterCitizenshipId: types.maybeNull(types.integer),
  firstRecruiterCitizenshipParentId: types.maybeNull(types.integer),
  firstRecruiterCitizenship: types.maybeNull(types.string),
  firstRecruiterEthnicity: types.maybeNull(types.string),
  firstRecruiterEthnicityId: types.maybeNull(types.integer),
  firstRecruiterEthnicityParentId: types.maybeNull(types.integer),
  relationshipTypesLookUps: types.optional( types.array(GenericWithParentIdLookUpDto),
      [],),
  secondRecruiterRelationshipTypes: types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
  secondRecruiterGender: types.maybeNull(types.integer),
  secondRecruiterCitizenshipId: types.maybeNull(types.integer),
  secondRecruiterCitizenshipParentId: types.maybeNull(types.integer),
  secondRecruiterCitizenship: types.maybeNull(types.string),
  secondRecruiterEthnicityId: types.maybeNull(types.integer),
  secondRecruiterEthnicity: types.maybeNull(types.string),
  secondRecruiterEthnicityParentId: types.maybeNull(types.integer),
  recruitmentTools: types.optional( types.array(GenericWithParentIdLookUpDto),
      [],),
  hasDebtToRecruiter: types.maybeNull(types.integer),
  recruiterDebtAmount: types.maybeNull(types.string),
  recruiterDebtPaymentStartDate: types.maybeNull(types.string),
  recruiterDebtPaymentEndDate: types.maybeNull(types.string),
  recruiterDebtReason: types.maybeNull(types.string),
  personCostAmount: types.maybeNull(types.string),
  hasDisplacedFromCountry: types.maybeNull(types.boolean),
  wasRemovedFromCountry: types.maybeNull(types.boolean),
  travelCostPayer: types.maybeNull(types.integer),
  travelMethodId: types.maybeNull(types.optional(types.integer, 0)),
  travelMethod: types.maybeNull(types.string),
  travelMethodParentId: types.maybeNull(types.integer),
  hasCrossedBorder: types.maybeNull(types.optional(types.integer, 2)),
  borderCrossType: types.maybeNull(types.integer),
  borderDocumentType: types.maybeNull(types.integer),
  visaRequired: types.maybeNull(types.integer),
  visaType: types.maybeNull(types.integer),
  identityDocumentLocation: types.maybeNull(types.integer),
  hasTransitTravel: types.maybeNull(types.integer),
  engagedInTransitWork: types.maybeNull(types.integer),
  transitWorkTypes: types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
  transportationControlTools: types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
  hasDebtToCarrier: types.maybeNull(types.integer),
  carrierDebtAmount: types.maybeNull(types.string),
  carrierDebtPaymentStartDate: types.maybeNull(types.string),
  carrierDebtPaymentEndDate: types.maybeNull(types.string),
  carrierDebtReason: types.maybeNull(types.string),
  wasExploited: types.maybeNull(types.integer),
  exploitationStartDate: types.maybeNull(types.string),
  exploitationCountryId: types.maybeNull(types.integer),
  exploitationCountryParentId: types.maybeNull(types.integer),
  exploitationCountry: types.maybeNull(types.string),
  numberOfTraffickers: types.maybeNull(types.integer),
  firstTraffickerRole: types.maybeNull(types.string),
  firstTraffickerGender: types.maybeNull(types.integer),
  firstTraffickerEthnicityId: types.maybeNull(types.integer),
  firstTraffickerEthnicity: types.maybeNull(types.string),
  firstTraffickerEthnicityParentId: types.maybeNull(types.integer),
  secondTraffickerRole: types.maybeNull(types.string),
  secondTraffickerGender: types.maybeNull(types.integer),
  secondTraffickerEthnicityId: types.maybeNull(types.integer),
  secondTraffickerEthnicity: types.maybeNull(types.string),
  secondTraffickerEthnicityParentId: types.maybeNull(types.integer),
  traffickingForms: types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
  exploitationControlTools: types.optional(
    types.array(GenericWithParentIdLookUpDto),
    [],
  ),
  hasDebtToExploiter: types.maybeNull(types.integer),
  exploiterDebtAmount: types.maybeNull(types.string),
  exploiterDebtPaymentStartDate: types.maybeNull(types.string),
  exploiterDebtPaymentEndDate: types.maybeNull(types.string),
  exploiterDebtReason: types.maybeNull(types.string),
  exploitationConditions: types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
  hasReceivedMoney: types.maybeNull(types.integer),
  receivedMoneyAmount: types.maybeNull(types.string),
  allowedToCollectMoney: types.maybeNull(types.integer),
  hasSimilarCases: types.maybeNull(types.integer),
  similarCaseCount: types.maybeNull(types.integer),
  similarCaseGender: types.maybeNull(types.integer),
  similarCaseAgeGroup: types.maybeNull(types.integer),
  exploitationEndDate: types.maybeNull(types.string),
  victimReleaseTypes: types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
  hasExperiencedTrafficking: types.maybeNull(types.integer),
  previousCaseDate: types.maybeNull(types.string),
  previousCaseCountryId: types.maybeNull(types.integer),
  previousCaseCountryParentId: types.maybeNull(types.integer),
  previousCaseCountry: types.maybeNull(types.string),
  previousCaseTotalYears: types.maybeNull(types.string),
  identifiedAsVictimPreviously: types.maybeNull(types.integer),
  identificationCountryId: types.maybeNull(types.integer),
  identificationCountry: types.maybeNull(types.string),
  identificationCountryParentId: types.maybeNull(types.integer),
  identifiedOrganization: types.maybeNull(types.optional(types.string, '')),
  assistanceProvidedInPreviousCase: types.maybeNull(types.integer),
  situationDetails: types.maybeNull(types.string),
  isIdentifiedAsVictimOfTrafficking: types.maybeNull(types.boolean),
  previousTraffickingForms: types.optional(
      types.array(GenericWithParentIdLookUpDto),
      [],
  ),
})

export interface IIndicator3_1_1DtoModel
  extends Instance<typeof Indicator3_1_1DtoModel> {}
export interface IIndicator3_1_1DtoModelSnapshotIn
  extends SnapshotIn<typeof Indicator3_1_1DtoModel> {}
export interface IIndicator3_1_1DtoModelSnapshotOut
  extends SnapshotOut<typeof Indicator3_1_1DtoModel> {}
export default Indicator3_1_1DtoModel
