import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import { apiV1 } from 'core/requests'
import {
  CreateBeneficiaryIndividualCommand,
  ControlQuestionLookUpDto,
  ICreateBeneficiaryIndividualCommandIn,
  IControlQuestionLookUpDtoSnapshotIn,
  BeneficiaryIndividualPagedDto,
  IBeneficiaryIndividualPagedDtoSnapshotIn,
  ValidateBeneficiaryIndividualQuery,
  BeneficiaryIndividualListRequestModel,
  IBeneficiaryIndividualListRequestSnapshotIn,
  IValidateBeneficiaryIndividualQuerySnapshotIn,
  ISimilarBeneficiaryIndividualInfoDtoSnapshotIn,
  SimilarBeneficiaryIndividualInfoDto,
  IAddBeneficiaryIndividualToOrganizationCommandIn,
  BeneficiaryIndividualCategoryChangeLogDto,
  IBeneficiaryIndividualCategoryChangeLogDtoIn,
  BeneficiaryActivityLogLookUpDto,
  IBeneficiaryActivityLogLookUpDtoIn,
  BeneficiaryActivitiesHistoryRequestModel,
  IBeneficiaryActivitiesHistoryRequestModelIn,
  IBeneficiaryIndividualCategoryCLRequestModelIn,
  BeneficiaryIndividualCategoryCLRequestModel,
  IBeneficiaryIndividualDetailedNameLookUpDtoIn,
  BeneficiaryIndividualDetailedNameLookUpDto,
  IUpdateBeneficiaryIndividualCategoriesCommandIn,
  UpdateBeneficiaryIndividualCategoriesCommand,
  UpdateBeneficiaryIndividualCommand,
  IUpdateBeneficiaryIndividualCommandIn,
  BeneficiaryIndividualsLookUpModel,
  IBeneficiaryIndividualsLookUpModelIn,
  IBeneficiaryIndividualsLookUpModelOut,
  IUpdateBeneficiaryActivitiesCommandIn,
  UpdateBeneficiaryActivitiesCommand,
  IBeneficiaryIndividualActivitiesParticipateRequestModelIn,
  BeneficiaryIndividualActivitiesParticipateRequestModel,
  ActivitiesParticipateLookUpModel,
  IActivitiesParticipateLookUpModelIn,
  BeneficiaryServiceHistoryLookUpDto,
  IBeneficiaryServiceHistoryLookUpDtoIn,
  IBeneficiaryServiceHistoryRequestModelIn,
  BeneficiaryServiceHistoryRequestModel,
  ICreateServiceHistoryCommandSnapshotIn,
  CreateServiceHistoryCommand,
} from 'stores/models'

const PartnersBeneficiaryIndividualStore = types
  .model('PartnersBeneficiaryIndividualStore')
  .props({
    _isLoading: types.optional(types.boolean, false),
    _questionsList: types.array(ControlQuestionLookUpDto),
    _validatedBeneficiaryIndividualList: types.optional(
      types.array(SimilarBeneficiaryIndividualInfoDto),
      [],
    ),
    _beneficiaryIndividualList: types.optional(
      BeneficiaryIndividualPagedDto,
      {},
    ),
    _beneficiaryIndividualDetails: types.optional(
      BeneficiaryIndividualDetailedNameLookUpDto,
      {},
    ),
    _beneficiaryIndividualsLookUps: types.array(
      BeneficiaryIndividualsLookUpModel,
    ),
    _isDetailsLoading: types.optional(types.boolean, false),
    _categoryHistory: types.array(BeneficiaryIndividualCategoryChangeLogDto),
    _activitiesHistory: types.array(BeneficiaryActivityLogLookUpDto),
    _serviceHistory: types.array(BeneficiaryServiceHistoryLookUpDto),
    _activitiesCanParticipateList: types.array(
      ActivitiesParticipateLookUpModel,
    ),
    _isCategoryHistoryLoading: types.optional(types.boolean, false),
    _isActivitiesHistoryLoading: types.optional(types.boolean, false),
    _isServiceHistoryLoading: types.optional(types.boolean, false),
  })
  .actions(self => {
    const BENEFICIARY_INDIVIDUAL_API = '/api/partners/beneficiaryindividuals'
    const BENEFICIARY_INDIVIDUAL_SERVICE_HISTORY_API = `${BENEFICIARY_INDIVIDUAL_API}/servicehistories`

    const setQuestionsList = (
      questionsList: Array<IControlQuestionLookUpDtoSnapshotIn>,
    ): void => {
      applySnapshot(self._questionsList, questionsList)
    }

    const setBeneficiaryIndividualList = (
      beneficiaryIndividualList: IBeneficiaryIndividualPagedDtoSnapshotIn,
    ): void => {
      applySnapshot(self._beneficiaryIndividualList, beneficiaryIndividualList)
    }

    const setBeneficiaryIndividualDetails = (
      beneficiaryIndividualDetails: IBeneficiaryIndividualDetailedNameLookUpDtoIn,
    ): void => {
      applySnapshot(
        self._beneficiaryIndividualDetails,
        beneficiaryIndividualDetails,
      )
    }

    const setIsCategoryHistoryLoading = (isLoading: boolean) => {
      self._isCategoryHistoryLoading = isLoading
    }
    const setIsActivitiesHistoryLoading = (isLoading: boolean) => {
      self._isActivitiesHistoryLoading = isLoading
    }
    const setIsServiceHistoryLoading = (isLoading: boolean) => {
      self._isServiceHistoryLoading = isLoading
    }
    const setIsDetailsLoading = (isLoading: boolean) => {
      self._isDetailsLoading = isLoading
    }

    const setCategoryHistory = (
      categoryHistory: Array<IBeneficiaryIndividualCategoryChangeLogDtoIn>,
    ): void => {
      applySnapshot(self._categoryHistory, categoryHistory)
    }
    const setActivitiesParticipateList = (
      activitiesList: Array<IActivitiesParticipateLookUpModelIn>,
    ): void => {
      applySnapshot(self._activitiesCanParticipateList, activitiesList)
    }
    const setActivitiesHistory = (
      activityHistory: Array<IBeneficiaryActivityLogLookUpDtoIn>,
    ): void => {
      applySnapshot(self._activitiesHistory, activityHistory)
    }
    const setServiceHistory = (
      serviceHistory: Array<IBeneficiaryServiceHistoryLookUpDtoIn>,
    ): void => {
      applySnapshot(self._serviceHistory, serviceHistory)
    }

    const getActivitiesParticipate = async (
      params: IBeneficiaryIndividualActivitiesParticipateRequestModelIn,
    ) => {
      const activitiesCanParticipateRequest = fromPromise(
        apiV1.get<Array<IActivitiesParticipateLookUpModelIn>>(
          `${BENEFICIARY_INDIVIDUAL_API}/activities/list`,
          {
            params: BeneficiaryIndividualActivitiesParticipateRequestModel.create(
              params,
            ),
          },
        ),
      )
      when(() =>
        activitiesCanParticipateRequest.case({
          fulfilled: response => {
            setActivitiesParticipateList(response.data)
            return true
          },
        }),
      )
    }

    const getActivitiesHistory = async (
      params: IBeneficiaryActivitiesHistoryRequestModelIn,
    ) => {
      const activitiesHistoryRequest = fromPromise(
        apiV1.get<Array<IBeneficiaryActivityLogLookUpDtoIn>>(
          `${BENEFICIARY_INDIVIDUAL_API}/activities/history`,
          {
            params: BeneficiaryActivitiesHistoryRequestModel.create(params),
          },
        ),
      )
      when(() =>
        activitiesHistoryRequest.case({
          fulfilled: response => {
            setActivitiesHistory(response.data)
            return true
          },
        }),
      )
      return activitiesHistoryRequest
    }

    const getServiceHistory = async (
      params: IBeneficiaryServiceHistoryRequestModelIn,
    ) => {
      const serviceHistoryRequest = fromPromise(
        apiV1.get<Array<IBeneficiaryServiceHistoryLookUpDtoIn>>(
          `${BENEFICIARY_INDIVIDUAL_SERVICE_HISTORY_API}/history`,
          {
            params: BeneficiaryServiceHistoryRequestModel.create(params),
          },
        ),
      )
      when(() =>
        serviceHistoryRequest.case({
          fulfilled: response => {
            setServiceHistory(response.data)
            return true
          },
        }),
      )
      return serviceHistoryRequest
    }

    const getCategoryHistory = async (
      params: IBeneficiaryIndividualCategoryCLRequestModelIn,
    ) => {
      const categoryHistoryRequest = fromPromise(
        apiV1.get<Array<IBeneficiaryIndividualCategoryChangeLogDtoIn>>(
          `${BENEFICIARY_INDIVIDUAL_API}/categories/history`,
          {
            params: BeneficiaryIndividualCategoryCLRequestModel.create(params),
          },
        ),
      )

      when(() =>
        categoryHistoryRequest.case({
          fulfilled: response => {
            setCategoryHistory(response.data)
            return true
          },
        }),
      )
      return categoryHistoryRequest
    }

    const setValidateBeneficiaryIndividualList = (
      validatedBeneficiaryIndividualList: Array<ISimilarBeneficiaryIndividualInfoDtoSnapshotIn>,
    ): void => {
      applySnapshot(
        self._validatedBeneficiaryIndividualList,
        validatedBeneficiaryIndividualList,
      )
    }

    const setIsLoading = (isLoading: boolean) => {
      self._isLoading = isLoading
    }

    const setBeneficiaryIndividualsLookUps = (
      beneficiaryIndividualList: Array<IBeneficiaryIndividualsLookUpModelIn>,
    ): void => {
      applySnapshot(
        self._beneficiaryIndividualsLookUps,
        beneficiaryIndividualList,
      )
    }

    const getQuestionsList = async () => {
      const questionsListRequest = fromPromise(
        apiV1.get<Array<IControlQuestionLookUpDtoSnapshotIn>>(
          `${BENEFICIARY_INDIVIDUAL_API}/questions`,
        ),
      )

      when(() =>
        questionsListRequest.case({
          fulfilled: response => {
            setQuestionsList(response.data)
            return true
          },
        }),
      )
      return questionsListRequest
    }

    const getBeneficiaryIndividualList = async (
      params: Omit<IBeneficiaryIndividualListRequestSnapshotIn, 'fullName'>,
    ) => {
      const beneficiaryIndividualListRequest = fromPromise(
        apiV1.get<IBeneficiaryIndividualPagedDtoSnapshotIn>(
          `${BENEFICIARY_INDIVIDUAL_API}`,
          {
            params: BeneficiaryIndividualListRequestModel.create(params),
          },
        ),
      )

      when(() =>
        beneficiaryIndividualListRequest.case({
          fulfilled: response => {
            setBeneficiaryIndividualList(response.data)
            return true
          },
        }),
      )
      return beneficiaryIndividualListRequest
    }

    const getBeneficiaryIndividualWithNameList = async (
      params: IBeneficiaryIndividualListRequestSnapshotIn,
    ) => {
      const beneficiaryIndividualWithNameListRequest = fromPromise(
        apiV1.get<IBeneficiaryIndividualPagedDtoSnapshotIn>(
          `${BENEFICIARY_INDIVIDUAL_API}/withfullname`,
          {
            params: BeneficiaryIndividualListRequestModel.create(params),
          },
        ),
      )

      when(() =>
        beneficiaryIndividualWithNameListRequest.case({
          fulfilled: response => {
            setBeneficiaryIndividualList(response.data)
            return true
          },
        }),
      )
      return beneficiaryIndividualWithNameListRequest
    }

    const getBeneficiaryIndividualDetails = async (id: number) => {
      const beneficiaryIndividualDetailsRequest = fromPromise(
        apiV1.get<IBeneficiaryIndividualDetailedNameLookUpDtoIn>(
          `${BENEFICIARY_INDIVIDUAL_API}/details`,
          {
            params: { id },
          },
        ),
      )

      when(() =>
        beneficiaryIndividualDetailsRequest.case({
          fulfilled: response => {
            setBeneficiaryIndividualDetails(response.data)
            return true
          },
        }),
      )
      return beneficiaryIndividualDetailsRequest
    }

    const getBeneficiaryIndividualDetailsWithName = async (id: number) => {
      const beneficiaryIndividualDetailsWithNameRequest = fromPromise(
        apiV1.get<IBeneficiaryIndividualDetailedNameLookUpDtoIn>(
          `${BENEFICIARY_INDIVIDUAL_API}/details/withfullname`,
          {
            params: { id },
          },
        ),
      )

      when(() =>
        beneficiaryIndividualDetailsWithNameRequest.case({
          fulfilled: response => {
            setBeneficiaryIndividualDetails(response.data)
            return true
          },
        }),
      )
      return beneficiaryIndividualDetailsWithNameRequest
    }

    const validateBeneficiaryIndividual = (
      beneficiaryIndividual: IValidateBeneficiaryIndividualQuerySnapshotIn,
    ) => {
      const validatedBeneficiaryIndividual = fromPromise(
        apiV1.get<Array<ISimilarBeneficiaryIndividualInfoDtoSnapshotIn>>(
          `${BENEFICIARY_INDIVIDUAL_API}/validate`,
          {
            params: ValidateBeneficiaryIndividualQuery.create(
              beneficiaryIndividual,
            ),
          },
        ),
      )

      when(() =>
        validatedBeneficiaryIndividual.case({
          fulfilled: response => {
            setValidateBeneficiaryIndividualList(response.data)
            return true
          },
        }),
      )
      return validatedBeneficiaryIndividual
    }

    const addBeneficiaryIndividualToOrganization = async (
      beneficiaryIndividualIds: IAddBeneficiaryIndividualToOrganizationCommandIn,
    ): Promise<void> =>
      apiV1.post(
        `${BENEFICIARY_INDIVIDUAL_API}/addtoorganization`,
        beneficiaryIndividualIds,
      )

    const editBeneficiaryIndividualCategories = async (
      categoriesParams: IUpdateBeneficiaryIndividualCategoriesCommandIn,
    ): Promise<void> =>
      apiV1.put(
        `${BENEFICIARY_INDIVIDUAL_API}/categories`,
        UpdateBeneficiaryIndividualCategoriesCommand.create(categoriesParams),
        {
          params: { id: categoriesParams.id },
        },
      )

    const editBeneficiaryIndividualActivities = async (
      activitiesParams: IUpdateBeneficiaryActivitiesCommandIn,
    ): Promise<void> =>
      apiV1.put(
        `${BENEFICIARY_INDIVIDUAL_API}/activities`,
        UpdateBeneficiaryActivitiesCommand.create(activitiesParams),
        {
          params: { id: activitiesParams.id },
        },
      )

    const editBeneficiaryIndividual = async (
      beneficiaryIndividual: IUpdateBeneficiaryIndividualCommandIn,
    ): Promise<void> =>
      apiV1.put(
        BENEFICIARY_INDIVIDUAL_API,
        UpdateBeneficiaryIndividualCommand.create(beneficiaryIndividual),
        {
          params: { id: beneficiaryIndividual.id },
        },
      )

    const createBeneficiaryIndividual = async (
      beneficiaryIndividual: ICreateBeneficiaryIndividualCommandIn,
    ): Promise<void> =>
      apiV1.post(
        BENEFICIARY_INDIVIDUAL_API,
        CreateBeneficiaryIndividualCommand.create(beneficiaryIndividual),
      )

    const getBeneficiaryIndividualListLookUps = async (
      organizationId: number,
    ) => {
      const beneficiaryIndividualListLookUpsRequest = fromPromise(
        apiV1.get<Array<IBeneficiaryIndividualsLookUpModelIn>>(
          `${BENEFICIARY_INDIVIDUAL_API}/byorganizationid`,
          {
            params: { organizationId },
          },
        ),
      )

      when(() =>
        beneficiaryIndividualListLookUpsRequest.case({
          fulfilled: response => {
            setBeneficiaryIndividualsLookUps(response.data)
            return true
          },
        }),
      )
      return beneficiaryIndividualListLookUpsRequest
    }

    const createServiceHistory = async (
      serviceHistory: ICreateServiceHistoryCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.post(
        `${BENEFICIARY_INDIVIDUAL_SERVICE_HISTORY_API}/addservicehistory`,
        CreateServiceHistoryCommand.create(serviceHistory),
      )

    const editCategoriesDates = async (
        categoryId?: number,
        createdDateTime?: string,
        updatedDateTime?: string
    ): Promise<void> => apiV1.put(
        `${BENEFICIARY_INDIVIDUAL_API}/categories/dates`,
        { categoryId, createdDateTime, updatedDateTime },
        {
          params: { id: categoryId }
        }
    )

    const editCategory = async (
        id?: number,
        categoryId?: number | null
    ): Promise<void> => apiV1.put(
        `${BENEFICIARY_INDIVIDUAL_API}/category`,
        {id, categoryId},
        {
          params: {  id: id  }
        }
    )
    return {
      createBeneficiaryIndividual,
      getQuestionsList,
      getBeneficiaryIndividualList,
      getBeneficiaryIndividualWithNameList,
      validateBeneficiaryIndividual,
      addBeneficiaryIndividualToOrganization,
      setValidateBeneficiaryIndividualList,
      setIsLoading,
      getBeneficiaryIndividualDetails,
      setIsDetailsLoading,
      getCategoryHistory,
      getActivitiesHistory,
      getActivitiesParticipate,
      getBeneficiaryIndividualDetailsWithName,
      setIsCategoryHistoryLoading,
      setIsActivitiesHistoryLoading,
      editBeneficiaryIndividualCategories,
      editBeneficiaryIndividualActivities,
      editBeneficiaryIndividual,
      getBeneficiaryIndividualListLookUps,
      getServiceHistory,
      setIsServiceHistoryLoading,
      createServiceHistory,
      editCategoriesDates,
      editCategory
    }
  })
  .views(self => ({
    get isLoading() {
      return self._isLoading
    },
    get questionsList() {
      return getSnapshot(self._questionsList)
    },
    get beneficiaryIndividual() {
      return getSnapshot(self._beneficiaryIndividualList)
    },
    get beneficiaryIndividualList() {
      return this.beneficiaryIndividual.data
    },
    get beneficiaryIndividualMetadata() {
      return this.beneficiaryIndividual.metadata
    },
    get validatedBeneficiaryIndividualList() {
      return getSnapshot(self._validatedBeneficiaryIndividualList)
    },
    get beneficiaryIndividualDetails() {
      return getSnapshot(self._beneficiaryIndividualDetails)
    },
    get activitiesHistory() {
      return getSnapshot(self._activitiesHistory)
    },
    get activitiesCanParticipateList() {
      return getSnapshot(self._activitiesCanParticipateList)
    },
    get isDetailsLoading() {
      return self._isDetailsLoading
    },
    get categoryHistory() {
      return getSnapshot(self._categoryHistory)
    },
    get isCategoryHistoryLoading() {
      return self._isCategoryHistoryLoading
    },
    get isActivitiesHistoryLoading() {
      return self._isActivitiesHistoryLoading
    },
    get isServiceHistoryLoading() {
      return self._isServiceHistoryLoading
    },
    get serviceHistory() {
      return getSnapshot(self._serviceHistory)
    },
    get beneficiaryIndividualListLookUps(): Array<IBeneficiaryIndividualsLookUpModelOut> {
      return getSnapshot(self._beneficiaryIndividualsLookUps)
    },
  }))

export default PartnersBeneficiaryIndividualStore
export interface IPartnersBeneficiaryIndividualStore
  extends Instance<typeof PartnersBeneficiaryIndividualStore> {}
