import { FC, FocusEventHandler, ReactNode, useEffect } from 'react'
import { CustomSelect } from 'components'
import { useStore } from 'stores/useStoreHook'
import { IBeneficiaryCategoryLookUpSnapshotOut } from 'stores/models'
import { observer } from 'mobx-react'

type BeneficiaryIndividualCategoriesSelectionProps = {
  label: string
  isOpen: boolean
  categoryIds: Array<number>
  placeholder: string
  name: string
  onChange: (
    categories: readonly IBeneficiaryCategoryLookUpSnapshotOut[],
  ) => void
  handleBlur: FocusEventHandler
  errorMessage: ReactNode
}

const BeneficiaryIndividualCategoriesSelection: FC<BeneficiaryIndividualCategoriesSelectionProps> = ({
  label,
  placeholder,
  name,
  isOpen,
  onChange,
  categoryIds,
  handleBlur,
  errorMessage,
}) => {
  const lookUpStore = useStore('lookUpStore')

  useEffect(() => {
    if (isOpen) {
      lookUpStore.getExcludedBeneficiaryCategories()
    }
  }, [isOpen, lookUpStore])

  return (
    <CustomSelect
      isMulti
      closeMenuOnSelect={false}
      label={label}
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      onBlur={handleBlur}
      options={lookUpStore.excludedBeneficiaryCategories.categories.map(
        beneficiary =>
          lookUpStore.excludedBeneficiaryCategories.excludedCategories.some(
            exclusion => {
              if (
                beneficiary.id === exclusion.memberTypeId ||
                beneficiary.id === exclusion.excludedMemberTypeId
              ) {
                return (
                  categoryIds.includes(exclusion.memberTypeId) ||
                  categoryIds.includes(exclusion.excludedMemberTypeId)
                )
              }
              return false
            },
          )
            ? { ...beneficiary, isDisabled: true }
            : beneficiary,
      )}
      value={lookUpStore.excludedBeneficiaryCategories.categories.filter(el =>
        categoryIds.includes(el.id),
      )}
      getOptionLabel={category => category.name || ''}
      getOptionValue={category => `${category.id}`}
      errorMessage={errorMessage}
    />
  )
}

export default observer(BeneficiaryIndividualCategoriesSelection)
