import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import IndicatorTargetYearsDto from './IndicatorTargetYearsDto'

const IndicatorTargetsDto = types.model('IndicatorTargetsDto', {
  id: types.optional(types.integer, 0),
  indicatorId: types.optional(types.integer, 0),
  isPercent: types.optional(types.boolean, false),
  indicatorTargetYearsDto: types.array(IndicatorTargetYearsDto),
})

export interface IIndicatorTargetsDto
  extends Instance<typeof IndicatorTargetsDto> {}
export interface IIndicatorTargetsDtoSnapshotIn
  extends SnapshotIn<typeof IndicatorTargetsDto> {}
export interface IIndicatorTargetsDtoSnapshotOut
  extends SnapshotOut<typeof IndicatorTargetsDto> {}
export default IndicatorTargetsDto
