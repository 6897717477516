import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import IndicatorReportsFilterBaseModel from '../IndicatorReportsFilterBaseModel'

const Indicator4_1_1ReportFilterModel = IndicatorReportsFilterBaseModel.named(
  'Indicator4_1_1ReportFilterModel',
).props({
  GenderId: types.union(types.number, types.undefined, types.null),
  RegionId: types.union(types.number, types.undefined, types.null),
  MemberTypeId: types.union(types.number, types.undefined, types.null),
})

export interface IIndicator4_1_1ReportFilterModel
  extends Instance<typeof Indicator4_1_1ReportFilterModel> {}

export interface IIndicator4_1_1ReportFilterSnapshotIn
  extends SnapshotIn<typeof Indicator4_1_1ReportFilterModel> {}

export interface IIndicator4_1_1ReportFilterSnapshotOut
  extends SnapshotOut<typeof Indicator4_1_1ReportFilterModel> {}

export default Indicator4_1_1ReportFilterModel
