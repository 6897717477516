import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const RegionLookUpModel = LookUpModel.named('RegionLookUpModel')

export interface IRegionLookUp extends Instance<typeof RegionLookUpModel> {}
export interface IRegionLookUpSnapshotIn
  extends SnapshotIn<typeof RegionLookUpModel> {}
export interface IRegionLookUpSnapshotOut
  extends SnapshotOut<typeof RegionLookUpModel> {}
export default RegionLookUpModel
