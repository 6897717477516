import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../../../PagedListRequestModel'
import IndicatorVulnerableMigrantReportFilterModel from "./IndicatorVulnerableMigrantReportFilterModel";

const IndicatorVulnerableMigrantReportRequestModel = PagedListRequestModel.named(
    'IndicatorVulnerableMigrantReportRequestModel',
).props({
    sortBy: types.optional(types.string, ''),
    filter: types.optional(IndicatorVulnerableMigrantReportFilterModel,{}),
})

export  interface IIndicatorVulnerableMigrantReportRequestModel
    extends Instance<typeof IndicatorVulnerableMigrantReportRequestModel> {}

export interface IIndicatorVulnerableMigrantReportRequestSnapshotIn
    extends SnapshotIn<typeof IndicatorVulnerableMigrantReportRequestModel> {}

export interface IIndicatorVulnerableMigrantReportRequestSnapshotOut
    extends SnapshotOut<typeof IndicatorVulnerableMigrantReportRequestModel> {}

export default IndicatorVulnerableMigrantReportRequestModel