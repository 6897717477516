import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const ActivityTestResultModel = types.model('ActivityTestResultModel', {
  id: types.optional(types.number, 0),
  code: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  preTestCorrectAnswerCount: types.maybeNull(types.number),
  preTestScore: types.maybeNull(types.number),
  postTestCorrectAnswerCount: types.maybeNull(types.number),
  postTestScore: types.maybeNull(types.number),
})

export interface IActivityTestResultModel
  extends Instance<typeof ActivityTestResultModel> {}

export interface IActivityTestResultModelIn
  extends SnapshotIn<typeof ActivityTestResultModel> {}

export interface IActivityTestResultModelOut
  extends SnapshotOut<typeof ActivityTestResultModel> {}

export default ActivityTestResultModel
