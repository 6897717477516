import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const CountryLookUpModel = LookUpModel.named('CountryLookUpModel').props({
  isComment: types.boolean,
})

export interface ICountryLookUpModel
  extends Instance<typeof CountryLookUpModel> {}
export interface ICountryLookUpSnapshotIn
  extends SnapshotIn<typeof CountryLookUpModel> {}
export interface ICountryLookUpSnapshotOut
  extends SnapshotOut<typeof CountryLookUpModel> {}
export default CountryLookUpModel
