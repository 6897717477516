import { CSSProperties, FC, ReactElement, ReactNode } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import classes from './styles.module.scss'
import { OverlayTriggerRenderProps } from 'react-bootstrap/OverlayTrigger'

type OverlayProps = {
  text: string
  children: ReactElement | ((props: OverlayTriggerRenderProps) => ReactNode)
  popoverStyles?: CSSProperties
}

const Overlay: FC<OverlayProps> = ({ text, children, popoverStyles }) => (
  <OverlayTrigger
    overlay={
      <Popover className={classes.overlay} id={text} style={popoverStyles}>
        <Popover.Content>{text}</Popover.Content>
      </Popover>
    }
  >
    {children}
  </OverlayTrigger>
)

export default Overlay
