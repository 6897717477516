import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const CreateIndicator2_2_3Command = types.model('CreateIndicator2_2_3Command', {
  creatorOrganizationId: types.integer,
  organizationId: types.integer,
  recordDate: types.maybeNull(types.string),
  countryId: types.integer,
  countryComment: types.optional(types.string, ''),
  regionId: types.maybeNull(types.integer),
  legalEntityTypeId: types.integer,
  legalEntityTypeComment: types.maybeNull(types.string),
  industryTypeId: types.integer,
  industryTypeComment: types.maybeNull(types.string),
  businessScaleId: types.integer,
  supportTypeIds: types.array(types.integer),
  supportTypeComment: types.maybeNull(types.string),
  collaborationAreaIds: types.array(types.integer),
  collaborationAreaComment: types.maybeNull(types.string),
  hasSupportedDocuments: types.boolean,
})

export interface ICreateIndicator2_2_3Command
  extends Instance<typeof CreateIndicator2_2_3Command> {}
export interface ICreateIndicator2_2_3CommandSnapshotIn
  extends SnapshotIn<typeof CreateIndicator2_2_3Command> {}
export interface ICreateIndicator2_2_3CommandSnapshotOut
  extends SnapshotOut<typeof CreateIndicator2_2_3Command> {}
export default CreateIndicator2_2_3Command
