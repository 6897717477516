import { Dropdown } from 'react-bootstrap'
import { useIntlContext } from 'localization'
import { useStore } from 'stores/useStoreHook'
import CustomDropdownToggle from '../CustomDropdownToggle'
import './LanguageDropdown.scss'

const LanguageDropdown = () => {
  const appStore = useStore('appStore')
  const { switchToEnglish, switchToRussian } = useIntlContext()

  return (
    <Dropdown className="lang-dropdown">
      <Dropdown.Toggle
        as={CustomDropdownToggle}
        name={appStore.currentLanguage}
        className="lang-dropdown-toggle"
      />
      <Dropdown.Menu>
        <Dropdown.Item onClick={switchToEnglish}>English</Dropdown.Item>
        <Dropdown.Item onClick={switchToRussian}>Русский</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LanguageDropdown
