import { FC, useState } from 'react'
import classes from './style.module.scss'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl'
import ToolbarButton from '../../ToolbarButton'
import Overlay from '../../Overlay'
import Loader from '../../Loader'

type StatusButtonsProps = {
  checkClick: () => Promise<void> | void
  rejectClick: () => Promise<void> | void
  overlayText?: MessageDescriptor['id']
  isToolbar?: boolean
  isDisabled?: boolean
  stopPropagation?: boolean
  showOnlyOnHover?: boolean
}

const StatusButtons: FC<StatusButtonsProps> = ({
  checkClick,
  rejectClick,
  overlayText,
  isToolbar = false,
  isDisabled = false,
  stopPropagation = false,
  showOnlyOnHover = false,
}) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [isCheck, setIsCheck] = useState(false)
  const [isPendingRequest, setIsPendingRequest] = useState(false)
  const intl = useIntl()

  const handleConfirm = async () => {
    setIsPendingRequest(true)
    await (isCheck ? checkClick() : rejectClick())
    setIsConfirmationOpen(false)
    setIsPendingRequest(false)
  }

  return (
    <>
      {isToolbar ? (
        <div className="d-flex">
          <ToolbarButton
            disabled={isDisabled}
            action={() => {
              setIsConfirmationOpen(true)
              setIsCheck(true)
            }}
            iconClassName={[
              'ms-Icon ms-Icon--CheckMark',
              classes.check,
              classes.statusIcon,
            ].join(' ')}
          >
            <FormattedMessage id="Approve" defaultMessage="Одобрить" />
          </ToolbarButton>
          <ToolbarButton
            disabled={isDisabled}
            action={() => {
              setIsConfirmationOpen(true)
              setIsCheck(false)
            }}
            iconClassName={[
              'ms-Icon ms-Icon--StatusCircleBlock2',
              classes.reject,
              classes.statusIcon,
            ].join(' ')}
          >
            <FormattedMessage id="Reject" defaultMessage="Отклонить" />
          </ToolbarButton>
        </div>
      ) : (
        <span
          className={[
            classes.statusButtonsWrapper,
            showOnlyOnHover ? 'showOnlyWhenHovered' : 'showWhenHovered',
          ].join(' ')}
        >
          <Overlay
            text={
              overlayText
                ? intl.formatMessage({
                    id: overlayText,
                  })
                : ' '
            }
          >
            <i
              className={[
                'ms-Icon ms-Icon--CheckMark',
                classes.check,
                classes.statusIcon,
              ].join(' ')}
              onClick={event => {
                setIsConfirmationOpen(true)
                setIsCheck(true)
                stopPropagation && event.stopPropagation()
              }}
            />
          </Overlay>
          <Overlay
            text={intl.formatMessage({
              id: 'Reject',
              defaultMessage: 'Отклонить',
            })}
          >
            <i
              className={[
                'ms-Icon ms-Icon--StatusCircleBlock2',
                classes.reject,
                classes.statusIcon,
              ].join(' ')}
              onClick={event => {
                setIsConfirmationOpen(true)
                setIsCheck(false)
                stopPropagation && event.stopPropagation()
              }}
            />
          </Overlay>
        </span>
      )}
      <Modal
        centered
        onHide={() => setIsConfirmationOpen(false)}
        show={isConfirmationOpen}
        onClick={(e: MouseEvent) => e.stopPropagation()}
      >
        {isPendingRequest && <Loader isOverlay />}
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id={`global.${isCheck ? 'approval' : 'rejection'}`}
              defaultMessage={isCheck ? 'Одобрение' : 'Отклонение'}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <FormattedMessage
              id="global.confirmation"
              defaultMessage="Вы уверены, что хотите выполнить это действие?"
            />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleConfirm}>
            <FormattedMessage
              id="global.confirm"
              defaultMessage="Подтвердить"
            />
          </Button>
          <Button
            onClick={() => setIsConfirmationOpen(false)}
            variant="secondary"
          >
            <FormattedMessage id="global.cancel" defaultMessage="Отменить" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default StatusButtons
