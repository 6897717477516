import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import { apiV1 } from 'core/requests'
import {
  DocumentTypeLookUpModel,
  IDocumentTypeLookUpSnapshotIn,
  IAdvocacyIssueLookUpSnapshotIn,
  AdvocacyIssueLookUpModel,
  InfluenceTypeLookUpModel,
  IInfluenceTypeLookUpSnapshotIn,
  AdvocacyInfluenceLevelLookUpModel,
  IAdvocacyInfluenceLevelLookUpSnapshotIn,
  DevelopmentStageLookUpModel,
  IDevelopmentStageLookUpSnapshotIn,
  AdvocacyTypeLookUpModel,
  IAdvocacyTypeLookUpSnapshotIn,
  IOrganizationTypesLookUpIn,
  OrganizationTypesLookUpModel,
  ImprovementAreaLookUpModel,
  IImprovementAreaLookUpSnapshotIn,
  SupportTypeLookUpModel,
  ISupportTypeLookUpSnapshotIn,
  DataTypeLookUpModel,
  DataFrequencyLookUpModel,
  IDataTypeLookUpSnapshotIn,
  IDataFrequencyLookUpSnapshotIn,
  CSOTypeLookUpModel,
  ICSOTypeLookUpSnapshotIn,
  CollaborationAreaLookUpModel,
  BusinessScaleLookUpModel,
  IndustryTypeLookUpModel,
  IBusinessScaleLookUpSnapshotIn,
  IIndustryTypeLookUpSnapshotIn,
  ICollaborationAreaLookUpSnapshotIn,
  LegalEntityTypeLookUpModel,
  ILegalEntityTypeLookUpSnapshotIn,
  IdentifyingAgencyLookUpModel,
  TIPCaseStatusLookUpModel,
  TraffickingFormLookUpModel,
  IIdentifyingAgencyLookUpIn,
  ITraffickingFormLookUpIn,
  GenderLookUpModel,
  BeneficiaryCategoryLookUpModel,
  IGenderLookUpSnapshotIn,
  IBeneficiaryCategoryLookUpSnapshotIn,
  ActivityTypeLookUpModel,
  IActivityTypeLookUpSnapshotIn,
  OrganizationServiceTypesLookUpModel,
  IOrganizationServiceTypesLookUpSnapshotIn,
} from 'stores/models'
import { ITIPCaseStatusLookUpIn } from 'stores/models/lookUps/TIPCaseStatusLookUpModel'

const DictionaryByIndicatorStore = types
  .model('DictionaryByIndicatorStore')
  .props({
    _documentTypesLookUps: types.array(DocumentTypeLookUpModel),
    _advocacyIssuesLookUps: types.array(AdvocacyIssueLookUpModel),
    _influenceTypesLookUps: types.array(InfluenceTypeLookUpModel),
    _advocacyInfluenceLevelLookUps: types.array(
      AdvocacyInfluenceLevelLookUpModel,
    ),
    _developmentStagesLookUps: types.array(DevelopmentStageLookUpModel),
    _advocacyTypesLookUps: types.array(AdvocacyTypeLookUpModel),
    _improvementAreasLookUps: types.array(ImprovementAreaLookUpModel),
    _organizationTypesLookUps: types.array(OrganizationTypesLookUpModel),
    _supportTypesLookUps: types.array(SupportTypeLookUpModel),
    _dataTypesLookUps: types.array(DataTypeLookUpModel),
    _dataFrequencyLookUps: types.array(DataFrequencyLookUpModel),
    _csoTypeLookUps: types.array(CSOTypeLookUpModel),
    _collaborationAreaLookUps: types.array(CollaborationAreaLookUpModel),
    _businessScaleLookUps: types.array(BusinessScaleLookUpModel),
    _industryTypeLookUps: types.array(IndustryTypeLookUpModel),
    _legalEntityTypeLookUps: types.array(LegalEntityTypeLookUpModel),
    _identifyingAgencyLookUps: types.array(IdentifyingAgencyLookUpModel),
    _tipCaseStatusLookUps: types.array(TIPCaseStatusLookUpModel),
    _traffickingFormLookUps: types.array(TraffickingFormLookUpModel),
    _gendersLookUps: types.array(GenderLookUpModel),
    _memberTypesLookUps: types.array(BeneficiaryCategoryLookUpModel),
    _activityTypesLookUps: types.array(ActivityTypeLookUpModel),
    _serviceTypesLookUps: types.array(OrganizationServiceTypesLookUpModel),
  })
  .actions(self => ({
    setDocumentTypesLookUps: (
      documentTypesList: Array<IDocumentTypeLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._documentTypesLookUps, documentTypesList)
    },
    setAdvocacyIssuesLookUps: (
      advocacyIssuesList: Array<IAdvocacyIssueLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._advocacyIssuesLookUps, advocacyIssuesList)
    },
    setInfluenceTypesLookUps: (
      influenceTypesList: Array<IInfluenceTypeLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._influenceTypesLookUps, influenceTypesList)
    },
    setAdvocacyInfluenceLevelLookUps: (
      advocacyInfluenceLevelList: Array<IAdvocacyInfluenceLevelLookUpSnapshotIn>,
    ): void => {
      applySnapshot(
        self._advocacyInfluenceLevelLookUps,
        advocacyInfluenceLevelList,
      )
    },
    setDevelopmentStagesLookUps: (
      developmentStagesList: Array<IDevelopmentStageLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._developmentStagesLookUps, developmentStagesList)
    },
    setAdvocacyTypesLookUps: (
      advocacyTypesList: Array<IAdvocacyTypeLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._advocacyTypesLookUps, advocacyTypesList)
    },
    setOrganizationTypesLookUps: (
      organizationTypesList: Array<IOrganizationTypesLookUpIn>,
    ): void => {
      applySnapshot(self._organizationTypesLookUps, organizationTypesList)
    },
    setSupportTypesLookUps: (
      supportTypesList: Array<ISupportTypeLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._supportTypesLookUps, supportTypesList)
    },
    setImprovementAreasLookUps: (
      improvementAreasList: Array<IImprovementAreaLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._improvementAreasLookUps, improvementAreasList)
    },
    setDataTypesLookUps: (
      dataTypesList: Array<IDataTypeLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._dataTypesLookUps, dataTypesList)
    },
    setDataFrequencyLookUps: (
      dataFrequencyList: Array<IDataFrequencyLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._dataFrequencyLookUps, dataFrequencyList)
    },
    setCSOTypeLookUps: (
      csoTypesList: Array<ICSOTypeLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._csoTypeLookUps, csoTypesList)
    },
    setCollaborationAreaLookUps: (
      collaborationAreaList: Array<ICollaborationAreaLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._collaborationAreaLookUps, collaborationAreaList)
    },
    setBusinessScaleLookUps: (
      businessScaleList: Array<IBusinessScaleLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._businessScaleLookUps, businessScaleList)
    },
    setIndustryTypeLookUps: (
      industryTypeList: Array<IIndustryTypeLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._industryTypeLookUps, industryTypeList)
    },
    setLegalEntityTypeLookUps: (
      legalEntityTypeList: Array<ILegalEntityTypeLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._legalEntityTypeLookUps, legalEntityTypeList)
    },
    setIdentifyingAgencyLookUps: (
      identifyingAgencyList: Array<IIdentifyingAgencyLookUpIn>,
    ): void => {
      applySnapshot(self._identifyingAgencyLookUps, identifyingAgencyList)
    },
    setTIPCaseStatusLookUps: (
      tipCaseStatusList: Array<ITIPCaseStatusLookUpIn>,
    ): void => {
      applySnapshot(self._tipCaseStatusLookUps, tipCaseStatusList)
    },
    setTraffickingFormLookUps: (
      traffickingFormList: Array<ITraffickingFormLookUpIn>,
    ): void => {
      applySnapshot(self._traffickingFormLookUps, traffickingFormList)
    },
    setGendersLookUps: (gendersList: Array<IGenderLookUpSnapshotIn>): void => {
      applySnapshot(self._gendersLookUps, gendersList)
    },
    setMemberTypesLookUps: (
      memberTypesList: Array<IBeneficiaryCategoryLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._memberTypesLookUps, memberTypesList)
    },
    setActivityTypesLookUps: (
      activityTypesLookUps: Array<IActivityTypeLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._activityTypesLookUps, activityTypesLookUps)
    },
    setServiceTypesLookUps: (
      serviceTypesLookUps: Array<IOrganizationServiceTypesLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._serviceTypesLookUps, serviceTypesLookUps)
    },
  }))
  .actions(self => {
    const DICTIONARY_BY_INDICATOR_API = '/api/dictionarybyindicator'

    const getDocumentTypesLookUps = async (indicatorId: number) => {
      const documentTypesLookUpsRequest = fromPromise(
        apiV1.get<Array<IDocumentTypeLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/documenttypes`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        documentTypesLookUpsRequest.case({
          fulfilled: response => {
            self.setDocumentTypesLookUps(response.data)
            return true
          },
        }),
      )
      return documentTypesLookUpsRequest
    }

    const getAdvocacyIssuesLookUps = async (indicatorId: number) => {
      const advocacyIssuesLookUpsRequest = fromPromise(
        apiV1.get<Array<IAdvocacyIssueLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/advocacyIssues`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        advocacyIssuesLookUpsRequest.case({
          fulfilled: response => {
            self.setAdvocacyIssuesLookUps(response.data)
            return true
          },
        }),
      )
      return advocacyIssuesLookUpsRequest
    }

    const getInfluenceTypesLookUps = async (indicatorId: number) => {
      const influenceTypesLookUpsRequest = fromPromise(
        apiV1.get<Array<IInfluenceTypeLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/influencetypes`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        influenceTypesLookUpsRequest.case({
          fulfilled: response => {
            self.setInfluenceTypesLookUps(response.data)
            return true
          },
        }),
      )
      return influenceTypesLookUpsRequest
    }

    const getAdvocacyInfluenceLevelLookUps = async (indicatorId: number) => {
      const advocacyInfluenceLevelLookUpsRequest = fromPromise(
        apiV1.get<Array<IAdvocacyInfluenceLevelLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/advocacyinfluencelevels`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        advocacyInfluenceLevelLookUpsRequest.case({
          fulfilled: response => {
            self.setAdvocacyInfluenceLevelLookUps(response.data)
            return true
          },
        }),
      )
      
      return advocacyInfluenceLevelLookUpsRequest
    }

    const getDevelopmentStageLookUps = async (indicatorId: number) => {
      const developmentStageLookUpsRequest = fromPromise(
        apiV1.get<Array<IDevelopmentStageLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/developmentstages`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        developmentStageLookUpsRequest.case({
          fulfilled: response => {
            self.setDevelopmentStagesLookUps(response.data)
            return true
          },
        }),
      )
      return developmentStageLookUpsRequest
    }

    const getAdvocacyTypesLookUps = async (indicatorId: number) => {
      const advocacyTypesLookUpsRequest = fromPromise(
        apiV1.get<Array<IAdvocacyTypeLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/advocacytypes`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        advocacyTypesLookUpsRequest.case({
          fulfilled: response => {
            self.setAdvocacyTypesLookUps(response.data)
            return true
          },
        }),
      )
      return advocacyTypesLookUpsRequest
    }

    const getOrganizationTypesLookUps = async (indicatorId: number) => {
      const organizationTypesLookUpsRequest = fromPromise(
        apiV1.get<Array<IOrganizationTypesLookUpIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/organizationtypes`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        organizationTypesLookUpsRequest.case({
          fulfilled: response => {
            self.setOrganizationTypesLookUps(response.data)
            return true
          },
        }),
      )
      return organizationTypesLookUpsRequest
    }

    const getSupportTypesLookUps = async (indicatorId: number) => {
      const supportTypesLookUpsRequest = fromPromise(
        apiV1.get<Array<ISupportTypeLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/supporttypes`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        supportTypesLookUpsRequest.case({
          fulfilled: response => {
            self.setSupportTypesLookUps(response.data)
            return true
          },
        }),
      )
      return supportTypesLookUpsRequest
    }

    const getImprovementAreasLookUps = async (indicatorId: number) => {
      const improvementAreasLookUpsRequest = fromPromise(
        apiV1.get<Array<IImprovementAreaLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/improvementareas`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        improvementAreasLookUpsRequest.case({
          fulfilled: response => {
            self.setImprovementAreasLookUps(response.data)
            return true
          },
        }),
      )
      return improvementAreasLookUpsRequest
    }

    const getDataTypesLookUps = async (indicatorId: number) => {
      const dataTypesLookUpsRequest = fromPromise(
        apiV1.get<Array<IDataTypeLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/datatypes`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        dataTypesLookUpsRequest.case({
          fulfilled: response => {
            self.setDataTypesLookUps(response.data)
            return true
          },
        }),
      )
      return dataTypesLookUpsRequest
    }

    const getDataFrequencyLookUps = async (indicatorId: number) => {
      const dataFrequencyLookUpsRequest = fromPromise(
        apiV1.get<Array<IDataFrequencyLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/datacollectionfrequencies`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        dataFrequencyLookUpsRequest.case({
          fulfilled: response => {
            self.setDataFrequencyLookUps(response.data)
            return true
          },
        }),
      )
      return dataFrequencyLookUpsRequest
    }

    const getCSOTypesLookUps = async (indicatorId: number) => {
      const csoTypesLookUpsRequest = fromPromise(
        apiV1.get<Array<ICSOTypeLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/csotypes`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        csoTypesLookUpsRequest.case({
          fulfilled: response => {
            self.setCSOTypeLookUps(response.data)
            return true
          },
        }),
      )
      return csoTypesLookUpsRequest
    }

    const getCollaborationAreaLookUps = async (indicatorId: number) => {
      const collaborationAreaLookUpsRequest = fromPromise(
        apiV1.get<Array<ICollaborationAreaLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/collaborationareas`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        collaborationAreaLookUpsRequest.case({
          fulfilled: response => {
            self.setCollaborationAreaLookUps(response.data)
            return true
          },
        }),
      )
      return collaborationAreaLookUpsRequest
    }

    const getBusinessScaleLookUps = async (indicatorId: number) => {
      const businessScaleLookUpsRequest = fromPromise(
        apiV1.get<Array<IBusinessScaleLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/businessscales`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        businessScaleLookUpsRequest.case({
          fulfilled: response => {
            self.setBusinessScaleLookUps(response.data)
            return true
          },
        }),
      )
      return businessScaleLookUpsRequest
    }

    const getIndustryTypeLookUps = async (indicatorId: number) => {
      const industryTypeLookUpsRequest = fromPromise(
        apiV1.get<Array<IIndustryTypeLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/industrytypes`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        industryTypeLookUpsRequest.case({
          fulfilled: response => {
            self.setIndustryTypeLookUps(response.data)
            return true
          },
        }),
      )
      return industryTypeLookUpsRequest
    }

    const getLegalEntityTypeLookUps = async (indicatorId: number) => {
      const legalEntityTypeLookUpsRequest = fromPromise(
        apiV1.get<Array<ILegalEntityTypeLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/legalentitytypes`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        legalEntityTypeLookUpsRequest.case({
          fulfilled: response => {
            self.setLegalEntityTypeLookUps(response.data)
            return true
          },
        }),
      )
      return legalEntityTypeLookUpsRequest
    }

    const getIdentifyingAgencyLookUps = async (indicatorId: number) => {
      const identifyingAgencyLookUpsRequest = fromPromise(
        apiV1.get<Array<IIdentifyingAgencyLookUpIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/identifyingagencies`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        identifyingAgencyLookUpsRequest.case({
          fulfilled: response => {
            self.setIdentifyingAgencyLookUps(response.data)
            return true
          },
        }),
      )
      return identifyingAgencyLookUpsRequest
    }

    const getTIPCaseStatusLookUps = async (indicatorId: number) => {
      const tipCaseStatusLookUpsRequest = fromPromise(
        apiV1.get<Array<ITIPCaseStatusLookUpIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/tipcasestatuses`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        tipCaseStatusLookUpsRequest.case({
          fulfilled: response => {
            self.setTIPCaseStatusLookUps(response.data)
            return true
          },
        }),
      )
      return tipCaseStatusLookUpsRequest
    }

    const getTraffickingFormLookUps = async (indicatorId: number) => {
      const traffickingFormLookUpsRequest = fromPromise(
        apiV1.get<Array<ITraffickingFormLookUpIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/traffickingforms`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        traffickingFormLookUpsRequest.case({
          fulfilled: response => {
            self.setTraffickingFormLookUps(response.data)
            return true
          },
        }),
      )
      return traffickingFormLookUpsRequest
    }

    const getGendersLookUps = async (indicatorId: number) => {
      const gendersLookUpsRequest = fromPromise(
        apiV1.get<Array<IGenderLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/genders`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        gendersLookUpsRequest.case({
          fulfilled: response => {
            self.setGendersLookUps(response.data)
            return true
          },
        }),
      )
      return gendersLookUpsRequest
    }

    const getMemberTypesLookUps = async (indicatorId: number) => {
      const memberTypesLookUpsRequest = fromPromise(
        apiV1.get<Array<IBeneficiaryCategoryLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/membertypes`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        memberTypesLookUpsRequest.case({
          fulfilled: response => {
            self.setMemberTypesLookUps(response.data)
            return true
          },
        }),
      )
      return memberTypesLookUpsRequest
    }

    const getActivityTypesLookUps = async (indicatorId: number) => {
      const activityTypesRequest = fromPromise(
        apiV1.get<Array<IActivityTypeLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/activitytypes`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        activityTypesRequest.case({
          fulfilled: response => {
            self.setActivityTypesLookUps(response.data)
            return true
          },
        }),
      )
      return activityTypesRequest
    }

    const getServiceTypesLookUps = async (indicatorId: number) => {
      const serviceTypesRequest = fromPromise(
        apiV1.get<Array<IOrganizationServiceTypesLookUpSnapshotIn>>(
          `${DICTIONARY_BY_INDICATOR_API}/servicetypes`,
          {
            params: {
              indicatorId,
            },
          },
        ),
      )

      when(() =>
        serviceTypesRequest.case({
          fulfilled: response => {
            self.setServiceTypesLookUps(response.data)
            return true
          },
        }),
      )
      return serviceTypesRequest
    }

    return {
      getDocumentTypesLookUps,
      getAdvocacyIssuesLookUps,
      getInfluenceTypesLookUps,
      getAdvocacyInfluenceLevelLookUps,
      getDevelopmentStageLookUps,
      getAdvocacyTypesLookUps,
      getImprovementAreasLookUps,
      getOrganizationTypesLookUps,
      getSupportTypesLookUps,
      getDataTypesLookUps,
      getDataFrequencyLookUps,
      getCSOTypesLookUps,
      getCollaborationAreaLookUps,
      getBusinessScaleLookUps,
      getIndustryTypeLookUps,
      getLegalEntityTypeLookUps,
      getTIPCaseStatusLookUps,
      getTraffickingFormLookUps,
      getIdentifyingAgencyLookUps,
      getGendersLookUps,
      getMemberTypesLookUps,
      getActivityTypesLookUps,
      getServiceTypesLookUps,
    }
  })
  .views(self => ({
    get documentTypesLookUps() {
      return getSnapshot(self._documentTypesLookUps)
    },
    get advocacyIssuesLookUps() {
      return getSnapshot(self._advocacyIssuesLookUps)
    },
    get influenceTypesLookUps() {
      return getSnapshot(self._influenceTypesLookUps)
    },
    get advocacyInfluenceLevelLookUps() {
      return getSnapshot(self._advocacyInfluenceLevelLookUps)
    },
    get developmentStagesLookUps() {
      return getSnapshot(self._developmentStagesLookUps)
    },
    get advocacyTypesLookUps() {
      return getSnapshot(self._advocacyTypesLookUps)
    },
    get organizationTypesLookUps() {
      return getSnapshot(self._organizationTypesLookUps)
    },
    get supportTypesLookUps() {
      return getSnapshot(self._supportTypesLookUps)
    },
    get improvementAreasLookUps() {
      return getSnapshot(self._improvementAreasLookUps)
    },
    get dataTypesLookUps() {
      return getSnapshot(self._dataTypesLookUps)
    },
    get dataFrequencyLookUps() {
      return getSnapshot(self._dataFrequencyLookUps)
    },
    get csoTypesLookUps() {
      return getSnapshot(self._csoTypeLookUps)
    },
    get collaborationAreaLookUps() {
      return getSnapshot(self._collaborationAreaLookUps)
    },
    get businessScaleLookUps() {
      return getSnapshot(self._businessScaleLookUps)
    },
    get industryTypeLookUps() {
      return getSnapshot(self._industryTypeLookUps)
    },
    get legalEntityTypeLookUps() {
      return getSnapshot(self._legalEntityTypeLookUps)
    },
    get identifyingAgencyLookUps() {
      return getSnapshot(self._identifyingAgencyLookUps)
    },
    get tipCaseStatusLookUps() {
      return getSnapshot(self._tipCaseStatusLookUps)
    },
    get traffickingFormLookUps() {
      return getSnapshot(self._traffickingFormLookUps)
    },
    get gendersLookUps() {
      return getSnapshot(self._gendersLookUps)
    },
    get memberTypesLookUps() {
      return getSnapshot(self._memberTypesLookUps)
    },
    get activityTypesLookUps() {
      return getSnapshot(self._activityTypesLookUps)
    },
    get serviceTypesLookUps() {
      return getSnapshot(self._serviceTypesLookUps)
    },
  }))

export default DictionaryByIndicatorStore
export interface IDictionaryByIndicatorStore
  extends Instance<typeof DictionaryByIndicatorStore> {}
