import { FC, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { RouteConfigObject } from 'routes'
import { Counter } from 'components'

const SideNavBar: FC<{ routes: Array<RouteConfigObject> }> = ({ routes }) => {
  const navigatedRoutes = useMemo(
    () => routes.filter(route => route.navigated),
    [routes],
  )

  return (
    <nav>
      {navigatedRoutes.map(({ path, icon, name, permissionName }) => (
        <NavLink key={name.id} to={path} className="SideBarLink">
          <span className="SideBarLinkContent">
            <i
              className={icon}
              style={{
                fontSize: '1.1rem',
                marginRight: '0.625rem',
                marginLeft: '0.625rem',
                color: '#0078D4',
              }}
            />
            <FormattedMessage {...name} />
          </span>
          <Counter permissionName={permissionName} />
        </NavLink>
      ))}
    </nav>
  )
}

export default SideNavBar
