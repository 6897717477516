import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { GenericWithParentIdLookUpDto } from '../../../lookUps'
import IndicatorBaseDtoModel from '../IndicatorBaseDtoModel'

const Indicator2_2_3DtoModel = IndicatorBaseDtoModel.named(
  'Indicator2_2_3DtoModel',
).props({
  organizationId: types.optional(types.integer, 0),
  organization: types.maybeNull(types.string),
  recordDate: types.maybeNull(types.string),
  legalEntityTypeId: types.optional(types.integer, 0),
  legalEntityType: types.maybeNull(types.string),
  legalEntityTypeParentId: types.maybeNull(types.number),
  industryTypeId: types.optional(types.integer, 0),
  industryType: types.maybeNull(types.string),
  industryTypeParentId: types.maybeNull(types.number),
  businessScaleId: types.optional(types.integer, 0),
  businessScale: types.maybeNull(types.string),
  supportTypes: types.optional(types.array(GenericWithParentIdLookUpDto), []),
  collaborationAreas: types.optional(
    types.array(GenericWithParentIdLookUpDto),
    [],
  ),
  hasSupportedDocuments: types.optional(types.boolean, false),
})

export interface IIndicator2_2_3DtoModel
  extends Instance<typeof Indicator2_2_3DtoModel> {}
export interface IIndicator2_2_3DtoModelSnapshotIn
  extends SnapshotIn<typeof Indicator2_2_3DtoModel> {}
export interface IIndicator2_2_3DtoModelSnapshotOut
  extends SnapshotOut<typeof Indicator2_2_3DtoModel> {}
export default Indicator2_2_3DtoModel
