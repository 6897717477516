import { CSSProperties, ElementType, FC } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

type CrudButtonProps = {
  iconClassName: string
  iconStyle?: CSSProperties
  onClick?: () => void
  tooltip: string
  disabled?: boolean
  as?: ElementType
  [K: string]: any
  preventDefault?: boolean
}

const CrudButton: FC<CrudButtonProps> = ({
  iconClassName,
  iconStyle = {},
  onClick,
  tooltip,
  disabled,
  as: As = 'button',
  preventDefault = false,
  ...props
}) => {
  return (
    <OverlayTrigger
      overlay={
        <Popover
          style={{
            zIndex: 16000,
            border: 'none',
            boxShadow:
              '0px 3.2px 7.2px rgba(0, 0, 0, 0.132), 0px 0.6px 1.8px rgba(0, 0, 0, 0.108)',
          }}
          id={tooltip}
        >
          <Popover.Content>{tooltip}</Popover.Content>
        </Popover>
      }
    >
      <As
        disabled={disabled}
        onClick={(event: MouseEvent) => {
          event.stopPropagation()
          preventDefault && event.preventDefault()
          onClick?.()
        }}
        style={{
          display: 'inline-block',
          border: 'none',
          outline: 'none',
          background: 'none',
          padding: '0 5px',
          verticalAlign: 'middle',
          lineHeight: '100%',
        }}
        {...props}
      >
        <i
          className={[iconClassName, disabled && 'disable-icon'].join(' ')}
          style={{
            color: '#0078D4',
            ...iconStyle,
          }}
        />
      </As>
    </OverlayTrigger>
  )
}

export default CrudButton
