import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateBeneficiaryActivitiesCommand = types.model(
  'UpdateBeneficiaryActivitiesCommand',
  {
    id: types.integer,
    activityIds: types.array(types.number),
  },
)

export interface IUpdateBeneficiaryActivitiesCommand
  extends Instance<typeof UpdateBeneficiaryActivitiesCommand> {}

export interface IUpdateBeneficiaryActivitiesCommandIn
  extends SnapshotIn<typeof UpdateBeneficiaryActivitiesCommand> {}

export interface IUpdateBeneficiaryActivitiesCommandOut
  extends SnapshotOut<typeof UpdateBeneficiaryActivitiesCommand> {}

export default UpdateBeneficiaryActivitiesCommand
