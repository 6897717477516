import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { apiV1 } from 'core/requests'
import {
  BeneficiaryModel,
  ExcludedCategoryModel,
  IBeneficiarySnapshotIn,
  IBeneficiarySnapshotOut,
  IExcludedSnapshotIn,
  IUpdateExcludeBeneficiaryCommandIn,
} from 'stores/models'
import { when } from 'mobx'

const BeneficiaryStore = types
  .model('BeneficiaryStore')
  .props({
    _beneficiaryList: types.array(BeneficiaryModel),
    _beneficiaryExcludes: types.optional(ExcludedCategoryModel, {}),
    _isLoading: types.optional(types.boolean, false),
    _isLoadingExcludes: types.optional(types.boolean, false),
  })
  .actions(self => {
    const BENEFICIARY_API = '/api/beneficiarycategories'

    const setBeneficiaryList = (
      beneficiaryList: Array<IBeneficiarySnapshotIn>,
    ): void => {
      applySnapshot(self._beneficiaryList, beneficiaryList)
    }
    const setIsLoading = (isLoading: boolean) => {
      self._isLoading = isLoading
    }

    const setIsLoadingExcludes = (isLoading: boolean) => {
      self._isLoadingExcludes = isLoading
    }

    const getBeneficiaryList = async (): Promise<any> => {
      const beneficiaryListRequest = fromPromise(
        apiV1.get<Array<IBeneficiarySnapshotIn>>(BENEFICIARY_API),
      )

      when(() =>
        beneficiaryListRequest.case({
          fulfilled: response => {
            setBeneficiaryList(response.data)
            return true
          },
        }),
      )
      return beneficiaryListRequest
    }

    const setBeneficiaryExcludes = (
      beneficiaryExcludes: Array<IExcludedSnapshotIn>,
    ): void => {
      applySnapshot(self._beneficiaryExcludes, beneficiaryExcludes)
    }

    const getBeneficiaryExcludes = async (): Promise<any> => {
      const beneficiaryExcludesRequest = fromPromise(
        apiV1.get<Array<IExcludedSnapshotIn>>(`${BENEFICIARY_API}/excluded`),
      )

      when(() =>
        beneficiaryExcludesRequest.case({
          fulfilled: response => {
            setBeneficiaryExcludes(response.data)
            return true
          },
        }),
      )
      return beneficiaryExcludesRequest
    }

    const editBeneficiaryExcludes = async (
      excludes: IUpdateExcludeBeneficiaryCommandIn,
    ): Promise<void> => apiV1.post(`${BENEFICIARY_API}/excluded`, excludes)

    const createBeneficiary = async (
      beneficiary: Omit<IBeneficiarySnapshotIn, 'id'>,
    ): Promise<void> => apiV1.post(BENEFICIARY_API, beneficiary)

    const editBeneficiary = async (
      beneficiary: IBeneficiarySnapshotIn,
    ): Promise<void> => apiV1.put(BENEFICIARY_API, beneficiary)

    const deleteBeneficiary = async (
      beneficiaryId: IBeneficiarySnapshotIn['id'],
    ): Promise<void> =>
      apiV1.delete(BENEFICIARY_API, { params: { id: beneficiaryId } })

    const deactivateBeneficiary = async (
      beneficiaryId: IBeneficiarySnapshotIn['id'],
    ): Promise<void> =>
      apiV1.put(`${BENEFICIARY_API}/deactivate`, null, {
        params: { id: beneficiaryId },
      })

    const activateBeneficiary = async (
      beneficiaryId: IBeneficiarySnapshotIn['id'],
    ): Promise<void> =>
      apiV1.put(`${BENEFICIARY_API}/activate`, null, {
        params: { id: beneficiaryId },
      })

    return {
      setBeneficiaryList,
      getBeneficiaryList,
      getBeneficiaryExcludes,
      editBeneficiaryExcludes,
      createBeneficiary,
      editBeneficiary,
      deleteBeneficiary,
      deactivateBeneficiary,
      activateBeneficiary,
      setIsLoading,
      setIsLoadingExcludes,
    }
  })
  .views(self => ({
    get beneficiaryList(): Array<IBeneficiarySnapshotOut> {
      return getSnapshot(self._beneficiaryList)
    },
    get beneficiaryExcludes() {
      return getSnapshot(self._beneficiaryExcludes)
    },
    get isLoading() {
      return self._isLoading
    },
    get isLoadingExcludes() {
      return self._isLoadingExcludes
    },
  }))

export default BeneficiaryStore
export interface IBeneficiaryStore extends Instance<typeof BeneficiaryStore> {}
