import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { apiV1 } from 'core/requests'
import { when } from 'mobx'
import {
  CreateIndicatorTargetCommand,
  ICreateIndicatorTargetCommandSnapshotIn,
  IIndicatorTargetsDtoSnapshotIn,
  IIndicatorTargetsDtoSnapshotOut,
  IndicatorTargetsDto,
} from 'stores/models'

const IndicatorTargetsStore = types
  .model('IndicatorTargetsStore')
  .props({
    _indicatorTargets: types.optional(IndicatorTargetsDto, {}),
    _isLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setIsLoading: (isLoading: boolean) => {
      self._isLoading = isLoading
    },
    setIndicatorTargets: (
      indicatorTargets: IIndicatorTargetsDtoSnapshotIn,
    ): void => {
      applySnapshot(self._indicatorTargets, indicatorTargets)
    },
  }))
  .actions(self => {
    const INDICATOR_TARGETS = '/api/indicators/targets'

    const getIndicatorTargets = async (id: number) => {
      const indicatorTargetsRequest = fromPromise(
        apiV1.get<IIndicatorTargetsDtoSnapshotIn>(INDICATOR_TARGETS, {
          params: {
            indicatorId: id,
          },
        }),
      )

      when(() =>
        indicatorTargetsRequest.case({
          fulfilled: response => {
            self.setIndicatorTargets(response.data || {})
            return true
          },
        }),
      )
      return indicatorTargetsRequest
    }

    const createIndicatorTargets = async (
      indicatorTargets: ICreateIndicatorTargetCommandSnapshotIn,
    ) =>
      apiV1.post(
        INDICATOR_TARGETS,
        CreateIndicatorTargetCommand.create(indicatorTargets),
      )

    return {
      getIndicatorTargets,
      createIndicatorTargets,
    }
  })
  .views(self => ({
    get indicatorTargets(): IIndicatorTargetsDtoSnapshotOut {
      return getSnapshot(self._indicatorTargets)
    },

    get isLoading(): boolean {
      return self._isLoading
    },
  }))

export default IndicatorTargetsStore

export interface IIndicatorTargetStore
  extends Instance<typeof IndicatorTargetsStore> {}
