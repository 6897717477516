import { FC, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import CrudButton from '../../CrudButton'
import Loader from '../../Loader'

type StatusButtonsProps = {
  onConfirm: () => Promise<void> | void
  onClick: () => void
}

const FinishEditingButton: FC<StatusButtonsProps> = ({
  onConfirm,
  onClick,
}) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [isPendingRequest, setIsPendingRequest] = useState(false)
  const intl = useIntl()

  const handleConfirm = async () => {
    setIsPendingRequest(true)
    await onConfirm()
    setIsConfirmationOpen(false)
    setIsPendingRequest(false)
  }

  return (
    <>
      <CrudButton
        onClick={() => {
          setIsConfirmationOpen(true)
          onClick()
        }}
        tooltip={intl.formatMessage({
          id: 'global.finish_editing',
          defaultMessage: 'Complete Editing',
        })}
        iconClassName="ms-Icon ms-Icon--PageArrowRight"
      />
      <Modal
        centered
        onHide={() => setIsConfirmationOpen(false)}
        show={isConfirmationOpen}
        onClick={(e: MouseEvent) => e.stopPropagation()}
      >
        {isPendingRequest && <Loader isOverlay />}
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id="global.completion"
              defaultMessage="Завершение"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <FormattedMessage
              id="global.confirmation"
              defaultMessage="Вы уверены, что хотите выполнить это действие?"
            />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleConfirm}>
            <FormattedMessage
              id="global.confirm"
              defaultMessage="Подтвердить"
            />
          </Button>
          <Button
            onClick={() => setIsConfirmationOpen(false)}
            variant="secondary"
          >
            <FormattedMessage id="global.cancel" defaultMessage="Отменить" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FinishEditingButton
