import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import { apiV1 } from 'core/requests'
import {
  ActivityApprovalPagedDto,
  IActivityApprovalListRequestModelIn,
  IActivityApprovalPagedDtoSnapshotIn,
  ActivityApprovalListRequestModel,
  IActivityApprovalCommandSnapshotIn,
  ActivityApprovalCommand,
  ExportFilesType,
  IActivityApprovalListExportRequest,
} from 'stores/models'
import fileDownload from 'js-file-download'
import {
  capitalizeFirstLetter,
  capitalizeObjectKeys,
  getFileNameFromContentDisposition,
} from 'core/utils'

const ActivityStore = types
  .model('ActivityStore')
  .props({
    _isLoading: types.optional(types.boolean, false),
    _activityList: types.optional(ActivityApprovalPagedDto, {}),
    _activityCounter: types.optional(types.number, 0),
  })
  .actions(self => ({
    setActivityCounter(activityCounter: number): void {
      self._activityCounter = activityCounter
    },
    setActivityList: (
      activityList: IActivityApprovalPagedDtoSnapshotIn,
    ): void => {
      applySnapshot(self._activityList, activityList)
    },
  }))
  .actions(self => {
    const ACTIVITY_API = '/api/activities'

    const setIsLoading = (isLoading: boolean) => {
      self._isLoading = isLoading
    }

    const getActivitiesList = async (
      params: IActivityApprovalListRequestModelIn,
    ) => {
      const activityListRequest = fromPromise(
        apiV1.get<IActivityApprovalListRequestModelIn>(`${ACTIVITY_API}`, {
          params: ActivityApprovalListRequestModel.create(params),
        }),
      )

      when(() =>
        activityListRequest.case({
          fulfilled: response => {
            self.setActivityList(response.data)
            return true
          },
        }),
      )
      return activityListRequest
    }

    const rejectActivity = async (
      ids: IActivityApprovalCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.put(`${ACTIVITY_API}/reject`, ActivityApprovalCommand.create(ids))

    const getActivitiesExportFile = async (
      params: IActivityApprovalListExportRequest,
      type: ExportFilesType,
      onDownloadProgress: (progressEvent: ProgressEvent) => void,
    ) => {
      const activitiesExportFileRequest = fromPromise(
        apiV1.get<ArrayBuffer>(`${ACTIVITY_API}/export`, {
          params: {
            ...params,
            sortBy: capitalizeFirstLetter(params.sortBy),
            filter: capitalizeObjectKeys(params.filter),
          },
          responseType: 'arraybuffer',
          onDownloadProgress,
        }),
      )

      when(() =>
        activitiesExportFileRequest.case({
          fulfilled: response => {
            const fileName = getFileNameFromContentDisposition(
              response.headers['content-disposition'],
            )
            fileDownload(response.data, fileName ? fileName : `report.${type}`)
            return true
          },
        }),
      )

      return activitiesExportFileRequest
    }

    const approveActivity = async (
      ids: IActivityApprovalCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.put(`${ACTIVITY_API}/approve`, ActivityApprovalCommand.create(ids))

    const getActivityCounter = async () => {
      const activityCounterRequest = fromPromise(
        apiV1.get<number>(`${ACTIVITY_API}/count`),
      )

      when(() =>
        activityCounterRequest.case({
          fulfilled: response => {
            self.setActivityCounter(response.data)
            return true
          },
        }),
      )
      return activityCounterRequest
    }

    return {
      setIsLoading,
      getActivitiesList,
      getActivitiesExportFile,
      rejectActivity,
      approveActivity,
      getActivityCounter,
    }
  })
  .views(self => ({
    get isLoading() {
      return self._isLoading
    },
    get activity() {
      return getSnapshot(self._activityList)
    },
    get activityList() {
      return this.activity.data
    },
    get activityMetadata() {
      return this.activity.metadata
    },
    get activityCounter() {
      return self._activityCounter
    },
  }))

export default ActivityStore
export interface IActivityStore extends Instance<typeof ActivityStore> {}
