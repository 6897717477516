import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const IndicatorFormsApprovalCommand = types.model(
  'IndicatorFormsApprovalCommand',
  {
    indicators: types.array(
      types.model({
        indicatorNumber: types.string,
        ids: types.array(types.integer),
      }),
    ),
  },
)

export interface IIndicatorFormsApprovalCommand
  extends Instance<typeof IndicatorFormsApprovalCommand> {}
export interface IIndicatorFormsApprovalCommandSnapshotIn
  extends SnapshotIn<typeof IndicatorFormsApprovalCommand> {}
export interface IIndicatorFormsApprovalCommandSnapshotOut
  extends SnapshotOut<typeof IndicatorFormsApprovalCommand> {}
export default IndicatorFormsApprovalCommand
