import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const ChangeUsersPasswordCommand = types.model('ChangeUsersPasswordCommand', {
  userId: types.identifier,
  confirmPassword: types.string,
  password: types.string,
})

export interface IChangeUsersPasswordCommand
  extends Instance<typeof ChangeUsersPasswordCommand> {}

export interface IChangeUsersPasswordCommandSnapshotIn
  extends SnapshotIn<typeof ChangeUsersPasswordCommand> {}

export interface IChangeUsersPasswordCommandSnapshotOut
  extends SnapshotOut<typeof ChangeUsersPasswordCommand> {}

export default ChangeUsersPasswordCommand
