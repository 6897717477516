import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const Indicator1_2_1ReportModel = types.model('Indicator1_2_1ReportModel', {
  partnerName: types.maybeNull(types.string),
  organizationName: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  organizationType: types.maybeNull(types.string),
  supportTypes: types.array(types.string),
  improvementAreas: types.array(types.string),
  notes: types.maybeNull(types.string),
})

export interface IIndicator1_2_1ReportModel
  extends Instance<typeof Indicator1_2_1ReportModel> {}
export interface IIndicator1_2_1ReportSnapshotIn
  extends SnapshotIn<typeof Indicator1_2_1ReportModel> {}
export interface IIndicator1_2_1ReportSnapshotOut
  extends SnapshotOut<typeof Indicator1_2_1ReportModel> {}
export default Indicator1_2_1ReportModel
