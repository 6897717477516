import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../../PagedListRequestModel'
import BeneficiaryIndividualsFilterModel from './BeneficiaryIndividualsFilterModel'

const BeneficiaryIndividualListRequestModel = PagedListRequestModel.named(
  'BeneficiaryIndividualListRequestModel',
).props({
  sortBy: types.optional(types.string, ''),
  filter: types.optional(BeneficiaryIndividualsFilterModel, {}),
})

export interface IBeneficiaryIndividualListRequestModel
  extends Instance<typeof BeneficiaryIndividualListRequestModel> {}

export interface IBeneficiaryIndividualListRequestSnapshotIn
  extends SnapshotIn<typeof BeneficiaryIndividualListRequestModel> {}

export interface IBeneficiaryIndividualListRequestSnapshotOut
  extends SnapshotOut<typeof BeneficiaryIndividualListRequestModel> {}

export default BeneficiaryIndividualListRequestModel
