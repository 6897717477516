import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const OrganizationActivityStaffRequestModel = types.model(
  'OrganizationActivityStaffRequestModel',
  {
    activityId: types.number,
    organizationId: types.number,
  },
)

export interface IOrganizationActivityStaffRequestModel
  extends Instance<typeof OrganizationActivityStaffRequestModel> {}

export interface IOrganizationActivityStaffRequestModelIn
  extends SnapshotIn<typeof OrganizationActivityStaffRequestModel> {}

export interface IOrganizationActivityStaffRequestModelOut
  extends SnapshotOut<typeof OrganizationActivityStaffRequestModel> {}

export default OrganizationActivityStaffRequestModel
