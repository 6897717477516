import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const ServiceHistoryApprovalCommand = types.model(
  'ServiceHistoryApprovalCommand',
  {
    ids: types.array(types.integer),
  },
)

export interface IServiceHistoryApprovalCommand
  extends Instance<typeof ServiceHistoryApprovalCommand> {}
export interface IServiceHistoryApprovalCommandSnapshotIn
  extends SnapshotIn<typeof ServiceHistoryApprovalCommand> {}
export interface IServiceHistoryApprovalCommandSnapshotOut
  extends SnapshotOut<typeof ServiceHistoryApprovalCommand> {}
export default ServiceHistoryApprovalCommand
