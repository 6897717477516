import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const ExplotationControlToolLookUpModel = LookUpModel.named('ExplotationControlToolLookUpModel').props({
  isComment: types.boolean
})

export interface IExplotationControlToolLookUp
  extends Instance<typeof ExplotationControlToolLookUpModel> {}

export interface IExplotationControlToolLookUpSnapshotIn
  extends SnapshotIn<typeof ExplotationControlToolLookUpModel> {}

export interface IExplotationControlToolLookUpSnapshotOut
  extends SnapshotOut<typeof ExplotationControlToolLookUpModel> {}

export default ExplotationControlToolLookUpModel