import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../../../PagedListRequestModel'
import Indicator1_2_2ReportFilterModel from './Indicator1_2_2ReportFilterModel'

const Indicator1_2_2ReportRequestModel = PagedListRequestModel.named(
  'Indicator1_2_2ReportRequestModel',
).props({
  sortBy: types.optional(types.string, ''),
  filter: types.optional(Indicator1_2_2ReportFilterModel, {}),
})

export interface IIndicator1_2_2ReportRequestModel
  extends Instance<typeof Indicator1_2_2ReportRequestModel> {}

export interface IIndicator1_2_2ReportRequestSnapshotIn
  extends SnapshotIn<typeof Indicator1_2_2ReportRequestModel> {}

export interface IIndicator1_2_2ReportRequestSnapshotOut
  extends SnapshotOut<typeof Indicator1_2_2ReportRequestModel> {}

export default Indicator1_2_2ReportRequestModel
