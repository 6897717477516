import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PermissionGroupName from './PermissionGroupNameEnum'
import PermissionActionModel from './PermissionActionModel'

const PermissionGroupModel = types.model('PermissionGroupModel', {
  id: types.identifierNumber,
  name: types.union(
    types.enumeration<PermissionGroupName>(Object.values(PermissionGroupName)),
    types.string,
  ),
  permissionActions: types.optional(
    types.array(PermissionActionModel),
    [],
    [undefined, null],
  ),
})

export interface IPermissionGroupModel
  extends Instance<typeof PermissionGroupModel> {}

export interface IPermissionGroupSnapshotIn
  extends SnapshotIn<typeof PermissionGroupModel> {}

export interface IPermissionGroupSnapshotOut
  extends SnapshotOut<typeof PermissionGroupModel> {}

export default PermissionGroupModel
