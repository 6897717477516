import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const TravelMethodLookUpModel = LookUpModel.named('TravelMethodLookUpModel').props({
    isComment: types.boolean
})

export interface ITravelMethodLookUp
    extends Instance<typeof TravelMethodLookUpModel> {}

export interface ITravelMethodLookUpSnapshotIn
    extends SnapshotIn<typeof TravelMethodLookUpModel> {}

export interface ITravelMethodLookUpSnapshotOut
    extends SnapshotOut<typeof TravelMethodLookUpModel> {}

export default TravelMethodLookUpModel