import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const CSOTypeLookUpModel = LookUpModel.named('CSOTypeLookUpModel').props({
  isComment: types.boolean,
})

export interface ICSOTypeLookUp extends Instance<typeof CSOTypeLookUpModel> {}

export interface ICSOTypeLookUpSnapshotIn
  extends SnapshotIn<typeof CSOTypeLookUpModel> {}

export interface ICSOTypeLookUpSnapshotOut
  extends SnapshotOut<typeof CSOTypeLookUpModel> {}

export default CSOTypeLookUpModel
