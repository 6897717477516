import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { apiV1 } from 'core/requests'
import { when } from 'mobx'
import {
  IIndicatorFormRequestListModelIn,
  IIndicatorFormPagedDtoSnapshotIn,
  IndicatorFormPagedDto,
  IndicatorFormRequestListModel,
  IIndicatorFormPagedDtoSnapshotOut,
  IIndicatorFormsApprovalCommandSnapshotIn,
  IndicatorFormsApprovalCommand,
} from 'stores/models'

const IndicatorsStore = types
  .model('IndicatorsStore')
  .props({
    _indicatorFormsList: types.optional(IndicatorFormPagedDto, {}),
    _isLoading: types.optional(types.boolean, false),
    _indicatorCounter: types.optional(types.number, 0),
  })
  .actions(self => ({
    setIsLoading: (isLoading: boolean) => {
      self._isLoading = isLoading
    },
    setIndicatorsList: (
      indicatorFormsList: IIndicatorFormPagedDtoSnapshotIn,
    ): void => {
      applySnapshot(self._indicatorFormsList, indicatorFormsList)
    },
    setIndicatorCounter(indicatorCounter: number): void {
      self._indicatorCounter = indicatorCounter
    },
  }))
  .actions(self => {
    const INDICATORS_API = '/api/indicators'

    const getIndicatorFormsList = async (
      requestParams: IIndicatorFormRequestListModelIn,
    ) => {
      const indicatorFormsListRequest = fromPromise(
        apiV1.get<IIndicatorFormPagedDtoSnapshotIn>(INDICATORS_API, {
          params: IndicatorFormRequestListModel.create(requestParams),
        }),
      )

      when(() =>
        indicatorFormsListRequest.case({
          fulfilled: response => {
            self.setIndicatorsList(response.data)
            return true
          },
        }),
      )
      return indicatorFormsListRequest
    }

    const getIndicatorCounter = async () => {
      const indicatorCounterRequest = fromPromise(
        apiV1.get<number>(`${INDICATORS_API}/count`),
      )

      when(() =>
        indicatorCounterRequest.case({
          fulfilled: response => {
            self.setIndicatorCounter(response.data)
            return true
          },
        }),
      )
      return indicatorCounterRequest
    }

    const approveIndicatorForm = async (
      indicators: IIndicatorFormsApprovalCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.put(
        `${INDICATORS_API}/approve`,
        IndicatorFormsApprovalCommand.create(indicators),
      )

    const rejectIndicatorForm = async (
      indicators: IIndicatorFormsApprovalCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.put(
        `${INDICATORS_API}/reject`,
        IndicatorFormsApprovalCommand.create(indicators),
      )

    return {
      getIndicatorFormsList,
      approveIndicatorForm,
      rejectIndicatorForm,
      getIndicatorCounter,
    }
  })
  .views(self => ({
    get indicatorForms(): IIndicatorFormPagedDtoSnapshotOut {
      return getSnapshot(self._indicatorFormsList)
    },
    get indicatorFormsList() {
      return this.indicatorForms.data
    },
    get indicatorFormsMetadata() {
      return this.indicatorForms.metadata
    },
    get isLoading() {
      return self._isLoading
    },
    get indicatorCounter() {
      return self._indicatorCounter
    },
  }))

export default IndicatorsStore
export interface IIndicatorsStore extends Instance<typeof IndicatorsStore> {}
