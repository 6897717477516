import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateBeneficiaryOrganizationActivityStaffCommand = types.model(
  'UpdateBeneficiaryOrganizationActivityStaffCommand',
  {
    staffIds: types.array(types.number),
    activityId: types.number,
    organizationId: types.number,
  },
)

export interface IUpdateBeneficiaryOrganizationActivityStaffCommand
  extends Instance<typeof UpdateBeneficiaryOrganizationActivityStaffCommand> {}

export interface IUpdateBeneficiaryOrganizationActivityStaffCommandIn
  extends SnapshotIn<
    typeof UpdateBeneficiaryOrganizationActivityStaffCommand
  > {}

export interface IUpdateBeneficiaryOrganizationActivityStaffCommandOut
  extends SnapshotOut<
    typeof UpdateBeneficiaryOrganizationActivityStaffCommand
  > {}

export default UpdateBeneficiaryOrganizationActivityStaffCommand
