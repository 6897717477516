import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { apiV1 } from 'core/requests'
import { when } from 'mobx'
import {
  IPermissionMenuSnapshotIn,
  IUpdateUserPermissionsCommandSnapshotIn,
  PermissionMenuModel,
  UpdateUserPermissionsCommand,
  UserAccessInfoPagedDto,
  IUserAccessInfoPagedDtoSnapshotIn,
  IUserAccessInfoRequestSnapshotIn,
  UserAccessInfoRequestModel,
  IUserAccessInfoPagedDtoSnapshotOut,
  UserPermissionsModel,
  IUserPermissionsSnapshotIn,
  UserAccessInfoModel,
  IUserAccessInfoSnapshotIn,
} from 'stores/models'

const UserPermissionsStore = types
  .model('UserPermissionsStore')
  .props({
    _permissions: types.array(PermissionMenuModel),
    _users: types.optional(UserAccessInfoPagedDto, {}),
    _userAccessInfo: types.optional(UserAccessInfoModel, {}),
    _userPermissions: types.optional(UserPermissionsModel, {}),
    _isPermissionsLoading: types.optional(types.boolean, false),
    _isUsersLoading: types.optional(types.boolean, false),
  })
  .actions(self => {
    const USERS_PERMISSIONS_API = '/api/users/permissions'

    const setIsPermissionsLoading = (isPermissionsLoading: boolean): void => {
      self._isPermissionsLoading = isPermissionsLoading
    }

    const setIsUsersLoading = (isUsersLoading: boolean): void => {
      self._isUsersLoading = isUsersLoading
    }

    const setPermissions = (
      permissions: Array<IPermissionMenuSnapshotIn>,
    ): void => {
      applySnapshot(self._permissions, permissions)
    }

    const setUsers = (users: IUserAccessInfoPagedDtoSnapshotIn): void => {
      applySnapshot(self._users, users)
    }

    const setUserPermissions = (
      userPermissions: IUserPermissionsSnapshotIn,
    ): void => {
      applySnapshot(self._userPermissions, userPermissions)
    }

    const setUserAccessInfo = (
      userAccessInfo: IUserAccessInfoSnapshotIn | null,
    ): void => {
      applySnapshot(self._userAccessInfo, userAccessInfo)
    }

    const getPermissionsByUserId = async (userId: string) => {
      const userPermissionsRequest = fromPromise(
        apiV1.get<IUserPermissionsSnapshotIn>(USERS_PERMISSIONS_API, {
          params: { userId },
        }),
      )

      when(() =>
        userPermissionsRequest.case({
          fulfilled: response => {
            setUserPermissions(response.data)
            return true
          },
        }),
      )
      return userPermissionsRequest
    }

    const getAllPermissions = async () => {
      const allPermissionsRequest = fromPromise(
        apiV1.get<Array<IPermissionMenuSnapshotIn>>(
          `${USERS_PERMISSIONS_API}/all`,
        ),
      )

      when(() =>
        allPermissionsRequest.case({
          fulfilled: response => {
            setPermissions(response.data)
            return true
          },
        }),
      )
      return allPermissionsRequest
    }

    const getUsers = async (params: IUserAccessInfoRequestSnapshotIn) => {
      const usersListRequest = fromPromise(
        apiV1.get<IUserAccessInfoPagedDtoSnapshotIn>(
          `${USERS_PERMISSIONS_API}/users`,
          {
            params: UserAccessInfoRequestModel.create(params),
          },
        ),
      )

      when(() =>
        usersListRequest.case({
          fulfilled: response => {
            setUsers(response.data)
            return true
          },
        }),
      )
      return usersListRequest
    }

    const editUserPermissions = async (
      userPermissionsData: IUpdateUserPermissionsCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.post(
        USERS_PERMISSIONS_API,
        UpdateUserPermissionsCommand.create(userPermissionsData),
      )

    return {
      setIsPermissionsLoading,
      setIsUsersLoading,
      setUserAccessInfo,
      getPermissionsByUserId,
      getAllPermissions,
      getUsers,
      editUserPermissions,
    }
  })
  .views(self => ({
    get permissions() {
      return getSnapshot(self._permissions)
    },
    get users(): IUserAccessInfoPagedDtoSnapshotOut {
      return getSnapshot(self._users)
    },
    get usersList() {
      return this.users.data
    },
    get usersMetadata() {
      return this.users.metadata
    },
    get userPermissions() {
      return getSnapshot(self._userPermissions)
    },
    get userAccessInfo() {
      return getSnapshot(self._userAccessInfo)
    },
    get isPermissionsLoading() {
      return self._isPermissionsLoading
    },
    get isUsersLoading() {
      return self._isUsersLoading
    },
  }))

export default UserPermissionsStore
export interface IUserPermissionsStore
  extends Instance<typeof UserPermissionsStore> {}
