import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const ReportingYearLookUpModel = LookUpModel.named('ReportingYearLookUpModel')

export interface IReportingYearLookUpModel
  extends Instance<typeof ReportingYearLookUpModel> {}
export interface IReportingYearLookUpSnapshotIn
  extends SnapshotIn<typeof ReportingYearLookUpModel> {}
export interface IReportingYearLookUpSnapshotOut
  extends SnapshotOut<typeof ReportingYearLookUpModel> {}
export default ReportingYearLookUpModel
