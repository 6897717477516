import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { apiV1 } from 'core/requests'
import {
  RegionLookUpModel,
  IRegionLookUpSnapshotIn,
  IRegionLookUpSnapshotOut,
  IAgeCategoriesLookUpSnapshotIn,
  AgeCategoriesLookUpModel,
  IAgeCategoriesLookUpSnapshotOut,
  CountryLookUpModel,
  ICountryLookUpSnapshotIn,
  ICountryLookUpSnapshotOut,
  IOrganizationTypesLookUpIn,
  IOrganizationTypesLookUpOut,
  OrganizationTypesLookUpModel,
  PartnerLookUpModel,
  IPartnerLookUpSnapshotIn,
  IPartnerLookUpSnapshotOut,
  OrganizationServiceTypesLookUpModel,
  IOrganizationServiceTypesLookUpSnapshotIn,
  IOrganizationServiceTypesLookUpSnapshotOut,
  EthnicityLookUpModel,
  GenderLookUpModel,
  IGenderLookUpSnapshotIn,
  IEthnicityLookUpSnapshotIn,
  IGenderLookUpSnapshotOut,
  IEthnicityLookUpSnapshotOut,
  BeneficiaryCategoryLookUpModel,
  IBeneficiaryCategoryLookUpSnapshotIn,
  IBeneficiaryCategoryLookUpSnapshotOut,
  ExcludedCategoryModel,
  IExcludedSnapshotIn,
  IExcludedSnapshotOut,
  OrganizationStaffLookUpModel,
  IOrganizationStaffLookUpModelSnapshotIn,
  IOrganizationStaffLookUpModelSnapshotOut,
  ApprovalStatusLookUpModel,
  ActivityParticipantTypeLookUpModel,
  IApprovalStatusLookUpSnapshotIn,
  IActivityParticipantTypeLookUpSnapshotIn,
  IActivityTypeLookUpSnapshotIn,
  ActivityTypeLookUpModel,
  BeneficiaryOrganizationLookUpModel,
  IBeneficiaryOrganizationLookUpSnapshotIn,
  IndicatorLookUpModel,
  IIndicatorLookUpSnapshotIn,
  IReportingYearLookUpSnapshotIn,
  ReportingYearLookUpModel,
  ReportingQuartersLookUpModel,
  IReportingQuartersLookUpSnapshotIn,
  MonthLookUpModel,
  IMonthLookUpSnapshotIn,
  IMonthLookUpSnapshotOut,
  IndicatorTargetYearsModel,
  IndicatorTargetCountriesModel,
  IIndicatorTargetYearsSnapshotIn,
  IIndicatorTargetCountriesSnapshotIn,
  IIndicatorTargetYearsSnapshotOut,
  IIndicatorTargetCountriesSnapshotOut,
  RelationshipEstablishmentsLookUpModel,
  IRelationshipEstablishmentlookUpSnapshotIn,
  IRelationshipEstablishmentlookUpSnapshotOut,
  WorkTypesLookUpModel,
  IWorkTypesLookUpModelLookUpSnapshotIn,
  IWorkTypesLookUpModellookUpSnapshotOut,
  LegalEntityTypeLookUpModel,
    ILegalEntityTypeLookUpSnapshotIn,
    ILegalEntityTypeLookUpSnapshotOut,
    RelationshipTypeLookUpModel,
    IRelationshipTypeLookUpSnapshotIn,
    IRelationshipTypeLookUpSnapshotOut,
    RecruitmentToolLookUpModel,
  IRecruitmentToolLookUpSnapshotIn,
  IRecruitmentToolLookUpSnapshotOut,
    TravelMethodLookUpModel,
    ITravelMethodLookUpSnapshotIn,
  ITravelMethodLookUpSnapshotOut,
  TransportationControlToolLookUpModel,
  ITransportationControlToolLookUpSnapshotIn,
  ITransportationControlToolLookUpSnapshotOut,
  ExplotationControlToolLookUpModel,
  IExplotationControlToolLookUpSnapshotIn,
  IExplotationControlToolLookUpSnapshotOut,
  TraffickingFormLookUpModel,
  ITraffickingFormLookUpIn,
  ITraffickingFormLookUpOut,
  ExploitationConditionLookUpModel,
  IExploitationConditionLookUpIn,
  IExploitationConditionLookUpOut,
  VictimReleaseTypeLookUpModel,
  IVictimReleaseTypeLookUpIn,
  IVictimReleaseTypeLookUpOut,
  MigrationactivitiesLooksUpModel,
  IMigrationactivitiesLooksUpModelSnapshotIn,
  WinrockassistancerejectionreasonsLooksUpModel,
  IWinrockassistancerejectionreasonsLooksUpModelSnapshotIn,
  WinrockassistancerefusereasonsLooksUpModel,
  IWinrockassistancerefusereasonsLooksUpModelSnapshotIn,
  PersonconditionsLooksUpModel,
  IPersonconditionsLooksUpModelSnapshotIn,
  RefferalOrgTypeLookUpModel,
  IRefferalOrgTypeLookUpSnapshotIn,
  IRefferalOrgTypeLookUpSnapshotOut
} from 'stores/models'
import { when } from 'mobx'
import { chunk } from 'lodash'

const LookUpStore = types
  .model('LookUpStore')
  .props({
    _regionsLookUps: types.array(RegionLookUpModel),
    _ageCategoriesLookUps: types.array(AgeCategoriesLookUpModel),
    _organizationTypesLookUps: types.array(OrganizationTypesLookUpModel),
    _organizationServiceTypesLookUps: types.array(
      OrganizationServiceTypesLookUpModel,
    ),
    _countriesLookUps: types.array(CountryLookUpModel),
    _ethnicitiesLookUps: types.array(EthnicityLookUpModel),
    _gendersLookUps: types.array(GenderLookUpModel),
    _beneficiaryCategoriesLookUps: types.array(BeneficiaryCategoryLookUpModel),
    _excludedBeneficiaryCategories: types.optional(ExcludedCategoryModel, {}),
    _partnersLookUps: types.array(PartnerLookUpModel),
    _partnerStaffLookUps: types.array(OrganizationStaffLookUpModel),
    _activityStaffLookUps: types.array(OrganizationStaffLookUpModel),
    _beneficiaryOrganizationsLookUps: types.array(
      BeneficiaryOrganizationLookUpModel,
    ),
    _approvalStatusesLookUps: types.array(ApprovalStatusLookUpModel),
    _activityParticipantTypesLookUps: types.array(
      ActivityParticipantTypeLookUpModel,
    ),

    _migrationactivitiesLooksUps: types.array(MigrationactivitiesLooksUpModel),
    _winrockassistancerejectionreasonsLooksUps: types.array(WinrockassistancerejectionreasonsLooksUpModel),
    _winrockassistancerefusereasonsLooksUps: types.array(WinrockassistancerefusereasonsLooksUpModel),
    _personconditionsLooksUps: types.array(PersonconditionsLooksUpModel),

    _activityTypesLookUps: types.array(ActivityTypeLookUpModel),
    _indicatorsLookUps: types.array(IndicatorLookUpModel),
    _rolesList: types.array(types.string),
    _reportingYearsLookUps: types.array(ReportingYearLookUpModel),
    _reportingQuartersLookUps: types.array(ReportingQuartersLookUpModel),
    _monthsLookUps: types.array(MonthLookUpModel),
    _fileUploadMaxSizeInMB: types.optional(types.number, 0),
    _indicatorTargetYears: types.array(IndicatorTargetYearsModel),
    _indicatorTargetCountries: types.array(IndicatorTargetCountriesModel),
    _relationshipEstablishments: types.array(RelationshipEstablishmentsLookUpModel),
    _workTypes: types.array(WorkTypesLookUpModel),
    _legalEntityTypes: types.array(LegalEntityTypeLookUpModel),
    _relationshipTypesLookUps: types.array(RelationshipTypeLookUpModel),
    _recruitmentToolsLookUps: types.array(RecruitmentToolLookUpModel),
    _travelMethodsLookUps: types.array(TravelMethodLookUpModel),
    _transportationControlToolsLookUps: types.array(TransportationControlToolLookUpModel),
    _explotationControlToolsLookUps: types.array(ExplotationControlToolLookUpModel),
    _traffickingFormsLookUps: types.array(TraffickingFormLookUpModel),
    _exploitationConditionsLookUps: types.array(ExploitationConditionLookUpModel),
    _victimReleaseTypesLookUps: types.array(VictimReleaseTypeLookUpModel),
    _refferalOrgTypes: types.array(RefferalOrgTypeLookUpModel)
  })
  .actions(self => ({
    setFileUploadMaxSizeInMB: (fileUploadMaxSizeInMB: number): void => {
      self._fileUploadMaxSizeInMB = fileUploadMaxSizeInMB
    },
  }))
  .actions(self => {
    const LOOKUPS_API = '/api/lookups'

    const setRegionsLookUps = (
      regionsList: Array<IRegionLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._regionsLookUps, regionsList)
    }

    const setAgeCategoriesLookUps = (
      ageCategoriesList: Array<IAgeCategoriesLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._ageCategoriesLookUps, ageCategoriesList)
    }

    const setPartnersLookUps = (
      partnersList: Array<IPartnerLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._partnersLookUps, partnersList)
    }

    const setOrganizationTypesLookUps = (
      organizationTypesList: Array<IOrganizationTypesLookUpIn>,
    ): void => {
      applySnapshot(self._organizationTypesLookUps, organizationTypesList)
    }

    const setOrganizationServiceTypesLookUps = (
      organizationServiceTypesLookUps: Array<IOrganizationServiceTypesLookUpSnapshotIn>,
    ): void => {
      applySnapshot(
        self._organizationServiceTypesLookUps,
        organizationServiceTypesLookUps,
      )
    }

    const setCountriesLookUps = (
      countriesList: Array<ICountryLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._countriesLookUps, countriesList)
    }

    const setGendersLookUps = (
      gendersList: Array<IGenderLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._gendersLookUps, gendersList)
    }

    const setEthnicitiesLookUps = (
      ethnicitiesList: Array<IEthnicityLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._ethnicitiesLookUps, ethnicitiesList)
    }

    const setBeneficiaryCategoriesLookUps = (
      beneficiaryCategoriesList: Array<IBeneficiaryCategoryLookUpSnapshotIn>,
    ): void => {
      applySnapshot(
        self._beneficiaryCategoriesLookUps,
        beneficiaryCategoriesList,
      )
    }

    const setApprovalStatusesLookUps = (
      approvalStatusesLookUps: Array<IApprovalStatusLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._approvalStatusesLookUps, approvalStatusesLookUps)
    }

    const setActivityParticipantTypesLookUps = (
      activityParticipantTypesLookUps: Array<IActivityParticipantTypeLookUpSnapshotIn>,
    ): void => {
      applySnapshot(
        self._activityParticipantTypesLookUps,
        activityParticipantTypesLookUps,
      )
    }

    const setMigrationactivitiesLooksUps = (
      migrationactivitiesLooksUps: Array<IMigrationactivitiesLooksUpModelSnapshotIn>,
    ): void => {
      applySnapshot(
        self._migrationactivitiesLooksUps,
        migrationactivitiesLooksUps,
      )
    }

    const setWinrockassistancerejectionreasonsLooksUps = (
      winrockassistancerejectionreasonsLooksUps: Array<IWinrockassistancerejectionreasonsLooksUpModelSnapshotIn>,
    ): void => {
      applySnapshot(
        self._winrockassistancerejectionreasonsLooksUps,
        winrockassistancerejectionreasonsLooksUps,
      )
    }

    const setWinrockassistancerefusereasonsLooksUps = (
      winrockassistancerefusereasonsLooksUps: Array<IWinrockassistancerefusereasonsLooksUpModelSnapshotIn>,
    ): void => {
      applySnapshot(
        self._winrockassistancerefusereasonsLooksUps,
        winrockassistancerefusereasonsLooksUps,
      )
    }

    const setPersonconditionsLooksUps = (
      personconditionsLooksUps: Array<IPersonconditionsLooksUpModelSnapshotIn>,
    ): void => {
      applySnapshot(
        self._personconditionsLooksUps,
        personconditionsLooksUps,
      )
    }

    const setActivityTypesLookUps = (
      activityTypesLookUps: Array<IActivityTypeLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._activityTypesLookUps, activityTypesLookUps)
    }

    const setExcludedBeneficiaryCategories = (
      excludedBeneficiaryCategories: IExcludedSnapshotIn,
    ): void => {
      applySnapshot(
        self._excludedBeneficiaryCategories,
        excludedBeneficiaryCategories,
      )
    }

    const setPartnerStaffLookUps = (
      partnerStaffList: Array<IOrganizationStaffLookUpModelSnapshotIn>,
    ): void => {
      applySnapshot(self._partnerStaffLookUps, partnerStaffList)
    }

    const setActivityStaffLookUps = (
      activityStaffList: Array<IOrganizationStaffLookUpModelSnapshotIn>,
    ): void => {
      applySnapshot(self._activityStaffLookUps, activityStaffList)
    }

    const setBeneficiaryOrganizationsLookUps = (
      beneficiaryOrganizationsList: Array<IBeneficiaryOrganizationLookUpSnapshotIn>,
    ): void => {
      applySnapshot(
        self._beneficiaryOrganizationsLookUps,
        beneficiaryOrganizationsList,
      )
    }

    const setIndicatorsLookUps = (
      indicatorList: Array<IIndicatorLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._indicatorsLookUps, indicatorList)
    }

    const setRolesList = (rolesList: Array<string>): void => {
      applySnapshot(self._rolesList, rolesList)
    }

    const setReportingLookUpYears = (
      years: Array<IReportingYearLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._reportingYearsLookUps, years)
    }

    const setReportingQuartersLookUps = (
      reportingQuartersList: Array<IReportingQuartersLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._reportingQuartersLookUps, reportingQuartersList)
    }

    const setMonthsLookUps = (months: Array<IMonthLookUpSnapshotIn>): void => {
      applySnapshot(self._monthsLookUps, months)
    }

    const setIndicatorTargetYears = (
      years: Array<IIndicatorTargetYearsSnapshotIn>,
    ): void => {
      applySnapshot(self._indicatorTargetYears, years)
    }

    const setIndicatorTargetCountries = (
      years: Array<IIndicatorTargetCountriesSnapshotIn>,
    ): void => {
      applySnapshot(self._indicatorTargetCountries, years)
    }

    const setRelationshipEstablishment = (
        relationshipEstablishments: Array<IRelationshipEstablishmentlookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._relationshipEstablishments, relationshipEstablishments)
    }

    const setWorkTypes = (
        workTypes: Array<IWorkTypesLookUpModelLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._workTypes, workTypes)
    }

    const setLegalEntityTypes = (
        legalEntityTypes: Array<ILegalEntityTypeLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._legalEntityTypes, legalEntityTypes)
    }

    const setRelationshipTypesLookUps = (
        relationshipTypesLookUps: Array<IRelationshipTypeLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._relationshipTypesLookUps, relationshipTypesLookUps)
    }

    const setRecruitmentToolsLookUps = (
        recruitmentToolsLookUps: Array<IRecruitmentToolLookUpSnapshotIn>
    ): void => {
      applySnapshot(self._recruitmentToolsLookUps, recruitmentToolsLookUps)
    }

    const setTravelMethodsLookUps = (
        travelMethodsLookUps: Array<ITravelMethodLookUpSnapshotIn>
    ): void => {
      applySnapshot(self._travelMethodsLookUps, travelMethodsLookUps)
    }

    const setTransportationControlToolsLookUps = (
        transportationControlToolsLookUps: Array<ITransportationControlToolLookUpSnapshotIn>
    ): void => {
      applySnapshot(self._transportationControlToolsLookUps, transportationControlToolsLookUps)
    }

    const setExplotationControlToolsLookUps = (
      explotationControlToolsLookUps: Array<IExplotationControlToolLookUpSnapshotIn>
    ): void => {
      applySnapshot(self._explotationControlToolsLookUps, explotationControlToolsLookUps)
    }

    const setTraffickingFormsLookUps = (
        traffickingFormsLookUps: Array<ITraffickingFormLookUpIn>
    ): void => {
      applySnapshot(self._traffickingFormsLookUps, traffickingFormsLookUps)
    }

    const setExploitationConditionsLookUps = (
        exploitationConditionsLookUps: Array<IExploitationConditionLookUpIn>
    ): void => {
      applySnapshot(self._exploitationConditionsLookUps, exploitationConditionsLookUps)
    }

    const setVictimReleaseTypesLookUps = (
        victimReleaseTypesLookUps: Array<IVictimReleaseTypeLookUpIn>
    ): void => {
      applySnapshot(self._victimReleaseTypesLookUps, victimReleaseTypesLookUps)
    }

    const setRefferalOrgTypes = (
        refferalOrgTypes: Array<IRefferalOrgTypeLookUpSnapshotIn>
    ): void => {
      applySnapshot(self._refferalOrgTypes, refferalOrgTypes)
    }

    const getIndicatorTargetYears = async () => {
      const indicatorTargetYearsRequest = fromPromise(
        apiV1.get<Array<IIndicatorTargetYearsSnapshotIn>>(
          `${LOOKUPS_API}/indicatortargetyears`,
        ),
      )

      when(() =>
        indicatorTargetYearsRequest.case({
          fulfilled: response => {
            setIndicatorTargetYears(response.data)
            return true
          },
        }),
      )
      return indicatorTargetYearsRequest
    }

    const getIndicatorTargetCountries = async () => {
      const indicatorTargetCountriesRequest = fromPromise(
        apiV1.get<Array<IIndicatorTargetCountriesSnapshotIn>>(
          `${LOOKUPS_API}/indicatortargetcountries`,
        ),
      )

      when(() =>
        indicatorTargetCountriesRequest.case({
          fulfilled: response => {
            setIndicatorTargetCountries(response.data)
            return true
          },
        }),
      )
      return indicatorTargetCountriesRequest
    }

    const getReportingYearsLookUps = async () => {
      const reportingYearsLookUpRequest = fromPromise(
        apiV1.get<Array<IReportingYearLookUpSnapshotIn>>(
          `${LOOKUPS_API}/reportingyears`,
        ),
      )

      when(() =>
        reportingYearsLookUpRequest.case({
          fulfilled: response => {
            setReportingLookUpYears(response.data)
            return true
          },
        }),
      )
      return reportingYearsLookUpRequest
    }

    const getReportingQuartersLookUps = async () => {
      const reportingQuartersLookUpsRequest = fromPromise(
        apiV1.get<Array<IReportingQuartersLookUpSnapshotIn>>(
          `${LOOKUPS_API}/reportingquarters`,
        ),
      )

      when(() =>
        reportingQuartersLookUpsRequest.case({
          fulfilled: response => {
            setReportingQuartersLookUps(response.data)
            return true
          },
        }),
      )
      return reportingQuartersLookUpsRequest
    }

    const getRegionsLookUpsByCountryId = async (countryId: number) => {
      const regionsLookUpsRequest = fromPromise(
        apiV1.get<Array<IRegionLookUpSnapshotIn>>(
          `${LOOKUPS_API}/regionsbycountryid`,
          {
            params: { id: countryId },
          },
        ),
      )

      when(() =>
        regionsLookUpsRequest.case({
          fulfilled: response => {
            setRegionsLookUps(response.data)
            return true
          },
        }),
      )
      return regionsLookUpsRequest
    }

    const getRegionsLookUpsByOrganizationId = async (
      organizationId: number,
    ) => {
      const regionsLookUpsRequest = fromPromise(
        apiV1.get<Array<IRegionLookUpSnapshotIn>>(
          `${LOOKUPS_API}/regionsbyorganizationid`,
          {
            params: { organizationId },
          },
        ),
      )

      when(() =>
        regionsLookUpsRequest.case({
          fulfilled: response => {
            setRegionsLookUps(response.data)
            return true
          },
        }),
      )
      return regionsLookUpsRequest
    }

    const getOrganizationTypesLookUps = async () => {
      const organizationTypesLookUpsRequest = fromPromise(
        apiV1.get<Array<IOrganizationTypesLookUpIn>>(
          `${LOOKUPS_API}/organizationtypes`,
        ),
      )

      when(() =>
        organizationTypesLookUpsRequest.case({
          fulfilled: response => {
            setOrganizationTypesLookUps(response.data)
            return true
          },
        }),
      )
      return organizationTypesLookUpsRequest
    }

    const getOrganizationServiceTypesLookUps = async () => {
      const organizationServiceTypesLookUpsRequest = fromPromise(
        apiV1.get<Array<IOrganizationServiceTypesLookUpSnapshotIn>>(
          `${LOOKUPS_API}/servicetypes`,
        ),
      )

      when(() =>
        organizationServiceTypesLookUpsRequest.case({
          fulfilled: response => {
            setOrganizationServiceTypesLookUps(response.data)
            return true
          },
        }),
      )
      return organizationServiceTypesLookUpsRequest
    }

    const getOrganizationServiceTypesByIdLookUps = async (
      organizationId: number,
    ) => {
      const organizationServiceTypesLookUpsRequest = fromPromise(
        apiV1.get<Array<IOrganizationServiceTypesLookUpSnapshotIn>>(
          `${LOOKUPS_API}/organizationservicetypes`,
          {
            params: { organizationId },
          },
        ),
      )

      when(() =>
        organizationServiceTypesLookUpsRequest.case({
          fulfilled: response => {
            setOrganizationServiceTypesLookUps(response.data)
            return true
          },
        }),
      )
      return organizationServiceTypesLookUpsRequest
    }

    const getPartnerStaffListLookUps = async (partnerId: number) => {
      const partnerStaffListLookUpsRequest = fromPromise(
        apiV1.get<Array<IOrganizationStaffLookUpModelSnapshotIn>>(
          `${LOOKUPS_API}/staffbypartnerid`,
          {
            params: { partnerId },
          },
        ),
      )

      when(() =>
        partnerStaffListLookUpsRequest.case({
          fulfilled: response => {
            setPartnerStaffLookUps(response.data)
            return true
          },
        }),
      )
      return partnerStaffListLookUpsRequest
    }

    const getAllOrganizationsStaffListLookUps = async () => {
      const allOrganizationsStaffListLookUpsRequest = fromPromise(
        apiV1.get<Array<IOrganizationStaffLookUpModelSnapshotIn>>(
          `${LOOKUPS_API}/partnersstaff`,
        ),
      )

      when(() =>
        allOrganizationsStaffListLookUpsRequest.case({
          fulfilled: response => {
            setPartnerStaffLookUps(response.data)
            return true
          },
        }),
      )
      return allOrganizationsStaffListLookUpsRequest
    }

    const getActivityStaffLookUps = async (activityId: number) => {
      const activityStaffLookUpsRequest = fromPromise(
        apiV1.get<Array<IOrganizationStaffLookUpModelSnapshotIn>>(
          `${LOOKUPS_API}/staffbyactivityid`,
          {
            params: { id: activityId },
          },
        ),
      )

      when(() =>
        activityStaffLookUpsRequest.case({
          fulfilled: response => {
            setActivityStaffLookUps(response.data)
            return true
          },
        }),
      )
      return activityStaffLookUpsRequest
    }

    const getPartnersBeneficiaryOrganizationsLookUps = async (
      organizationId: number,
    ) => {
      const beneficiaryOrganizationsLookUpsRequest = fromPromise(
        apiV1.get<Array<IBeneficiaryOrganizationLookUpSnapshotIn>>(
          `${LOOKUPS_API}/organizationpartners`,
          {
            params: { id: organizationId },
          },
        ),
      )

      when(() =>
        beneficiaryOrganizationsLookUpsRequest.case({
          fulfilled: response => {
            setBeneficiaryOrganizationsLookUps(response.data)
            return true
          },
        }),
      )

      return beneficiaryOrganizationsLookUpsRequest
    }

    const getCountriesLookUps = async () => {
      const countriesLookUpsRequest = fromPromise(
        apiV1.get<Array<ICountryLookUpSnapshotIn>>(`${LOOKUPS_API}/countries`),
      )

      when(() =>
        countriesLookUpsRequest.case({
          fulfilled: response => {
            setCountriesLookUps(response.data)
            return true
          },
        }),
      )
      return countriesLookUpsRequest
    }

    const getGendersLookUps = async (countryId: number) => {
      const gendersLookUpsRequest = fromPromise(
        apiV1.get<Array<IGenderLookUpSnapshotIn>>(`${LOOKUPS_API}/genders`, {
          params: { countryId },
        }),
      )

      when(() =>
        gendersLookUpsRequest.case({
          fulfilled: response => {
            setGendersLookUps(response.data)
            return true
          },
        }),
      )
      return gendersLookUpsRequest
    }

    const getEthnicitiesLookUps = async () => {
      const ethnicitiesLookUpsRequest = fromPromise(
        apiV1.get<Array<IEthnicityLookUpSnapshotIn>>(
          `${LOOKUPS_API}/ethnicities`,
        ),
      )

      when(() =>
        ethnicitiesLookUpsRequest.case({
          fulfilled: response => {
            setEthnicitiesLookUps(response.data)
            return true
          },
        }),
      )
      return ethnicitiesLookUpsRequest
    }

    const getBeneficiaryCategoriesLookUps = async () => {
      const beneficiaryCategoriesLookUpsRequest = fromPromise(
        apiV1.get<Array<IBeneficiaryCategoryLookUpSnapshotIn>>(
          `${LOOKUPS_API}/beneficiarycategories`,
        ),
      )

      when(() =>
        beneficiaryCategoriesLookUpsRequest.case({
          fulfilled: response => {
            setBeneficiaryCategoriesLookUps(response.data)
            return true
          },
        }),
      )
      return beneficiaryCategoriesLookUpsRequest
    }

    const getExcludedBeneficiaryCategories = async () => {
      const excludedCategoriesRequest = fromPromise(
        apiV1.get<IExcludedSnapshotIn>(`${LOOKUPS_API}/excludedcategories`),
      )

      when(() =>
        excludedCategoriesRequest.case({
          fulfilled: response => {
            setExcludedBeneficiaryCategories(response.data)
            return true
          },
        }),
      )
      return excludedCategoriesRequest
    }

    const getAgeCategoriesLookUps = async () => {
      const ageCategoriesLookUpsRequest = fromPromise(
        apiV1.get<Array<IAgeCategoriesLookUpSnapshotIn>>(`${LOOKUPS_API}/age`),
      )

      when(() =>
        ageCategoriesLookUpsRequest.case({
          fulfilled: response => {
            setAgeCategoriesLookUps(response.data)
            return true
          },
        }),
      )
      return ageCategoriesLookUpsRequest
    }

    const getPartnersLookUps = async () => {
      const partnersLookUpsRequest = fromPromise(
        apiV1.get<Array<IPartnerLookUpSnapshotIn>>(`${LOOKUPS_API}/partners`),
      )

      when(() =>
        partnersLookUpsRequest.case({
          fulfilled: response => {
            setPartnersLookUps(response.data)
            return true
          },
        }),
      )
      return partnersLookUpsRequest
    }

    const getPartnersByRoleLookUps = async () => {
      const partnersLookUpsRequest = fromPromise(
        apiV1.get<Array<IPartnerLookUpSnapshotIn>>(
          `${LOOKUPS_API}/partnersbyrole`,
        ),
      )

      when(() =>
        partnersLookUpsRequest.case({
          fulfilled: response => {
            setPartnersLookUps(response.data)
            return true
          },
        }),
      )
      return partnersLookUpsRequest
    }

    const getRolesList = async () => {
      const usersListRequest = fromPromise(
        apiV1.get<Array<string>>(`${LOOKUPS_API}/roles`),
      )

      when(() =>
        usersListRequest.case({
          fulfilled: response => {
            setRolesList(response.data)
            return true
          },
        }),
      )
      return usersListRequest
    }

    const getApprovalStatusesLookUps = async () => {
      const approvalStatusesRequest = fromPromise(
        apiV1.get<Array<IApprovalStatusLookUpSnapshotIn>>(
          `${LOOKUPS_API}/approvalstatuses`,
        ),
      )

      when(() =>
        approvalStatusesRequest.case({
          fulfilled: response => {
            setApprovalStatusesLookUps(response.data)
            return true
          },
        }),
      )
      return approvalStatusesRequest
    }

    const getActivityParticipantTypesLookUps = async () => {
      const activityParticipantTypesRequest = fromPromise(
        apiV1.get<Array<IActivityParticipantTypeLookUpSnapshotIn>>(
          `${LOOKUPS_API}/activityparticipantstypes`,
        ),
      )

      when(() =>
        activityParticipantTypesRequest.case({
          fulfilled: response => {
            setActivityParticipantTypesLookUps(response.data)
            return true
          },
        }),
      )
      return activityParticipantTypesRequest
    }

    const getMigrationactivitiesLooksUp = async () => {
      const migrationactivitiesRequest = fromPromise(
        apiV1.get<Array<IMigrationactivitiesLooksUpModelSnapshotIn>>(
          `${LOOKUPS_API}/migrationactivities`,
        ),
      )

      when(() =>
        migrationactivitiesRequest.case({
          fulfilled: response => {
            setMigrationactivitiesLooksUps(response.data)
            return true
          },
        }),
      )
      return migrationactivitiesRequest
    }

    const getWinrockassistancerejectionreasonsLooksUps = async () => {
      const winrockassistancerejectionreasonsRequest = fromPromise(
        apiV1.get<Array<IWinrockassistancerejectionreasonsLooksUpModelSnapshotIn>>(
          `${LOOKUPS_API}/winrockassistancerejectionreasons`,
        ),
      )

      when(() =>
        winrockassistancerejectionreasonsRequest.case({
          fulfilled: response => {
            setWinrockassistancerejectionreasonsLooksUps(response.data)
            return true
          },
        }),
      )
      return winrockassistancerejectionreasonsRequest
    }

    const getWinrockassistancerefusereasonsLooksUps = async () => {
      const winrockassistancerefusereasonsRequest = fromPromise(
        apiV1.get<Array<IWinrockassistancerefusereasonsLooksUpModelSnapshotIn>>(
          `${LOOKUPS_API}/winrockassistancerefusereasons`,
        ),
      )

      when(() =>
      winrockassistancerefusereasonsRequest.case({
          fulfilled: response => {
            setWinrockassistancerefusereasonsLooksUps(response.data)
            return true
          },
        }),
      )
      return winrockassistancerefusereasonsRequest
    }

    const getPersonconditionsLooksUps = async () => {
      const personconditionsRequest = fromPromise(
        apiV1.get<Array<IPersonconditionsLooksUpModelSnapshotIn>>(
          `${LOOKUPS_API}/personconditions`,
        ),
      )

      when(() =>
      personconditionsRequest.case({
          fulfilled: response => {
            setPersonconditionsLooksUps(response.data)
            return true
          },
        }),
      )
      return personconditionsRequest
    }

    const getActivityTypesLookUps = async () => {
      const activityTypesRequest = fromPromise(
        apiV1.get<Array<IActivityTypeLookUpSnapshotIn>>(
          `${LOOKUPS_API}/activitytypes`,
        ),
      )

      when(() =>
        activityTypesRequest.case({
          fulfilled: response => {
            setActivityTypesLookUps(response.data)
            return true
          },
        }),
      )
      return activityTypesRequest
    }

    const getIndicatorsLookUps = async () => {
      const indicatorsRequest = fromPromise(
        apiV1.get<Array<IApprovalStatusLookUpSnapshotIn>>(
          `${LOOKUPS_API}/indicators`,
        ),
      )

      when(() =>
        indicatorsRequest.case({
          fulfilled: response => {
            setIndicatorsLookUps(response.data)
            return true
          },
        }),
      )
      return indicatorsRequest
    }

    const getMonthsLookUps = async () => {
      const monthsRequest = fromPromise(
        apiV1.get<Array<IMonthLookUpSnapshotIn>>(`${LOOKUPS_API}/months`),
      )

      when(() =>
        monthsRequest.case({
          fulfilled: response => {
            setMonthsLookUps(response.data)
            return true
          },
        }),
      )
      return monthsRequest
    }

    const getFileUploadMaxSizeInMB = async () => {
      const fileUploadMaxSizeRequest = fromPromise(
        apiV1.get<number>(`${LOOKUPS_API}/fileuploadmaxsize/megabytes`),
      )

      when(() =>
        fileUploadMaxSizeRequest.case({
          fulfilled: response => {
            self.setFileUploadMaxSizeInMB(response.data)
            return true
          },
        }),
      )
      return fileUploadMaxSizeRequest
    }

    const getRelationshipEstablishmentLookUps = async () => {
      const indicatorsRequest = fromPromise(
          apiV1.get<Array<IRelationshipEstablishmentlookUpSnapshotIn>>(
              `${LOOKUPS_API}/relationshipestablishments`,
          ),
      )

      when(() =>
          indicatorsRequest.case({
            fulfilled: response => {
              setRelationshipEstablishment(response.data)
              return true
            },
          }),
      )
      return indicatorsRequest
    }

    const getWorkTypesLookUps = async () => {
      const indicatorsRequest = fromPromise(
          apiV1.get<Array<IWorkTypesLookUpModelLookUpSnapshotIn>>(
              `${LOOKUPS_API}/worktypes`,
          ),
      )

      when(() =>
          indicatorsRequest.case({
            fulfilled: response => {
              setWorkTypes(response.data)
              return true
            },
          }),
      )
      return indicatorsRequest
    }

    const getLegalEntityTypes = async () => {
      const indicatorsRequest = fromPromise(
          apiV1.get<Array<ILegalEntityTypeLookUpSnapshotIn>>(
              `${LOOKUPS_API}/recruiterlegalentitytypes`,
          ),
      )

      when(() =>
          indicatorsRequest.case({
            fulfilled: response => {
              // @ts-ignore
              setLegalEntityTypes(response.data)
              return true
            },
          }),
      )
      return indicatorsRequest
    }

    const getRelationshipTypesLookUps = async () => {
      const indicatorsRequest = fromPromise(
          apiV1.get<Array<IRelationshipTypeLookUpSnapshotIn>>(
              `${LOOKUPS_API}/relationshiptypes`,
          ),
      )

      when(() =>
          indicatorsRequest.case({
            fulfilled: response => {
              // @ts-ignore
              setRelationshipTypesLookUps(response.data)
              return true
            },
          }),
      )
      return indicatorsRequest
    }

    const getRecruitmentToolsLookUps = async () => {
      const indicatorsRequest = fromPromise(
          apiV1.get<Array<IRecruitmentToolLookUpSnapshotIn>>(
              `${LOOKUPS_API}/recruitmenttools`,
          ),
      )

      when(() =>
          indicatorsRequest.case({
            fulfilled: response => {
              // @ts-ignore
              setRecruitmentToolsLookUps(response.data)
              return true
            },
          }),
      )
      return indicatorsRequest
    }

    const getTravelMethodsLookUps = async () => {
      const indicatorsRequest = fromPromise(
          apiV1.get<Array<ITravelMethodLookUpSnapshotIn>>(
              `${LOOKUPS_API}/travelmethods`,
          ),
      )

      when(() =>
          indicatorsRequest.case({
            fulfilled: response => {
              // @ts-ignore
              setTravelMethodsLookUps(response.data)
              return true
            },
          }),
      )
      return indicatorsRequest
    }

    const getTransportationControlToolsLookUps = async () => {
      const indicatorsRequest = fromPromise(
          apiV1.get<Array<ITransportationControlToolLookUpSnapshotIn>>(
              `${LOOKUPS_API}/transportationcontroltools`,
          ),
      )

      when(() =>
          indicatorsRequest.case({
            fulfilled: response => {
              // @ts-ignore
              setTransportationControlToolsLookUps(response.data)
              return true
            },
          }),
      )
      return indicatorsRequest
    }

    const getExploitationControlToolsLookUps = async () => {
      const indicatorsRequest = fromPromise(
        apiV1.get<Array<IExplotationControlToolLookUpSnapshotIn>>(
          `${LOOKUPS_API}/exploitationcontroltools`,
        ),
      )

      when(() =>
        indicatorsRequest.case({
          fulfilled: response => {
            // @ts-ignore
            setExplotationControlToolsLookUps(response.data)
            return true
          },
        }),
      )
      return indicatorsRequest
    }

    const getTraffickingFormsLookUps = async () => {
      const indicatorsRequest = fromPromise(
          apiV1.get<Array<ITraffickingFormLookUpIn>>(
              `${LOOKUPS_API}/traffickingforms`,
          ),
      )

      when(() =>
          indicatorsRequest.case({
            fulfilled: response => {
              // @ts-ignore
              setTraffickingFormsLookUps(response.data)
              return true
            },
          }),
      )
      return indicatorsRequest
    }

    const getExploitationConditionsLookUps = async () => {
      const indicatorsRequest = fromPromise(
          apiV1.get<Array<IExploitationConditionLookUpIn>>(
              `${LOOKUPS_API}/exploitationconditions`,
          ),
      )

      when(() =>
          indicatorsRequest.case({
            fulfilled: response => {
              // @ts-ignore
              setExploitationConditionsLookUps(response.data)
              return true
            },
          }),
      )
      return indicatorsRequest
    }

    const getVictimReleaseTypesLookUps = async () => {
      const indicatorsRequest = fromPromise(
          apiV1.get<Array<IVictimReleaseTypeLookUpIn>>(
              `${LOOKUPS_API}/victimreleasetypes`,
          ),
      )

      when(() =>
          indicatorsRequest.case({
            fulfilled: response => {
              // @ts-ignore
              setVictimReleaseTypesLookUps(response.data)
              return true
            },
          }),
      )
      return indicatorsRequest
    }

    const getRefferalOrgTypesLookUps = async () => {
      const indicatorsRequest = fromPromise(
          apiV1.get<Array<IRefferalOrgTypeLookUpSnapshotIn>>(
              `${LOOKUPS_API}/refferalorgtypes`,
          ),
      )

      when(() =>
          indicatorsRequest.case({
            fulfilled: response => {
              setRefferalOrgTypes(response.data)
              return true
            },
          }),
      )
      return indicatorsRequest
    }

    return {
      getIndicatorTargetCountries,
      getIndicatorTargetYears,
      getRegionsLookUpsByCountryId,
      getAgeCategoriesLookUps,
      getPartnersLookUps,
      getOrganizationTypesLookUps,
      getOrganizationServiceTypesLookUps,
      getCountriesLookUps,
      getGendersLookUps,
      getEthnicitiesLookUps,
      getBeneficiaryCategoriesLookUps,
      getExcludedBeneficiaryCategories,
      getRolesList,
      getRegionsLookUpsByOrganizationId,
      getOrganizationServiceTypesByIdLookUps,
      getPartnerStaffListLookUps,
      getActivityParticipantTypesLookUps,
      getMigrationactivitiesLooksUp,
      getWinrockassistancerejectionreasonsLooksUps,
      getWinrockassistancerefusereasonsLooksUps,
      getPersonconditionsLooksUps,
      getApprovalStatusesLookUps,
      getActivityTypesLookUps,
      getPartnersBeneficiaryOrganizationsLookUps,
      getIndicatorsLookUps,
      getAllOrganizationsStaffListLookUps,
      getActivityStaffLookUps,
      getFileUploadMaxSizeInMB,
      getReportingYearsLookUps,
      getReportingQuartersLookUps,
      getPartnersByRoleLookUps,
      getMonthsLookUps,
      setRegionsLookUps,
      getRelationshipEstablishmentLookUps,
      getWorkTypesLookUps,
      getLegalEntityTypes,
      getRelationshipTypesLookUps,
      getRecruitmentToolsLookUps,
      getTravelMethodsLookUps,
      getTransportationControlToolsLookUps,
      getExploitationControlToolsLookUps,
      getTraffickingFormsLookUps,
      getExploitationConditionsLookUps,
      getVictimReleaseTypesLookUps,
      getRefferalOrgTypesLookUps
    }
  })
  .views(self => ({
    get indicatorTargetYears(): Array<IIndicatorTargetYearsSnapshotOut> {
      return getSnapshot(self._indicatorTargetYears)
    },

    get indicatorTargetCountries(): Array<IIndicatorTargetCountriesSnapshotOut> {
      return getSnapshot(self._indicatorTargetCountries)
    },

    get regionsLookUps(): Array<IRegionLookUpSnapshotOut> {
      return getSnapshot(self._regionsLookUps)
    },
    get ageCategoriesLookUps(): Array<IAgeCategoriesLookUpSnapshotOut> {
      return getSnapshot(self._ageCategoriesLookUps)
    },
    get partnersLookUps(): Array<IPartnerLookUpSnapshotOut> {
      return getSnapshot(self._partnersLookUps)
    },
    get organizationTypesLookUps(): Array<IOrganizationTypesLookUpOut> {
      return getSnapshot(self._organizationTypesLookUps)
    },
    get organizationServiceTypesLookUps(): Array<IOrganizationServiceTypesLookUpSnapshotOut> {
      return getSnapshot(self._organizationServiceTypesLookUps)
    },
    get countriesLookUps(): Array<ICountryLookUpSnapshotOut> {
      return getSnapshot(self._countriesLookUps)
    },
    get gendersLookUps(): Array<IGenderLookUpSnapshotOut> {
      return getSnapshot(self._gendersLookUps)
    },
    get ethnicitiesLookUps(): Array<IEthnicityLookUpSnapshotOut> {
      return getSnapshot(self._ethnicitiesLookUps)
    },
    get beneficiaryCategoriesLookUps(): Array<IBeneficiaryCategoryLookUpSnapshotOut> {
      return getSnapshot(self._beneficiaryCategoriesLookUps)
    },
    get excludedBeneficiaryCategories(): IExcludedSnapshotOut {
      return getSnapshot(self._excludedBeneficiaryCategories)
    },
    get rolesList() {
      return getSnapshot(self._rolesList)
    },
    get partnerStaffListLookUps(): Array<IOrganizationStaffLookUpModelSnapshotOut> {
      return getSnapshot(self._partnerStaffLookUps)
    },
    get activityStaffLookUps(): Array<IOrganizationStaffLookUpModelSnapshotOut> {
      return getSnapshot(self._activityStaffLookUps)
    },
    get activityParticipantTypesLookUps() {
      return getSnapshot(self._activityParticipantTypesLookUps)
    },
    get winrockassistancerejectionreasonsLooksUps(){
      return getSnapshot(self._winrockassistancerejectionreasonsLooksUps)
    },
    get winrockassistancerefusereasonsLooksUps(){
      return getSnapshot(self._winrockassistancerefusereasonsLooksUps)
    },
    get migrationactivitiesLooksUps(){
      return getSnapshot(self._migrationactivitiesLooksUps)
    },
    get personconditionsLooksUps(){
      return getSnapshot(self._personconditionsLooksUps)
    },
    get approvalStatusesLookUps() {
      return getSnapshot(self._approvalStatusesLookUps)
    },
    get activityTypesLookUps() {
      return getSnapshot(self._activityTypesLookUps)
    },
    get beneficiaryOrganizationsLookUps() {
      return getSnapshot(self._beneficiaryOrganizationsLookUps)
    },
    get reportingYearsLookUps() {
      return getSnapshot(self._reportingYearsLookUps)
    },
    get reportingQuartersLookUps() {
      return getSnapshot(self._reportingQuartersLookUps)
    },
    get indicatorsLookUps() {
      return getSnapshot(self._indicatorsLookUps)
    },
    get monthsLookUps() {
      return getSnapshot(self._monthsLookUps)
    },
    get fileUploadMaxSizeInMB() {
      return self._fileUploadMaxSizeInMB
    },
    getMonthsLookUpsByQuarter(quarter: number): Array<IMonthLookUpSnapshotOut> {
      if (!this.monthsLookUps.length) return []
      const monthsChunk = chunk(this.monthsLookUps, 3)
      const monthByQuartersChunk = [monthsChunk[3], ...monthsChunk.slice(0, 3)]
      return monthByQuartersChunk[quarter - 1] || []
    },
    get relationshipEstablishments(): Array<IRelationshipEstablishmentlookUpSnapshotOut> {
      return self._relationshipEstablishments
    },
    get workTypes(): Array<IWorkTypesLookUpModellookUpSnapshotOut> {
      return self._workTypes
    },
    get legalEntityTypes(): Array<ILegalEntityTypeLookUpSnapshotOut> {
      return self._legalEntityTypes
    },
    get relationshipTypesLookUps(): Array<IRelationshipTypeLookUpSnapshotOut> {
      return self._relationshipTypesLookUps
    },
    get recruitmentToolsLookUps(): Array<IRecruitmentToolLookUpSnapshotOut> {
      return self._recruitmentToolsLookUps
    },
    get travelMethodsLookUps(): Array<ITravelMethodLookUpSnapshotOut> {
      return self._travelMethodsLookUps
    },
    get transportationControlToolsLookUps(): Array<ITransportationControlToolLookUpSnapshotOut> {
      return self._transportationControlToolsLookUps
    },
    get explotationControlToolsLookUps(): Array<IExplotationControlToolLookUpSnapshotOut> {
      return self._explotationControlToolsLookUps
    },
    get traffickingFormsLookUps(): Array<ITraffickingFormLookUpOut> {
      return self._traffickingFormsLookUps
    },
    get exploitationConditionsLookUps(): Array<IExploitationConditionLookUpOut> {
      return self._exploitationConditionsLookUps
    },
    get victimReleaseTypesLookUps(): Array<IVictimReleaseTypeLookUpOut> {
      return self._victimReleaseTypesLookUps
    },
    get refferalOrgTypes(): Array<IRefferalOrgTypeLookUpSnapshotOut> {
      return self._refferalOrgTypes
    }
  }))

export default LookUpStore

export interface ILookUpStore extends Instance<typeof LookUpStore> {}
