enum ActivityTestType {
  BeneficiaryIndividualPreTest = 'beneficiaryIndividualPreTest',
  BeneficiaryIndividualPostTest = 'beneficiaryIndividualPostTest',
  StaffPreTest = 'staffPreTest',
  StaffPostTest = 'staffPostTest',
  SingleBeneficiaryIndividualTest = 'singleBeneficiaryIndividualTest',
  SingleStaffTest = 'singleStaffTest',
}

export default ActivityTestType
