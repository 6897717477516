import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import BeneficiaryOrganizationStaffWithNameModel from './BeneficiaryOrganizationStaffWithNameModel'

const BeneficiaryOrganizationStaffDetailsModel = BeneficiaryOrganizationStaffWithNameModel.named(
  'BeneficiaryOrganizationStaffDetailsModel',
).props({
  memberType: types.maybeNull(types.string),
  memberTypeId: types.optional(types.number, 0),
})

export interface IBeneficiaryOrganizationStaffDetailsModel
  extends Instance<typeof BeneficiaryOrganizationStaffDetailsModel> {}

export interface IBeneficiaryOrganizationStaffDetailsSnapshotIn
  extends SnapshotIn<typeof BeneficiaryOrganizationStaffDetailsModel> {}

export interface IBeneficiaryOrganizationStaffDetailsSnapshotOut
  extends SnapshotOut<typeof BeneficiaryOrganizationStaffDetailsModel> {}

export default BeneficiaryOrganizationStaffDetailsModel
