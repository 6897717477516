import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const IndustryTypeLookUpModel = LookUpModel.named(
  'IndustryTypeLookUpModel',
).props({
  isComment: types.boolean,
})

export interface IIndustryTypeLookUpModel
  extends Instance<typeof IndustryTypeLookUpModel> {}
export interface IIndustryTypeLookUpSnapshotIn
  extends SnapshotIn<typeof IndustryTypeLookUpModel> {}
export interface IIndustryTypeLookUpSnapshotOut
  extends SnapshotOut<typeof IndustryTypeLookUpModel> {}
export default IndustryTypeLookUpModel
