import { ReactElement } from 'react'
import {
  ExcelIcon,
  PNGIcon,
  DOCIcon,
  TXTIcon,
  JPGIcon,
  GIFIcon,
  PDFIcon,
  PPTIcon,
  RARIcon,
  ZIPIcon,
  CSVIcon,
} from '../../icons'

type FileType =
  | '.pdf'
  | '.doc'
  | '.docx'
  | '.xls'
  | '.xlsx'
  | '.txt'
  | '.jpg'
  | '.png'
  | '.gif'
  | '.ppt'
  | '.pptx'
  | '.zip'
  | '.rar'
  | '.csv'

export const fileTypeIcons = new Map<FileType | string, ReactElement>([
  ['.png', <PNGIcon />],
  ['.xls', <ExcelIcon />],
  ['.xlsx', <ExcelIcon />],
  ['.doc', <DOCIcon />],
  ['.docx', <DOCIcon />],
  ['.txt', <TXTIcon />],
  ['.jpg', <JPGIcon />],
  ['.gif', <GIFIcon />],
  ['.pdf', <PDFIcon />],
  ['.ppt', <PPTIcon />],
  ['.pptx', <PPTIcon />],
  ['.rar', <RARIcon />],
  ['.zip', <ZIPIcon />],
  ['.csv', <CSVIcon />],
])
