import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const ReportingQuartersLookUpModel = LookUpModel.named(
  'ReportingQuartersLookUpModel',
).props({
  isComment: types.boolean,
})

export interface IReportingQuartersLookUpModel
  extends Instance<typeof ReportingQuartersLookUpModel> {}
export interface IReportingQuartersLookUpSnapshotIn
  extends SnapshotIn<typeof ReportingQuartersLookUpModel> {}
export interface IReportingQuartersLookUpSnapshotOut
  extends SnapshotOut<typeof ReportingQuartersLookUpModel> {}
export default ReportingQuartersLookUpModel
