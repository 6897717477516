import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const AdvocacyIssueLookUpModel = LookUpModel.named(
  'AdvocacyIssueLookUpModel',
).props({
  isComment: types.boolean,
})

export interface IAdvocacyIssueLookUpModel
  extends Instance<typeof AdvocacyIssueLookUpModel> {}
export interface IAdvocacyIssueLookUpSnapshotIn
  extends SnapshotIn<typeof AdvocacyIssueLookUpModel> {}
export interface IAdvocacyIssueLookUpSnapshotOut
  extends SnapshotOut<typeof AdvocacyIssueLookUpModel> {}
export default AdvocacyIssueLookUpModel
