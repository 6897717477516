import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../PagedListRequestModel'
import ServiceHistoryWithStatusListFilterModel from './ServiceHistoryWithStatusListFilterModel'

const ServiceHistoryWithStatusListRequestModel = PagedListRequestModel.named(
  'ServiceHistoryWithStatusListRequestModel',
).props({
  sortBy: types.optional(types.string, ''),
  filter: types.optional(ServiceHistoryWithStatusListFilterModel, {}),
})

export interface IServiceHistoryWithStatusListRequestModel
  extends Instance<typeof ServiceHistoryWithStatusListRequestModel> {}

export interface IServiceHistoryWithStatusListRequestModelIn
  extends SnapshotIn<typeof ServiceHistoryWithStatusListRequestModel> {}

export interface IServiceHistoryWithStatusListRequestModelOut
  extends SnapshotOut<typeof ServiceHistoryWithStatusListRequestModel> {}

export default ServiceHistoryWithStatusListRequestModel
