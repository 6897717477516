import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { GenericWithParentIdLookUpDto } from '../../../lookUps'
import GenericWithCommentLookUpDto from '../GenericWithCommentLookUpDto'
import IndicatorBaseDtoModel from '../IndicatorBaseDtoModel'

const Indicator1_2_1DtoModel = IndicatorBaseDtoModel.named(
  'Indicator1_2_1DtoModel',
).props({
  organizationId: types.optional(types.integer, 0),
  organization: types.maybeNull(types.string),
  recordDate: types.maybeNull(types.string),
  organizationTypeId: types.optional(types.integer, 0),
  organizationType: types.maybeNull(types.string),
  organizationTypeParentId: types.maybeNull(types.number),
  improvementAreas: types.optional(
    types.array(GenericWithCommentLookUpDto),
    [],
  ),
  supportTypes: types.optional(types.array(GenericWithParentIdLookUpDto), []),
  notes: types.maybeNull(types.string),
  hasSupportedDocuments: types.optional(types.boolean, false),
})

export interface IIndicator1_2_1DtoModel
  extends Instance<typeof Indicator1_2_1DtoModel> {}
export interface IIndicator1_2_1DtoModelSnapshotIn
  extends SnapshotIn<typeof Indicator1_2_1DtoModel> {}
export interface IIndicator1_2_1DtoModelSnapshotOut
  extends SnapshotOut<typeof Indicator1_2_1DtoModel> {}
export default Indicator1_2_1DtoModel
