import { FC, useEffect, useState } from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { useIntl } from 'react-intl'
import CrudButton from '../CrudButton'

type FileDownloadButtonProps = {
  onDownload: (
    onDownloadProgress: (progressEvent: ProgressEvent) => void,
  ) => void
}

const FileDownloadButton: FC<FileDownloadButtonProps> = ({ onDownload }) => {
  const intl = useIntl()
  const [progress, setProgress] = useState<number>(0)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setIsDownloading(false)
        setProgress(0)
      }, 500)
    }
  }, [progress])

  const onDownloadProgress = (progressEvent: ProgressEvent) => {
    const { loaded, total } = progressEvent
    setProgress(Math.floor((loaded * 100) / total))
  }

  const onDownloadHandler = () => {
    onDownload(onDownloadProgress)
    setIsDownloading(true)
  }

  return !isDownloading ? (
    <CrudButton
      onClick={onDownloadHandler}
      tooltip={intl.formatMessage({
        id: 'global.download',
        defaultMessage: 'Скачать',
      })}
      iconClassName="ms-Icon ms-Icon--Download"
    />
  ) : (
    <div style={{ width: '1.2rem', display: 'inline-block', margin: '0 5px' }}>
      <CircularProgressbarWithChildren value={progress}>
        <CrudButton
          tooltip={`${progress}%`}
          iconClassName="ms-Icon ms-Icon--Download"
          iconStyle={
            isDownloading
              ? {
                  fontSize: '0.8rem',
                  marginBottom: '0.4rem',
                  marginLeft: '0.1rem',
                }
              : {}
          }
        />
      </CircularProgressbarWithChildren>
    </div>
  )
}

export default FileDownloadButton
