import { FC, useMemo } from 'react'
import Select from 'react-select'
import { StylesConfig } from 'react-select'

const backgroundOptionColor = '#f8f9fa'
const backgroundOptionSelectedColor = '#eeeeee'
const controlTextColor = '#201F1E'

export const styles: StylesConfig<any, false, any> = {
  container: base => ({
    ...base,
    border: 'none',
    width: '5rem',
    fontWeight: 'normal',
    fontSize: '14px',
  }),
  control: base => ({
    ...base,
    border: 'none',
    backgroundColor: '#fff',
    boxShadow: 'none',
    color: controlTextColor,
    alignItems: 'center',
    borderRadius: '0',
  }),
  valueContainer: base => ({
    ...base,
    fontWeight: 400,
    fontSize: '1.2rem',
    padding: '0 5px',
  }),
  input: base => ({
    ...base,
    color: controlTextColor,
  }),
  singleValue: base => ({
    ...base,
    color: controlTextColor,
  }),
  placeholder: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: base => ({
    ...base,
    border: 'none',
    backgroundColor: 'transparent',
    color: '#201F1E',
    padding: '5px 2px',
    display: 'flex',
    alignItems: 'center',
  }),
  option: (base, state) => ({
    ...base,
    color: controlTextColor,
    backgroundColor: state.isSelected
      ? backgroundOptionSelectedColor
      : state.isFocused
      ? backgroundOptionColor
      : '#fff',
  }),
  menu: base => ({
    ...base,
    padding: '0',
    margin: '0',
    borderRadius: '0',
  }),
}

type YearDropdownOption = {
  label: number
  value: number
}

type YearDropdownProps = {
  minYear: number
  maxYear: number
  currentYear: number
  onChange: (selectedYear: YearDropdownOption | null) => void
}

const YearSelect: FC<YearDropdownProps> = ({
  minYear,
  maxYear,
  currentYear,
  onChange,
}) => {
  const yearDropdownOptions = useMemo(
    () =>
      Array(maxYear - minYear + 1)
        .fill(null)
        .map((item, index) => {
          const year = minYear + index
          return { value: year, label: year }
        })
        .reverse(),
    [maxYear, minYear],
  )

  return (
    <Select
      styles={styles}
      value={{ label: currentYear, value: currentYear }}
      defaultValue={yearDropdownOptions[yearDropdownOptions.length - 1]}
      options={yearDropdownOptions}
      onChange={onChange}
    />
  )
}

export default YearSelect
