import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import { apiV1 } from 'core/requests'
import {
  CreateServiceHistoryCommand,
  ICreateServiceHistoryCommandSnapshotIn,
  IServiceHistoryListRequestModelIn,
  IServiceHistoryModelSnapshotOut,
  IServiceHistoryPagedDtoSnapshotIn,
  IUpdateServiceHistoryCommandSnapshotIn,
  ServiceHistoryListRequestModel,
  ServiceHistoryPagedDto,
  UpdateServiceHistoryCommand,
} from 'stores/models'

const PartnersServiceHistoryStore = types
  .model('PartnersServiceHistoryStore')
  .props({
    _isLoading: types.optional(types.boolean, false),
    _serviceHistoryList: types.optional(ServiceHistoryPagedDto, {}),
    _serviceHistoryCounter: types.optional(types.number, 0),
  })
  .actions(self => ({
    setServiceHistoryCounter(serviceHistoryCounter: number): void {
      self._serviceHistoryCounter = serviceHistoryCounter
    },
    setServiceHistoryList(
      serviceHistoryList: IServiceHistoryPagedDtoSnapshotIn,
    ): void {
      applySnapshot(self._serviceHistoryList, serviceHistoryList)
    },
  }))
  .actions(self => {
    const SERVICE_HISTORY_API = '/api/partners/servicehistories'

    const setIsLoading = (isLoading: boolean) => {
      self._isLoading = isLoading
    }

    const getServiceHistoryList = async (
      params: IServiceHistoryListRequestModelIn,
    ) => {
      const serviceHistoryListRequest = fromPromise(
        apiV1.get<IServiceHistoryPagedDtoSnapshotIn>(`${SERVICE_HISTORY_API}`, {
          params: ServiceHistoryListRequestModel.create(params),
        }),
      )

      when(() =>
        serviceHistoryListRequest.case({
          fulfilled: response => {
            self.setServiceHistoryList(response.data)
            return true
          },
        }),
      )
      return serviceHistoryListRequest
    }

    const getServiceHistoryWithNameList = async (
      params: IServiceHistoryListRequestModelIn,
    ) => {
      const serviceHistoryWithNameListRequest = fromPromise(
        apiV1.get<IServiceHistoryPagedDtoSnapshotIn>(
          `${SERVICE_HISTORY_API}/withfullname`,
          {
            params: ServiceHistoryListRequestModel.create(params),
          },
        ),
      )

      when(() =>
        serviceHistoryWithNameListRequest.case({
          fulfilled: response => {
            self.setServiceHistoryList(response.data)
            return true
          },
        }),
      )
      return serviceHistoryWithNameListRequest
    }

    const editServiceHistory = async (
      serviceHistory: IUpdateServiceHistoryCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.put(
        SERVICE_HISTORY_API,
        UpdateServiceHistoryCommand.create(serviceHistory),
        {
          params: { id: serviceHistory.id },
        },
      )

    const createServiceHistory = async (
      serviceHistory: ICreateServiceHistoryCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.post(
        SERVICE_HISTORY_API,
        CreateServiceHistoryCommand.create(serviceHistory),
      )

    const pendServiceHistory = async (
      id: IServiceHistoryModelSnapshotOut['id'],
    ): Promise<void> => apiV1.put(`${SERVICE_HISTORY_API}/pend`, { id })

    const deleteServiceHistory = async (id: number): Promise<void> =>
      apiV1.delete(SERVICE_HISTORY_API, {
        params: { id },
      })

    const getServiceHistoryCounter = async (organizationId: number) => {
      const serviceHistoryCounterRequest = fromPromise(
        apiV1.get<number>(`${SERVICE_HISTORY_API}/count`, {
          params: { organizationId },
        }),
      )

      when(() =>
        serviceHistoryCounterRequest.case({
          fulfilled: response => {
            self.setServiceHistoryCounter(response.data)
            return true
          },
        }),
      )
      return serviceHistoryCounterRequest
    }

    return {
      setIsLoading,
      getServiceHistoryWithNameList,
      createServiceHistory,
      editServiceHistory,
      getServiceHistoryList,
      deleteServiceHistory,
      pendServiceHistory,
      getServiceHistoryCounter,
    }
  })
  .views(self => ({
    get isLoading() {
      return self._isLoading
    },
    get serviceHistory() {
      return getSnapshot(self._serviceHistoryList)
    },
    get serviceHistoryList() {
      return this.serviceHistory.data
    },
    get serviceHistoryMetadata() {
      return this.serviceHistory.metadata
    },
    get serviceHistoryCounter() {
      return self._serviceHistoryCounter
    },
  }))

export default PartnersServiceHistoryStore
export interface IPartnersServiceHistoryStore
  extends Instance<typeof PartnersServiceHistoryStore> {}
