import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const BeneficiaryServiceHistoryRequestModel = types.model(
  'BeneficiaryServiceHistoryRequestModel',
  {
    organizationId: types.maybeNull(types.number),
    beneficiaryIndividualId: types.number,
    sortBy: types.optional(types.string, ''),
  },
)

export interface IBeneficiaryServiceHistoryRequestModel
  extends Instance<typeof BeneficiaryServiceHistoryRequestModel> {}

export interface IBeneficiaryServiceHistoryRequestModelIn
  extends SnapshotIn<typeof BeneficiaryServiceHistoryRequestModel> {}

export interface IBeneficiaryServiceHistoryRequestModelOut
  extends SnapshotOut<typeof BeneficiaryServiceHistoryRequestModel> {}

export default BeneficiaryServiceHistoryRequestModel
