import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import { apiV1 } from 'core/requests'
import {
  IActivitySnapshotOut,
  CreateIndicator1_1_1Command,
  ICreateIndicator1_1_1CommandSnapshotIn,
  IAllIndicatorsPagedDtoSnapshotIn,
  AllIndicatorsPagedDto,
  IAllIndicatorsListRequestSnapshotIn,
  AllIndicatorsListRequestModel,
  IAllIndicatorsPagedDtoSnapshotOut,
  Indicator1_1_1DtoModel,
  IIndicator1_1_1DtoModelSnapshotIn,
  IUpdateIndicator1_1_1CommandSnapshotIn,
  IIndicator1_1_1DtoModelSnapshotOut,
  UpdateIndicator1_1_1Command,
  Indicator1_2_1DtoModel,
  IIndicator1_2_1DtoModelSnapshotIn,
  IUpdateIndicator1_2_1CommandSnapshotIn,
  IIndicator1_2_1DtoModelSnapshotOut,
  UpdateIndicator1_2_1Command,
  CreateIndicator1_2_1Command,
  ICreateIndicator1_2_1CommandSnapshotIn,
  Indicator1_2_2DtoModel,
  IIndicator1_2_2DtoModelSnapshotIn,
  IUpdateIndicator1_2_2CommandSnapshotIn,
  IIndicator1_2_2DtoModelSnapshotOut,
  UpdateIndicator1_2_2Command,
  ICreateIndicator1_2_2CommandSnapshotIn,
  CreateIndicator1_2_2Command,
  Indicator2_2_1DtoModel,
  IIndicator2_2_1DtoModelSnapshotIn,
  ICreateIndicator2_2_1CommandSnapshotIn,
  IIndicator2_2_1DtoModelSnapshotOut,
  CreateIndicator2_2_1Command,
  IUpdateIndicator2_2_1CommandSnapshotIn,
  UpdateIndicator2_2_1Command,
  ICreateIndicator2_2_3CommandSnapshotIn,
  IIndicator2_2_3DtoModelSnapshotOut,
  CreateIndicator2_2_3Command,
  IUpdateIndicator2_2_3CommandSnapshotIn,
  UpdateIndicator2_2_3Command,
  IIndicator2_2_3DtoModelSnapshotIn,
  Indicator2_2_3DtoModel,
  IAllIndicatorsModelOut,
  Indicator3_1_1DtoModel,
  IIndicator3_1_1DtoModelSnapshotIn,
  IIndicator3_1_1DtoModelSnapshotOut,
  IUpdateIndicator3_1_1CommandSnapshotIn,
  UpdateIndicator3_1_1Command,
  CreateIndicator3_1_1Command,
  ICreateIndicator3_1_1CommandSnapshotIn,
  IFileLookUpSnapshotIn,
  FileLookUpModel,
  IFileLookUpSnapshotOut,
  IFileDataSnapshotOut,
  IndicatorsNumber,
  ICreateIndicatorVulnerableMigrantCommandSnapshotIn,
  CreateIndicatorVulnerableMigrantCommand,
  IIndicatorVulnerableMigrantDtoModelSnapshotOut,
  IUpdateIndicatorVulnerableMigrantCommandSnapshotIn,
  UpdateIndicatorVulnerableMigrantCommand,
  IndicatorVulnerableMigrantDtoModel,
  IIndicatorVulnerableMigrantDtoModelSnapshotIn,
} from 'stores/models'
import fileDownload from 'js-file-download'

const PartnersIndicatorsStore = types
  .model('PartnersIndicatorsStore')
  .props({
    _isLoading: types.optional(types.boolean, false),
    _allIndicators: types.optional(AllIndicatorsPagedDto, {}),
    _indicator1_1_1Information: types.optional(Indicator1_1_1DtoModel, {}),
    _indicator1_2_1Information: types.optional(Indicator1_2_1DtoModel, {}),
    _indicator1_2_2Information: types.optional(Indicator1_2_2DtoModel, {}),
    _indicator2_2_1Information: types.optional(Indicator2_2_1DtoModel, {}),
    _indicator2_2_3Information: types.optional(Indicator2_2_3DtoModel, {}),
    _indicator3_1_1Information: types.optional(Indicator3_1_1DtoModel, {}),
    _indicators_vulnerableMigrantInformation: types.optional(IndicatorVulnerableMigrantDtoModel, {}),
    _isInformationLoading: types.optional(types.boolean, false),
    _indicatorAttachments: types.array(FileLookUpModel),
    _isAttachmentsLoading: types.optional(types.boolean, false),
    _isAttachmentsUploading: types.optional(types.boolean, false),
    _indicatorCounter: types.optional(types.number, 0),
  })
  .actions(self => ({
    setIndicatorCounter(indicatorCounter: number): void {
      self._indicatorCounter = indicatorCounter
    },
    setIsLoading: (isLoading: boolean) => {
      self._isLoading = isLoading
    },
    setIsInformationLoading: (isLoading: boolean) => {
      self._isInformationLoading = isLoading
    },
    setIndicators: (indicators: IAllIndicatorsPagedDtoSnapshotIn): void => {
      applySnapshot(self._allIndicators, indicators)
    },
    setIndicator1_1_1Information: (
      indicator1_1_1Information: IIndicator1_1_1DtoModelSnapshotIn,
    ): void => {
      applySnapshot(self._indicator1_1_1Information, indicator1_1_1Information)
    },
    setIndicator1_2_1Information: (
      indicator1_2_1Information: IIndicator1_2_1DtoModelSnapshotIn,
    ): void => {
      applySnapshot(self._indicator1_2_1Information, indicator1_2_1Information)
    },
    setIndicator1_2_2Information: (
      indicator1_2_2Information: IIndicator1_2_2DtoModelSnapshotIn,
    ): void => {
      applySnapshot(self._indicator1_2_2Information, indicator1_2_2Information)
    },
    setIndicator2_2_1Information: (
      indicator2_2_1Information: IIndicator2_2_1DtoModelSnapshotIn,
    ): void => {
      applySnapshot(self._indicator2_2_1Information, indicator2_2_1Information)
    },
    setIndicator2_2_3Information: (
      indicator2_2_3Information: IIndicator2_2_3DtoModelSnapshotIn,
    ): void => {
      applySnapshot(self._indicator2_2_3Information, indicator2_2_3Information)
    },
    setIndicator3_1_1Information: (
      indicator3_1_1Information: IIndicator3_1_1DtoModelSnapshotIn,
    ): void => {
      applySnapshot(self._indicator3_1_1Information, indicator3_1_1Information)
    },
    setIndicatorVulnerableMigrantInformation: (
      indicatorVulnerableMigrantInformation: IIndicatorVulnerableMigrantDtoModelSnapshotIn,
    ): void => {
      applySnapshot(self._indicators_vulnerableMigrantInformation, indicatorVulnerableMigrantInformation)
    },
    setIndicatorAttachments: (
      indicatorAttachments: Array<IFileLookUpSnapshotIn>,
    ): void => {
      applySnapshot(self._indicatorAttachments, indicatorAttachments)
    },
    setIsAttachmentsLoading: (isLoading: boolean) => {
      self._isAttachmentsLoading = isLoading
    },
    setIsAttachmentsUploading: (isLoading: boolean) => {
      self._isAttachmentsUploading = isLoading
    },
  }))
  .actions(self => {
    const PARTNER_INDICATORS_API = '/api/partners/indicators'
    const PARTNER_INDICATORS_ATTACHMENTS_API = `${PARTNER_INDICATORS_API}/attachments`

    const getIndicatorsList = async (
      params: IAllIndicatorsListRequestSnapshotIn,
    ) => {
      const indicatorsListRequest = fromPromise(
        apiV1.get<IAllIndicatorsPagedDtoSnapshotIn>(
          `${PARTNER_INDICATORS_API}/all`,
          {
            params: AllIndicatorsListRequestModel.create(params),
          },
        ),
      )

      when(() =>
        indicatorsListRequest.case({
          fulfilled: response => {
            self.setIndicators(response.data)
            return true
          },
        }),
      )

      return indicatorsListRequest
    }

    const createIndicator1_1_1 = async (
      indicator: ICreateIndicator1_1_1CommandSnapshotIn,
    ): Promise<IActivitySnapshotOut['id']> => {
      const { data: createdIndicatorId } = await apiV1.post<
        IActivitySnapshotOut['id']
      >(
        `${PARTNER_INDICATORS_API}/indicator1_1_1`,
        CreateIndicator1_1_1Command.create(indicator),
      )

      return createdIndicatorId
    }

    const getIndicator1_1_1Information = async (indicatorId: number) => {
      const indicator1_1_1InformationRequest = fromPromise(
        apiV1.get<IIndicator1_1_1DtoModelSnapshotIn>(
          `${PARTNER_INDICATORS_API}/indicator1_1_1`,
          {
            params: { id: indicatorId },
          },
        ),
      )

      when(() =>
        indicator1_1_1InformationRequest.case({
          fulfilled: response => {
            self.setIndicator1_1_1Information(response.data)
            return true
          },
        }),
      )
      return indicator1_1_1InformationRequest
    }

    const createIndicator1_2_1 = async (
      indicator: ICreateIndicator1_2_1CommandSnapshotIn,
    ): Promise<IIndicator1_2_1DtoModelSnapshotOut['id']> => {
      const { data: createdIndicatorId } = await apiV1.post<
        IIndicator1_2_1DtoModelSnapshotOut['id']
      >(
        `${PARTNER_INDICATORS_API}/indicator1_2_1`,
        CreateIndicator1_2_1Command.create(indicator),
      )

      return createdIndicatorId
    }

    const getIndicator1_2_1Information = async (indicatorId: number) => {
      const indicator1_2_1InformationRequest = fromPromise(
        apiV1.get<IIndicator1_2_1DtoModelSnapshotIn>(
          `${PARTNER_INDICATORS_API}/indicator1_2_1`,
          {
            params: { id: indicatorId },
          },
        ),
      )

      when(() =>
        indicator1_2_1InformationRequest.case({
          fulfilled: response => {
            self.setIndicator1_2_1Information(response.data)
            return true
          },
        }),
      )
      return indicator1_2_1InformationRequest
    }

    const createIndicator1_2_2 = async (
      indicator: ICreateIndicator1_2_2CommandSnapshotIn,
    ): Promise<IIndicator1_2_2DtoModelSnapshotOut['id']> => {
      const { data: createdIndicatorId } = await apiV1.post<
        IIndicator1_2_2DtoModelSnapshotOut['id']
      >(
        `${PARTNER_INDICATORS_API}/indicator1_2_2`,
        CreateIndicator1_2_2Command.create(indicator),
      )

      return createdIndicatorId
    }

    const createIndicator2_2_1 = async (
      indicator: ICreateIndicator2_2_1CommandSnapshotIn,
    ): Promise<IIndicator2_2_1DtoModelSnapshotOut['id']> => {
      const { data: createdIndicatorId } = await apiV1.post<
        IIndicator2_2_1DtoModelSnapshotOut['id']
      >(
        `${PARTNER_INDICATORS_API}/indicator2_2_1`,
        CreateIndicator2_2_1Command.create(indicator),
      )

      return createdIndicatorId
    }

    const getIndicator1_2_2Information = async (indicatorId: number) => {
      const indicator1_2_2InformationRequest = fromPromise(
        apiV1.get<IIndicator1_2_2DtoModelSnapshotIn>(
          `${PARTNER_INDICATORS_API}/indicator1_2_2`,
          {
            params: { id: indicatorId },
          },
        ),
      )

      when(() =>
        indicator1_2_2InformationRequest.case({
          fulfilled: response => {
            self.setIndicator1_2_2Information(response.data)
            return true
          },
        }),
      )
      return indicator1_2_2InformationRequest
    }

    const getIndicator2_2_1Information = async (indicatorId: number) => {
      const indicator2_2_1InformationRequest = fromPromise(
        apiV1.get<IIndicator2_2_1DtoModelSnapshotIn>(
          `${PARTNER_INDICATORS_API}/indicator2_2_1`,
          {
            params: { id: indicatorId },
          },
        ),
      )

      when(() =>
        indicator2_2_1InformationRequest.case({
          fulfilled: response => {
            self.setIndicator2_2_1Information(response.data)
            return true
          },
        }),
      )
      return indicator2_2_1InformationRequest
    }

    const editIndicator1_1_1 = async (
      indicator: IUpdateIndicator1_1_1CommandSnapshotIn,
      indicatorId: IIndicator1_1_1DtoModelSnapshotOut['id'],
    ): Promise<void> =>
      apiV1.put(
        `${PARTNER_INDICATORS_API}/indicator1_1_1`,
        UpdateIndicator1_1_1Command.create(indicator),
        {
          params: { id: indicatorId },
        },
      )

    const editIndicator1_2_1 = async (
      indicator: IUpdateIndicator1_2_1CommandSnapshotIn,
      indicatorId: IIndicator1_2_1DtoModelSnapshotOut['id'],
    ): Promise<void> =>
      apiV1.put(
        `${PARTNER_INDICATORS_API}/indicator1_2_1`,
        UpdateIndicator1_2_1Command.create(indicator),
        {
          params: { id: indicatorId },
        },
      )

    const editIndicator1_2_2 = async (
      indicator: IUpdateIndicator1_2_2CommandSnapshotIn,
      indicatorId: IIndicator1_2_2DtoModelSnapshotOut['id'],
    ): Promise<void> =>
      apiV1.put(
        `${PARTNER_INDICATORS_API}/indicator1_2_2`,
        UpdateIndicator1_2_2Command.create(indicator),
        {
          params: { id: indicatorId },
        },
      )

    const editIndicator2_2_1 = async (
      indicator: IUpdateIndicator2_2_1CommandSnapshotIn,
      indicatorId: IIndicator2_2_1DtoModelSnapshotOut['id'],
    ): Promise<void> =>
      apiV1.put(
        `${PARTNER_INDICATORS_API}/indicator2_2_1`,
        UpdateIndicator2_2_1Command.create(indicator),
        {
          params: { id: indicatorId },
        },
      )

    const createIndicator2_2_3 = async (
      indicator: ICreateIndicator2_2_3CommandSnapshotIn,
    ): Promise<IIndicator2_2_3DtoModelSnapshotOut['id']> => {
      const { data: createdIndicatorId } = await apiV1.post<
        IIndicator2_2_3DtoModelSnapshotOut['id']
      >(
        `${PARTNER_INDICATORS_API}/indicator2_2_3`,
        CreateIndicator2_2_3Command.create(indicator),
      )

      return createdIndicatorId
    }

    const editIndicator2_2_3 = async (
      indicator: IUpdateIndicator2_2_3CommandSnapshotIn,
      indicatorId: IIndicator2_2_3DtoModelSnapshotOut['id'],
    ): Promise<void> =>
      apiV1.put(
        `${PARTNER_INDICATORS_API}/indicator2_2_3`,
        UpdateIndicator2_2_3Command.create(indicator),
        {
          params: { id: indicatorId },
        },
      )

    const getIndicator2_2_3Information = async (indicatorId: number) => {
      const indicator2_2_3InformationRequest = fromPromise(
        apiV1.get<IIndicator2_2_3DtoModelSnapshotIn>(
          `${PARTNER_INDICATORS_API}/indicator2_2_3`,
          {
            params: { id: indicatorId },
          },
        ),
      )

      when(() =>
        indicator2_2_3InformationRequest.case({
          fulfilled: response => {
            self.setIndicator2_2_3Information(response.data)
            return true
          },
        }),
      )
      return indicator2_2_3InformationRequest
    }

    const getIndicator3_1_1Information = async (indicatorId: number) => {
      const indicator3_1_1InformationRequest = fromPromise(
        apiV1.get<IIndicator3_1_1DtoModelSnapshotIn>(
          `${PARTNER_INDICATORS_API}/indicator3_1_1`,
          {
            params: { id: indicatorId },
          },
        ),
      )

      when(() =>
        indicator3_1_1InformationRequest.case({
          fulfilled: response => {
            self.setIndicator3_1_1Information(response.data)
            return true
          },
        }),
      )
      return indicator3_1_1InformationRequest
    }

    const editIndicator3_1_1 = async (
      indicator: IUpdateIndicator3_1_1CommandSnapshotIn,
      indicatorId: IIndicator3_1_1DtoModelSnapshotOut['id'],
    ): Promise<void> =>
      apiV1.put(
        `${PARTNER_INDICATORS_API}/indicator3_1_1`,
        UpdateIndicator3_1_1Command.create(indicator),
        {
          params: { id: indicatorId },
        },
      )

    const createIndicator3_1_1 = async (
      indicator: ICreateIndicator3_1_1CommandSnapshotIn,
    ): Promise<IIndicator3_1_1DtoModelSnapshotOut['id']> => {
      const { data: createdIndicatorId } = await apiV1.post<
        IIndicator3_1_1DtoModelSnapshotOut['id']
      >(
        `${PARTNER_INDICATORS_API}/indicator3_1_1`,
        CreateIndicator3_1_1Command.create(indicator),
      )

      return createdIndicatorId
    }

    const getIndicatorVulnerableMigrant = async (indicatorId: number) => {
      const indicatorVulnerableMigrantRequest = fromPromise(
        apiV1.get<IIndicatorVulnerableMigrantDtoModelSnapshotIn>(
          `${PARTNER_INDICATORS_API}/indicatorvulnerablemigrant`,
          {
            params: { id: indicatorId },
          },
        ),
      )

      when(() =>
      indicatorVulnerableMigrantRequest.case({
          fulfilled: response => {
            self.setIndicatorVulnerableMigrantInformation(response.data)
            return true
          },
        }),
      )
      return indicatorVulnerableMigrantRequest
    }

    const createIndicatorVulnerableMigrant = async (
      indicator: ICreateIndicatorVulnerableMigrantCommandSnapshotIn,
    ): Promise<IIndicatorVulnerableMigrantDtoModelSnapshotOut['id']> => {
      const { data: createdIndicatorId } = await apiV1.post<
      IIndicatorVulnerableMigrantDtoModelSnapshotOut['id']
      >(
        `${PARTNER_INDICATORS_API}/indicatorvulnerablemigrant`,
        CreateIndicatorVulnerableMigrantCommand.create(indicator),
      )

      return createdIndicatorId
    }

    const editIndicatorVulnerableMigrant = async (
      indicator: IUpdateIndicatorVulnerableMigrantCommandSnapshotIn,
      indicatorId: IIndicatorVulnerableMigrantDtoModelSnapshotOut['id'],
    ): Promise<void> =>
      apiV1.put(
        `${PARTNER_INDICATORS_API}/indicatorvulnerablemigrant`,
        UpdateIndicatorVulnerableMigrantCommand.create(indicator),
        {
          params: { id: indicatorId },
        },
      )

    const addIndicatorAttachments = async (
      indicatorId: IAllIndicatorsModelOut['id'],
      indicatorNumber: IAllIndicatorsModelOut['indicatorNumber'],
      attachments: Array<File>,
    ): Promise<void> => {
      const formData = new FormData()
      formData.append('indicatorNumber', `${indicatorNumber}`)
      formData.append('id', `${indicatorId}`)
      for (const attachment of attachments) {
        formData.append('attachments', attachment)
      }

      return apiV1.post(PARTNER_INDICATORS_ATTACHMENTS_API, formData)
    }

    const getIndicatorAttachments = async (
      id: IAllIndicatorsModelOut['id'],
      indicatorNumber: IAllIndicatorsModelOut['indicatorNumber'],
    ) => {
      const indicatorAttachmentsRequest = fromPromise(
        apiV1.get<Array<IFileLookUpSnapshotIn>>(
          `${PARTNER_INDICATORS_ATTACHMENTS_API}/all`,
          {
            params: { indicatorNumber, id },
          },
        ),
      )

      when(() =>
        indicatorAttachmentsRequest.case({
          fulfilled: response => {
            self.setIndicatorAttachments(response.data)
            return true
          },
        }),
      )

      return indicatorAttachmentsRequest
    }

    const getIndicatorAttachmentDataById = async (
      fileId: IFileLookUpSnapshotOut['id'],
      onDownloadProgress: (progressEvent: ProgressEvent) => void,
    ) =>
      apiV1.get<IFileDataSnapshotOut>(
        `${PARTNER_INDICATORS_ATTACHMENTS_API}/download`,
        {
          params: { fileId },
          onDownloadProgress,
        },
      )

    const downloadAttachment = async (
      fileId: IFileLookUpSnapshotOut['id'],
      onDownloadProgress: (progressEvent: ProgressEvent) => void,
    ) => {
      const {
        data: { file, originalFileName, contentType },
      } = await getIndicatorAttachmentDataById(fileId, onDownloadProgress)
      if (!file || !originalFileName || !contentType) throw new Error()
      const fileDataUrl = `data:${contentType};base64,${file}`
      const fetchedFile = new File(
        [await (await fetch(fileDataUrl)).arrayBuffer()],
        originalFileName,
        { type: contentType },
      )
      fileDownload(fetchedFile, originalFileName)
    }

    const getIndicatorCounter = async (organizationId: number) => {
      const indicatorCounterRequest = fromPromise(
        apiV1.get<number>(`${PARTNER_INDICATORS_API}/count`, {
          params: { organizationId },
        }),
      )

      when(() =>
        indicatorCounterRequest.case({
          fulfilled: response => {
            self.setIndicatorCounter(response.data)
            return true
          },
        }),
      )
      return indicatorCounterRequest
    }

    const deleteIndicatorAttachments = async (
      id: IFileLookUpSnapshotOut['id'],
    ): Promise<void> =>
      apiV1.delete(PARTNER_INDICATORS_ATTACHMENTS_API, {
        params: { id },
      })

    const pendIndicator = async (
      id: IAllIndicatorsModelOut['id'],
      indicatorNumber: IAllIndicatorsModelOut['indicatorNumber'],
    ): Promise<void> =>
      apiV1.put(`${PARTNER_INDICATORS_API}/pend`, { id, indicatorNumber })

    const deleteIndicator = async (
      id: IAllIndicatorsModelOut['id'],
      indicatorNumber: IAllIndicatorsModelOut['indicatorNumber'],
    ): Promise<void> =>
      apiV1.delete(PARTNER_INDICATORS_API, {
        params: { id, indicatorNumber },
      })

    const importIndicator = async (
      file: File,
      indicatorNumber: IndicatorsNumber,
      organizationId: number,
    ): Promise<void> => {
      const formData = new FormData()
      formData.append('organizationId', `${organizationId}`)
      formData.append('indicatorNumber', indicatorNumber)
      formData.append('file', file)
      await apiV1.post(`${PARTNER_INDICATORS_API}/import`, formData)
    }

    return {
      getIndicatorsList,
      createIndicator1_1_1,
      getIndicator1_1_1Information,
      editIndicator1_1_1,
      createIndicator1_2_1,
      getIndicator1_2_1Information,
      editIndicator1_2_1,
      getIndicator1_2_2Information,
      editIndicator1_2_2,
      createIndicator1_2_2,
      editIndicator2_2_1,
      getIndicator2_2_1Information,
      createIndicator2_2_1,
      createIndicator2_2_3,
      getIndicator2_2_3Information,
      editIndicator2_2_3,
      createIndicator3_1_1,
      getIndicator3_1_1Information,
      editIndicator3_1_1,
      getIndicatorVulnerableMigrant,
      createIndicatorVulnerableMigrant,
      editIndicatorVulnerableMigrant,
      pendIndicator,
      deleteIndicator,
      addIndicatorAttachments,
      getIndicatorAttachments,
      downloadAttachment,
      deleteIndicatorAttachments,
      getIndicatorCounter,
      importIndicator,
    }
  })
  .views(self => ({
    get isLoading() {
      return self._isLoading
    },
    get isInformationLoading() {
      return self._isInformationLoading
    },
    get indicators(): IAllIndicatorsPagedDtoSnapshotOut {
      return getSnapshot(self._allIndicators)
    },
    get indicatorsList() {
      return this.indicators.data
    },
    get indicatorsMetadata() {
      return this.indicators.metadata
    },
    get indicator1_1_1Information() {
      return getSnapshot(self._indicator1_1_1Information)
    },
    get indicator1_2_1Information() {
      return getSnapshot(self._indicator1_2_1Information)
    },
    get indicator1_2_2Information() {
      return getSnapshot(self._indicator1_2_2Information)
    },
    get indicator2_2_1Information() {
      return getSnapshot(self._indicator2_2_1Information)
    },
    get indicator2_2_3Information() {
      return getSnapshot(self._indicator2_2_3Information)
    },
    get indicator3_1_1Information() {
      return getSnapshot(self._indicator3_1_1Information)
    },
    get indicatorVulnerableMigrantInformation() {
      return getSnapshot(self._indicators_vulnerableMigrantInformation)
    },
    get indicatorAttachments() {
      return getSnapshot(self._indicatorAttachments)
    },
    get isAttachmentsLoading() {
      return self._isAttachmentsLoading
    },
    get isAttachmentsUploading() {
      return self._isAttachmentsUploading
    },
    get indicatorCounter() {
      return self._indicatorCounter
    },
  }))

export default PartnersIndicatorsStore
export interface IPartnersIndicatorsStore
  extends Instance<typeof PartnersIndicatorsStore> {}
