import { FC } from 'react'
import { Button, Col } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

interface CommonProps {
  onSubmit: () => void
  wrapperClassName?: string
  floatLeft?: boolean
}

type FilterButtonsProps = (
  | {
      onShow: () => void
      isShowAllFilters: boolean
    }
  | {
      onShow?: never
      isShowAllFilters?: never
    }
) &
  CommonProps

const FilterButtons: FC<FilterButtonsProps> = ({
  onSubmit,
  onShow,
  isShowAllFilters,
  wrapperClassName = 'my-2',
  floatLeft = false,
}) => {
  return (
    <Col
      className={`d-flex align-items-end p-0 ${
        floatLeft ? '' : 'ml-auto'
      } ${wrapperClassName}`}
      xs="auto"
    >
      <Button onClick={onSubmit}>
        <FormattedMessage id="global.show" defaultMessage="Показать" />
      </Button>
      {onShow && (
        <Button
          variant="outline-dark"
          onClick={onShow}
          style={{ marginLeft: '1rem' }}
          className="d-inline-flex align-items-center"
        >
          {isShowAllFilters ? (
            <>
              <i className="ms-Icon ms-Icon--Cancel lh-100 mr-2 border border-dark" />
              <FormattedMessage
                id="global.close_all_filters"
                defaultMessage="Закрыть"
              />
            </>
          ) : (
            <>
              <i className="ms-Icon ms-Icon--Filter lh-100 mr-2" />
              <FormattedMessage
                id="global.show_all_filters"
                defaultMessage="Все фильтры"
              />
            </>
          )}
        </Button>
      )}
    </Col>
  )
}

export default FilterButtons
