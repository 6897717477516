import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const CreateIndicatorVulnerableMigrantCommand = types.model(
  'CreateIndicatorVulnerableMigrantCommand',
  {
    organizationId: types.maybeNull(types.integer),
    beneficiaryIndividualId: types.maybeNull(types.integer),
    interviewDate: types.maybeNull(types.string),
    interviewerStaffId: types.maybeNull(types.integer),
      indicatorVulnerableMigrantRefferalOrgTypeIds: types.array(types.integer),
      indicatorVulnerableMigrantRefferalOrgTypeComment: types.maybeNull(types.string),
    isVulnerableMigrant: types.boolean,
    howBecameVulnerableMigrant: types.maybeNull(types.string),
    hasRelativesInCountryWhereIsVulnerable: types.maybeNull(types.boolean),
    relativesFinancialSituationType: types.maybeNull(types.integer),
    indicatorVulnerableMigrantDestinationMigrationActivityIds: types.array(types.integer),
    indicatorVulnerableMigrantDestinationMigrationActivityComment: types.maybeNull(types.string),
    migrationProcessEnterDate: types.maybeNull(types.string),
    migrationProcessEnterCountryId: types.maybeNull(types.integer),
      migrationProcessEnterCountryComment: types.optional(types.string, ''),
    migrationProcessEndCountryId: types.maybeNull(types.integer),
    migrationProcessEndCountryComment: types.optional(types.string, ''),
    spentTimeInTransitCountries: types.maybeNull(types.boolean),
    chronologyInTransitCountry: types.maybeNull(types.string),
    engagedInActivityWhenTransit: types.boolean,
    indicatorVulnerableMigrantTransitMigrationActivityIds: types.array(types.integer),
    indicatorVulnerableMigrantTransitMigrationActivityComment: types.maybeNull(types.string),
    vulnerableStartDate: types.maybeNull(types.string),
    returnDate: types.maybeNull(types.string),
    becameVulnerableLocationType: types.maybeNull(types.integer),
    meetWinrockAssistanceRequirement: types.boolean,
    winrockAssistanceRejectionReasonId: types.maybeNull(types.integer),
    winrockAssistanceRejectionReasonComment: types.maybeNull(types.string),
    agreeToGetWinrockAssistance: types.boolean,
    indicatorVulnerableMigrantWinrockAssistanceRefuseReasonIds: types.array(types.integer),
    indicatorVulnerableMigrantWinrockAssistanceRefuseReasonComment: types.maybeNull(types.string),
    doesPersonNeedHelp: types.boolean,
    indicatorVulnerableMigrantPersonConditionIds: types.array(types.integer),
    indicatorVulnerableMigrantPersonConditionComment: types.string,

    countryId: types.integer,
    countryComment: types.optional(types.string, ''),
    regionId: types.maybeNull(types.integer),
  },
)

export interface ICreateIndicatorVulnerableMigrantCommand
  extends Instance<typeof CreateIndicatorVulnerableMigrantCommand> {}
export interface ICreateIndicatorVulnerableMigrantCommandSnapshotIn
  extends SnapshotIn<typeof CreateIndicatorVulnerableMigrantCommand> {}
export interface ICreateIndicatorVulnerableMigrantCommandSnapshotOut
  extends SnapshotOut<typeof CreateIndicatorVulnerableMigrantCommand> {}
export default CreateIndicatorVulnerableMigrantCommand
