import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../PagedListRequestModel'

const UserAccessInfoRequestModel = PagedListRequestModel.named(
  'UserAccessInfoRequestModel',
).props({
  sortBy: types.optional(types.string, ''),
  filter: types.optional(
    types.model({
      countryId: types.union(types.number, types.undefined, types.null),
      organizationId: types.union(types.number, types.undefined, types.null),
      roleName: types.union(types.string, types.undefined, types.null),
      userName: types.union(types.string, types.undefined, types.null),
    }),
    {},
  ),
})

export interface IUserAccessInfoRequestModel
  extends Instance<typeof UserAccessInfoRequestModel> {}

export interface IUserAccessInfoRequestSnapshotIn
  extends SnapshotIn<typeof UserAccessInfoRequestModel> {}

export interface IUserAccessInfoRequestSnapshotOut
  extends SnapshotOut<typeof UserAccessInfoRequestModel> {}

export default UserAccessInfoRequestModel
