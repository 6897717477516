enum StatusName {
  New = 'New',
  PendingForReview = 'PendingForReview',
  RejectedByReviewer = 'RejectedByReviewer',
  PendingForApproval = 'PendingForApproval',
  RejectedByApprover = 'RejectedByApprover',
  PendingForVerification = 'PendingForVerification',
  RejectedByVerifier = 'RejectedByVerifier',
  Verified = 'Verified',
}

export default StatusName
