import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const BeneficiaryOrganizationStaffWithNameModel = types.model(
  'BeneficiaryOrganizationStaffWithNameModel',
  {
    id: types.optional(types.integer, 0),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    position: types.maybeNull(types.string),
    genderId: types.optional(types.number, 0),
    gender: types.maybeNull(types.string),
    genderParentId: types.maybeNull(types.number),
    code: types.optional(types.string, ''),
  },
)

export interface IBeneficiaryOrganizationStaffWithNameModel
  extends Instance<typeof BeneficiaryOrganizationStaffWithNameModel> {}

export interface IBeneficiaryOrganizationStaffWithNameSnapshotIn
  extends SnapshotIn<typeof BeneficiaryOrganizationStaffWithNameModel> {}

export interface IBeneficiaryOrganizationStaffWithNameSnapshotOut
  extends SnapshotOut<typeof BeneficiaryOrganizationStaffWithNameModel> {}

export default BeneficiaryOrganizationStaffWithNameModel
