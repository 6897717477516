import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateBeneficiaryIndividualCommand = types.model(
  'UpdateBeneficiaryIndividualCommand',
  {
    id: types.integer,
    firstName: types.string,
    lastName: types.string,
    middleName: types.maybeNull(types.string),
    serviceCountryId: types.number,
    serviceCountryComment: types.maybeNull(types.string),
    birthCountryId: types.maybeNull(types.number),
    birthCountryComment: types.maybeNull(types.string),
    genderId: types.number,
    genderComment: types.maybeNull(types.string),
    ethnicityId: types.maybeNull(types.number),
    ethnicityComment: types.maybeNull(types.string),
    birthDate: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    age: types.maybeNull(types.number),
    ageCategory: types.maybeNull(types.number),
    email: types.maybeNull(types.string),
    isEdit: types.boolean,
  },
)

export interface IUpdateBeneficiaryIndividualCommand
  extends Instance<typeof UpdateBeneficiaryIndividualCommand> {}

export interface IUpdateBeneficiaryIndividualCommandIn
  extends SnapshotIn<typeof UpdateBeneficiaryIndividualCommand> {}

export interface IUpdateBeneficiaryIndividualCommandOut
  extends SnapshotOut<typeof UpdateBeneficiaryIndividualCommand> {}

export default UpdateBeneficiaryIndividualCommand
