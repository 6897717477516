import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const FileDataModel = types.model('FileDataModel', {
  file: types.maybeNull(types.string),
  contentType: types.maybeNull(types.string),
  originalFileName: types.maybeNull(types.string),
})

export interface IFileDataModel extends Instance<typeof FileDataModel> {}

export interface IFileDataSnapshotIn extends SnapshotIn<typeof FileDataModel> {}

export interface IFileDataSnapshotOut
  extends SnapshotOut<typeof FileDataModel> {}

export default FileDataModel
