import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from 'stores/models/PagedListRequestModel'

const ActivityTestResultRequestModel = PagedListRequestModel.named(
  'ActivityTestResultRequestModel',
).props({
  id: types.number, // id of activity
  sortBy: types.optional(types.string, ''),
})

export interface IActivityTestResultRequestModel
  extends Instance<typeof ActivityTestResultRequestModel> {}

export interface IActivityTestResultRequestIn
  extends SnapshotIn<typeof ActivityTestResultRequestModel> {}

export interface IActivityTestResultRequestOut
  extends SnapshotOut<typeof ActivityTestResultRequestModel> {}

export default ActivityTestResultRequestModel
