import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../../../PagedListMetaData'
import Indicator2_2_3ReportModel from './Indicator2_2_3ReportModel'

const Indicator2_2_3ReportPagedModel = types.model(
  'Indicator2_2_3ReportPagedModel',
  {
    data: types.array(Indicator2_2_3ReportModel),
    metadata: types.optional(PagedListMetaData, {}),
  },
)

export interface IIndicator2_2_3ReportPagedModel
  extends Instance<typeof Indicator2_2_3ReportPagedModel> {}

export interface IIndicator2_2_3ReportPagedSnapshotIn
  extends SnapshotIn<typeof Indicator2_2_3ReportPagedModel> {}

export interface IIndicator2_2_3ReportPagedSnapshotOut
  extends SnapshotOut<typeof Indicator2_2_3ReportPagedModel> {}

export default Indicator2_2_3ReportPagedModel
