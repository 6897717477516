import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateIndicator2_2_1Command = types.model('UpdateIndicator2_2_1Command', {
  organizationId: types.integer,
  recordDate: types.maybeNull(types.string),
  countryId: types.integer,
  countryComment: types.optional(types.string, ''),
  regionId: types.maybeNull(types.integer),
  csoTypeId: types.integer,
  csoTypeComment: types.maybeNull(types.string),
  supportTypeId: types.integer,
  supportTypeComment: types.maybeNull(types.string),
  advocacyIssueId: types.integer,
  advocacyIssueComment: types.maybeNull(types.string),
  advocacyInfluenceLevelId: types.integer,
  advocacyTypeIds: types.array(types.integer),
  advocacyTypeComment: types.maybeNull(types.string),
  plansWereDeveloped: types.maybeNull(types.boolean),
  plansWereDevelopedComment: types.maybeNull(types.string),
})

export interface IUpdateIndicator2_2_1Command
  extends Instance<typeof UpdateIndicator2_2_1Command> {}
export interface IUpdateIndicator2_2_1CommandSnapshotIn
  extends SnapshotIn<typeof UpdateIndicator2_2_1Command> {}
export interface IUpdateIndicator2_2_1CommandSnapshotOut
  extends SnapshotOut<typeof UpdateIndicator2_2_1Command> {}
export default UpdateIndicator2_2_1Command
