import { FC, useEffect } from 'react'
import { PermissionGroupName, PermissionMenuName } from 'stores/models'
import { useStore } from 'stores/useStoreHook'
import { observer } from 'mobx-react'
import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'

type CounterProps = {
  permissionName: PermissionMenuName | PermissionGroupName | string | undefined
  className?: string
}

interface ICounterData {
  counter: number
  getCount: (organizationId: number) => Promise<AxiosResponse<number>>
}

const Counter: FC<CounterProps> = ({
  permissionName,
  className = 'counter',
}) => {
  const { partnerId } = useParams<{ partnerId: string }>()
  const partnersServiceHistoryStore = useStore('partnersServiceHistoryStore')
  const partnersActivitiesStore = useStore('partnersActivitiesStore')
  const partnersIndicatorsStore = useStore('partnersIndicatorsStore')
  const serviceHistoryStore = useStore('serviceHistoryStore')
  const activityStore = useStore('activityStore')
  const indicatorsStore = useStore('indicatorsStore')

  const CountersData = new Map<
    PermissionMenuName | PermissionGroupName,
    ICounterData
  >([
    [
      PermissionGroupName.PartnersServiceHistory,
      {
        counter: partnersServiceHistoryStore.serviceHistoryCounter,
        getCount: partnersServiceHistoryStore.getServiceHistoryCounter,
      },
    ],
    [
      PermissionMenuName.ServiceHistory,
      {
        counter: serviceHistoryStore.serviceHistoryCounter,
        getCount: serviceHistoryStore.getServiceHistoryCounter,
      },
    ],
    [
      PermissionGroupName.PartnersActivities,
      {
        counter: partnersActivitiesStore.activityCounter,
        getCount: partnersActivitiesStore.getActivityCounter,
      },
    ],
    [
      PermissionMenuName.Activities,
      {
        counter: activityStore.activityCounter,
        getCount: activityStore.getActivityCounter,
      },
    ],
    [
      PermissionGroupName.PartnersIndicators,
      {
        counter: partnersIndicatorsStore.indicatorCounter,
        getCount: partnersIndicatorsStore.getIndicatorCounter,
      },
    ],
    [
      PermissionMenuName.Indicators,
      {
        counter: indicatorsStore.indicatorCounter,
        getCount: indicatorsStore.getIndicatorCounter,
      },
    ],
  ])

  const counterData = CountersData.get(
    permissionName as PermissionMenuName | PermissionGroupName,
  )

  useEffect(() => {
    counterData?.getCount(+partnerId)
  })

  return counterData?.counter ? (
    <span className={className}>{counterData.counter}</span>
  ) : (
    <></>
  )
}

export default observer(Counter)
