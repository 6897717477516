import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { BeneficiaryCategoryLookUpModel, LookUpModel } from '../../lookUps'
import ActivityModel from './ActivityModel'

const ActivityDetailsModel = ActivityModel.named('ActivityDetailsModel').props({
  relatedToCovid19: types.optional(types.boolean, false),
  countryId: types.optional(types.number, 0),
  countryParentId: types.maybeNull(types.number),
  activityTypeId: types.optional(types.number, 0),
  activityType: types.optional(types.string, ''),
  activityParentId: types.maybeNull(types.number),
  hasSupportedDocuments: types.optional(types.boolean, false),
  memberTypes: types.array(BeneficiaryCategoryLookUpModel),
  organizationTypes: types.array(LookUpModel),
  indicators: types.array(types.number),
  regionId: types.maybeNull(types.number),
  region: types.maybeNull(types.string),
  testQuestionCount: types.maybeNull(types.number),
  canBeEdited: types.optional(types.boolean, false),
  partnerName: types.maybeNull(types.string),
})

export interface IActivityDetailsModel
  extends Instance<typeof ActivityDetailsModel> {}

export interface IActivityDetailsSnapshotIn
  extends SnapshotIn<typeof ActivityDetailsModel> {}

export interface IActivityDetailsSnapshotOut
  extends SnapshotOut<typeof ActivityDetailsModel> {}

export default ActivityDetailsModel
