import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from '../../lookUps/LookUpModel'

const SimilarBeneficiaryIndividualInfoDto = types.model(
  'SimilarBeneficiaryIndividualInfoDto',
  {
    id: types.integer,
    fullName: types.string,
    country: types.maybeNull(types.string),
    ethnicity: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    categories: types.array(LookUpModel),
    organizations: types.array(types.string),
    controlQuestion: types.maybeNull(types.string),
    controlAnswer: types.maybeNull(types.string),
    birthDate: types.maybeNull(types.string),
    age: types.maybeNull(types.integer),
    ageCategory: types.maybeNull(types.string),
  },
)

export interface ISimilarBeneficiaryIndividualInfoDto
  extends Instance<typeof SimilarBeneficiaryIndividualInfoDto> {}

export interface ISimilarBeneficiaryIndividualInfoDtoSnapshotIn
  extends SnapshotIn<typeof SimilarBeneficiaryIndividualInfoDto> {}

export interface ISimilarBeneficiaryIndividualInfoDtoSnapshotOut
  extends SnapshotOut<typeof SimilarBeneficiaryIndividualInfoDto> {}

export default SimilarBeneficiaryIndividualInfoDto
