import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const ExcludedCategoriesLookUpDto = types.model('ExcludedCategoriesLookUpDto', {
  memberTypeId: types.number,
  excludedMemberTypeId: types.number,
})

export interface IExcludeCategoriesLookUpDto
  extends Instance<typeof ExcludedCategoriesLookUpDto> {}
export interface IExcludeCategoriesLookUpDtoSnapshotIn
  extends SnapshotIn<typeof ExcludedCategoriesLookUpDto> {}
export interface IExcludeCategoriesLookUpDtoSnapshotOut
  extends SnapshotOut<typeof ExcludedCategoriesLookUpDto> {}
export default ExcludedCategoriesLookUpDto
