import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const IndicatorBaseDtoModel = types.model('IndicatorBaseDtoModel', {
  id: types.optional(types.integer, 0),
  countryId: types.optional(types.integer, 0),
  country: types.maybeNull(types.string),
  countryParentId: types.maybeNull(types.number),
  regionId: types.maybeNull(types.integer),
  region: types.maybeNull(types.string),
  canBePended: types.optional(types.boolean, false),
  canBeEdited: types.optional(types.boolean, false),
  canBeDeleted: types.optional(types.boolean, false),
})

export interface IIndicatorBaseDtoModel
  extends Instance<typeof IndicatorBaseDtoModel> {}
export interface IIndicatorBaseDtoModelSnapshotIn
  extends SnapshotIn<typeof IndicatorBaseDtoModel> {}
export interface IIndicatorBaseDtoModelSnapshotOut
  extends SnapshotOut<typeof IndicatorBaseDtoModel> {}
export default IndicatorBaseDtoModel
