import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import { apiV1 } from 'core/requests'
import {
  IBeneficiaryIndividualWithOrganizationsPagedDtoSnapshotIn,
  BeneficiaryIndividualWithOrganizationsPagedDto,
  BeneficiaryIndividualListRequestModel,
  IBeneficiaryIndividualListRequestSnapshotIn,
  ExportFilesType,
  IBeneficiaryIndividualsExportRequest,
} from 'stores/models'
import fileDownload from 'js-file-download'
import {
  capitalizeFirstLetter,
  capitalizeObjectKeys,
  getFileNameFromContentDisposition,
} from 'core/utils'

export const BeneficiariesIndividualStore = types
  .model('BeneficiariesIndividualStore')
  .props({
    _isLoading: types.optional(types.boolean, false),
    _beneficiariesIndividualList: types.optional(
      BeneficiaryIndividualWithOrganizationsPagedDto,
      {},
    ),
  })
  .actions(self => {
    const BENEFICIARIES_INDIVIDUAL_API = '/api/beneficiaries/individuals'

    const setIsLoading = (isLoading: boolean) => {
      self._isLoading = isLoading
    }

    const setBeneficiariesIndividualList = (
      beneficiaryIndividualList: IBeneficiaryIndividualWithOrganizationsPagedDtoSnapshotIn,
    ): void => {
      applySnapshot(
        self._beneficiariesIndividualList,
        beneficiaryIndividualList,
      )
    }

    const getBeneficiariesIndividualExportFile = async (
      params: IBeneficiaryIndividualsExportRequest,
      type: ExportFilesType,
      onDownloadProgress: (progressEvent: ProgressEvent) => void,
    ) => {
      const beneficiariesIndividualExportRequest = fromPromise(
        apiV1.get<ArrayBuffer>(`${BENEFICIARIES_INDIVIDUAL_API}/export`, {
          params: {
            ...params,
            sortBy: capitalizeFirstLetter(params.sortBy),
            filter: capitalizeObjectKeys(params.filter),
          },
          responseType: 'arraybuffer',
          onDownloadProgress,
        }),
      )

      when(() =>
        beneficiariesIndividualExportRequest.case({
          fulfilled: response => {
            const fileName = getFileNameFromContentDisposition(
              response.headers['content-disposition'],
            )
            fileDownload(response.data, fileName ? fileName : `report.${type}`)
            return true
          },
        }),
      )

      return beneficiariesIndividualExportRequest
    }

    const getBeneficiariesIndividualWithNameExportFile = async (
      params: IBeneficiaryIndividualsExportRequest,
      type: ExportFilesType,
      onDownloadProgress: (progressEvent: ProgressEvent) => void,
    ) => {
      const beneficiariesIndividualExportRequest = fromPromise(
        apiV1.get<ArrayBuffer>(
          `${BENEFICIARIES_INDIVIDUAL_API}/withfullname/export`,
          {
            params: {
              ...params,
              sortBy: capitalizeFirstLetter(params.sortBy),
              filter: capitalizeObjectKeys(params.filter),
            },
            responseType: 'arraybuffer',
            onDownloadProgress,
          },
        ),
      )

      when(() =>
        beneficiariesIndividualExportRequest.case({
          fulfilled: response => {
            const fileName = getFileNameFromContentDisposition(
              response.headers['content-disposition'],
            )
            fileDownload(response.data, fileName ? fileName : `report.${type}`)
            return true
          },
        }),
      )

      return beneficiariesIndividualExportRequest
    }

    const getBeneficiariesIndividualList = async (
      params: Omit<IBeneficiaryIndividualListRequestSnapshotIn, 'fullName'>,
    ) => {
      const beneficiariesIndividualListRequest = fromPromise(
        apiV1.get<IBeneficiaryIndividualWithOrganizationsPagedDtoSnapshotIn>(
          `${BENEFICIARIES_INDIVIDUAL_API}`,
          {
            params: BeneficiaryIndividualListRequestModel.create(params),
          },
        ),
      )

      when(() =>
        beneficiariesIndividualListRequest.case({
          fulfilled: response => {
            setBeneficiariesIndividualList(response.data)
            return true
          },
        }),
      )
      return beneficiariesIndividualListRequest
    }

    const getBeneficiaryIndividualWithNameList = async (
      params: IBeneficiaryIndividualListRequestSnapshotIn,
    ) => {
      const beneficiaryIndividualWithNameListRequest = fromPromise(
        apiV1.get<IBeneficiaryIndividualWithOrganizationsPagedDtoSnapshotIn>(
          `${BENEFICIARIES_INDIVIDUAL_API}/withfullname`,
          {
            params: BeneficiaryIndividualListRequestModel.create(params),
          },
        ),
      )

      when(() =>
        beneficiaryIndividualWithNameListRequest.case({
          fulfilled: response => {
            setBeneficiariesIndividualList(response.data)
            return true
          },
        }),
      )
      return beneficiaryIndividualWithNameListRequest
    }
    return {
      setIsLoading,
      getBeneficiariesIndividualList,
      setBeneficiariesIndividualList,
      getBeneficiaryIndividualWithNameList,
      getBeneficiariesIndividualExportFile,
      getBeneficiariesIndividualWithNameExportFile,
    }
  })
  .views(self => ({
    get isLoading() {
      return self._isLoading
    },

    get beneficiariesIndividual() {
      return getSnapshot(self._beneficiariesIndividualList)
    },
    get beneficiariesIndividualList() {
      return this.beneficiariesIndividual.data
    },
    get beneficiariesIndividualMetadata() {
      return this.beneficiariesIndividual.metadata
    },
  }))

export default BeneficiariesIndividualStore
export interface IBeneficiaryIndividualStore
  extends Instance<typeof BeneficiariesIndividualStore> {}
