import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const ServiceHistoryWithStatusListFilterModel = types.model({
  organizationId: types.union(types.number, types.undefined, types.null),
  beneficiaryIndividualCode: types.union(
    types.string,
    types.undefined,
    types.null,
  ),
  beneficiaryIndividualFullName: types.union(
    types.string,
    types.undefined,
    types.null,
  ),
  serviceDeliveryDate: types.union(types.string, types.undefined, types.null),
  serviceTypeId: types.union(types.number, types.undefined, types.null),
  statusId: types.union(types.number, types.undefined, types.null),
  regionId: types.union(types.number, types.undefined, types.null),
  relatedToCovid19: types.union(types.boolean, types.undefined, types.null),
  serviceDeliveredStaffId: types.union(
    types.number,
    types.undefined,
    types.null,
  ),
})

export interface IServiceHistoryWithStatusListFilterModel
  extends Instance<typeof ServiceHistoryWithStatusListFilterModel> {}

export interface IServiceHistoryWithStatusListFilterSnapshotIn
  extends SnapshotIn<typeof ServiceHistoryWithStatusListFilterModel> {}

export interface IServiceHistoryWithStatusListFilterSnapshotOut
  extends SnapshotOut<typeof ServiceHistoryWithStatusListFilterModel> {}

export default ServiceHistoryWithStatusListFilterModel
