import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const ExternalLoginCommand = types.model('ExternalLoginCommand', {
  providerName: types.string,
  externalAccessToken: types.string,
  tenantId: types.string,
})

export interface IExternalLoginCommand
  extends Instance<typeof ExternalLoginCommand> {}

export interface IExternalLoginCommandSnapshotIn
  extends SnapshotIn<typeof ExternalLoginCommand> {}

export interface IExternalLoginCommandSnapshotOut
  extends SnapshotOut<typeof ExternalLoginCommand> {}

export default ExternalLoginCommand
