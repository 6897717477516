import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../../../PagedListMetaData'
import Indicator4_1_1ReportModel from './Indicator4_1_1ReportModel'

const Indicator4_1_1ReportPagedModel = types.model(
  'Indicator4_1_1ReportPagedModel',
  {
    data: types.array(Indicator4_1_1ReportModel),
    metadata: types.optional(PagedListMetaData, {}),
  },
)

export interface IIndicator4_1_1ReportPagedModel
  extends Instance<typeof Indicator4_1_1ReportPagedModel> {}

export interface IIndicator4_1_1ReportPagedSnapshotIn
  extends SnapshotIn<typeof Indicator4_1_1ReportPagedModel> {}

export interface IIndicator4_1_1ReportPagedSnapshotOut
  extends SnapshotOut<typeof Indicator4_1_1ReportPagedModel> {}

export default Indicator4_1_1ReportPagedModel
