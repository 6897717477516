import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const VictimReleaseTypeLookUpModel = LookUpModel.named(
    'VictimReleaseTypeLookUpModel',
).props({
    isComment: types.boolean,
})

export interface IVictimReleaseTypeLookUp
    extends Instance<typeof VictimReleaseTypeLookUpModel> {}
export interface IVictimReleaseTypeLookUpIn
    extends SnapshotIn<typeof VictimReleaseTypeLookUpModel> {}
export interface IVictimReleaseTypeLookUpOut
    extends SnapshotOut<typeof VictimReleaseTypeLookUpModel> {}
export default VictimReleaseTypeLookUpModel
