import Header from '../Header'
import './index.scss'
import SideNavBar from '../SideBarNav'
import { LanguageDropdown, SignOutButton } from 'components'
import winrockLogo from '../../../assets/icon/winrock-logo.png'
import usaidLogo from '../../../assets/icon/usaid-logo.png'
import UserInfo from '../UserInfo'
import { FC, PropsWithChildren } from 'react'
import { RouteConfigObject } from 'routes'

const Layout: FC<PropsWithChildren<{ routes: Array<RouteConfigObject> }>> = ({
  children,
  routes,
}) => {
  return (
    <div className="main_layout">
      <Header className="header d-flex align-items-center px-2">
        <div>
          <img src={usaidLogo} alt="USAID" className="header-logo" />
        </div>
        <h1 className="smica-title">SMICA MIS</h1>
        <div className="d-flex pr-2">
          <img src={winrockLogo} alt="Winrock" className="header-logo" />
          <LanguageDropdown />
        </div>
      </Header>
      <div className="left_sidebar">
        <UserInfo />
        <SideNavBar routes={routes} />
        <SignOutButton />
      </div>
      <main className="main_content">{children}</main>
    </div>
  )
}

export default Layout
