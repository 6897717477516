import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from 'stores/models/PagedListRequestModel'

const ActivityBeneficiaryIndividualsRequestModel = PagedListRequestModel.named(
  'ActivityBeneficiaryIndividualsRequestModel',
).props({
  id: types.number, // id of activity
  sortBy: types.optional(types.string, ''),
})

export interface IActivityBeneficiaryIndividualsRequestModel
  extends Instance<typeof ActivityBeneficiaryIndividualsRequestModel> {}

export interface IActivityBeneficiaryIndividualsRequestSnapshotIn
  extends SnapshotIn<typeof ActivityBeneficiaryIndividualsRequestModel> {}

export interface IActivityBeneficiaryIndividualsRequestSnapshotOut
  extends SnapshotOut<typeof ActivityBeneficiaryIndividualsRequestModel> {}

export default ActivityBeneficiaryIndividualsRequestModel
