import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { apiV1 } from 'core/requests'
import { when } from 'mobx'
import {
  IPermissionMenuSnapshotIn,
  IRoleLookUpSnapshotIn,
  IUpdateRolePermissionsCommandSnapshotIn,
  PermissionMenuModel,
  RoleLookUp,
  UpdateRolePermissionsCommand,
} from 'stores/models'

const RolePermissionsStore = types
  .model('RolePermissionsStore')
  .props({
    _permissions: types.array(PermissionMenuModel),
    _rolePermissionIds: types.array(types.number),
    _roles: types.array(RoleLookUp),
    _isPermissionsLoading: types.optional(types.boolean, false),
    _isRolesLoading: types.optional(types.boolean, false),
  })
  .actions(self => {
    const ROLES_PERMISSIONS_API = '/api/roles/permissions'

    const setIsPermissionsLoading = (isPermissionsLoading: boolean): void => {
      self._isPermissionsLoading = isPermissionsLoading
    }

    const setIsRolesLoading = (isRolesLoading: boolean): void => {
      self._isRolesLoading = isRolesLoading
    }

    const setPermissions = (
      permissions: Array<IPermissionMenuSnapshotIn>,
    ): void => {
      applySnapshot(self._permissions, permissions)
    }

    const setRolePermissionIds = (permissionIds: Array<number>) => {
      applySnapshot(self._rolePermissionIds, permissionIds)
    }

    const setRoles = (roles: Array<IRoleLookUpSnapshotIn>) => {
      applySnapshot(self._roles, roles)
    }

    const getPermissionsByRoleName = async (roleName: string) => {
      const rolePermissionIdsRequest = fromPromise(
        apiV1.get<Array<number>>(ROLES_PERMISSIONS_API, {
          params: { roleName },
        }),
      )

      when(() =>
        rolePermissionIdsRequest.case({
          fulfilled: response => {
            setRolePermissionIds(response.data)
            return true
          },
        }),
      )
      return rolePermissionIdsRequest
    }

    const getAllPermissions = async () => {
      const allPermissionsRequest = fromPromise(
        apiV1.get<Array<IPermissionMenuSnapshotIn>>(
          `${ROLES_PERMISSIONS_API}/all`,
        ),
      )

      when(() =>
        allPermissionsRequest.case({
          fulfilled: response => {
            setPermissions(response.data)
            return true
          },
        }),
      )
      return allPermissionsRequest
    }

    const getAllRoles = async () => {
      const allRolesRequest = fromPromise(
        apiV1.get<Array<IRoleLookUpSnapshotIn>>(
          `${ROLES_PERMISSIONS_API}/allroles`,
        ),
      )

      when(() =>
        allRolesRequest.case({
          fulfilled: response => {
            setRoles(response.data)
            return true
          },
        }),
      )
      return allRolesRequest
    }

    const editRolePermissions = async (
      rolePermissionsData: IUpdateRolePermissionsCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.post(
        ROLES_PERMISSIONS_API,
        UpdateRolePermissionsCommand.create(rolePermissionsData),
      )

    return {
      setIsRolesLoading,
      setIsPermissionsLoading,
      getPermissionsByRoleName,
      getAllPermissions,
      getAllRoles,
      editRolePermissions,
    }
  })
  .views(self => ({
    get permissions() {
      return getSnapshot(self._permissions)
    },
    get rolePermissionIds() {
      return getSnapshot(self._rolePermissionIds)
    },
    get roles() {
      return getSnapshot(self._roles)
    },
    get isRolesLoading() {
      return self._isRolesLoading
    },
    get isPermissionsLoading() {
      return self._isPermissionsLoading
    },
  }))

export default RolePermissionsStore
export interface IRolePermissionsStore
  extends Instance<typeof RolePermissionsStore> {}
