import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const ActivityApprovalListFilterModel = types.model({
  name: types.union(types.string, types.undefined, types.null),
  countryId: types.union(types.number, types.undefined, types.null),
  organizationId: types.union(types.number, types.undefined, types.null),
  indicatorId: types.union(types.number, types.undefined, types.null),
  status: types.union(types.number, types.undefined, types.null),
  type: types.union(types.number, types.undefined, types.null),
  startDate: types.union(types.string, types.undefined, types.null),
  endDate: types.union(types.string, types.undefined, types.null),
  relatedToCovid19: types.union(types.boolean, types.undefined, types.null),
})

export interface IActivityApprovalListFilterModel
  extends Instance<typeof ActivityApprovalListFilterModel> {}

export interface IActivityApprovalListFilterSnapshotIn
  extends SnapshotIn<typeof ActivityApprovalListFilterModel> {}

export interface IActivityApprovalListFilterSnapshotOut
  extends SnapshotOut<typeof ActivityApprovalListFilterModel> {}

export default ActivityApprovalListFilterModel
