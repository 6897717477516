import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const TraffickingFormLookUpModel = LookUpModel.named(
  'TraffickingFormLookUpModel',
).props({
  isComment: types.boolean,
})

export interface ITraffickingFormLookUp
  extends Instance<typeof TraffickingFormLookUpModel> {}
export interface ITraffickingFormLookUpIn
  extends SnapshotIn<typeof TraffickingFormLookUpModel> {}
export interface ITraffickingFormLookUpOut
  extends SnapshotOut<typeof TraffickingFormLookUpModel> {}
export default TraffickingFormLookUpModel
