import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const RefferalOrgTypeLookUpModel = LookUpModel
    .named('RefferalOrgTypeLookUpModel')
    .props({
        isComment: types.boolean,
    })

export interface IRefferalOrgTypeLookUpModel extends Instance<typeof RefferalOrgTypeLookUpModel> {}
export interface IRefferalOrgTypeLookUpSnapshotIn
    extends SnapshotIn<typeof RefferalOrgTypeLookUpModel> {}
export interface IRefferalOrgTypeLookUpSnapshotOut
    extends SnapshotOut<typeof RefferalOrgTypeLookUpModel> {}
export default RefferalOrgTypeLookUpModel