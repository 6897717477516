import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../../PagedListMetaData'
import ActivityModel from './ActivityModel'

const ActivityPagedDto = types.model('ActivityPagedDto', {
  data: types.array(ActivityModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export interface IActivityPagedDto extends Instance<typeof ActivityPagedDto> {}

export interface IActivityPagedDtoSnapshotIn
  extends SnapshotIn<typeof ActivityPagedDto> {}

export interface IActivityPagedDtoSnapshotOut
  extends SnapshotOut<typeof ActivityPagedDto> {}

export default ActivityPagedDto
