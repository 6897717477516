import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import { GenericWithParentIdLookUpDto } from '../../../lookUps'
import IndicatorBaseDtoModel from '../IndicatorBaseDtoModel'

const Indicator1_1_1DtoModel = IndicatorBaseDtoModel.named(
  'Indicator1_1_1DtoModel',
).props({
  documentName: types.maybeNull(types.string),
  recordDate: types.maybeNull(types.string),
  documentTypeId: types.optional(types.integer, 0),
  documentType: types.maybeNull(types.string),
  documentTypeParentId: types.maybeNull(types.number),
  advocacyIssueId: types.optional(types.integer, 0),
  advocacyIssue: types.maybeNull(types.string),
  advocacyIssueParentId: types.maybeNull(types.number),
  advocacyInfluenceLevelId: types.optional(types.integer, 0),
  advocacyInfluenceLevel: types.maybeNull(types.string),
  reportingYear: types.optional(types.integer, -1),
  reportingQuarterId: types.optional(types.integer, 0),
  reportingQuarter: types.maybeNull(types.string),
  actualEngagementDate: types.maybeNull(types.string),
  engagementDevelopmentStageId: types.optional(types.integer, 0),
  engagementDevelopmentStage: types.maybeNull(types.string),
  recordDevelopmentStageId: types.optional(types.integer, 0),
  recordDevelopmentStage: types.maybeNull(types.string),
  influenceTypeId: types.optional(types.integer, 0),
  influenceType: types.optional(types.string, ''),
  influenceTypeParentId: types.maybeNull(types.number),
  advocacyTypes: types.optional(types.array(GenericWithParentIdLookUpDto), []),
  notes: types.maybeNull(types.string),
  hasSupportedDocuments: types.optional(types.boolean, false),
})

export interface IIndicator1_1_1DtoModel
  extends Instance<typeof Indicator1_1_1DtoModel> {}
export interface IIndicator1_1_1DtoModelSnapshotIn
  extends SnapshotIn<typeof Indicator1_1_1DtoModel> {}
export interface IIndicator1_1_1DtoModelSnapshotOut
  extends SnapshotOut<typeof Indicator1_1_1DtoModel> {}
export default Indicator1_1_1DtoModel
