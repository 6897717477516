import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import { apiV1 } from 'core/requests'
import {
  UsersPagedDto,
  IUsersPagedDtoSnapshotIn,
  IUsersPagedDtoSnapshotOut,
  UsersListRequestModel,
  IUsersListRequestSnapshotIn,
  CreateUserCommand,
  ICreateUserCommandSnapshotIn,
  IUpdateUserCommandSnapshotIn,
  UpdateUserCommand,
  IUserSnapshotOut,
  ChangeUsersPasswordCommand,
  IChangeUsersPasswordCommandSnapshotIn,
} from 'stores/models'

const UsersStore = types
  .model('UsersStore')
  .props({
    _users: types.optional(UsersPagedDto, {}),
    _isLoading: types.optional(types.boolean, false),
  })
  .actions(self => {
    const USERS_API = '/api/users'

    const setUsers = (users: IUsersPagedDtoSnapshotIn): void => {
      applySnapshot(self._users, users)
    }

    const setIsLoading = (isLoading: boolean) => {
      self._isLoading = isLoading
    }

    const getUsersList = async (params: IUsersListRequestSnapshotIn) => {
      const usersListRequest = fromPromise(
        apiV1.get<IUsersPagedDtoSnapshotIn>(USERS_API, {
          params: UsersListRequestModel.create(params),
        }),
      )

      when(() =>
        usersListRequest.case({
          fulfilled: response => {
            setUsers(response.data)
            return true
          },
        }),
      )
      return usersListRequest
    }

    const createUser = async (
      userData: ICreateUserCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.post(USERS_API, CreateUserCommand.create(userData))

    const editUser = async (
      userData: IUpdateUserCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.put(USERS_API, UpdateUserCommand.create(userData), {
        params: { id: userData.id },
      })

    const deleteUser = async (userId: IUserSnapshotOut['id']): Promise<void> =>
      apiV1.delete(`${USERS_API}/deleteuser`, { params: { id: userId } })

    const blockUser = async (userId: IUserSnapshotOut['id']): Promise<void> =>
      apiV1.post(`${USERS_API}/blockuser`, { id: userId })

    const unblockUser = async (userId: IUserSnapshotOut['id']): Promise<void> =>
      apiV1.post(`${USERS_API}/unblockuser`, { id: userId })

    const changeUsersPassword = async (
      changePasswordData: IChangeUsersPasswordCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.post(
        `${USERS_API}/changepassword`,
        ChangeUsersPasswordCommand.create(changePasswordData),
      )

    const confirmUser = async (userId: IUserSnapshotOut['id']): Promise<void> =>
      apiV1.post(`${USERS_API}/confirmuser`, { id: userId })

    return {
      getUsersList,
      createUser,
      editUser,
      deleteUser,
      blockUser,
      unblockUser,
      setIsLoading,
      changeUsersPassword,
      confirmUser,
    }
  })
  .views(self => ({
    get users(): IUsersPagedDtoSnapshotOut {
      return getSnapshot(self._users)
    },
    get usersList() {
      return this.users.data
    },
    get usersMetadata() {
      return this.users.metadata
    },
    get isLoading() {
      return self._isLoading
    },
  }))

export default UsersStore
export interface IUsersStore extends Instance<typeof UsersStore> {}
