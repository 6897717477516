import { lazy, Suspense, useCallback, useEffect, useState } from 'react'
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useLocation,
} from 'react-router-dom'
import './App.css'
import { useStore } from './stores/useStoreHook'
import { observer } from 'mobx-react'
import Layout from 'views/Layout/MainLayout'
import routes, { RouteConfigObject } from 'routes'
import { useIntl } from 'react-intl'
import { Loader } from 'components'
import { useBeforeUnload } from 'hooks'
import { handleError } from 'core/utils'

const Auth = lazy(() => import('./views/Auth'))

const App = observer(() => {
  const authStore = useStore('authStore')
  const intl = useIntl()
  const { pathname } = useLocation()
  const match = useRouteMatch(pathname)
  const [filteredRoutes, setFilteredRoutes] = useState<
    Array<RouteConfigObject>
  >([])
  useEffect(() => {
    const name = routes.find(route => match?.path === route.path)?.name
    if (name) document.title = intl.formatMessage(name)
  }, [match, match?.path, intl])

  const unloadEventHandler = useCallback(() => {
    if (!authStore.isRememberMe) {
      authStore.resetUserInfo()
    }
    return null
  }, [authStore])

  useBeforeUnload(unloadEventHandler)

  useEffect(() => {
    ;(async () => {
      if (authStore.user.accessToken) {
        try {
          authStore.setIsLoadingUserInfo(true)
          await Promise.all([
            authStore.getUserInfo(),
            authStore.getAllowedMenus(),
          ])

          setFilteredRoutes(
            routes
              .map(route => {
                const permissioMenu = authStore.allowedMenus.find(
                  menu => menu.name === route.permissionName,
                )
                return permissioMenu
                  ? { ...route, permissionId: permissioMenu.id }
                  : route
              })
              .filter(route => route.permissionId !== null),
          )
        } catch (error) {
          handleError(
            error,
            intl.formatMessage({
              id: 'global.default_error_message',
              defaultMessage: 'Произошла ошибка',
            }),
          )
        } finally {
          authStore.setIsLoadingUserInfo(false)
        }
      }
    })()
  }, [authStore, authStore.user.accessToken, intl])

  if (authStore.isLoadingUserInfo || authStore.isSignOutInProgress)
    return <Loader />
  if (
    !authStore.isAuth &&
    !JSON.parse(localStorage.getItem('authStore') || '{}')?._isAuth //TODO: add utils for localStorage
  )
    return (
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/auth">
            <Auth />
          </Route>
          <Redirect to="/auth" />
        </Switch>
      </Suspense>
    )

  return (
    <Layout routes={filteredRoutes}>
      <Suspense fallback={<Loader />}>
        <Switch>
          {filteredRoutes.map(
            ({ component: Component, path, children, ...route }) => (
              <Route exact={!children?.length} path={path} key={path}>
                <Component routes={children} selfRoute={{ path, ...route }} />
              </Route>
            ),
          )}
          {!!filteredRoutes[0] && <Redirect to={filteredRoutes[0].path} />}
        </Switch>
      </Suspense>
    </Layout>
  )
})

export default App
