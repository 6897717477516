import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import ExcludedCategoriesLookUpDto from '../lookUps/ExcludedCategoriesLookUpDto'

const UpdateExcludeBeneficiaryCommand = types.model(
  'UpdateExcludeBeneficiaryCommand',
  {
    excludedCategories: types.array(ExcludedCategoriesLookUpDto),
  },
)

export interface IUpdateExcludeBeneficiaryCommand
  extends Instance<typeof UpdateExcludeBeneficiaryCommand> {}
export interface IUpdateExcludeBeneficiaryCommandIn
  extends SnapshotIn<typeof UpdateExcludeBeneficiaryCommand> {}
export interface IUpdateExcludeBeneficiaryCommandOut
  extends SnapshotOut<typeof UpdateExcludeBeneficiaryCommand> {}
export default UpdateExcludeBeneficiaryCommand
