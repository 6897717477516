import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const IndicatorVulnerableMigrantReportModel = types.model('IndicatorVulnerableMigrantReportModel', {
    interviewDate: types.maybeNull(types.string),
    code: types.maybeNull(types.string),
    age: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    countryOfOrigin: types.maybeNull(types.string),
    ethnicity: types.maybeNull(types.string),
    migrationProcessEndCountry: types.maybeNull(types.string),
    refferalOrgTypes: types.array(types.string),
    indicatorCountry: types.maybeNull(types.string),
    organizationName: types.maybeNull(types.string)
})

export interface IIndicatorVulnerableMigrantReportModel
    extends Instance<typeof IndicatorVulnerableMigrantReportModel> {}
export interface IIndicatorVulnerableMigrantReportSnapshotIn
    extends SnapshotIn<typeof IndicatorVulnerableMigrantReportModel> {}
export interface IIndicatorVulnerableMigrantReportSnapshotOut
    extends SnapshotOut<typeof IndicatorVulnerableMigrantReportModel> {}
export default IndicatorVulnerableMigrantReportModel