import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import IndicatorReportsFilterBaseModel from '../IndicatorReportsFilterBaseModel'

const Indicator2_1_1ReportFilterModel = IndicatorReportsFilterBaseModel.named(
  'Indicator2_1_1ReportFilterModel',
).props({
  GenderId: types.union(types.number, types.undefined, types.null),
  AgeCategoryEnum: types.union(types.number, types.undefined, types.null),
  EthnicityId: types.union(types.number, types.undefined, types.null),
  TypeOfActivityId: types.union(types.number, types.undefined, types.null),
})

export interface IIndicator2_1_1ReportFilterModel
  extends Instance<typeof Indicator2_1_1ReportFilterModel> {}

export interface IIndicator2_1_1ReportFilterSnapshotIn
  extends SnapshotIn<typeof Indicator2_1_1ReportFilterModel> {}

export interface IIndicator2_1_1ReportFilterSnapshotOut
  extends SnapshotOut<typeof Indicator2_1_1ReportFilterModel> {}

export default Indicator2_1_1ReportFilterModel
