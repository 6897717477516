import { useEffect, useState } from 'react'

const usePersistedState = <T>(
  key: string,
  initialValue: T,
  storage: Storage,
) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from storage by key
      const item = storage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  })

  //useEffect to persist value to storage
  useEffect(() => {
    try {
      storage.setItem(key, JSON.stringify(storedValue))
    } catch (error) {
      console.log(error)
    }
  }, [storedValue, key, storage])

  return [storedValue, setStoredValue] as const
}

export default usePersistedState
