import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const Indicator3_1_1ReportModel = types.model('Indicator3_1_1ReportModel', {
  identificationDate: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  age: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  countryOfOrigin: types.maybeNull(types.string),
  ethnicity: types.maybeNull(types.string),
  isFormal: types.maybeNull(types.boolean),
  // traffickingForm: types.maybeNull(types.string),
  mainTraffickingForms: types.array(types.string),
  identifyingAgencies: types.array(types.string),
  country: types.maybeNull(types.string),
  tipCaseStatuses: types.array(types.string),
})

export interface IIndicator3_1_1ReportModel
  extends Instance<typeof Indicator3_1_1ReportModel> {}
export interface IIndicator3_1_1ReportSnapshotIn
  extends SnapshotIn<typeof Indicator3_1_1ReportModel> {}
export interface IIndicator3_1_1ReportSnapshotOut
  extends SnapshotOut<typeof Indicator3_1_1ReportModel> {}
export default Indicator3_1_1ReportModel
