import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../../PagedListMetaData'
import BeneficiaryIndividualModel from './BeneficiaryIndividualModel'

const beneficiaryIndividualPagedDto = types.model(
  'beneficiaryIndividualPagedDto',
  {
    data: types.array(BeneficiaryIndividualModel),
    metadata: types.optional(PagedListMetaData, {}),
  },
)

export interface IBeneficiaryIndividualPagedDto
  extends Instance<typeof beneficiaryIndividualPagedDto> {}

export interface IBeneficiaryIndividualPagedDtoSnapshotIn
  extends SnapshotIn<typeof beneficiaryIndividualPagedDto> {}

export interface IBeneficiaryIndividualPagedDtoSnapshotOut
  extends SnapshotOut<typeof beneficiaryIndividualPagedDto> {}

export default beneficiaryIndividualPagedDto
