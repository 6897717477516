import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const CreateIndicator1_2_1Command = types.model('CreateIndicator1_2_1Command', {
  creatorOrganizationId: types.integer,
  organizationId: types.integer,
  recordDate: types.maybeNull(types.string),
  countryId: types.integer,
  countryComment: types.optional(types.string, ''),
  regionId: types.maybeNull(types.integer),
  organizationTypeId: types.integer,
  organizationTypeComment: types.maybeNull(types.string),
  improvementAreaIds: types.array(types.integer),
  supportTypeIds: types.array(types.integer),
  supportTypeComment: types.maybeNull(types.string),
  notes: types.maybeNull(types.string),
  hasSupportedDocuments: types.boolean,
})

export interface ICreateIndicator1_2_1Command
  extends Instance<typeof CreateIndicator1_2_1Command> {}
export interface ICreateIndicator1_2_1CommandSnapshotIn
  extends SnapshotIn<typeof CreateIndicator1_2_1Command> {}
export interface ICreateIndicator1_2_1CommandSnapshotOut
  extends SnapshotOut<typeof CreateIndicator1_2_1Command> {}
export default CreateIndicator1_2_1Command
