import ToolbarButton from '../ToolbarButton'
import { ChangeEventHandler, FC, useRef } from 'react'
import { FormattedMessage, MessageDescriptor } from 'react-intl'

export type FileInputButtonProps = {
  buttonClassName?: string
  iconClassName?: string
  title: MessageDescriptor & { values?: Record<string, string> }
  onFileChange: ChangeEventHandler<HTMLInputElement>
  isMulti?: boolean
  accept?: Array<string>
}

const FileInputButton: FC<FileInputButtonProps> = ({
  iconClassName = 'd-none',
  buttonClassName,
  title,
  onFileChange,
  isMulti = false,
  accept,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={onFileChange}
        multiple={isMulti}
        {...(accept?.length ? { accept: accept.join(',') } : {})}
      />
      <ToolbarButton
        buttonClassName={buttonClassName}
        action={() => {
          if (!fileInputRef.current) return
          // resetting the file path stored in the file input value
          // to allow re-uploading the same file multiple times
          fileInputRef.current.value = ''
          fileInputRef.current.click()
        }}
        iconClassName={iconClassName}
      >
        <FormattedMessage {...title} />
      </ToolbarButton>
    </>
  )
}

export default FileInputButton
