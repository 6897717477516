import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateActivityStaffCommand = types.model('UpdateActivityStaffCommand', {
  id: types.integer,
  staffIds: types.array(types.number),
})

export interface IUpdateActivityStaffCommand
  extends Instance<typeof UpdateActivityStaffCommand> {}

export interface IUpdateActivityStaffCommandSnapshotIn
  extends SnapshotIn<typeof UpdateActivityStaffCommand> {}

export interface IUpdateActivityStaffCommandSnapshotOut
  extends SnapshotOut<typeof UpdateActivityStaffCommand> {}

export default UpdateActivityStaffCommand
