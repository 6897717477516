import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateUserCommand = types.model('UpdateUserCommand', {
  id: types.identifier,
  email: types.string,
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  roleName: types.maybeNull(types.string),
  countryId: types.integer,
  organizationId: types.integer,
})

export interface IUpdateUserCommand
  extends Instance<typeof UpdateUserCommand> {}

export interface IUpdateUserCommandSnapshotIn
  extends SnapshotIn<typeof UpdateUserCommand> {}

export interface IUpdateUserCommandSnapshotOut
  extends SnapshotOut<typeof UpdateUserCommand> {}

export default UpdateUserCommand
