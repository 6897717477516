import { Button, Modal } from 'react-bootstrap'
import { LogOutIcon } from '../../icons'
import { useStore } from 'stores/useStoreHook'
import { FormattedMessage } from 'react-intl'
import { FC, useState } from 'react'
import './SignOutButton.scss'

const SignOutButton: FC = () => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const authStore = useStore('authStore')

  return (
    <>
      <Button
        variant="secondary"
        className="sign-out-btn"
        onClick={() => setIsConfirmationOpen(true)}
      >
        <LogOutIcon />
        <span className="sign-out-btn_text">
          <FormattedMessage id="authorization.signout" defaultMessage="Выход" />
        </span>
      </Button>
      <Modal
        centered
        onHide={() => setIsConfirmationOpen(false)}
        show={isConfirmationOpen}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id="authorization.signout"
              defaultMessage="Выход"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <FormattedMessage
              id="signout_confirmation.description"
              defaultMessage="Вы уверены, что хотите выйти из системы?"
            />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={authStore.resetUserInfo}>
            <FormattedMessage
              id="global.confirm"
              defaultMessage="Подтвердить"
            />
          </Button>
          <Button
            onClick={() => setIsConfirmationOpen(false)}
            variant="secondary"
          >
            <FormattedMessage id="global.cancel" defaultMessage="Отменить" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SignOutButton
