import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../../../PagedListMetaData'
import Indicator3_2_2ReportModel from './Indicator3_2_2ReportModel'

const Indicator3_2_2ReportPagedModel = types.model(
  'Indicator3_2_2ReportPagedModel',
  {
    data: types.array(Indicator3_2_2ReportModel),
    metadata: types.optional(PagedListMetaData, {}),
  },
)

export interface IIndicator3_2_2ReportPagedModel
  extends Instance<typeof Indicator3_2_2ReportPagedModel> {}

export interface IIndicator3_2_2ReportPagedSnapshotIn
  extends SnapshotIn<typeof Indicator3_2_2ReportPagedModel> {}

export interface IIndicator3_2_2ReportPagedSnapshotOut
  extends SnapshotOut<typeof Indicator3_2_2ReportPagedModel> {}

export default Indicator3_2_2ReportPagedModel
