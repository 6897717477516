import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const ExploitationConditionLookUpModel = LookUpModel.named(
    'ExploitationConditionLookUpModel',
).props({
    isComment: types.boolean,
})

export interface IExploitationConditionLookUp
    extends Instance<typeof ExploitationConditionLookUpModel> {}
export interface IExploitationConditionLookUpIn
    extends SnapshotIn<typeof ExploitationConditionLookUpModel> {}
export interface IExploitationConditionLookUpOut
    extends SnapshotOut<typeof ExploitationConditionLookUpModel> {}
export default ExploitationConditionLookUpModel
