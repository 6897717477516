import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import { apiV1 } from 'core/requests'
import {
  IOrganizationSnapshotOut,
  ICreateOrganizationCommandSnapshotIn,
  IUpdateOrganizationCommandSnapshotIn,
  UpdateOrganizationCommand,
  CreateOrganizationCommand,
  OrganizationPagedDto,
  IOrganizationPagedDtoSnapshotIn,
  IOrganizationPagedDtoSnapshotOut,
  OrganizationsListRequestModel,
  IOrganizationsListRequestSnapshotIn,
  OrganizationModel,
  IOrganizationSnapshotIn,
} from 'stores/models'

const PartnersStore = types
  .model('PartnersStore')
  .props({
    _organizations: types.optional(OrganizationPagedDto, {}),
    _activeOrganizations: types.optional(OrganizationPagedDto, {}),
    _isLoading: types.optional(types.boolean, false),
    _organization: types.optional(OrganizationModel, {}, [null, undefined]),
  })
  .actions(self => {
    const PARTNERS_API = '/api/partners'

    const setOrganizations = (
      organizations: IOrganizationPagedDtoSnapshotIn,
    ): void => {
      applySnapshot(self._organizations, organizations)
    }

    const resetOrganizations = () => setOrganizations({})

    const setActiveOrganizations = (
      organizations: IOrganizationPagedDtoSnapshotIn,
    ): void => {
      applySnapshot(self._activeOrganizations, organizations)
    }

    const setOrganization = (
      organization: IOrganizationSnapshotIn | null,
    ): void => {
      applySnapshot(self._organization, organization)
    }

    const resetOrganization = () => setOrganization({})

    const setIsLoading = (isLoading: boolean) => {
      self._isLoading = isLoading
    }

    const getOrganizationsList = async (
      params: IOrganizationsListRequestSnapshotIn,
    ) => {
      const organizationsListRequest = fromPromise(
        apiV1.get<IOrganizationPagedDtoSnapshotIn>(`${PARTNERS_API}/all`, {
          params: OrganizationsListRequestModel.create(params),
        }),
      )

      when(() =>
        organizationsListRequest.case({
          fulfilled: response => {
            setOrganizations(response.data)
            return true
          },
        }),
      )
      return organizationsListRequest
    }

    const getActiveOrganizationsList = async (
      params: IOrganizationsListRequestSnapshotIn,
    ) => {
      const organizationsListRequest = fromPromise(
        apiV1.get<IOrganizationPagedDtoSnapshotIn>(`${PARTNERS_API}/active`, {
          params: OrganizationsListRequestModel.create(params),
        }),
      )

      when(() =>
        organizationsListRequest.case({
          fulfilled: response => {
            setActiveOrganizations(response.data)
            return true
          },
        }),
      )
      return organizationsListRequest
    }

    const getOrganization = async (
      organizationId: IOrganizationSnapshotOut['id'],
    ) => {
      const organizationRequest = fromPromise(
        apiV1.get<IOrganizationSnapshotIn>(PARTNERS_API, {
          params: { id: organizationId },
        }),
      )

      when(() =>
        organizationRequest.case({
          fulfilled: response => {
            setOrganization(response.data)
            return true
          },
        }),
      )
      return organizationRequest
    }

    const createOrganization = async (
      organization: ICreateOrganizationCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.post(PARTNERS_API, CreateOrganizationCommand.create(organization))

    const editOrganization = async (
      organization: IUpdateOrganizationCommandSnapshotIn,
    ): Promise<void> =>
      apiV1.put(PARTNERS_API, UpdateOrganizationCommand.create(organization), {
        params: { id: organization.id },
      })

    const deleteOrganization = async (
      organizationId: IOrganizationSnapshotOut['id'],
    ): Promise<void> =>
      apiV1.delete(PARTNERS_API, {
        params: { id: organizationId },
      })

    const deactivateOrganization = async (
      organizationId: IOrganizationSnapshotOut['id'],
    ): Promise<void> =>
      apiV1.put(`${PARTNERS_API}/deactivate`, null, {
        params: { id: organizationId },
      })

    const activateOrganization = async (
      organizationId: IOrganizationSnapshotOut['id'],
    ): Promise<void> =>
      apiV1.put(`${PARTNERS_API}/activate`, null, {
        params: { id: organizationId },
      })

    return {
      getOrganizationsList,
      getActiveOrganizationsList,
      getOrganization,
      createOrganization,
      editOrganization,
      deleteOrganization,
      deactivateOrganization,
      activateOrganization,
      setIsLoading,
      resetOrganizations,
      resetOrganization,
    }
  })
  .views(self => ({
    get organizations(): IOrganizationPagedDtoSnapshotOut {
      return getSnapshot(self._organizations)
    },
    get organizationsList() {
      return this.organizations.data
    },
    get organizationsMetadata() {
      return this.organizations.metadata
    },
    get activeOrganizations(): IOrganizationPagedDtoSnapshotOut {
      return getSnapshot(self._activeOrganizations)
    },
    get activeOrganizationsList() {
      return this.activeOrganizations.data
    },
    get activeOrganizationsMetadata() {
      return this.activeOrganizations.metadata
    },
    get organization(): IOrganizationSnapshotOut {
      return getSnapshot(self._organization)
    },
    get isLoading() {
      return self._isLoading
    },
  }))

export default PartnersStore
export interface IPartnersStore extends Instance<typeof PartnersStore> {}
