import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateOrganizationCommand = types.model('UpdateOrganizationCommand', {
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
  countryId: types.integer,
  countryComment: types.optional(types.string, ''),
  regionIds: types.maybeNull(types.array(types.integer)),
  organizationTypeId: types.integer,
  organizationTypeComment: types.maybeNull(types.string),
})

export interface IUpdateOrganizationCommand
  extends Instance<typeof UpdateOrganizationCommand> {}
export interface IUpdateOrganizationCommandSnapshotIn
  extends SnapshotIn<typeof UpdateOrganizationCommand> {}
export interface IUpdateOrganizationCommandSnapshotOut
  extends SnapshotOut<typeof UpdateOrganizationCommand> {}
export default UpdateOrganizationCommand
