import { FC, PropsWithChildren } from 'react'
import classes from './style.module.css'

const Header: FC<PropsWithChildren<{ className?: string }>> = ({
  className,
  children,
}) => {
  return (
    <header className={`${classes.header} ${className}`}>{children}</header>
  )
}

export default Header
