import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const Indicator2_2_3ReportModel = types.model('Indicator2_2_3ReportModel', {
  organizationName: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  legalEntityType: types.maybeNull(types.string),
  businessScale: types.maybeNull(types.string),
  industryType: types.maybeNull(types.string),
  supportTypes: types.array(types.string),
  collaborationAreas: types.array(types.string),
})

export interface IIndicator2_2_3ReportModel
  extends Instance<typeof Indicator2_2_3ReportModel> {}
export interface IIndicator2_2_3ReportSnapshotIn
  extends SnapshotIn<typeof Indicator2_2_3ReportModel> {}
export interface IIndicator2_2_3ReportSnapshotOut
  extends SnapshotOut<typeof Indicator2_2_3ReportModel> {}
export default Indicator2_2_3ReportModel
