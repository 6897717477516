import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import { apiV1 } from 'core/requests'
import {
  BeneficiaryOrganizationListRequestModel,
  BeneficiaryOrganizationPagedDto,
  IBeneficiaryOrganizationsExportRequest,
  ExportFilesType,
  IBeneficiaryOrganizationListRequestSnapshotIn,
  IBeneficiaryOrganizationPagedDtoSnapshotIn,
} from 'stores/models'
import fileDownload from 'js-file-download'
import {
  capitalizeFirstLetter,
  capitalizeObjectKeys,
  getFileNameFromContentDisposition,
} from 'core/utils'

const BeneficiariesOrganizationsStore = types
  .model('BeneficiariesOrganizationsStore')
  .props({
    _beneficiariesOrganizationsList: types.optional(
      BeneficiaryOrganizationPagedDto,
      {},
    ),
    _isLoading: types.optional(types.boolean, false),
  })
  .actions(self => {
    const BENEFICIARIES_ORGANIZATION_API = '/api/beneficiaries/organizations'

    const setBeneficiariesOrganizations = (
      beneficiariesOrganizations: IBeneficiaryOrganizationPagedDtoSnapshotIn,
    ): void => {
      applySnapshot(
        self._beneficiariesOrganizationsList,
        beneficiariesOrganizations,
      )
    }

    const setIsLoading = (isLoading: boolean) => {
      self._isLoading = isLoading
    }

    const getBeneficiariesOrganizationsExportFile = async (
      params: IBeneficiaryOrganizationsExportRequest,
      type: ExportFilesType,
      onDownloadProgress: (progressEvent: ProgressEvent) => void,
    ) => {
      const beneficiariesOrganizationsExportRequest = fromPromise(
        apiV1.get<ArrayBuffer>(`${BENEFICIARIES_ORGANIZATION_API}/export`, {
          params: {
            ...params,
            sortBy: capitalizeFirstLetter(params.sortBy),
            filter: capitalizeObjectKeys(params.filter),
          },
          responseType: 'arraybuffer',
          onDownloadProgress,
        }),
      )

      when(() =>
        beneficiariesOrganizationsExportRequest.case({
          fulfilled: response => {
            const fileName = getFileNameFromContentDisposition(
              response.headers['content-disposition'],
            )
            fileDownload(response.data, fileName ? fileName : `report.${type}`)
            return true
          },
        }),
      )

      return beneficiariesOrganizationsExportRequest
    }

    const getBeneficiariesOrganizationsList = async (
      params: IBeneficiaryOrganizationListRequestSnapshotIn,
    ) => {
      const beneficiariesOrganizationsListRequest = fromPromise(
        apiV1.get<IBeneficiaryOrganizationPagedDtoSnapshotIn>(
          BENEFICIARIES_ORGANIZATION_API,
          {
            params: BeneficiaryOrganizationListRequestModel.create(params),
          },
        ),
      )

      when(() =>
        beneficiariesOrganizationsListRequest.case({
          fulfilled: response => {
            setBeneficiariesOrganizations(response.data)
            return true
          },
        }),
      )
      return beneficiariesOrganizationsListRequest
    }

    return {
      getBeneficiariesOrganizationsExportFile,
      setBeneficiariesOrganizations,
      getBeneficiariesOrganizationsList,
      setIsLoading,
    }
  })
  .views(self => ({
    get beneficiariesOrganizations() {
      return getSnapshot(self._beneficiariesOrganizationsList)
    },
    get beneficiariesOrganizationsList() {
      return this.beneficiariesOrganizations.data
    },
    get beneficiariesOrganizationsMetadata() {
      return this.beneficiariesOrganizations.metadata
    },
    get isLoading() {
      return self._isLoading
    },
  }))

export default BeneficiariesOrganizationsStore

export interface IBeneficiariesOrganizationStore
  extends Instance<typeof BeneficiariesOrganizationsStore> {}
