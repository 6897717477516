import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const EmbedReportModel = types.model('EmbedReportModel', {
  reportId: types.optional(types.string, ''),
  reportName: types.optional(types.string, ''),
  description: types.maybeNull(types.string),
  embedReportPageUrl: types.optional(types.string, '', [null, undefined]),
  embedUrl: types.optional(types.string, '', [null, undefined]),
  tableName: types.optional(types.string, '', [null, undefined]),
  type: types.optional(types.string, ''),
})

export interface IEmbedReportModel extends Instance<typeof EmbedReportModel> {}

export interface IEmbedReportSnapshotIn
  extends SnapshotIn<typeof EmbedReportModel> {}

export interface IEmbedReportSnapshotOut
  extends SnapshotOut<typeof EmbedReportModel> {}

export default EmbedReportModel
