import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const OrganizationStaffLookUpModel = LookUpModel.named(
  'OrganizationStaffLookUpModel',
)

export interface IOrganizationStaffLookUpModel
  extends Instance<typeof OrganizationStaffLookUpModel> {}

export interface IOrganizationStaffLookUpModelSnapshotIn
  extends SnapshotIn<typeof OrganizationStaffLookUpModel> {}

export interface IOrganizationStaffLookUpModelSnapshotOut
  extends SnapshotOut<typeof OrganizationStaffLookUpModel> {}

export default OrganizationStaffLookUpModel
