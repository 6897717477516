import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const DevelopmentStageLookUpModel = LookUpModel.named(
  'DevelopmentStageLookUpModel',
).props({
  isComment: types.boolean,
})

export interface IDevelopmentStageLookUpModel
  extends Instance<typeof DevelopmentStageLookUpModel> {}
export interface IDevelopmentStageLookUpSnapshotIn
  extends SnapshotIn<typeof DevelopmentStageLookUpModel> {}
export interface IDevelopmentStageLookUpSnapshotOut
  extends SnapshotOut<typeof DevelopmentStageLookUpModel> {}
export default DevelopmentStageLookUpModel
