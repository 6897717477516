import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const ControlQuestionLookUpDto = types.model('ControlQuestionLookUpDto', {
  id: types.number,
  name: types.string,
})

export interface IControlQuestionLookUpDto
  extends Instance<typeof ControlQuestionLookUpDto> {}

export interface IControlQuestionLookUpDtoSnapshotIn
  extends SnapshotIn<typeof ControlQuestionLookUpDto> {}

export interface IControlQuestionLookUpDtoSnapshotOut
  extends SnapshotOut<typeof ControlQuestionLookUpDto> {}

export default ControlQuestionLookUpDto
