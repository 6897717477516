enum CatalogName {
  Gender = 'gender',
  Ethnicity = 'ethnicity',
  TypesOfService = 'typesOfService',
  Beneficiary = 'beneficiary',
  OrganizationTypes = 'organizationTypes',
  ActivityTypes = 'activityTypes',
  TraffickingForms = 'traffickingForms',
  IdentifyingAgencies = 'identifyingAgencies',
  TipCaseStatuses = 'tipCaseStatuses',
  AdvocacyIssues = 'advocacyIssues',
  AdvocacyTypes = 'advocacyTypes',
  SupportTypes = 'supportTypes',
  AdvocacyInfluenceLevel = 'advocacyInfluenceLevel',
  DevelopmentStages = 'developmentStages',
  InfluenceTypes = 'influenceTypes',
  DocumentType = 'documentTypes',
  ImprovementArea = 'improvementArea',
  CSOTypes = 'csoTypes',
  DataTypes = 'dataTypes',
  DataCollectionFrequency = 'dataCollectionFrequency',
  BusinessScale = 'businessScale',
  SupportingDocuments = 'supportingDocuments',
  IndustryTypes = 'industryTypes',
  LegalEntityTypes = 'legalEntityTypes',
  CollaborationAreas = 'collaborationAreas',
}

export default CatalogName
