import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import {
  ExternalLoginCommand,
  IExternalLoginCommandSnapshotIn,
  ILoginCommandSnapshotIn,
  IPermissionActionSnapshotIn,
  IPermissionGroupSnapshotIn,
  IPermissionMenuSnapshotIn,
  IResetPasswordCommandSnapshotIn,
  IUserInformationSnapshotIn,
  IUserAccessTokenSnapshotIn,
  LoginCommand,
  PermissionActionModel,
  PermissionGroupModel,
  PermissionMenuModel,
  ResetPasswordCommand,
  UserAccessTokenModel,
  UserInformationModel,
  RoleName,
} from 'stores/models'
import { apiV1 } from 'core/requests'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'

const AuthStore = types
  .model('AuthStore')
  .props({
    _user: types.optional(UserAccessTokenModel, {}),
    _userInfo: types.optional(UserInformationModel, {}),
    _allowedMenus: types.array(PermissionMenuModel),
    _allowedPermissionGroups: types.array(PermissionGroupModel),
    _allowedActions: types.array(PermissionActionModel),
    _isLoading: types.optional(types.boolean, false),
    _isLoadingUserInfo: types.optional(types.boolean, false),
    _isAuth: types.optional(types.boolean, false),
    _isRememberMe: types.optional(types.boolean, false),
    _isTokenRefreshing: types.optional(types.boolean, false),
    _isSignOutInProgress: types.optional(types.boolean, false),
  })
  .actions(self => {
    const setIsTokenRefreshing = (isTokenRefreshing: boolean) => {
      self._isTokenRefreshing = isTokenRefreshing
    }
    const setIsAuth = (isAuth: boolean) => {
      self._isAuth = isAuth
    }
    const setIsRememberMe = (isRememberMe: boolean) => {
      self._isRememberMe = isRememberMe
    }
    const setIsLoading = (isLoading: boolean) => {
      self._isLoading = isLoading
    }
    const setIsLoadingUserInfo = (isLoading: boolean) => {
      self._isLoadingUserInfo = isLoading
    }
    const setIsSignOutInProgress = (isLoading: boolean) => {
      self._isSignOutInProgress = isLoading
    }
    return {
      setIsTokenRefreshing,
      setIsAuth,
      setIsRememberMe,
      setIsLoading,
      setIsLoadingUserInfo,
      setIsSignOutInProgress,
    }
  })
  .actions(self => {
    const ACCOUNT_API = '/api/account'
    const setUser = (userData: IUserAccessTokenSnapshotIn) => {
      applySnapshot(self._user, userData)
    }
    const setUserInformation = (userInfo: IUserInformationSnapshotIn) => {
      applySnapshot(self._userInfo, userInfo)
    }
    const setAllowedMenus = (
      allowedMenus: Array<IPermissionMenuSnapshotIn>,
    ) => {
      applySnapshot(self._allowedMenus, allowedMenus)
    }
    const setAllowedPermissionGroups = (
      allowedPermissionGroups: Array<IPermissionGroupSnapshotIn>,
    ) => {
      applySnapshot(self._allowedPermissionGroups, allowedPermissionGroups)
    }
    const setAllowedActions = (
      allowedActions: Array<IPermissionActionSnapshotIn>,
    ) => {
      applySnapshot(self._allowedActions, allowedActions)
    }
    const resetAllowedActions = () => setAllowedActions([])

    const resetUserInfo = () => {
      self.setIsAuth(false)
      setUser(UserAccessTokenModel.create())
      setUserInformation(UserInformationModel.create())
    }

    const signOut = async () => {
      self.setIsSignOutInProgress(true)
      await apiV1.post(`${ACCOUNT_API}/logout`)
      resetUserInfo()
      self.setIsSignOutInProgress(false)
    }

    const getUserInfo = async () => {
      const userInfoRequest = fromPromise(
        apiV1.post<IUserInformationSnapshotIn>(`${ACCOUNT_API}/userinfo`),
      )

      when(() =>
        userInfoRequest.case({
          fulfilled: response => {
            setUserInformation(response.data)
            return true
          },
        }),
      )
      return userInfoRequest
    }

    const getAllowedMenus = async () => {
      const allowedMenusRequest = fromPromise(
        apiV1.get<Array<IPermissionMenuSnapshotIn>>(
          `${ACCOUNT_API}/allowedmenus`,
        ),
      )

      when(() =>
        allowedMenusRequest.case({
          fulfilled: response => {
            setAllowedMenus(response.data)
            return true
          },
        }),
      )
      return allowedMenusRequest
    }

    const getAllowedPermissionGroupsByMenuId = async (menuId: number) => {
      const allowedMenusRequest = fromPromise(
        apiV1.get<Array<IPermissionGroupSnapshotIn>>(
          `${ACCOUNT_API}/allowedgroupsbymenuid`,
          { params: { id: menuId } },
        ),
      )

      when(() =>
        allowedMenusRequest.case({
          fulfilled: response => {
            setAllowedPermissionGroups(response.data)
            return true
          },
        }),
      )
      return allowedMenusRequest
    }

    const getAllowedActionsByGroupId = async (permissionGroupId: number) => {
      const allowedMenusRequest = fromPromise(
        apiV1.get<Array<IPermissionActionSnapshotIn>>(
          `${ACCOUNT_API}/allowedpermissionsbygroupid`,
          { params: { permissionGroupId } },
        ),
      )

      when(() =>
        allowedMenusRequest.case({
          fulfilled: response => {
            setAllowedActions(response.data)
            return true
          },
        }),
      )
      return allowedMenusRequest
    }

    const logIn = (userData: ILoginCommandSnapshotIn) => {
      const loginRequest = fromPromise(
        apiV1.post<Omit<IUserAccessTokenSnapshotIn, 'userName'>>(
          `${ACCOUNT_API}/login`,
          LoginCommand.create(userData),
        ),
      )

      when(() =>
        loginRequest.case({
          fulfilled: response => {
            setUser({
              ...response.data,
              userName: userData.userName,
            })
            return true
          },
        }),
      )
      return loginRequest
    }

    const logInByExternal = (
      loginData: IExternalLoginCommandSnapshotIn,
      userName: string,
    ) => {
      const loginRequest = fromPromise(
        apiV1.post<Omit<IUserAccessTokenSnapshotIn, 'userName'>>(
          `${ACCOUNT_API}/internaltokenbyexternal`,
          ExternalLoginCommand.create(loginData),
        ),
      )

      when(() =>
        loginRequest.case({
          fulfilled: response => {
            setUser({
              ...response.data,
              userName,
            })
            return true
          },
        }),
      )
      return loginRequest
    }

    const getUserTokenInfoByRefresh = async (refreshToken: string) => {
      const refreshTokenRequest = fromPromise(
        apiV1.post<Omit<IUserAccessTokenSnapshotIn, 'userName'>>(
          `${ACCOUNT_API}/refreshaccesstoken`,
          {
            refreshToken,
          },
        ),
      )
      self.setIsTokenRefreshing(true)

      when(() =>
        refreshTokenRequest.case({
          fulfilled: response => {
            setUser({ ...response.data, refreshToken })
            self.setIsTokenRefreshing(false)
            return true
          },
          rejected: () => {
            self.setIsTokenRefreshing(false)
            return true
          },
        }),
      )
      return refreshTokenRequest
    }

    const resetPassword = async (
      passwordData: IResetPasswordCommandSnapshotIn,
    ) =>
      apiV1.post<void>(
        `${ACCOUNT_API}/resetpassword`,
        ResetPasswordCommand.create(passwordData),
      )

    return {
      logIn,
      getUserTokenInfoByRefresh,
      signOut,
      resetPassword,
      setUser,
      logInByExternal,
      getUserInfo,
      getAllowedMenus,
      getAllowedPermissionGroupsByMenuId,
      getAllowedActionsByGroupId,
      setAllowedActions,
      resetAllowedActions,
      resetUserInfo,
    }
  })
  .views(self => ({
    get user() {
      return getSnapshot(self._user)
    },
    get userInfo() {
      return getSnapshot(self._userInfo)
    },
    get allowedMenus() {
      return getSnapshot(self._allowedMenus)
    },
    get allowedPermissionGroups() {
      return getSnapshot(self._allowedPermissionGroups)
    },
    get allowedActions() {
      return getSnapshot(self._allowedActions)
    },
    get isAuth() {
      return self._isAuth
    },
    get isLoading() {
      return self._isLoading
    },
    get isLoadingUserInfo() {
      return self._isLoadingUserInfo
    },
    get isRememberMe() {
      return self._isRememberMe
    },
    get isTokenRefreshing() {
      return self._isTokenRefreshing
    },
    get isSignOutInProgress() {
      return self._isSignOutInProgress
    },
    isInRole(...roles: Array<RoleName>): boolean {
      return roles.includes(this.userInfo.userRole as RoleName)
    },
    get isInCountryScopedRole(): boolean {
      return this.isInRole(
        RoleName.GranteeStaff,
        RoleName.CountryCoordinator,
        RoleName.ProjectAssociate,
      )
    },
  }))

export default AuthStore
export interface IAuthStore extends Instance<typeof AuthStore> {}
