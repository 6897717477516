import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from 'stores/models/PagedListRequestModel'

const ActivityStaffRequestModel = PagedListRequestModel.named(
  'ActivityStaffRequestModel',
).props({
  id: types.number, // id of activity
  sortBy: types.optional(types.string, ''),
})

export interface IActivityStaffRequestModel
  extends Instance<typeof ActivityStaffRequestModel> {}

export interface IActivityStaffRequestSnapshotIn
  extends SnapshotIn<typeof ActivityStaffRequestModel> {}

export interface IActivityStaffRequestSnapshotOut
  extends SnapshotOut<typeof ActivityStaffRequestModel> {}

export default ActivityStaffRequestModel
