import { Form, FormControlProps } from 'react-bootstrap'
import PasswordInput from './PasswordInput'
import styles from './Input.module.scss'
import { FC } from 'react'
import { SearchIcon } from '../../icons'

export interface InputProps extends FormControlProps {
  label?: string
  errorMessage?: string | Array<string>
  type: string
  name: string
  inputWrapperClasses?: string
  onBlur?: (event: any) => any
  onFocus?: (event: any) => any
  onClick?: (event: any) => any
  placeholder?: string
  autoComplete?: string
}

const Input: FC<InputProps> = ({
  label = '',
  errorMessage,
  type,
  inputWrapperClasses = '',
  className,
  ...props
}) => {
  const inputClassName = [
    styles.input,
    className,
    props.disabled ? 'disabledInput' : '',
  ].join(' ')
  return (
    <Form.Group className={'mb-0 ' + inputWrapperClasses}>
      {!!label && <Form.Label htmlFor={label}>{label}</Form.Label>}
      {type === 'password' ? (
        <PasswordInput
          isInvalid={!!errorMessage}
          errorMessage={errorMessage}
          {...props}
          className={inputClassName}
        />
      ) : type === 'search' ? (
        <div className="position-relative">
          <SearchIcon className={styles.searchIcon} />
          <Form.Control
            placeholder={label}
            isInvalid={!!errorMessage}
            {...props}
            type={type}
            className={styles.searchInput}
          />
        </div>
      ) : (
        <>
          <Form.Control
            placeholder={label}
            isInvalid={!!errorMessage}
            {...props}
            type={type}
            className={inputClassName}
          />
          {!!errorMessage && (
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          )}
        </>
      )}
    </Form.Group>
  )
}

export default Input
