import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateActivityTestResultCommand = types.model(
  'UpdateActivityTestResultCommand',
  {
    activityId: types.integer,
    participantIds: types.array(types.number),
    testCorrectAnswerCount: types.maybeNull(types.number),
  },
)

export interface IUpdateActivityTestResultCommand
  extends Instance<typeof UpdateActivityTestResultCommand> {}

export interface IUpdateActivityTestResultCommandSnapshotIn
  extends SnapshotIn<typeof UpdateActivityTestResultCommand> {}

export interface IUpdateActivityTestResultCommandSnapshotOut
  extends SnapshotOut<typeof UpdateActivityTestResultCommand> {}

export default UpdateActivityTestResultCommand
