import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'mobx-react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { stores } from 'stores/domain'
import { setupInterceptors } from 'core/requests'
import persist from 'mst-persist'
import { IntlProviderWrapper } from 'localization'

persist('authStore', stores.authStore, {
  storage: localStorage,
  whitelist: ['_user', '_isAuth', '_isRememberMe'],
})

persist('appStore', stores.appStore, {
  storage: localStorage,
  whitelist: ['_currentLanguage'],
})
setupInterceptors(stores.appStore, stores.authStore)

ReactDOM.render(
  <React.StrictMode>
    <Provider {...stores}>
      <BrowserRouter>
        <IntlProviderWrapper>
          <App />
        </IntlProviderWrapper>
      </BrowserRouter>
      <ToastContainer
        autoClose={7000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
