import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from '../PagedListMetaData'
import UserModel from './UserModel'

const UsersPagedDto = types.model('UsersPagedDto', {
  data: types.array(UserModel),
  metadata: types.optional(PagedListMetaData, {}),
})

export interface IUsersPagedDto extends Instance<typeof UsersPagedDto> {}

export interface IUsersPagedDtoSnapshotIn
  extends SnapshotIn<typeof UsersPagedDto> {}

export interface IUsersPagedDtoSnapshotOut
  extends SnapshotOut<typeof UsersPagedDto> {}

export default UsersPagedDto
