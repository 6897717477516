import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const LookUpModel = types.model('LookUpModel', {
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
})

export interface ILookUpModel extends Instance<typeof LookUpModel> {}
export interface ILookUpSnapshotIn extends SnapshotIn<typeof LookUpModel> {}
export interface ILookUpSnapshotOut extends SnapshotOut<typeof LookUpModel> {}
export default LookUpModel
