import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const DataTypeLookUpModel = LookUpModel.named('DataTypeLookUpModel').props({
  isComment: types.boolean,
})

export interface IDataTypeLookUp extends Instance<typeof DataTypeLookUpModel> {}

export interface IDataTypeLookUpSnapshotIn
  extends SnapshotIn<typeof DataTypeLookUpModel> {}

export interface IDataTypeLookUpSnapshotOut
  extends SnapshotOut<typeof DataTypeLookUpModel> {}

export default DataTypeLookUpModel
