import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const DocumentTypeLookUpModel = LookUpModel.named(
  'DocumentTypeLookUpModel',
).props({
  isComment: types.boolean,
})

export interface IDocumentTypeLookUpModel
  extends Instance<typeof DocumentTypeLookUpModel> {}
export interface IDocumentTypeLookUpSnapshotIn
  extends SnapshotIn<typeof DocumentTypeLookUpModel> {}
export interface IDocumentTypeLookUpSnapshotOut
  extends SnapshotOut<typeof DocumentTypeLookUpModel> {}
export default DocumentTypeLookUpModel
