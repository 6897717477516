import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const ActivityModel = types.model('ActivityModel', {
  id: types.optional(types.number, 0),
  name: types.maybeNull(types.string),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  attendeesCount: types.optional(types.number, 0),
  type: types.maybeNull(types.string),
  typeId: types.optional(types.number, 0),
  activityType: types.maybeNull(types.string),
  activityTypeId: types.optional(types.number, 0),
  status: types.maybeNull(types.string),
  statusId: types.optional(types.number, 0),
  canBePended: types.optional(types.boolean, false),
  canBeDeleted: types.optional(types.boolean, false),
  relatedToCovid19: types.maybeNull(types.boolean),
})

export interface IActivityModel extends Instance<typeof ActivityModel> {}

export interface IActivitySnapshotIn extends SnapshotIn<typeof ActivityModel> {}

export interface IActivitySnapshotOut
  extends SnapshotOut<typeof ActivityModel> {}

export default ActivityModel
