import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import { apiV1 } from 'core/requests'
import {
  EmbedReportModel,
  ReportEmbedParams,
  IEmbedReportSnapshotIn,
  IReportEmbedParamsSnapshotIn,
} from 'stores/models'

const PowerBIReportsStore = types
  .model('PowerBIReportsStore')
  .props({
    _reportsList: types.array(EmbedReportModel),
    _report: types.optional(ReportEmbedParams, {}),
    _isReportLoading: types.optional(types.boolean, false),
    _isReportsListLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setIsReportLoading: (isReportLoading: boolean) => {
      self._isReportLoading = isReportLoading
    },
    setIsReportsListLoading: (isReportsListLoading: boolean) => {
      self._isReportsListLoading = isReportsListLoading
    },
    setReportsList: (reportsList: Array<IEmbedReportSnapshotIn>): void => {
      applySnapshot(self._reportsList, reportsList)
    },
    setReport: (report: IReportEmbedParamsSnapshotIn): void => {
      applySnapshot(self._report, report)
    },
  }))
  .actions(self => {
    const POWERBI_REPORTS_API = '/api/dashboards'

    const getReportsList = async () => {
      const reportsListRequest = fromPromise(
        apiV1.get<Array<IEmbedReportSnapshotIn>>(
          `${POWERBI_REPORTS_API}/reports`,
        ),
      )

      when(() =>
        reportsListRequest.case({
          fulfilled: response => {
            self.setReportsList(response.data)
            return true
          },
        }),
      )
      return reportsListRequest
    }

    const getDashboardsList = async () => {
      const reportsListRequest = fromPromise(
        apiV1.get<Array<IEmbedReportSnapshotIn>>(
          `${POWERBI_REPORTS_API}/dashboards`,
        ),
      )

      when(() =>
        reportsListRequest.case({
          fulfilled: response => {
            const newData = response.data.map(dashboard => ({
              ...dashboard,
              type: 'Dashboard',
            }))
            self.setReportsList([...self._reportsList, ...newData])
            return true
          },
        }),
      )
      return reportsListRequest
    }

    const getReportById = async (reportId: string) => {
      const reportRequest = fromPromise(
          apiV1.get<IReportEmbedParamsSnapshotIn>(
              `${POWERBI_REPORTS_API}/report`,
              { params: { reportId } },
          ),
      )

      when(() =>
          reportRequest.case({
            fulfilled: response => {
              self.setReport(response.data)
              return true
            },
          }),
      )
      return reportRequest
    }

    const getReport = async (reportId: string, displayName: string) => {
      const reportRequest = fromPromise(
        apiV1.get<IReportEmbedParamsSnapshotIn>(
          `${POWERBI_REPORTS_API}/report/page`,
          { params: { reportId, displayName } },
        ),
      )

      when(() =>
        reportRequest.case({
          fulfilled: response => {
            self.setReport(response.data)
            return true
          },
        }),
      )
      return reportRequest
    }

    const getDashboard = async (dashboardId: string) => {
      const reportRequest = fromPromise(
        apiV1.get<IReportEmbedParamsSnapshotIn>(
          `${POWERBI_REPORTS_API}/dashboard`,
          { params: { dashboardId } },
        ),
      )

      when(() =>
        reportRequest.case({
          fulfilled: response => {
            self.setReport(response.data)
            return true
          },
        }),
      )
      return reportRequest
    }

    return {
      getReportsList,
      getDashboardsList,
      getDashboard,
      getReport,
      getReportById
    }
  })
  .views(self => ({
    get isReportLoading() {
      return self._isReportLoading
    },
    get isReportsListLoading() {
      return self._isReportsListLoading
    },
    get reportsList() {
      return getSnapshot(self._reportsList)
    },
    get report() {
      return getSnapshot(self._report)
    },
  }))

export default PowerBIReportsStore
export interface IPowerBIReportsStore
  extends Instance<typeof PowerBIReportsStore> {}
