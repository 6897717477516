import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import IndicatorReportsFilterBaseModel from '../IndicatorReportsFilterBaseModel'

const Indicator2_2_1ReportFilterModel = IndicatorReportsFilterBaseModel.named(
  'Indicator2_2_1ReportFilterModel',
).props({
  OrganizationTypeId: types.union(types.number, types.undefined, types.null),
  AdvocacyIssueId: types.union(types.number, types.undefined, types.null),
  AdvocacyInfluenceLevelId: types.union(
    types.number,
    types.undefined,
    types.null,
  ),
})

export interface IIndicator2_2_1ReportFilterModel
  extends Instance<typeof Indicator2_2_1ReportFilterModel> {}

export interface IIndicator2_2_1ReportFilterSnapshotIn
  extends SnapshotIn<typeof Indicator2_2_1ReportFilterModel> {}

export interface IIndicator2_2_1ReportFilterSnapshotOut
  extends SnapshotOut<typeof Indicator2_2_1ReportFilterModel> {}

export default Indicator2_2_1ReportFilterModel
