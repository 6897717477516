import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const BeneficiaryIndividualsFilterModel = types.model({
  organizationId: types.union(types.number, types.undefined, types.null),
  fullName: types.union(types.string, types.undefined, types.null),
  code: types.union(types.string, types.undefined, types.null),
  serviceCountryId: types.union(types.number, types.undefined, types.null),
  ethnicityId: types.union(types.number, types.undefined, types.null),
  genderId: types.union(types.number, types.undefined, types.null),
  memberTypeId: types.union(types.number, types.undefined, types.null),
  ageCategory: types.union(types.number, types.undefined, types.null),
})

export interface IBeneficiaryIndividualsFilterModel
  extends Instance<typeof BeneficiaryIndividualsFilterModel> {}

export interface IBeneficiaryIndividualsFilterSnapshotIn
  extends SnapshotIn<typeof BeneficiaryIndividualsFilterModel> {}

export interface IBeneficiaryIndividualsFilterSnapshotOut
  extends SnapshotOut<typeof BeneficiaryIndividualsFilterModel> {}

export default BeneficiaryIndividualsFilterModel
