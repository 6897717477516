import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const GenderLookUpModel = LookUpModel.named('GenderLookUpModel').props({
  isComment: types.boolean,
})

export interface IGenderLookUp extends Instance<typeof GenderLookUpModel> {}

export interface IGenderLookUpSnapshotIn
  extends SnapshotIn<typeof GenderLookUpModel> {}

export interface IGenderLookUpSnapshotOut
  extends SnapshotOut<typeof GenderLookUpModel> {}

export default GenderLookUpModel
