import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateActivityBeneficiaryIndividualsCommand = types.model(
  'UpdateActivityBeneficiaryIndividualsCommand',
  {
    id: types.integer,
    beneficiaryIndividualIds: types.array(types.number),
  },
)

export interface IUpdateActivityBeneficiaryIndividualsCommand
  extends Instance<typeof UpdateActivityBeneficiaryIndividualsCommand> {}

export interface IUpdateActivityBeneficiaryIndividualsCommandSnapshotIn
  extends SnapshotIn<typeof UpdateActivityBeneficiaryIndividualsCommand> {}

export interface IUpdateActivityBeneficiaryIndividualsCommandSnapshotOut
  extends SnapshotOut<typeof UpdateActivityBeneficiaryIndividualsCommand> {}

export default UpdateActivityBeneficiaryIndividualsCommand
