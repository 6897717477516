import { PermissionGroupName, PermissionActionName } from 'stores/models'
import { PropsWithChildren, ReactElement, ReactNode } from 'react'

type GuardProps<T extends { name: string }> = {
  allowedPermissions: Array<T>
  permissionName: PermissionActionName | PermissionGroupName | string
  fallback?: ReactNode
  extraCondition?: boolean
}

const Guard = <T extends { name: string }>({
  allowedPermissions,
  permissionName,
  fallback = null,
  extraCondition = true,
  children,
}: PropsWithChildren<GuardProps<T>>): ReactElement | null => {
  return (
    <>
      {extraCondition &&
      allowedPermissions.some(permission => permission.name === permissionName)
        ? children
        : fallback}
    </>
  )
}

export default Guard
