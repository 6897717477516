import { useCallback, useEffect, useState } from 'react'
import usePersistedState from './usePersistedState'

const useSortingState = (
  initialSortBy: string,
  initialOrderBy: 'asc' | 'desc' = 'asc',
) => {
  const [sorting, setSorting] = useState(
    () => `${initialSortBy}.${initialOrderBy}`,
  )

  const toggleSorting = (sortBy: string) => {
    setSorting(prevSorting => {
      const [prevSortBy, orderBy] = prevSorting.split('.')

      return sortBy === prevSortBy
        ? `${sortBy}.${orderBy === 'asc' ? 'desc' : 'asc'}`
        : `${sortBy}.${initialOrderBy}`
    })
  }

  useEffect(() => {}, [sorting])

  return [sorting, toggleSorting] as const
}

const usePersistedSorting = (
  persistName: string,
  storage: Storage,
  initialSortBy: string,
  initialOrderBy: 'asc' | 'desc' = 'asc',
) => {
  const [sorting, setSorting] = usePersistedState(
    persistName,
    `${initialSortBy}.${initialOrderBy}`,
    storage,
  )

  const toggleSorting = useCallback(
    (sortBy: string) => {
      setSorting(prevSorting => {
        const [prevSortBy, orderBy] = prevSorting.split('.')

        return sortBy === prevSortBy
          ? `${sortBy}.${orderBy === 'asc' ? 'desc' : 'asc'}`
          : `${sortBy}.${initialOrderBy}`
      })
    },
    [setSorting, initialOrderBy],
  )

  return [sorting, toggleSorting] as [
    `${string}.${'asc' | 'desc'}`,
    typeof toggleSorting,
  ]
}

export { useSortingState, usePersistedSorting }
