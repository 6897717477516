import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const ResetPasswordCommand = types.model('ResetPasswordCommand', {
  confirmPassword: types.string,
  password: types.string,
})

export interface IResetPasswordCommand
  extends Instance<typeof ResetPasswordCommand> {}

export interface IResetPasswordCommandSnapshotIn
  extends SnapshotIn<typeof ResetPasswordCommand> {}

export interface IResetPasswordCommandSnapshotOut
  extends SnapshotOut<typeof ResetPasswordCommand> {}

export default ResetPasswordCommand
