import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import IndicatorReportsFilterBaseModel from '../IndicatorReportsFilterBaseModel'

const Indicator1_2_1ReportFilterModel = IndicatorReportsFilterBaseModel.named(
  'Indicator1_2_1ReportFilterModel',
).props({
  OrganizationTypeId: types.union(types.number, types.undefined, types.null),
  ImprovementAreaId: types.union(types.number, types.undefined, types.null),
})

export interface IIndicator1_2_1ReportFilterModel
  extends Instance<typeof Indicator1_2_1ReportFilterModel> {}

export interface IIndicator1_2_1ReportFilterSnapshotIn
  extends SnapshotIn<typeof Indicator1_2_1ReportFilterModel> {}

export interface IIndicator1_2_1ReportFilterSnapshotOut
  extends SnapshotOut<typeof Indicator1_2_1ReportFilterModel> {}

export default Indicator1_2_1ReportFilterModel
