import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useStore } from 'stores/useStoreHook'
import styles from './UserInfo.module.scss'

const UserInfo: FC = () => {
  const authStore = useStore('authStore')
  return (
    <div className={styles.userInfo}>
      <div className={styles.avatar}>
        <span className={styles.defaultAvatar}>
          <i
            className="ms-Icon ms-Icon--Contact"
            style={{
              fontSize: '1.2rem',
              color: '#0078D4',
            }}
          />
        </span>
      </div>
      <h5 className={styles.fullName}>{authStore.userInfo.fullName}</h5>
      <p className={styles.userRole}>
        {!!authStore.userInfo.userRole && (
          <FormattedMessage
            id={authStore.userInfo.userRole}
            defaultMessage={authStore.userInfo.userRole}
          />
        )}
      </p>
    </div>
  )
}

export default UserInfo
