import { FC, useEffect, useState, forwardRef, MouseEventHandler } from 'react'
import { Dropdown } from 'react-bootstrap'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { ExportFilesType } from 'stores/models'
import { FormattedMessage, useIntl } from 'react-intl'
import ToolbarButton from '../ToolbarButton'
import { usePersistedState } from 'hooks'
import { fileTypeIcons } from '../FileInputButton/fileTypeIcons'
import './ExportDropdown.scss'
import { EXPORT_DROPDOWN_SELECTED_TYPE_KEY } from 'core/constants'

const ExportDropdownToggle = forwardRef<
  HTMLButtonElement,
  {
    onClick?: MouseEventHandler
    className?: string
    disabled?: boolean
  }
>(({ onClick = () => {}, className, disabled = false }, ref) => (
  <span className={className} ref={ref}>
    <ToolbarButton
      buttonClassName="mr-0"
      iconClassName="ms-Icon ms-Icon--ChevronDown mr-0"
      action={onClick}
      disabled={disabled}
    />
  </span>
))

export type ExportDropdownProps = {
  expotType?: ExportFilesType
  className?: string
  disabled?: boolean
  onExport: (
    type: ExportFilesType,
    onDownloadProgress: (progressEvent: ProgressEvent) => void,
  ) => void
}

const ExportDropdown: FC<ExportDropdownProps> = ({
  expotType,
  className = '',
  disabled = false,
  onExport,
}) => {
  const [selectedExportType, setSelectedExportType] = usePersistedState(
    EXPORT_DROPDOWN_SELECTED_TYPE_KEY,
    ExportFilesType.xlsx,
    localStorage,
  )
  const [downloadProgress, setDownloadProgress] = useState<number>(0)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  useEffect(() => {
    if (downloadProgress === 100) {
      setTimeout(() => {
        setIsDownloading(false)
        setDownloadProgress(0)
      }, 500)
    }
  }, [downloadProgress])

  const onExportHandle = (
    type: ExportFilesType,
    onDownloadProgress: (progressEvent: ProgressEvent) => void,
  ) => {
    onExport(type, onDownloadProgress)
    setSelectedExportType(type)
    setIsDownloading(true)
  }
  const intl = useIntl()

  const onDownloadProgress = (progressEvent: ProgressEvent) => {
    const { loaded, total } = progressEvent
    setDownloadProgress(Math.floor((loaded * 100) / total))
  }

  const renderExportTypeIcon = () =>
    fileTypeIcons.get(
      `.${
        selectedExportType === ExportFilesType.xlsx
          ? 'xlsx'
          : selectedExportType
      }`,
    )

  return (
    <Dropdown drop="down" className={`export-dropdown ${className}`}>
      <ToolbarButton
        action={() =>
          !isDownloading &&
          onExportHandle(selectedExportType, onDownloadProgress)
        }
        disabled={disabled}
      >
        {isDownloading ? (
          <div className="progress-bar-wrapper">
            <CircularProgressbarWithChildren value={downloadProgress}>
              <span className="icon-wrapper">{renderExportTypeIcon()}</span>
            </CircularProgressbarWithChildren>
          </div>
        ) : (
          renderExportTypeIcon()
        )}

        <span className="ml-2">
          <FormattedMessage id="export" />
        </span>
      </ToolbarButton>
      {!expotType && (
        <>
          <Dropdown.Toggle disabled={disabled} as={ExportDropdownToggle} />
          <Dropdown.Menu>
            {Object.values(ExportFilesType).map(exportType => (
              <Dropdown.Item
                key={exportType}
                onSelect={() =>
                  !isDownloading &&
                  onExportHandle(exportType, onDownloadProgress)
                }
              >
                {intl.formatMessage(
                  {
                    id: 'export_file',
                    defaultMessage: `как .${exportType} файл`,
                  },
                  {
                    type: `.${
                      exportType === ExportFilesType.xlsx ? 'xlsx' : exportType
                    }`,
                  },
                )}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </>
      )}
    </Dropdown>
  )
}

export default ExportDropdown
