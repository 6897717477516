import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateStaffCommand = types.model('UpdateStaffCommand', {
  id: types.identifierNumber,
  firstName: types.string,
  lastName: types.string,
  position: types.string,
  email: types.string,
})

export interface IUpdateStaffCommand
  extends Instance<typeof UpdateStaffCommand> {}
export interface IUpdateStaffCommandSnapshotIn
  extends SnapshotIn<typeof UpdateStaffCommand> {}
export interface IUpdateStaffCommandSnapshotOut
  extends SnapshotOut<typeof UpdateStaffCommand> {}
export default UpdateStaffCommand
