import { useState, FC } from 'react'
import { Form, FormControlProps } from 'react-bootstrap'
import styles from './Input.module.scss'
interface InputProps extends FormControlProps {
  errorMessage?: string | Array<string>
}

const PasswordInput: FC<InputProps> = ({ errorMessage, ...props }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <div className="position-relative">
      <Form.Control {...props} type={isVisible ? 'text' : 'password'} />
      <i
        className={[
          styles.passwordVisibilityToggle,
          errorMessage ? styles.validated : '',
          'lh-100 ms-Icon',
          isVisible ? 'ms-Icon--RedEye' : 'ms-Icon--Hide',
        ].join(' ')}
        style={{
          color: isVisible ? '#0078D4' : '#605E5C',
        }}
        onClick={event => {
          event.preventDefault()
          setIsVisible(!isVisible)
        }}
      />
      {errorMessage && (
        <Form.Text className="invalid-feedback">{errorMessage}</Form.Text>
      )}
    </div>
  )
}

export default PasswordInput
