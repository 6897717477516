import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import { when } from 'mobx'
import { apiV1 } from 'core/requests'
import {
  AddBeneficiaryOrganizationStaffCommand,
  BeneficiaryActivityLogLookUpDto,
  BeneficiaryActivitiesHistoryRequestModel,
  BeneficiaryOrganizationListRequestModel,
  BeneficiaryOrganizationModel,
  BeneficiaryOrganizationPagedDto,
  BeneficiaryOrganizationStaffDetailsModel,
  BeneficiaryOrganizationStaffWithNameModel,
  CreateBeneficiaryOrganizationCommand,
  IAddBeneficiaryOrganizationStaffCommandIn,
  IBeneficiaryActivityLogLookUpDtoIn,
  IBeneficiaryActivitiesHistoryRequestModelIn,
  IBeneficiaryOrganizationListRequestSnapshotIn,
  IBeneficiaryOrganizationModelIn,
  IBeneficiaryOrganizationModelOut,
  IBeneficiaryOrganizationPagedDtoSnapshotIn,
  IBeneficiaryOrganizationStaffDetailsSnapshotIn,
  IBeneficiaryOrganizationStaffWithNameSnapshotIn,
  ICreateBeneficiaryOrganizationCommandIn,
  IUpdateBeneficiaryOrganizationCommandIn,
  IUpdateBeneficiaryOrganizationStaffCommandIn,
  UpdateBeneficiaryOrganizationCommand,
  UpdateBeneficiaryOrganizationStaffCommand,
  ActivitiesParticipateLookUpModel,
  IActivitiesParticipateLookUpModelIn,
  IBeneficiaryOrganizationActivitiesParticipateRequestModelIn,
  BeneficiaryOrganizationActivitiesParticipateRequestModel,
  UpdateBeneficiaryOrganizationActivityStaffCommand,
  IUpdateBeneficiaryOrganizationActivityStaffCommandIn,
  IOrganizationActivityStaffRequestModelIn,
  OrganizationActivityStaffRequestModel,
  OrganizationStaffLookUpModel,
  IOrganizationStaffLookUpModelSnapshotIn,
} from 'stores/models'

const PartnersBeneficiaryOrganizationStore = types
  .model('PartnersStore')
  .props({
    _beneficiaryOrganizationsList: types.optional(
      BeneficiaryOrganizationPagedDto,
      {},
    ),
    _beneficiaryOrganizationDetails: types.optional(
      BeneficiaryOrganizationModel,
      {},
    ),
    _beneficiaryOrganizationStaff: types.array(
      BeneficiaryOrganizationStaffWithNameModel,
    ),
    _beneficiaryOrganizationStaffDetails: types.optional(
      BeneficiaryOrganizationStaffDetailsModel,
      {},
    ),
    _organizationActivityStaff: types.array(types.number),
    _activitiesCanParticipateList: types.array(
      ActivitiesParticipateLookUpModel,
    ),
    _beneficiaryOrganizationStaffLookUps: types.optional(
      types.array(OrganizationStaffLookUpModel),
      [],
    ),
    _activitiesHistory: types.array(BeneficiaryActivityLogLookUpDto),
    _isLoading: types.optional(types.boolean, false),
    _isDetailsLoading: types.optional(types.boolean, false),
    _isStaffLoading: types.optional(types.boolean, false),
    _isStaffDetailsLoading: types.optional(types.boolean, false),
    _isActivitiesHistoryLoading: types.optional(types.boolean, false),
    _isActivityStaffLoading: types.optional(types.boolean, false),
  })
  .actions(self => {
    const BENEFICIARY_ORGANIZATION_API = '/api/partners/beneficiaryorganization'

    const setBeneficiaryOrganizations = (
      beneficiaryOrganizations: IBeneficiaryOrganizationPagedDtoSnapshotIn,
    ): void => {
      applySnapshot(
        self._beneficiaryOrganizationsList,
        beneficiaryOrganizations,
      )
    }

    const setBeneficiaryOrganizationStaffLookUps = (
      beneficiaryOrganizationStaffLookUps: Array<IOrganizationStaffLookUpModelSnapshotIn>,
    ): void => {
      applySnapshot(
        self._beneficiaryOrganizationStaffLookUps,
        beneficiaryOrganizationStaffLookUps,
      )
    }

    const setBeneficiaryOrganizationDetails = (
      beneficiaryOrganizationDetails: IBeneficiaryOrganizationModelIn,
    ): void => {
      applySnapshot(
        self._beneficiaryOrganizationDetails,
        beneficiaryOrganizationDetails,
      )
    }

    const setBeneficiaryOrganizationStaff = (
      beneficiaryOrganizationStaff: Array<IBeneficiaryOrganizationStaffWithNameSnapshotIn>,
    ): void => {
      applySnapshot(
        self._beneficiaryOrganizationStaff,
        beneficiaryOrganizationStaff,
      )
    }

    const setActivitiesHistory = (
      activityHistory: Array<IBeneficiaryActivityLogLookUpDtoIn>,
    ): void => {
      applySnapshot(self._activitiesHistory, activityHistory)
    }

    const setActivitiesParticipateList = (
      activitiesList: Array<IActivitiesParticipateLookUpModelIn>,
    ): void => {
      applySnapshot(self._activitiesCanParticipateList, activitiesList)
    }

    const setBeneficiaryOrganizationStaffDetails = (
      beneficiaryOrganizationStaffDetails: IBeneficiaryOrganizationStaffDetailsSnapshotIn,
    ): void => {
      applySnapshot(
        self._beneficiaryOrganizationStaffDetails,
        beneficiaryOrganizationStaffDetails,
      )
    }

    const setOrganizationActivityStaff = (
      organizationActivityStaff: Array<number>,
    ): void => {
      applySnapshot(self._organizationActivityStaff, organizationActivityStaff)
    }

    const setIsLoading = (isLoading: boolean) => {
      self._isLoading = isLoading
    }
    const setIsDetailsLoading = (isLoading: boolean) => {
      self._isDetailsLoading = isLoading
    }
    const setIsStaffLoading = (isLoading: boolean) => {
      self._isStaffLoading = isLoading
    }
    const setIsActivityStaffLoading = (isLoading: boolean) => {
      self._isActivityStaffLoading = isLoading
    }
    const setIsStaffDetailsLoading = (isLoading: boolean) => {
      self._isStaffDetailsLoading = isLoading
    }
    const setIsActivitiesLoading = (isLoading: boolean) => {
      self._isActivitiesHistoryLoading = isLoading
    }

    const getBeneficiaryOrganizationsList = async (
      params: IBeneficiaryOrganizationListRequestSnapshotIn,
    ) => {
      const beneficiaryOrganizationsListRequest = fromPromise(
        apiV1.get<IBeneficiaryOrganizationPagedDtoSnapshotIn>(
          BENEFICIARY_ORGANIZATION_API,
          {
            params: BeneficiaryOrganizationListRequestModel.create(params),
          },
        ),
      )

      when(() =>
        beneficiaryOrganizationsListRequest.case({
          fulfilled: response => {
            setBeneficiaryOrganizations(response.data)
            return true
          },
        }),
      )
      return beneficiaryOrganizationsListRequest
    }

    const getBeneficiaryOrganizationDetailsById = async (
      beneficiaryOrganizationId: IBeneficiaryOrganizationModelOut['id'],
    ) => {
      const beneficiaryOrganizationDetailsRequest = fromPromise(
        apiV1.get<IBeneficiaryOrganizationModelIn>(
          `${BENEFICIARY_ORGANIZATION_API}/organizationbyid`,
          {
            params: { id: beneficiaryOrganizationId },
          },
        ),
      )

      when(() =>
        beneficiaryOrganizationDetailsRequest.case({
          fulfilled: response => {
            setBeneficiaryOrganizationDetails(response.data)
            return true
          },
        }),
      )
      return beneficiaryOrganizationDetailsRequest
    }

    const createBeneficiaryOrganization = async (
      beneficiaryOrganization: ICreateBeneficiaryOrganizationCommandIn,
    ): Promise<void> =>
      apiV1.post(
        BENEFICIARY_ORGANIZATION_API,
        CreateBeneficiaryOrganizationCommand.create(beneficiaryOrganization),
      )

    const editBeneficiaryOrganization = async (
      beneficiaryOrganization: IUpdateBeneficiaryOrganizationCommandIn,
      beneficiaryOrganizationId: IBeneficiaryOrganizationModelOut['id'],
    ): Promise<void> =>
      apiV1.put(
        BENEFICIARY_ORGANIZATION_API,
        UpdateBeneficiaryOrganizationCommand.create(beneficiaryOrganization),
        {
          params: { id: beneficiaryOrganizationId },
        },
      )

    const editBeneficiaryOrganizationActivities = async (
      activitiesParams: IUpdateBeneficiaryOrganizationActivityStaffCommandIn,
    ): Promise<void> =>
      apiV1.put(
        `${BENEFICIARY_ORGANIZATION_API}/activities/staff`,
        UpdateBeneficiaryOrganizationActivityStaffCommand.create(
          activitiesParams,
        ),
      )

    const getStaffListByBeneficiaryOrganizationId = async (
      beneficiaryOrganizationId: IBeneficiaryOrganizationModelOut['id'],
    ) => {
      const staffListRequest = fromPromise(
        apiV1.get<Array<IBeneficiaryOrganizationStaffWithNameSnapshotIn>>(
          `${BENEFICIARY_ORGANIZATION_API}/staff`,
          {
            params: { organizationId: beneficiaryOrganizationId },
          },
        ),
      )

      when(() =>
        staffListRequest.case({
          fulfilled: response => {
            setBeneficiaryOrganizationStaff(response.data)
            return true
          },
        }),
      )
      return staffListRequest
    }

    const getStaffLookUpsByBeneficiaryOrganizationId = async (
      beneficiaryOrganizationId: IBeneficiaryOrganizationModelOut['id'],
    ) => {
      const staffListRequest = fromPromise(
        apiV1.get<Array<IOrganizationStaffLookUpModelSnapshotIn>>(
          `${BENEFICIARY_ORGANIZATION_API}/staffbyorganizationid/withfullname`,
          {
            params: { organizationId: beneficiaryOrganizationId },
          },
        ),
      )

      when(() =>
        staffListRequest.case({
          fulfilled: response => {
            setBeneficiaryOrganizationStaffLookUps(response.data)
            return true
          },
        }),
      )
      return staffListRequest
    }

    const getStaffListWithNameByBeneficiaryOrganizationId = async (
      beneficiaryOrganizationId: IBeneficiaryOrganizationModelOut['id'],
    ) => {
      const staffListRequest = fromPromise(
        apiV1.get<Array<IBeneficiaryOrganizationStaffWithNameSnapshotIn>>(
          `${BENEFICIARY_ORGANIZATION_API}/staff/withfullname`,
          {
            params: { organizationId: beneficiaryOrganizationId },
          },
        ),
      )

      when(() =>
        staffListRequest.case({
          fulfilled: response => {
            setBeneficiaryOrganizationStaff(response.data)
            return true
          },
        }),
      )
      return staffListRequest
    }

    const getBeneficiaryOrganizationStaffDetailsById = async (
      beneficiaryOrganizationStaffId: IBeneficiaryOrganizationStaffWithNameSnapshotIn['id'],
    ) => {
      const staffDetailsRequest = fromPromise(
        apiV1.get<IBeneficiaryOrganizationStaffDetailsSnapshotIn>(
          `${BENEFICIARY_ORGANIZATION_API}/staffbyid`,
          {
            params: { id: beneficiaryOrganizationStaffId },
          },
        ),
      )

      when(() =>
        staffDetailsRequest.case({
          fulfilled: response => {
            setBeneficiaryOrganizationStaffDetails(response.data)
            return true
          },
        }),
      )
      return staffDetailsRequest
    }

    const getActivitiesHistory = async (
      params: IBeneficiaryActivitiesHistoryRequestModelIn,
    ) => {
      const activitiesHistoryRequest = fromPromise(
        apiV1.get<Array<IBeneficiaryActivityLogLookUpDtoIn>>(
          `${BENEFICIARY_ORGANIZATION_API}/activities/history`,
          {
            params: BeneficiaryActivitiesHistoryRequestModel.create(params),
          },
        ),
      )
      when(() =>
        activitiesHistoryRequest.case({
          fulfilled: response => {
            setActivitiesHistory(response.data)
            return true
          },
        }),
      )
      return activitiesHistoryRequest
    }

    const getActivitiesParticipate = async (
      params: IBeneficiaryOrganizationActivitiesParticipateRequestModelIn,
    ) => {
      const activitiesCanParticipateRequest = fromPromise(
        apiV1.get<Array<IActivitiesParticipateLookUpModelIn>>(
          `${BENEFICIARY_ORGANIZATION_API}/activities/list`,
          {
            params: BeneficiaryOrganizationActivitiesParticipateRequestModel.create(
              params,
            ),
          },
        ),
      )
      when(() =>
        activitiesCanParticipateRequest.case({
          fulfilled: response => {
            setActivitiesParticipateList(response.data)
            return true
          },
        }),
      )
      return activitiesCanParticipateRequest
    }

    const getOrganizationActivityStaff = async (
      params: IOrganizationActivityStaffRequestModelIn,
    ) => {
      const beneficiaryActivityStaffRequest = fromPromise(
        apiV1.get<Array<number>>(
          `${BENEFICIARY_ORGANIZATION_API}/activities/staff`,
          {
            params: OrganizationActivityStaffRequestModel.create(params),
          },
        ),
      )

      when(() =>
        beneficiaryActivityStaffRequest.case({
          fulfilled: response => {
            setOrganizationActivityStaff(response.data)
            return true
          },
        }),
      )
      return beneficiaryActivityStaffRequest
    }

    const createBeneficiaryOrganizationStaff = async (
      staff: IAddBeneficiaryOrganizationStaffCommandIn,
    ): Promise<void> =>
      apiV1.post(
        `${BENEFICIARY_ORGANIZATION_API}/staff`,
        AddBeneficiaryOrganizationStaffCommand.create(staff),
      )

    const editBeneficiaryOrganizationStaff = async (
      staff: IUpdateBeneficiaryOrganizationStaffCommandIn,
    ): Promise<void> =>
      apiV1.put(
        `${BENEFICIARY_ORGANIZATION_API}/staff`,
        UpdateBeneficiaryOrganizationStaffCommand.create(staff),
        {
          params: { id: staff.id },
        },
      )

    return {
      setBeneficiaryOrganizations,
      setBeneficiaryOrganizationStaffDetails,
      setBeneficiaryOrganizationStaff,
      setBeneficiaryOrganizationDetails,
      setBeneficiaryOrganizationStaffLookUps,
      getOrganizationActivityStaff,
      getActivitiesHistory,
      getActivitiesParticipate,
      getBeneficiaryOrganizationsList,
      getBeneficiaryOrganizationDetailsById,
      createBeneficiaryOrganization,
      editBeneficiaryOrganization,
      getStaffListByBeneficiaryOrganizationId,
      getStaffListWithNameByBeneficiaryOrganizationId,
      getBeneficiaryOrganizationStaffDetailsById,
      createBeneficiaryOrganizationStaff,
      editBeneficiaryOrganizationStaff,
      editBeneficiaryOrganizationActivities,
      setIsLoading,
      setIsDetailsLoading,
      setIsStaffLoading,
      setIsStaffDetailsLoading,
      setIsActivitiesLoading,
      setIsActivityStaffLoading,
      getStaffLookUpsByBeneficiaryOrganizationId,
    }
  })
  .views(self => ({
    get beneficiaryOrganizations() {
      return getSnapshot(self._beneficiaryOrganizationsList)
    },
    get activitiesHistory() {
      return getSnapshot(self._activitiesHistory)
    },
    get activitiesCanParticipateList() {
      return getSnapshot(self._activitiesCanParticipateList)
    },
    get organizationActivityStaff() {
      return getSnapshot(self._organizationActivityStaff)
    },
    get beneficiaryOrganizationsList() {
      return this.beneficiaryOrganizations.data
    },
    get beneficiaryOrganizationsMetadata() {
      return this.beneficiaryOrganizations.metadata
    },
    get beneficiaryOrganizationDetails() {
      return getSnapshot(self._beneficiaryOrganizationDetails)
    },
    get beneficiaryOrganizationStaff() {
      return getSnapshot(self._beneficiaryOrganizationStaff)
    },
    get beneficiaryOrganizationStaffDetails() {
      return getSnapshot(self._beneficiaryOrganizationStaffDetails)
    },
    get beneficiaryOrganizationStaffLookUps() {
      return getSnapshot(self._beneficiaryOrganizationStaffLookUps)
    },
    get isLoading() {
      return self._isLoading
    },
    get isDetailsLoading() {
      return self._isDetailsLoading
    },
    get isStaffLoading() {
      return self._isStaffLoading
    },
    get isStaffDetailsLoading() {
      return self._isStaffDetailsLoading
    },
    get isActivitiesHistoryLoading() {
      return self._isActivitiesHistoryLoading
    },
    get isActivityStaffLoading() {
      return self._isActivityStaffLoading
    },
  }))

export default PartnersBeneficiaryOrganizationStore

export interface IBeneficiaryOrganizationStore
  extends Instance<typeof PartnersBeneficiaryOrganizationStore> {}
