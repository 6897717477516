import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UpdateBeneficiaryOrganizationCommand = types.model(
  'UpdateBeneficiaryOrganizationCommand',
  {
    name: types.maybeNull(types.string),
    countryId: types.integer,
    countryComment: types.optional(types.string, ''),
    organizationTypeId: types.number,
    organizationTypeComment: types.maybeNull(types.string),
  },
)

export interface IUpdateBeneficiaryOrganizationCommand
  extends Instance<typeof UpdateBeneficiaryOrganizationCommand> {}

export interface IUpdateBeneficiaryOrganizationCommandIn
  extends SnapshotIn<typeof UpdateBeneficiaryOrganizationCommand> {}

export interface IUpdateBeneficiaryOrganizationCommandOut
  extends SnapshotOut<typeof UpdateBeneficiaryOrganizationCommand> {}

export default UpdateBeneficiaryOrganizationCommand
