import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'

const IndicatorReportsFilterBaseModel = types.model(
  'IndicatorReportsFilterBaseModel',
  {

    CountryId: types.union(types.number, types.undefined, types.null),
    PartnerId: types.union(types.number, types.undefined, types.null),
    ReportingQuarterId: types.union(types.number, types.undefined, types.null),
    // ReportingYear: types.union(types.number, types.undefined, types.null),
      ReportingYear:  types.union(types.number, types.string, types.undefined, types.null, types.array(types.number)),
      MonthId: types.union(types.number, types.undefined, types.null),
  },
)

export interface IIndicatorReportsFilterBaseModel
  extends Instance<typeof IndicatorReportsFilterBaseModel> {}

export interface IIndicatorReportsFilterBaseModelSnapshotIn
  extends SnapshotIn<typeof IndicatorReportsFilterBaseModel> {}

export interface IIndicatorReportsFilterBaseModelSnapshotOut
  extends SnapshotOut<typeof IndicatorReportsFilterBaseModel> {}

export default IndicatorReportsFilterBaseModel
