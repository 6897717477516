import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { FC, ReactNode } from 'react'
import { RouteConfigObject } from 'routes'
import styles from './NavHeader.module.scss'
import { Counter } from 'components/index'

type NavHeaderProps = {
  childRoutes: Array<
    RouteConfigObject | Omit<RouteConfigObject, 'component' | 'children'>
  >
  other?: ReactNode
  title?: ReactNode
}

const NavHeader: FC<NavHeaderProps> = ({ childRoutes, title, other }) => {
  return (
    <div className={styles['NavHeader']}>
      {!!title && <h4 className={styles['NavHeader__title']}>{title}</h4>}
      <div className={styles['NavHeader__links-container']}>
        {childRoutes.map(({ path, name, permissionName }) => (
          <NavLink
            to={path}
            className={styles['NavHeader__link']}
            activeClassName={styles['active']}
            key={name.id + path}
          >
            <FormattedMessage {...name} />
            <Counter
              permissionName={permissionName}
              className={styles.counter}
            />
          </NavLink>
        ))}
      </div>
      {!!other && <div className={styles['NavHeader__other']}>{other}</div>}
    </div>
  )
}

export default NavHeader
