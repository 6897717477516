import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const BeneficiaryOrganizationsFilterModel = types.model({
  organizationId: types.union(types.number, types.undefined, types.null),
  name: types.union(types.string, types.undefined, types.null),
  countryId: types.union(types.number, types.undefined, types.null),
  organizationTypeId: types.union(types.number, types.undefined, types.null),
})

export interface IBeneficiaryOrganizationsFilterModel
  extends Instance<typeof BeneficiaryOrganizationsFilterModel> {}

export interface IBeneficiaryOrganizationsFilterSnapshotIn
  extends SnapshotIn<typeof BeneficiaryOrganizationsFilterModel> {}

export interface IBeneficiaryOrganizationsFilterSnapshotOut
  extends SnapshotOut<typeof BeneficiaryOrganizationsFilterModel> {}

export default BeneficiaryOrganizationsFilterModel
