import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import CatalogModel from './CatalogModel'

const IndicatorModel = CatalogModel.named('IndicatorModel').props({
  number: types.maybeNull(types.string),
})

export interface IIndicator extends Instance<typeof IndicatorModel> {}

export interface IIndicatorSnapshotIn
  extends SnapshotIn<typeof IndicatorModel> {}

export interface IIndicatorSnapshotOut
  extends SnapshotOut<typeof IndicatorModel> {}

export default IndicatorModel
