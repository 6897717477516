import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PermissionGroupModel from './PermissionGroupModel'
import PermissionMenuName from './PermissionMenuNameEnum'

const PermissionMenuModel = types.model('PermissionMenuModel', {
  id: types.identifierNumber,
  name: types.union(
    types.enumeration<PermissionMenuName>(Object.values(PermissionMenuName)),
    types.string,
  ),
  permissionGroups: types.array(PermissionGroupModel),
})

export interface IPermissionMenuModel
  extends Instance<typeof PermissionMenuModel> {}

export interface IPermissionMenuSnapshotIn
  extends SnapshotIn<typeof PermissionMenuModel> {}

export interface IPermissionMenuSnapshotOut
  extends SnapshotOut<typeof PermissionMenuModel> {}

export default PermissionMenuModel
