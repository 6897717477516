import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const UserPermissionsModel = types.model('UserPermissionsModel', {
  userPermissionIds: types.array(types.number),
  userRolePermissionIds: types.array(types.number),
})

export interface IUserPermissionsModel
  extends Instance<typeof UserPermissionsModel> {}

export interface IUserPermissionsSnapshotIn
  extends SnapshotIn<typeof UserPermissionsModel> {}

export interface IUserPermissionsSnapshotOut
  extends SnapshotOut<typeof UserPermissionsModel> {}

export default UserPermissionsModel
