import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const AgeCategoriesLookUpModel = LookUpModel.named('AgeCategoriesLookUpModel')

export interface IAgeCategoriesLookUp
  extends Instance<typeof AgeCategoriesLookUpModel> {}
export interface IAgeCategoriesLookUpSnapshotIn
  extends SnapshotIn<typeof AgeCategoriesLookUpModel> {}
export interface IAgeCategoriesLookUpSnapshotOut
  extends SnapshotOut<typeof AgeCategoriesLookUpModel> {}
export default AgeCategoriesLookUpModel
