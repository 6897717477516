import { useEffect, useState } from 'react'
import Table from 'rc-table'
import 'react-resizable/css/styles.css'
import { ColumnType } from 'rc-table/lib/interface'
import { TableProps } from 'rc-table/lib/Table'
import './ResizableTable.scss'
import ResizableHeaderCell, {
  ResizableOptions,
  ResizableHeaderCellProps,
} from './ResizableHeaderCell'

interface ResizableColumn<RecordType>
  extends Omit<ColumnType<RecordType>, 'onHeaderCell'>,
    ResizableOptions {
  onHeaderCell?: (
    column: ResizableColumn<RecordType>,
    index?: number,
  ) => ResizableHeaderCellProps<RecordType>
}

type ResizableColumns<RecordType> = readonly ResizableColumn<RecordType>[]

interface ResizableTableProps<RecordType>
  extends Omit<TableProps<RecordType>, 'columns'> {
  persistColumnsSizesStorage?: Storage
  persistColumnsSizesKey?: string
  columns: ResizableColumns<RecordType>
}

const getStoredColumnSizes = (storage: Storage, key?: string) =>
  key ? JSON.parse(storage.getItem(key) || '[]') : []

const ResizableTable = <RecordType extends {}>({
  columns,
  persistColumnsSizesKey,
  persistColumnsSizesStorage = localStorage,
  ...tableProps
}: ResizableTableProps<RecordType>) => {
  const [tableColumns, setTableColumns] = useState<
    ResizableColumns<RecordType>
  >([])

  useEffect(() => {
    setTableColumns(
      columns.map((column, index) => {
        const columnsSizes = getStoredColumnSizes(
          persistColumnsSizesStorage,
          persistColumnsSizesKey,
        )
        return {
          ...column,
          width: columnsSizes[index]?.width || column.width,
          height: columnsSizes[index]?.height || column.height,
          onHeaderCell: (columnData, columnIndex) => {
            const columnsSizes = getStoredColumnSizes(
              persistColumnsSizesStorage,
              persistColumnsSizesKey,
            )

            return {
              ...(column.onHeaderCell
                ? column.onHeaderCell(columnData, columnIndex)
                : {}),
              minConstraints: columnData.minConstraints,
              maxConstraints: columnData.maxConstraints,
              axis: columnData.axis,
              width: columnsSizes[index]?.width || columnData.width,
              height: columnsSizes[index]?.height || columnData.height,
              onResize: (event, { size }) => {
                if (persistColumnsSizesKey) {
                  const nextColumnsSizes = getStoredColumnSizes(
                    persistColumnsSizesStorage,
                    persistColumnsSizesKey,
                  )
                  nextColumnsSizes[index] = {
                    width: size.width,
                    height: size.height,
                  }
                  persistColumnsSizesStorage.setItem(
                    persistColumnsSizesKey,
                    JSON.stringify(nextColumnsSizes),
                  )
                }
                setTableColumns(prevColumns => {
                  const nextColumns = [...prevColumns]
                  nextColumns[index] = {
                    ...prevColumns[index],
                    width: size.width,
                    height: size.height,
                  }
                  return nextColumns
                })
              },
            }
          },
        }
      }),
    )
  }, [columns, persistColumnsSizesKey, persistColumnsSizesStorage])

  return (
    <Table
      {...tableProps}
      components={{
        ...tableProps.components,
        header: {
          ...tableProps.components?.header,
          cell: ResizableHeaderCell,
        },
      }}
      columns={tableColumns}
    />
  )
}

export type { ResizableTableProps, ResizableColumn, ResizableColumns }
export default ResizableTable
