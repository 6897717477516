import { matchPath, NavLink, useLocation } from 'react-router-dom'
import { FormattedMessage, MessageDescriptor } from 'react-intl'
import './DynamicNavHeader.scss'
import {
  useRef,
  useState,
  useEffect,
  createRef,
  RefObject,
  ReactNode,
} from 'react'
import { Dropdown } from 'react-bootstrap'
import { RouteConfigObject } from 'routes'
import { useWindowResize } from 'hooks'
import { CustomDropdownToggle, Overlay } from 'components'
import { ReactElement } from 'react'
import { useCallback } from 'react'

type LinkObjectType = Pick<RouteConfigObject, 'path'> & {
  name: MessageDescriptor & { values?: Record<string, React.ReactNode> }
  description?: string
}

type DynamicNavHeaderProps<Link extends LinkObjectType> = {
  links: Array<Link>
  dropdownMenuChildren: ReactNode
}

type NavLinkObjectType<Link extends LinkObjectType> = {
  width: number
  hidden: boolean
  isActive: boolean
} & Link

const DynamicNavHeader = <P extends LinkObjectType>({
  links,
  dropdownMenuChildren,
}: DynamicNavHeaderProps<P>): ReactElement => {
  const [navLinks, setNavLinks] = useState<Array<NavLinkObjectType<P>>>([])
  const visibleLinksContainer = useRef<HTMLDivElement>(null)
  const visibleLinksRefs = useRef<Array<RefObject<HTMLAnchorElement>>>(
    links.map(() => createRef()),
  )
  const { pathname } = useLocation()

  const reduceNavLinks = useCallback(
    (navLinks: Array<NavLinkObjectType<P>>): Array<NavLinkObjectType<P>> =>
      navLinks
        .map((link, idx) => {
          const linkElement = visibleLinksRefs.current?.[idx]?.current
          return {
            ...link,
            width: linkElement?.offsetWidth
              ? linkElement.offsetWidth + 25
              : link.width,
          }
        })
        .reduce<{
          stopWidth: number
          passedLinks: Array<NavLinkObjectType<P>>
        }>(
          (accumulator, link, _idx, linksArray) => {
            const activeLinkWidth =
              linksArray.find(link => link.isActive)?.width || 0
            const isActiveReduced = accumulator.passedLinks.some(
              link => link.isActive,
            )

            const doesFit =
              (visibleLinksContainer.current?.offsetWidth || 0) + 23 >=
              accumulator.stopWidth +
                link.width +
                (!isActiveReduced ? activeLinkWidth : 0)

            const isPassed = link.isActive || doesFit

            return {
              stopWidth: !isPassed
                ? accumulator.stopWidth
                : accumulator.stopWidth + link.width,
              passedLinks: [
                ...accumulator.passedLinks,
                { ...link, hidden: !isPassed },
              ],
            }
          },
          { stopWidth: 0, passedLinks: [] },
        ).passedLinks,
    [],
  )

  useWindowResize(() => {
    setNavLinks(navLinks => reduceNavLinks(navLinks))
  })

  useEffect(() => {
    setNavLinks(
      links.map(link => ({
        ...link,
        width: 0,
        isActive: false,
        hidden: true,
      })),
    )
  }, [links])

  useEffect(() => {
    setNavLinks(navLinks =>
      reduceNavLinks(
        navLinks.map(link => ({
          ...link,
          isActive: !!matchPath(pathname, link.path),
        })),
      ),
    )
  }, [setNavLinks, pathname, reduceNavLinks])

  useEffect(() => {
    if (navLinks.some(link => link.width === 0))
      setNavLinks(navLinks => reduceNavLinks(navLinks))
  }, [setNavLinks, navLinks, reduceNavLinks])

  return (
    <div className="NavHeader">
      <div ref={visibleLinksContainer} className="NavHeader__links-container">
        {navLinks.map(({ path, name, hidden, description }, idx) => (
          <NavLink
            style={hidden ? { display: 'none' } : {}}
            ref={visibleLinksRefs.current[idx]}
            to={path}
            className="NavHeader__link"
            key={name.id + path}
          >
            <FormattedMessage {...name} />
            {description && (
              <Overlay text={description} popoverStyles={{ maxWidth: '500px' }}>
                <span className="ml-2 lh-100">
                  <i
                    className="ms-Icon ms-Icon--Info align-middle"
                    style={{ fontSize: '1.2rem', color: '#0078D4' }}
                  />
                </span>
              </Overlay>
            )}
          </NavLink>
        ))}
      </div>
      <div className="NavHeader__dropdown-container">
        <Dropdown drop="left">
          <Dropdown.Toggle
            as={CustomDropdownToggle}
            name={<FormattedMessage id="global.more" defaultMessage="Еще" />}
            className="dropdown-custom-toggle"
          />
          <Dropdown.Menu className="p-3">{dropdownMenuChildren}</Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

export default DynamicNavHeader
