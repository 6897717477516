import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const WinrockassistancerejectionreasonsLooksUpModel = LookUpModel.named(
  'WinrockassistancerejectionreasonsLooksUpModel',
).props({
    isComment: types.boolean,
})

export interface IWinrockassistancerejectionreasonsLooksUpModel
  extends Instance<typeof WinrockassistancerejectionreasonsLooksUpModel> {}

export interface IWinrockassistancerejectionreasonsLooksUpModelSnapshotIn
  extends SnapshotIn<typeof WinrockassistancerejectionreasonsLooksUpModel> {}

export interface IWinrockassistancerejectionreasonsLooksUpModelSnapshotOut
  extends SnapshotOut<typeof WinrockassistancerejectionreasonsLooksUpModel> {}

export default WinrockassistancerejectionreasonsLooksUpModel
