import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListRequestModel from '../../../PagedListRequestModel'
import Indicator3_2_2ReportFilterModel from './Indicator3_2_2ReportFilterModel'

const Indicator3_2_2ReportRequestModel = PagedListRequestModel.named(
  'Indicator3_2_2ReportRequestModel',
).props({
  sortBy: types.optional(types.string, ''),
  filter: types.optional(Indicator3_2_2ReportFilterModel, {}),
})

export interface IIndicator3_2_2ReportRequestModel
  extends Instance<typeof Indicator3_2_2ReportRequestModel> {}

export interface IIndicator3_2_2ReportRequestSnapshotIn
  extends SnapshotIn<typeof Indicator3_2_2ReportRequestModel> {}

export interface IIndicator3_2_2ReportRequestSnapshotOut
  extends SnapshotOut<typeof Indicator3_2_2ReportRequestModel> {}

export default Indicator3_2_2ReportRequestModel
