import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

const BeneficiaryOrganizationActivitiesParticipateRequestModel = types.model(
  'BeneficiaryOrganizationActivitiesParticipateRequestModel',
  {
    organizerId: types.number,
    sortBy: types.optional(types.string, ''),
  },
)

export interface IBeneficiaryOrganizationActivitiesParticipateRequestModel
  extends Instance<
    typeof BeneficiaryOrganizationActivitiesParticipateRequestModel
  > {}

export interface IBeneficiaryOrganizationActivitiesParticipateRequestModelIn
  extends SnapshotIn<
    typeof BeneficiaryOrganizationActivitiesParticipateRequestModel
  > {}

export interface IBeneficiaryOrganizationActivitiesParticipateRequestModelOut
  extends SnapshotOut<
    typeof BeneficiaryOrganizationActivitiesParticipateRequestModel
  > {}

export default BeneficiaryOrganizationActivitiesParticipateRequestModel
