import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import IndicatorFormRequestListModel from '../../indicators/indicatorForms/IndicatorFormRequestListModel'

const AllIndicatorsListRequestModel = IndicatorFormRequestListModel.named(
  'AllIndicatorsListRequestModel',
)

export interface IAllIndicatorsListRequestModel
  extends Instance<typeof AllIndicatorsListRequestModel> {}

export interface IAllIndicatorsListRequestSnapshotIn
  extends SnapshotIn<typeof AllIndicatorsListRequestModel> {}

export interface IAllIndicatorsListRequestSnapshotOut
  extends SnapshotOut<typeof AllIndicatorsListRequestModel> {}

export default AllIndicatorsListRequestModel
