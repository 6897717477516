import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const AdvocacyInfluenceLevelLookUpModel = LookUpModel.named(
  'AdvocacyInfluenceLevelLookUpModel',
).props({
  isComment: types.boolean,
})

export interface IAdvocacyInfluenceLevelLookUpModel
  extends Instance<typeof AdvocacyInfluenceLevelLookUpModel> {}
export interface IAdvocacyInfluenceLevelLookUpSnapshotIn
  extends SnapshotIn<typeof AdvocacyInfluenceLevelLookUpModel> {}
export interface IAdvocacyInfluenceLevelLookUpSnapshotOut
  extends SnapshotOut<typeof AdvocacyInfluenceLevelLookUpModel> {}
export default AdvocacyInfluenceLevelLookUpModel
