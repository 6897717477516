import { useEffect } from 'react'

const useWindowResize = (listener: EventListener): void => {
  useEffect(() => {
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [listener])
}

export default useWindowResize
