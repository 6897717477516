import { Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const BeneficiaryOrganizationLookUpModel = LookUpModel.named(
  'BeneficiaryOrganizationLookUpModel',
)

export interface IBeneficiaryOrganizationLookUpModel
  extends Instance<typeof BeneficiaryOrganizationLookUpModel> {}

export interface IBeneficiaryOrganizationLookUpSnapshotIn
  extends SnapshotIn<typeof BeneficiaryOrganizationLookUpModel> {}

export interface IBeneficiaryOrganizationLookUpSnapshotOut
  extends SnapshotOut<typeof BeneficiaryOrganizationLookUpModel> {}

export default BeneficiaryOrganizationLookUpModel
