import { IntlProvider } from 'react-intl'
import { observer } from 'mobx-react'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import localeRu from 'date-fns/locale/ru'
import localeEn from 'date-fns/locale/en-US'
import langRu from './translates/ru.json'
import langEn from './translates/en.json'
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react'
import { useStore } from 'stores/useStoreHook'
const messages = {
  ru: langRu,
  en: langEn,
}

registerLocale('ru', localeRu)
registerLocale('en', localeEn)

const IntlContext = createContext({
  switchToEnglish: (): void => {},
  switchToRussian: (): void => {},
})

const IntlProviderWrapper: FC<PropsWithChildren<{}>> = observer(
  ({ children }) => {
    const appStore = useStore('appStore')

    useEffect(() => {
      setDefaultLocale(appStore.currentLanguage)
    }, [appStore.currentLanguage])

    const switchToEnglish = (): void => {
      appStore.setLanguage('en')
    }

    const switchToRussian = (): void => {
      appStore.setLanguage('ru')
    }

    const lang = appStore.currentLanguage || 'ru'

    return (
      <IntlContext.Provider value={{ switchToEnglish, switchToRussian }}>
        <IntlProvider
          locale={lang}
          messages={messages[lang]}
          defaultLocale={lang}
        >
          {children}
        </IntlProvider>
      </IntlContext.Provider>
    )
  },
)

const useIntlContext = () => useContext(IntlContext)

export { IntlContext, useIntlContext }
export default IntlProviderWrapper
