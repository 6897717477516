import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import LookUpModel from './LookUpModel'

const LegalEntityTypeLookUpModel = LookUpModel.named(
  'LegalEntityTypeLookUpModel',
).props({
  isComment: types.boolean,
})

export interface ILegalEntityTypeLookUpModel
  extends Instance<typeof LegalEntityTypeLookUpModel> {}
export interface ILegalEntityTypeLookUpSnapshotIn
  extends SnapshotIn<typeof LegalEntityTypeLookUpModel> {}
export interface ILegalEntityTypeLookUpSnapshotOut
  extends SnapshotOut<typeof LegalEntityTypeLookUpModel> {}
export default LegalEntityTypeLookUpModel
