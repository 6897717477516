enum RoleName {
  Administrator = 'Administrator',
  ChiefOfParty = 'ChiefOfParty',
  WinrockHOStaff = 'WinrockHOStaff',
  USAIDStaff = 'USAIDStaff',
  RegionalMELManager = 'RegionalMELManager',
  CountryCoordinator = 'CountryCoordinator',
  ProjectAssociate = 'ProjectAssociate',
  GranteeStaff = 'GranteeStaff',
}

export default RoleName
