import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'
import PagedListMetaData from 'stores/models/PagedListMetaData'
import ActivityBeneficiaryIndividualWithFullNameModel from './ActivityBeneficiaryIndividualWithFullNameModel'

const ActivityBeneficiaryIndividualsPagedDto = types.model(
  'ActivityBeneficiaryIndividualsPagedDto',
  {
    data: types.array(ActivityBeneficiaryIndividualWithFullNameModel),
    metadata: types.optional(PagedListMetaData, {}),
  },
)

export interface IActivityBeneficiaryIndividualsPagedDto
  extends Instance<typeof ActivityBeneficiaryIndividualsPagedDto> {}

export interface IActivityBeneficiaryIndividualsPagedDtoSnapshotIn
  extends SnapshotIn<typeof ActivityBeneficiaryIndividualsPagedDto> {}

export interface IActivityBeneficiaryIndividualsPagedDtoSnapshotOut
  extends SnapshotOut<typeof ActivityBeneficiaryIndividualsPagedDto> {}

export default ActivityBeneficiaryIndividualsPagedDto
